.ontheshow-video-list-item-image {
  flex-shrink: 0;
  height: 120px;
  margin: 0 20px 0 0;
  position: relative;
  width: 120px;

  @include breakpoint-l-only {
    height: 80px;
    width: 80px;
  }
}

.ontheshow-video-list-item-image__tease-image {
  border-radius: 50%;
  display: block;
  overflow: auto;
}
