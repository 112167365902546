$waffle-badge-background-color: $default-color !default;
$waffle-badge-bottom-font-family: $founders-semibold-font-family !default;
$waffle-badge-bottom-font-style: normal !default;

@include override-tokens-for("news") {
  $waffle-badge-background-color: $blue-40 !global;
}

@include override-tokens-for("noticias") {
  $waffle-badge-background-color: $blue-60 !global;
}

@include override-tokens-for("leftfield") {
  $waffle-badge-background-color: $grey-50 !global;
}

@include override-tokens-for("globalcitizen") {
  $waffle-badge-background-color: $red-40 !global;
}

@include override-tokens-for("today") {
  $waffle-badge-bottom-font-family: $publico-light-font-family !global;
  $waffle-badge-bottom-font-style: italic !global;
}

@include override-tokens-for("think") {
  $waffle-badge-bottom-font-family: $publico-light-font-family !global;
  $waffle-badge-bottom-font-style: italic !global;
}

@include override-tokens-for("knowyourvalue") {
  $waffle-badge-bottom-font-family: $publico-light-font-family !global;
  $waffle-badge-bottom-font-style: italic !global;
}
