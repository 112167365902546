// on lg and xl tease is shifted down 48px (3rem)
.big-headline-tease {
  @include breakpoint-l {
    top: $big-headline-tease-top-offset;
  }
}

.big-headline-tease__play-btn {
  width: rem(40px);
  height: rem(40px);
  font-size: rem(12px);
  background-color: $big-headline-tease-play-button-background-color;

  .bigHeadline--breaking & {
    background-color: $big-headline-tease-play-button-background-color--breaking;
  }
}

.big-headline-tease__picture {
  z-index: 0;
}
