@mixin cover-spread-tease-row-format-mixin {
  width: 100%;
  display: flex;
  flex-direction: row;

  .cover-spread-tease__image {
    margin-right: $space-16;
    margin-bottom: $space-16;
    flex-shrink: 0;
  }
}

@mixin cover-spread-tease-no-top-border {
  .cover-spread-tease--0 {
    border-top: 0;
    padding-top: 0;
  }
}

// define css for all layouts below
// live blog, 5 slot, 6 slot, 7 slot, 8 slot and 9 slot
.cs-liveblog-layout {
  // 2 UP Tease Layout ******
  &--2-teases {
    @include breakpoint-s-only {
      .cover-spread-tease--1 {
        display: flex;

        .cover-spread-tease__image {
          margin-right: $space-16;
          flex-shrink: 0;
        }
      }
    }

    @include breakpoint-m-to-l-only {
      display: flex;
      margin-left: -$space-16;
      margin-right: -$space-16;

      .cover-spread-tease {
        margin: 0 $space-16;
      }
    }

    @include breakpoint-m-only {
      .cover-spread-tease--0 {
        display: flex;
        width: 75%;

        .cover-spread-tease__image {
          max-width: $space-152;
          margin-right: $space-16;
          flex-shrink: 0;
        }
      }

      .cover-spread-tease--1 {
        width: 25%;
      }
    }

    @include breakpoint-l-only {
      .cover-spread-tease--0,
      .cover-spread-tease--1 {
        width: 50%;
        display: flex;

        .cover-spread-tease__image {
          max-width: 200px;
          margin-right: $space-16;
          flex-shrink: 0;
        }
      }
    }
  }

  // 3 UP Tease Layout ******
  &--3-teases {
    @include breakpoint-s-only {
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        display: flex;

        .cover-spread-tease__image {
          margin-right: $space-16;
          flex-shrink: 0;
        }
      }
    }

    @include breakpoint-m-to-l-only {
      display: flex;
      margin-left: -$space-16;
      margin-right: -$space-16;

      .cover-spread-tease {
        margin: 0 $space-16;
        width: 100%;
      }
    }

    @include breakpoint-l {
      .cover-spread-tease--0 {
        width: 50%;
        display: flex;

        .cover-spread-tease__image {
          margin-right: $space-16;
          max-width: 200px;
          flex-shrink: 0;
        }
      }

      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        width: 25%;
      }
    }

    @include breakpoint-x {
      .cover-spread-tease {
        display: flex;
        width: 100%;

        .cover-spread-tease__image {
          margin-right: $space-16;
          flex-shrink: 0;
        }
      }
    }
  }

  // 4 UP Tease Layout ******
  &--4-teases {
    @include breakpoint-s-only {
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        display: flex;

        .cover-spread-tease__image {
          margin-right: $space-16;
          flex-shrink: 0;
        }
      }
    }

    @include breakpoint-m-to-l-only {
      @include clearfix;
      margin-left: -$space-16;
      margin-right: -$space-16;

      .cover-spread-tease {
        float: left;
        margin: 0 $space-16;
      }
    }

    @include breakpoint-m-only {
      .cover-spread-tease {
        width: calc(33% - 32px);
      }

      .cover-spread-tease--2,
      .cover-spread-tease--3 {
        float: right;
      }
    }

    @include breakpoint-l-only {
      .cover-spread-tease {
        width: calc(25% - #{$space-32});
      }
    }

    @include breakpoint-x {
      $video-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: $video-height;

      .cover-spread-tease:last-child .cover-spread-tease__headline {
        margin-bottom: 0;
      }
    }
  }
}

.cs-5-slot-layout {
  &__column--1 {
    @include breakpoint-s-only {
      .cover-spread-tease--1 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      @include cover-spread-tease-no-top-border;
    }
  }

  &__column--2 {
    @include breakpoint-s-only {
      .cover-spread-tease--2 {
        border-top: solid $space-1 $cs-tease-border-color;
        padding-top: $space-16;
      }

      .cover-spread-tease--3 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      display: flex;
      flex-direction: row;

      .cover-spread-tease--2,
      .cover-spread-tease--3 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-only {
      .cover-spread-tease--2 {
        margin-right: $space-32;
      }
    }

    @include breakpoint-l-only {
      .cover-spread-tease--2 {
        margin-right: $space-40;
      }
    }
  }
}

.cs-6-slot-layout {
  &__column--1 {
    @include breakpoint-s-only {
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      @include cover-spread-tease-no-top-border;

      .cover-spread-tease--0,
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__column--2 {
    @include breakpoint-s-only {
      .cover-spread-tease--2 {
        border-top: solid $space-1 $cs-tease-border-color;
        padding-top: $space-16;
      }

      .cover-spread-tease--3,
      .cover-spread-tease--4 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      display: flex;
      flex-direction: row;

      .cover-spread-tease--2,
      .cover-spread-tease--3,
      .cover-spread-tease--4 {
        width: 50%;
        display: flex;
        flex-direction: column;
      }
    }

    @include breakpoint-m-only {
      .cover-spread-tease--2,
      .cover-spread-tease--3 {
        margin-right: $space-32;
      }
    }

    @include breakpoint-l-only {
      .cover-spread-tease--2,
      .cover-spread-tease--3 {
        margin-right: $space-40;
      }
    }

    @include breakpoint-x {
      .cover-spread-tease--2,
      .cover-spread-tease--3,
      .cover-spread-tease--4 {
        @include cover-spread-tease-row-format-mixin;
      }
    }
  }
}

.cs-7-slot-layout {
  &__column--1 {
    @include breakpoint-s-only {
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      @include cover-spread-tease-no-top-border;

      .cover-spread-tease--0,
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-x {
      .cover-spread-tease--0,
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }
  }

  &__column--2 {
    @include breakpoint-s-only {
      .cover-spread-tease--3 {
        border-top: solid $space-1 $cs-tease-border-color;
        padding-top: $space-16;
      }

      .cover-spread-tease--4,
      .cover-spread-tease--5 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      display: flex;
      flex-direction: row;

      .cover-spread-tease--3,
      .cover-spread-tease--4,
      .cover-spread-tease--5 {
        display: flex;
        flex-direction: column;
        width: 33%;
      }
    }

    @include breakpoint-m-only {
      .cover-spread-tease--3,
      .cover-spread-tease--4 {
        margin-right: $space-32;
      }
    }

    @include breakpoint-l-only {
      .cover-spread-tease--3,
      .cover-spread-tease--4 {
        margin-right: $space-40;
      }
    }

    @include breakpoint-x {
      .cover-spread-tease--3,
      .cover-spread-tease--4,
      .cover-spread-tease--5 {
        @include cover-spread-tease-row-format-mixin;
      }
    }
  }
}

.cs-8-slot-layout {
  &__column--1 {
    @include breakpoint-s-only {
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      @include cover-spread-tease-no-top-border;

      .cover-spread-tease--0,
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-x {
      .cover-spread-tease--0,
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }
  }

  &__column--2 {
    @include breakpoint-s-only {
      .cover-spread-tease--3 {
        border-top: solid $space-1 $cs-tease-border-color;
        padding-top: $space-16;
      }

      .cover-spread-tease--4,
      .cover-spread-tease--5 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      display: flex;
      flex-direction: row;

      .cover-spread-tease--3,
      .cover-spread-tease--4,
      .cover-spread-tease--5,
      .cover-spread-tease--6 {
        display: flex;
        flex-direction: column;
        width: 25%;
      }
    }

    @include breakpoint-m-only {
      .cover-spread-tease--3,
      .cover-spread-tease--4,
      .cover-spread-tease--5 {
        margin-right: $space-32;
      }
    }

    @include breakpoint-l-only {
      .cover-spread-tease--3,
      .cover-spread-tease--4,
      .cover-spread-tease--5 {
        margin-right: $space-40;
      }
    }

    @include breakpoint-x {
      .cover-spread-tease--3,
      .cover-spread-tease--4,
      .cover-spread-tease--5,
      .cover-spread-tease--6 {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.cs-9-slot-layout {
  &__column--1 {
    @include breakpoint-s-only {
      .cover-spread-tease--1,
      .cover-spread-tease--2 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      @include cover-spread-tease-no-top-border;
    }

    @include breakpoint-x {
      .cover-spread-tease--0,
      .cover-spread-tease--1,
      .cover-spread-tease--2,
      .cover-spread-tease--3 {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
    }
  }

  &__column--2 {
    @include breakpoint-s-only {
      .cover-spread-tease--4 {
        border-top: solid $space-1 $cs-tease-border-color;
        padding-top: $space-16;
      }

      .cover-spread-tease--5,
      .cover-spread-tease--6 {
        @include cover-spread-tease-row-format-mixin;
      }
    }

    @include breakpoint-m-to-l-only {
      display: flex;
      flex-direction: row;

      .cover-spread-tease--4,
      .cover-spread-tease--5,
      .cover-spread-tease--6,
      .cover-spread-tease--7 {
        display: flex;
        flex-direction: column;
        width: 25%;
      }
    }

    @include breakpoint-m-only {
      .cover-spread-tease--4,
      .cover-spread-tease--5,
      .cover-spread-tease--6 {
        margin-right: $space-32;
      }
    }

    @include breakpoint-l-only {
      .cover-spread-tease--4,
      .cover-spread-tease--5,
      .cover-spread-tease--6 {
        margin-right: $space-40;
      }
    }

    @include breakpoint-x {
      .cover-spread-tease--4,
      .cover-spread-tease--5,
      .cover-spread-tease--6,
      .cover-spread-tease--7 {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
    }
  }
}
