$straight-up-inner-margin-top: auto !default;
$straight-up-inner-display: none !default;
$straight-up-live-background-color: transparent !default;
$straight-up-inner-before-background-color: $default-color !default;

@include override-tokens-for("news") {
  $straight-up-inner-margin-top: rem(5px) !global;
  $straight-up-inner-display: block !global;
  $straight-up-live-background-color: $red-30 !global;
}

@include override-tokens-for("today") {
  $straight-up-inner-margin-top: rem(5px) !global;
  $straight-up-inner-display: block !global;
}

@include override-tokens-for("msnbc") {
  $straight-up-inner-margin-top: rem(5px) !global;
  $straight-up-inner-display: block !global;
  $straight-up-inner-before-background-color: $msnbc-yellow-40 !global;
  $straight-up-live-background-color: $red-30 !global;
}

@include override-tokens-for("telemundo") {
  $straight-up-inner-margin-top: rem(5px) !global;
  $straight-up-inner-display: block !global;
}

@include override-tokens-for("noticias") {
  $straight-up-inner-margin-top: rem(5px) !global;
  $straight-up-inner-display: block !global;
}

@include override-tokens-for("telemundoentretenimiento") {
  $straight-up-inner-margin-top: rem(5px) !global;
  $straight-up-inner-display: block !global;
}

@include override-tokens-for("telemundoshows") {
  $straight-up-inner-margin-top: rem(5px) !global;
  $straight-up-inner-display: block !global;
}
