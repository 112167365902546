.tease-card__picture-link {
  position: relative;
  display: block;
}

.tease-card__content {
  position: relative;
  z-index: 1;
  display: block;

  @include breakpoint-m {
    width: 100%;
    display: inline-block;
  }

  &.tease-card__title--is-paid-content {
    background: $grey-70;
    padding-bottom: 0;
    // This is getting overrides on a package level
    // We need an exception for sponsored content
    margin-bottom: rem(48px) !important;
  }

  &.tease-card--no-main-image {
    height: auto;
    background-color: transparent;

    @include breakpoint-m {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    & .tease-card__picture-link {
      position: absolute;
      z-index: 2;
      background-color: transparent;
    }
  }
}

.tease-card__info {
  background-color: $tease-card-info-background-color;
  position: relative;
  display: block;
  width: 100%;
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;

  @include breakpoint-m {
    width: 100%;
  }

  .tease-card__title--is-paid-content & {
    background: $grey-70;
  }

  .tease-card--no-main-image & {
    background: $beige;
    flex: 1 0 auto;
    justify-content: center;
    line-height: 22px;
    margin: rem(20px 0 0 20px);
    min-height: rem(200px);
    padding: rem(16px 20px 40px 20px);
    position: relative;
    width: calc(100% - #{2 * $tease-card-outer-gutter});

    @include breakpoint-m {
      width: calc(100% - #{$tease-card-outer-gutter});
      padding-right: rem(19px);
    }

    &::after {
      border: 1px solid  $grey-40;
      position: absolute;
      height: 100%;
      width: 100%;
      top: -20px;
      left: -20px;
      display: block;
      content: ' ';
      pointer-events: none;
    }
  }

  .tease-card__has-featured-author.tease-card--no-main-image & {
    padding-bottom: rem(19px);
  }
}

.tease-card__featured-author {
  font-family: $tease-card-featured-author-font-family;

  @include responsive-variations(font-size, $tease-card-featured-author-font-sizes);
  @include responsive-variations(line-height, $tease-card-featured-author-line-heights);
  font-weight: normal;
  color: $tease-card-featured-author-color;
}

.tease-card__title {
  @include responsive-variations(font-size, $tease-card-title-font-sizes);
  @include responsive-variations(line-height, $tease-card-title-line-heights);
  color: $tease-card-title-color;
  font-family: $tease-card-title-font-family;
  font-weight: normal;
  flex-basis: 50%;
  flex-grow: 1;
  margin: 0;

  .tease-card--no-main-image & {
    flex-basis: auto;
    flex-grow: 0;
    margin-right: rem(20px);
    padding-top: rem(40px);

    @include breakpoint-m {
      padding-top: rem(46px);
    }

    @include breakpoint-x {
      padding-top: rem(68px);
    }
  }

  .tease-card__headline {
    @include style-only-for('today') {
      color: $purple-70;
    }

    &.tease-card__headline--is-paid-content {
      color: $white;
    }
  }

  .tease-card__title--is-paid-content & {
    .tease-card__headline {
      color: $white;
    }

    .tease-card__paid-sponsor {
      margin-top: rem(10px);
    }
  }
}

.tease-card__dek {
  .tease-card--no-main-image &,
  .tease-card__has-featured-author & {
    flex-basis: auto;
    flex-grow: 0;
  }

  .tease-card__title--is-paid-content & {
    color: $grey-30;
  }
}

.tease-card__live {
  display: flex;
  vertical-align: center;
  flex-basis: 100%;
}

.tease-card__live-icon {
  svg {
    padding: rem(0.5 0.5 0.5 0.5);
  }
  float: left;
  clear: both;
}

.tease-card__live-text {
  color: $white;

  @include breakpoint-m {
    color: $black;
  }

  @include breakpoint-l {
    color: $white;
  }
  font-family: $founders-semibold-font-family;
  font-size: rem(14px);
  letter-spacing: rem(0.5px);
  font-weight: bold;
  line-height: 1.17;
  float: left;
  clear: both;
  padding-top: rem(4px);
  margin-left: rem(10px);
}

.tease-card__byline {
  flex-basis: 100%;

  .tease-card--no-main-image & {
    flex-basis: auto;
  }
}

.tease-card__author {
  color: $black;

  .tease-card__title--is-paid-content & {
    color: $grey-30;
  }
}

.tease-card__date {
  &::before {
    content: ' ';
  }

  &:nth-child(2) {
    &::before {
      .tease-card__title--is-paid-content & {
        color: $grey-30;
      }
    }
  }
}

.tease-card__featured-author-image-container {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tease-card__featured-author-image {
  flex-shrink: 0;

  img {
    display: block;
    height: rem(110px);
    filter: grayscale(100%);
    width: auto;

    @include breakpoint-m {
      max-width: 100%;
    }
  }
}

.tease-card__quote {
  color: $tease-card-quote-color;
  display: block;
  margin: rem(20px 0);
}

.tease-card__paid-content-sponsor {
  font-family: $founders-mono-font-family;
  color: $grey-50;
  display: inherit;
  font-size: 12px;
  line-height: 12px;
  padding-bottom: 20px;
  padding-top: 8px;
}

// Paid content tease
.tease-card__content .ad {
  h2.plr_article:not(.unibrow) {
    @include responsive-variations(font-size, $tease-card-title-font-sizes);
    @include responsive-variations(line-height, $tease-card-title-line-heights);
    color: $tease-card-title-color;
    font-family: $tease-card-title-font-family;
    font-weight: normal;
    margin: 0;
  }
}
