.ontheshow__video-list-header {
  margin-bottom: 30px;
}

.ontheshow__video-list-header-heading {
  color: $ontheshow-video-list-header-heading-color;
  font-family: $publico-hed;
  font-size: $text-32;
  font-style: italic;
  font-weight: normal;
  line-height: 36px;
  margin-bottom: 5px;

  @include breakpoint-l {
    font-size: $text-24;
  }
}

.ontheshow__video-list-header-description {
  color: $grey-60;
  font-family: $publico-hed;
  font-size: $text-14;
  line-height: 18px;
  margin: 5px 0;
}

.ontheshow__video-list-header-link,
.ontheshow__video-list-header-link > * {
  transition: opacity 0.25s ease-in-out;
}

.ontheshow__video-list-header-link:hover,
.ontheshow__video-list-header-link:hover > * {
  opacity: .7;
}
