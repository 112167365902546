$mosaic-content-title-width: auto !default;
$mosaic-content-title-height: auto !default;
$mosaic-content-title-bg-color: $default-color !default;
$mosaic-border-top-height: auto !default;
$mosaic-border-top-width: auto !default;
$mosaic-border-top-bg-color: $default-color !default;

$mosaic-tease-card-title-font-size: (s: rem($text-24), m: rem(30px), l: rem($text-24), x: rem(30px)) !default;
$mosaic-tease-card-title-line-height: (s: rem(25px), m: rem(30px), l: rem(25px), x: rem(30px)) !default;
$mosaic-tease-card-title-font-size--non-lead: rem($text-24) !default;
$tease-card-content-first-child-top: 0 !default;

@include override-tokens-for('msnbc') {
  $mosaic-content-title-width: 100% !global;
  $mosaic-content-title-height: rem(5px) !global;
}

@include override-tokens-for('noticias') {
  $mosaic-border-top-height: 5px !global;
  $mosaic-border-top-width: 100% !global;
}

@include override-tokens-for('msnbc') {
  $tease-card-content-first-child-top: rem(5px) !global;
}

@include override-tokens-for("today") {
  $mosaic-tease-card-title-font-size: (s: rem($text-18), m: rem(22.5px), l: rem($text-18), x: rem(22.5)) !global;
  $mosaic-tease-card-title-font-size--non-lead: rem($text-18) !global;
}

@include override-tokens-for("think") {
  $mosaic-tease-card-title-font-size: (s: rem($text-18), m: rem(22.5px), l: rem($text-18), x: rem(22.5)) !global;
  $mosaic-tease-card-title-font-size--non-lead: rem($text-18) !global;
}

@include override-tokens-for("knowyourvalue") {
  $mosaic-tease-card-title-font-size: (s: rem($text-18), m: rem(22.5px), l: rem($text-18), x: rem(22.5)) !global;
  $mosaic-tease-card-title-font-size--non-lead: rem($text-18) !global;
}
