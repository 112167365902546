$related-title-color: (s: $default-color, m: $white) !default;
$related-title-background-color: $default-color !default;
$related-title-background-max-height: $space-112 !default;
$related-title-font-family: $default-headline-font-family !default;
$related-title-font-style: normal !default;
$related-title-leading: $leading-100 !default;
$related-title-has-decorative-border: false !default;
$related-title-border-color: $default-color !default;
$related-title-padding: $space-24 $space-40 $space-20 !default;
$related-items-max-height: initial !default;
$related-item-background-color: $grey-20 !default;
$unibrow-height: $space-32 !default;
$border-width: $space-1 !default;
$related-item-eyebrow-height: calc(100% - #{$unibrow-height} - #{$border-width}) !default;

$related-title-nbcnews-commerce-color: inherit !default;
$related-title-nbcnews-commerce-background-color: inherit !default;
$related-title-nbcnews-commerce-border-styles: inherit !default;
$related-title-nbcnews-commerce-background-max-height: initial !default;
$related-title-betternews-commerce-background-max-height: initial !default;

@include override-tokens-for('news') {
  $related-title-nbcnews-commerce-color: $blue-70 !global;
  $related-title-nbcnews-commerce-background-color: $blue-70 !global;
  $related-title-nbcnews-commerce-border-styles: solid $space-1 $blue-70 !global;
  $related-title-nbcnews-commerce-background-max-height: 110px !global;
}

@include override-tokens-for('better') {
  $related-title-betternews-commerce-background-max-height: 110px !global;
}

@include override-tokens-for('noticias') {
  $related-item-background-color: $neutral-10 !global;
  $related-title-background-color: $blue-60 !global;
}

@include override-tokens-for('today') {
  $related-title-color: $default-color !global;
  $related-title-background-color: $default-neutral !global;
  $related-title-font-style: italic !global;
  $related-title-has-decorative-border: true !global;
  $related-title-padding: $space-16 $space-40 !global;
  $related-items-max-height: 475px !global;
  $related-item-background-color: $white !global;
  $related-item-eyebrow-height: 119px !global;
}

@include override-tokens-for('think') {
  $related-title-font-family: $founders-cond !global;
}

@include override-tokens-for('knowyourvalue') {
  $related-title-font-family: $founders-cond !global;
}
