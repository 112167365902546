.ontheshow__header-list-item {
  color: $ontheshow-header-list-item-color;
  display: flex;
  font-family: $publico-txt;
  font-size: $text-16;
  line-height: 20px;

  @include breakpoint-m {
    font-size: $text-18;
    line-height: normal;
  }

  @include breakpoint-l {
    font-size: $text-14;
    line-height: 20px;
  }
}

.ontheshow__header-list-item-bullet {
  background: $ontheshow-header-list-item-bullet-color;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 8px;
  margin-right: 8px;
  margin-top: 7px;
  width: 8px;
}

.ontheshow__header-list-item-link {
  transition: opacity 0.25s ease-in-out;
}

.ontheshow__header-list-item-link:hover {
  opacity: .7;
}
