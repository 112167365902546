.live-blog-pkg {
  // Used by the mobile app team as a web render
  &--embedded {
    border-top: solid $space-4 $live-blog-pkg-border-top-color;
    padding-top: $space-16;
    margin-bottom: 0 !important; //required to override pkg specificity

    &.live-blog-pkg--breaking {
      border-top-color: $live-blog-pkg-border-top-color-breaking;
    }

    @include breakpoint-m {
      display: flex;
      justify-content: space-between;

      .live-blog-pkg__column {
        flex-basis: 30%;

        &:not(:last-child) {
          margin-right: $space-20;
        }
      }

      .live-blog-pkg__headline {
        margin-right: $space-20;
        margin-bottom: 0;
        margin-top: 0;
      }

      .live-blog-pkg__list {
        flex-grow: 2;

        &--wrapped {
          flex-flow: row wrap;
          display: flex;

          > * {
            // Update children into a two column grid
            flex-basis: 50%;

            // Update order since column wrap leads to weird side effects
            &:nth-child(1) {
              order: 1;
            }

            &:nth-child(2) {
              order: 3;
            }

            &:nth-child(3) {
              order: 2;
            }

            &:nth-child(4) {
              order: 4;
            }

            &:nth-child(2)::after {
              display: none;
            }
          }
        }
      }
    }

    // Write custom IE & MD Media query since nested queries
    // aren't supported on ie11
    @media only screen
      and (min-width: $grid-m-width)
      and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      .live-blog-pkg__list--wrapped > * {
        // Rows don't wrap without removing padding from flex basis
        $total-padding: $space-8 * 4;
        flex-basis: calc(50% - #{$total-padding});
      }
    }
  }

  &__headline {
    font-family: $live-blog-pkg-headline-font-family;
    font-weight: $live-blog-pkg-headline-font-weight;
    font-style: $live-blog-pkg-headline-font-style;
    line-height: $live-blog-pkg-headline-line-height;
    letter-spacing: $live-blog-pkg-headline-letter-spacing;
    margin: 0 0 $space-4 0;

    &:hover {
      opacity: $default-hover-opacity;
    }

    &:active {
      opacity: $default-active-opacity;
    }

    &__text {
      margin: 0;
      color: $live-blog-pkg-headline-color;
      font-size: $text-24;
      line-height: $leading-100;
      font-family: $live-blog-pkg-headline-font-family;
    }

    &__label {
      font-family: $live-blog-pkg-label-font-family;
      font-weight: $live-blog-pkg-label-font-weight;
      font-size: $live-blog-pkg-label-font-size;
      font-style: normal;
      color: $live-blog-pkg-label-color;
      line-height: normal;

      &--breaking {
        color: $live-blog-pkg-label-color-breaking;
      }
    }
  }

  &__dek {
    color: $grey-60;
    font-size: $text-14;
    font-family: $publico-txt;
    line-height: $leading-150;
    margin: $space-12 0 $space-12;
  }

  &__list {
    margin-left: $space-4;
  }
}
