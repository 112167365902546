$twelve-col-percentage-tablet: calc((100% + #{$gutter-tablet}) / 12);
$col-width-tablet: calc(#{$twelve-col-percentage-tablet} - #{$gutter-tablet});

.featured-tease {
  background: none;
  position: relative;

  @include breakpoint-l {
    width: 100%;
    margin-left: 0;
  }
}

.featured-tease__title {
  font-family: $featured-tease-title-font-family;

  @include responsive-variations(font-size, $featured-tease-title-font-sizes);
  @include responsive-variations(line-height, $featured-tease-title-line-heights);
  font-weight: 400;
  background: none;
  color: $white;
  margin: 0;

  @include breakpoint-m {
    margin-left: 0;
    float: none;
    display: inline-block;
    width: calc((6 * #{$col-width-tablet}) + (6 * #{$gutter-tablet}));
  }

  @include breakpoint-l {
    width: auto;
    height: rem(175px);
    margin-left: rem(40px);
    padding-right: rem(40px);
    position: relative;
    display: block;
  }

  @include breakpoint-x {
    height: rem(205px);
    margin-bottom: rem(25px);
    width: auto;
  }

  a {
    @include breakpoint-l {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 40px);
    }
  }
}

.featured-tease__byline {
  margin: rem(10px 0 20px);

  @include breakpoint-m {
    margin: rem(20px 0 40px);
  }

  @include breakpoint-l {
    width: rem(160px);
    margin-left: rem(40px);
    margin-bottom: rem(90px);
    float: left;
  }

  @include breakpoint-x {
    margin-top: 0;
    margin-bottom: rem(100px);
  }
}

.featured-tease__dek {
  display: none;

  @include breakpoint-m {
    font-family: $default-text-font-family;
    font-size: rem(14px);
    line-height: rem(22px);
    font-weight: 400;
    color: $white;
    display: block;
    float: right;
    margin: rem(23px 0);
    width: calc((5 * #{$col-width-tablet}) + (4 * #{$gutter-tablet}));
  }

  @include breakpoint-l {
    position: static;
    width: rem(270px);
    margin-top: rem(20px);
    margin-bottom: rem(90px);
    float: right;
    margin-right: rem(41px);
  }

  @include breakpoint-x {
    width: rem(415px);
    margin-top: 0;
    margin-bottom: rem(100px);
  }
}

.featured-tease__picture {
  display: block;
  width: calc(100% + #{$featured-tease-picture-outer-gutter});
  position: relative;
  clear: both;
  border-bottom: rem(40px) solid $white;

  @include breakpoint-m {
    border-bottom: none;
    width: auto;
  }

  &::before {
    @include breakpoint-m {
      content: '';
      display: block;
      position: absolute;
      background-color: $white;
      width: rem(40px);
      height: calc(100% - 32px);
      left: rem(-40px);
      top: rem(32px);
    }

    @include breakpoint-l {
      content: none;
    }
  }
}

.featured-tease__category {
  background-color: $featured-tease-category-background-color !important;
  color: $featured-tease-category-color !important;
  height: rem(32px);
  min-width: rem(160px);
  align-items: center;
  margin: 0;
  position: absolute !important;
  padding: 0 0 0 rem(16px) !important;
  top: 0;
  left: 0;
}

.featured-tease__picture .featured-tease__category {
  // higher specificity than the style that `<TeaseCategory>` adds by default
  margin-top: 0;
}

.featured-tease__author {
  font-family: $default-headline-font-family;
  font-size: 24px;
  line-height: 30px;
  font-weight: normal;

  @include breakpoint-m {
    font-size: 32px;
    line-height: 40px;
  }

  @include breakpoint-l {
    font-size: 40px;
    line-height: 50px;
  }

  @include breakpoint-x {
    font-size: 48px;
    line-height: 60px;
  }
}
