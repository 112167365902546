$rem-base: 16px;

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;

  /* stylelint-disable */
  @if $value == 0 {
    $value: 0;
  }
  /* stylelint-enable */

  @return $value;
}

@function rem($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();

  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }

  @return $remValues;
}

@function to-list($args...) {
  @return append((), $args);
}

@function is-map($var) {
  @return type-of($var) == 'map';
}
