.waffle-badge {
  background-color: $waffle-badge-background-color;
  height: 80px;
  min-width: 80px;
  pointer-events: none; //make sure vilynx preview still plays
}

.waffle-badge__top {
  height: 18px;
  line-height: 18px;
}

.waffle-badge__bottom {
  font-family: $waffle-badge-bottom-font-family;
  font-style: $waffle-badge-bottom-font-style;
}
