@mixin cover-spread-tease-small-margins {
  @include breakpoint-s-only {
    margin-bottom: -$space-12;

    .cover-spread-tease__text-wrapper {
      margin: 0 $space-20;
      padding-bottom: $space-16;
    }
  }
}

@mixin cover-spread-override-first-child {
  .cover-spread-tease,
  .cover-spread-tease:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.default-cover-spread-tease--featured {
  @include cover-spread-tease-small-margins;
  @include cover-spread-override-first-child;

  @include breakpoint-l-only {
    .cover-spread-tease {
      &__headline {
        font-size: 28px;
      }
    }
  }

  @include breakpoint-x {
    .cover-spread-tease {
      &__headline {
        font-size: 28px;
      }
    }
  }
}

.cover-spread-tease--featured {
  @include cover-spread-tease-small-margins;
  @include cover-spread-override-first-child;
}
