
// TODO: rewrite this stylesheet so this is not necessary:
// stylelint-disable no-descending-specificity

// when a p tag is the last element in article-body__content and over min character length show endmark
$endmarkSelector: ".article-body__content p.endmarkEnabled:last-child::after";
$globalNavbarHeight: 80px;
$hero_media_holder_height: 215px;

.scrollLink {
  &::before {
    display: block;
    position: absolute;
    content: " ";
    margin-top: -90px;
    height: 90px;
    visibility: hidden;
    pointer-events: none;
  }

  &:focus {
    @include headings {
      outline: 1px dotted;
    }
  }
}

.article-body__grid--container {
  background-color: $article-body-grid-container-background;

  &.article-body__grid--container-live-blog {
    // overflow-x-hidden breaks sticky
    // this is a replacement for it
    max-width: 100vw;
  }

  &.article-body__grid--container-no-main-image {
    padding-top: rem(50px);

    .byline > aside {
      @include breakpoint-l {
        margin-top: rem(30px);
      }
    }
  }
}

.article-body__right-rail--recommended {
  display: none;

  @include breakpoint-l {
    display: inherit;
  }
}

.article-body__bottom-rail--recommended {
  display: inherit;

  @include breakpoint-l {
    display: none;
  }
}

.article-body__section {
  @include clearfix;

  @include breakpoint-l {
    display: flex;
    width: 100%;
  }
}

.article-body__content--sticky-video {
  position: relative;
  z-index: get-z-index('sticky-video-parent');
}

.article-body__last-section {
  padding-bottom: rem(48px);

  // when a p tag is only followed by articleBylineContainer show endmark
  #{$endmarkSelector} {
    background-image: $article-body-last-section-bg-image;
    background-size: $article-body-last-section-bg-image-width $article-body-last-section-bg-image-height;
    background-repeat: no-repeat;
    display: inline-block;
    width: $article-body-last-section-bg-image-width;
    height: $article-body-last-section-bg-image-height;
    content: "";
    margin-left: rem(2px);
  }
}

.article-body {
  word-wrap: break-word;
  position: relative;

  ::selection,
  ::-moz-selection {
    background: $article-body-selection-color;
  }

  .article-body__content {
    > p {
      margin: rem(24px 0);

      > strong {
        color: $article-body-content-strong;
      }
    }

    // All headings
    @include direct-headings {
      line-height: 1em;
      clear: both;
      margin: rem(24px 0);

      :not(h1) {
        font-family: $article-body-heading-font-family;
      }

      > a {
        @include fade-on-hover();
      }

      a {
        border-bottom: $article-body-heading-link-underline;
        padding-bottom: 2px;
        color: $article-body-heading-color;

        &:hover {
          color: $article-body-heading-link-hover-color;
        }
      }
    }

    // Headings and headings in page anchors
    > h1,
    > a > h1 {
      font: $article-body-heading-font-weight rem(20px)/1 $article-body-heading-font-family;

      @include breakpoint-m {
        font-size: rem(32px);
      }
    }

    > h2,
    > a > h2 {
      font: $article-body-heading-font-weight rem(32px)/1 $article-body-heading-font-family;
    }

    > h3,
    > a > h3 {
      font: $article-body-heading-font-weight rem(24px)/1 $article-body-heading-font-family;
    }

    > h4,
    > a > h4 {
      font: $article-body-heading-font-weight rem(20px)/1 $article-body-heading-font-family;
    }

    > h5,
    > a > h5 {
      font: $article-body-heading-font-weight rem(18px)/1 $article-body-heading-font-family;
    }

    > h6,
    > a > h6 {
      font: $article-body-heading-font-weight rem(16px)/1 $article-body-heading-font-family;
    }

    // Headings in page anchors
    > a > h2,
    > a > h3,
    > a > h4,
    > a > h5,
    > a > h6 {
      clear: both;
      margin: rem(24px 0);
      color: $article-body-anchor-heading-color;

      @include fade-on-hover();

      // first headline of the article should float around the newsletter signup.
      // the first child is the newsletter signup itself
      &:nth-child(2) {
        clear: none;
      }
    }

    > a > h2 {
      margin: rem(48px 0 24px);
    }

    ol {
      counter-reset: item;
      padding-left: 0;

      > li {
        position: relative;
        list-style-type: none;
        padding-left: rem(35px);
        margin: 0 0 rem(24px) rem(30px);

        &::before {
          font-family: $article-body-content-font-family;
          color: $article-body-bullet-color;
          content: counter(item) " ";
          counter-increment: item;
          font-size: rem(24px);
          line-height: 1;
          display: inline-block;
          width: rem(35px);
          margin-left: rem(-35px);
          vertical-align: text-bottom;
        }

        &:nth-of-type(n+100) {
          padding-left: rem(45px);

          &::before {
            margin-left: rem(-45px);
            width: rem(45px);
          }
        }

        > p {
          margin: 0;
          display: inline-block;
          vertical-align: text-top;
        }
      }
    }

    > ul,
    > ol ul { // handle uls inside of ols the same way
      padding-left: 1em;

      > li,
      ul > li {
        position: relative;
        margin-bottom: rem(24px);

        &::before {
          color: $article-body-bullet-color;
          background-repeat: no-repeat;
          content: "\2022";
          display: inline-block;
          margin-left: -1em;
          width: 1em;
          text-align: center;
          vertical-align: middle;
        }
      }

      ul {
        padding-left: 1em;
        margin-top: 24px;
      }

      ol {
        margin-top: 24px;
      }
    }

    > p,
    > ol > li,
    > ul > li {
      a {
        color: $article-body-list-link-color;

        @include style-only-for('today') {
          border-bottom: 1px solid $article-body-list-link-underline-color;
          padding-bottom: 2px;
        }

        &:hover {
          color: $article-body-list-link-hover-color;
          opacity: $article-body-list-hover-opacity;
        }

        &:active {
          opacity: $article-body-list-active-opacity;
        }
      }

      // Remove margin at beginning of .article-body wrapper of components
      &:first-of-type {
        margin-top: 0;
      }

      // Remove margin at end of .article-body wrapper of components
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  pre {
    font-family: $article-body-pre-font-family;
    line-height: $article-body-pre-leading;
    color: $article-body-pre-text-color;
  }

  .article-body__sponsored-by-text {
    font-family: $article-body-sponsored-by-font-family;
    line-height: $article-body-sponsored-by-leading;
    color: $article-body-sponsored-by-color;
    letter-spacing: rem(.5px);
    text-transform: uppercase;
  }

  // Remove global margin on ads and add margin when ad is not hidden.
  .ad-container.mb8 {
    margin: 0;

    .mps-slot:not(.mps-gpt-hidden) {
      margin: rem(48px) 0;
    }
  }
}

.article-body__right-rail {
  float: right;

  .article-body__grid--container-live-blog:not(.article-body__grid--container-no-main-image) & {
    @include breakpoint-l {
      margin-top: -$hero_media_holder_height;
    }
  }
}

.article-body__non-sticky {
  position: relative !important;
  top: 0 !important;
}

.article-body__right-rail--ad {
  @include breakpoint-l {
    position: sticky;
    top: rem(90px);
    margin-top: rem(24px);
    display: flex;
    justify-content: center;
    transition: top 200ms ease-in;
  }

  @include ie {
    min-height: 600px;
  }

  .HFSBumped & {
    top: rem(90px + $globalNavbarHeight);
  }
}

.article-body__box-inline-ad {
  text-align: center;
  padding: rem(10px 0);
  background-color: $article-body-box-inline-ad-background;
  clear: left;

  @include breakpoint-l {
    background-color: transparent;
    padding: 0;
    margin: 0;
    display: none;
  }
}

.article-body__ecommerce-enabled {
  p.ecom {
    display: block;
    color: $article-body-ecommerce-enabled-text;

    a {
      border-bottom: 1px solid;
    }
  }

  .article-body__content {
    > h2 > a,
    > h3 > a {
      color: $article-body-ecommerce-enabled-text;
    }
  }
}

.article-body__amp-social-share--wrapper {
  height: rem(60px);

  @include breakpoint-m {
    height: rem(80px);
  }
}

.article-body__tablet-newsletter {
  display: none;
  float: right;
  margin-bottom: rem(48px);

  @include breakpoint-m {
    display: block;
  }

  @include breakpoint-l {
    display: none;
  }

  @include style-only-for('news') {
    display: none;
  }
}

.article-body__desktop-newsletter {
  display: none;

  @include breakpoint-l {
    display: block;
    margin-bottom: rem(48px);
  }
}

.article-body__contributors {
  border-top: 1px solid $article-body-contributors-border-color;
  border-bottom: 1px solid $article-body-contributors-border-color;
  padding-left: 4rem;

  &::before {
    content: ' ';
    width: rem(48px);
    height: rem(1px);
    display: inline-block;
    background-color: $article-body-contributors-line-bg-color;
    margin-right: rem(16px);
    margin-left: -4rem;
    vertical-align: middle;
  }
}
