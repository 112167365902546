$column-sizes: 1, 2, 3, 4, 5;

.package-grid {
  &.package-grid--zone-type-normal,
  &.package-grid--zone-type-railAdjacent {
    margin-bottom: $space-48; // add package spacing

    .package-grid__column {
      position: relative;
      display: flex;
      border-top: 1px solid transparent; // space for pseudoelement below
      padding-top: $space-20;
      margin-top: $space-20;

      // Small and medium breakpoints must use pseudoelement to allow the packages below
      // to dictate their own gutter display spacing.
      &::before {
        content: '';
        position: absolute;
        width: calc(100% - #{$space-40});
        height: 1px;
        background-color: $grey-40;
        top: 0;
        left: $space-20;

        // M layouts include gutter
        @include breakpoint-m {
          width: 100%;
          left: 0;
        }
      }
    }

    .pkg {
      margin-bottom: $space-28; // resize individual package spacing

      &:last-child {
        margin-bottom: 0;

        @include breakpoint-l {
          margin-bottom: $space-28; // resize individual package spacing
        }
      }
    }

    @include breakpoint-l {
      display: grid;
      padding-top: $space-36;
      border-top: 1px solid $grey-40;

      .package-grid__column {
        margin-top: 0;
        border-top: none;
        border-right: 1px solid $grey-40;
        border-bottom: 1px solid $grey-40;
        padding: 0 $space-20;

        &::before {
          content: none;
        }
      }

      @each $column-size in $column-sizes {
        &.package-grid--columns-#{$column-size} {
          grid-template-columns: repeat($column-size, 1fr);
          grid-template-rows: auto;

          // First column in row
          .package-grid__column:nth-child(#{$column-size}n+1) {
            padding-left: 0;
          }

          // Last column in row
          .package-grid__column:nth-child(#{$column-size}n+#{$column-size}) {
            border-right: none;
          }

          // Every item after the first row (item wrap)
          .package-grid__column:nth-child(n+#{$column-size + 1}) {
            margin-top: $space-36;
          }
        }
      }
    }
  }

  &.package-grid--zone-type-rail {
    margin-bottom: 0; // reset margin bottom since it's on .pkg
  }
}
