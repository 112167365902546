@mixin zone-a-margin {
  @include breakpoint-m {
    &.zone-a-margin {
      margin-top: rem(48px);
    }

    // Leaving package types camelCased
    &.zone-a-margin.lead-type--bigStoryTakeover,
    &.zone-a-margin.lead-type--bigStoryTakeoverPlus2,
    &.zone-a-margin.lead-type--bigStoryTakeoverPlus3,
    &.zone-a-margin.lead-type--bigStoryTakeoverPlus4,
    &.zone-a-margin.lead-type--bigHeadline,
    &.zone-a-margin.lead-type--coverSpread {
      margin-top: 0;
    }
  }
}

// Smooth scrolling for preview
body {
  scroll-behavior: smooth;
}

.layout-container {
  // Intentionally camel-cased
  .componentHighlight {
    position: relative;

    // Intentionally camel-cased
    & > span.curatorOverlay {
      content: "";
      background-color: $curator-yellow;
      z-index: 200;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
  }
  // Mosaic primary card position is absolute for large screen sizes, this keeps the positioning.
  @include breakpoint-l {
    .pkg.mosaic article:first-child.componentHighlight {
      position: absolute;
    }
  }

  &.flat-layout > div:not([class]) > * {
    @include package-spacing;
  }

  @include style-only-for("news") {
    &.first-package-has-title-or-is-pancake {
      margin-top: rem(24px);

      @include breakpoint-m {
        margin-top: 0;
      }
    }
  }

  @include style-only-for("news") {
    @include zone-a-margin;
  }

  @include style-only-for("noticias") {
    @include zone-a-margin;
  }

  &::before {
    @include responsive-variations(display, $front-display-visibility);
    background-color: $front-background-color;
    position: $front-background-position;
    content: "";

    @include responsive-variations(width, $front-background-width);
    @include responsive-variations(height, $front-background-height);
  }
}

body.go-see-a-star-war {
  height: 100vh;

  .layout-container {
    transform: perspective(250px) rotateX(29deg) translateY(11%);
    transform-origin: center 500px !important;
    animation: s-w 300s linear 0s 1 normal forwards;
    will-change: transform;
  }
}

@keyframes s-w {
  from {
    transform: perspective(250px) rotateX(29deg) translateY(11%);
  }

  to {
    transform: perspective(250px) rotateX(29deg) translateY(-420%);
  }
}

.header-package {
  margin-top: rem(-60px);
  position: relative;

  @include breakpoint-m {
    margin-top: rem(-80px);
  }
}

.pkg {
  @each $heading in (h1, h2, h3, h4, h5, h6) {
    #{$heading} > a,
    a > #{heading} {
      transition: opacity 0.25s ease-in-out;
    }

    #{$heading} > a:visited,
    a:visited > #{heading} {
      opacity: 1;
    }

    #{$heading} > a:hover,
    a:hover > #{heading} {
      opacity: 0.7;
    }

    #{$heading} > a:active,
    a:active > #{heading} {
      opacity: 0.5;
    }
  }
}
