$menu-title-color: (s: $default-color, m: $white) !default;
$menu-title-background-color: $default-color !default;
$menu-title-background-max-height: $space-112 !default;
$menu-title-font-family: $default-headline-font-family !default;
$menu-title-font-style: normal !default;
$menu-title-font-weight: $semibold !default;
$menu-title-border-color: $default-color !default;
$menu-title-leading: $leading-100 !default;
$menu-title-has-decorative-border: false !default;
$menu-title-border-color: $default-color !default;
$menu-title-padding: $space-24 $space-40 $space-16 !default;
$menu-title-min-width: 345px !default;
$menu-items-max-height: initial !default;
$menu-items-background-color: $neutral-10 !default;
$menu-items-font-weight: $semibold !default;
$menu-items-font-style: $normal !default;
$menu-items-font-family: $default-headline-font-family !default;
$menu-items-color: $default-text-color !default;
$menu-items-font-size: $text-20 !default;
$menu-items-line-height: 1 !default;
$menu-items-font-size-small: $text-18 !default;
$border-width: $space-1 !default;
$menu-items-spacing: $space-16 !default;
$menu-items-spacing-m: $space-16 !default;

@include override-tokens-for('today') {
  $menu-title-color: $default-color !global;
  $menu-title-background-color: $default-neutral !global;
  $menu-title-font-style: italic !global;
  $menu-title-font-weight: $light !global;
  $menu-title-has-decorative-border: true !global;
  $menu-title-padding: $space-20 $space-40 !global;
  $menu-title-min-width: inherit !global;
  $menu-items-font-size: $text-16 !global;
  $menu-items-line-height: 1.25 !global;
  $menu-items-font-weight: $light !global;
  $menu-items-color: $today-purple-70 !global;
  $menu-items-background-color: $white !global;
  $menu-items-font-size-small: $text-14 !global;
  $menu-items-spacing-m: $space-20 !global;
}

@include override-tokens-for('think') {
  $menu-title-font-family: $founders-cond !global;
  $menu-items-font-size: $text-16 !global;
  $menu-items-line-height: 1.25 !global;
  $menu-items-font-weight: $light !global;
  $menu-items-font-size-small: $text-14 !global;
  $menu-items-spacing-m: $space-20 !global;
}
