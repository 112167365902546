.mosaic {
  position: relative;
  z-index: 1;
}

.mosaic__border-top {
  height: $mosaic-border-top-height;
  width: $mosaic-border-top-width;
  background: $mosaic-border-top-bg-color;
}

.mosaic__content {
  @include style-only-for("msnbc") {
    &:not(.hasTitle):not(:first-of-type)::before {
      @include breakpoint-m {
        content: '';
        width: $mosaic-content-title-width;
        height: $mosaic-content-title-height;
        background: $mosaic-content-title-bg-color;
        display: block;
      }
    }
  }
  clear: both;

  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
}

// Tease Card Mosaic
.tease-card__mosaic {
  .tease-card__content {
    &.tease-card__has-featured-author {
      @include breakpoint-m {
        margin-bottom: rem(80px);
        display: flex;
        flex-direction: column;
      }
    }

    &:nth-child(even) {
      @include breakpoint-m {
        clear: left;
      }

      @include breakpoint-l {
        margin-right: rem(440px);
      }
    }

    &:nth-of-type(-n+4) {
      @include package-spacing;
    }

    @include breakpoint-m {
      &:nth-of-type(n+4) {
        margin-bottom: 0;
      }

      &:nth-child(n+2):last-of-type {
        margin-left: auto;
      }
    }
  }

  .tease-card__title {
    @include responsive-variations(font-size, $mosaic-tease-card-title-font-size);
    @include responsive-variations(line-height, $mosaic-tease-card-title-line-height);
    margin-bottom: rem(5px);
  }

  .tease-card__featured-author {
    font-size: 20px;
    line-height: 25px;

    @include breakpoint-m {
      font-size: 24px;
      line-height: 30px;
    }

    @include breakpoint-l {
      font-size: 20px;
      line-height: 25px;
    }

    @include breakpoint-x {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .tease-card__featured-author-image {
    margin-left: rem(40px);
    margin-right: 0;
    position: relative;

    @include breakpoint-m {
      width: calc(100% - 40px);
      margin: rem(0 20px);
    }
  }

  .tease-card__content:first-child {
    @include breakpoint-l {
      width: rem(439px);
      position: absolute;
      top: $tease-card-content-first-child-top;
      left: rem(240px);
    }

    @include breakpoint-x {
      width: rem(559px);
      left: rem(300px);
    }

    .tease-card__title {
      @include breakpoint-l {
        margin-right: 0;
      }
    }

    .tease-card__dek {
      @include breakpoint-m {
        font-size: rem(15px);
        line-height: rem(22px);
      }
    }
  }

  .tease-card__content:nth-child(n+2) {
    @include breakpoint-m {
      width: 50%;
      float: left;
    }

    @include breakpoint-l {
      flex-basis: calc(#{240px} - 1.33px);
    }

    @include breakpoint-x {
      flex-basis: calc(#{300px} - 1.33px);
    }

    .tease-card__title {
      @include breakpoint-l {
        margin-right: 0;
      }

      @include breakpoint-x {
        font-size: $mosaic-tease-card-title-font-size--non-lead;
        line-height: rem(25px);
      }
    }
  }
}
