.multi-up-rail {
  clear: both;
}

.multi-up-rail__articles {
  position: relative;
  z-index: 2;

  @include breakpoint-m {
    display: flex;
    justify-content: space-between;
  }

  .multi-up-rail__three-up & {
    @include breakpoint-m {
      flex-wrap: wrap;
    }

    @include breakpoint-x {
      flex-wrap: nowrap;
    }
  }

  .multi-up-rail__four-up & {
    @include breakpoint-m {
      flex-wrap: wrap;
    }

    @include breakpoint-x {
      flex-wrap: nowrap;
    }
  }
}

.multi-up-rail__article {
  background-color: $multi-up-rail-article-background-color;
  flex-basis: calc(#{50%} - 1px);

  @include breakpoint-m {
    &:first-of-type {
      margin-right: auto;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }

  @include package-spacing;

  .multi-up-rail__two-up & {
    @include breakpoint-m {
      margin-bottom: 0;
    }
  }

  .multi-up-rail__three-up & {
    @include breakpoint-m {
      flex-basis: calc(#{50%} - 1px);

      &:nth-of-type(n+2) {
        margin-bottom: 0;
      }
    }

    @include breakpoint-x {
      flex-basis: calc(#{25%} - 1.33px);
      margin-bottom: 0;
    }
  }

  .multi-up-rail__four-up & {
    @include breakpoint-m {
      flex-basis: calc(#{50%} - 1px);

      &:nth-of-type(n+3) {
        margin-bottom: 0;
      }
    }

    @include breakpoint-x {
      flex-basis: calc(#{25%} - 1.5px);

      &:first-of-type {
        margin-right: unset;
      }

      &:last-of-type {
        margin-left: unset;
      }
      margin-bottom: 0;
    }
  }

  .multi-up-rail__three-up &:first-child {
    @include breakpoint-m {
      flex-basis: 100%;
    }

    @include breakpoint-x {
      flex-basis: calc(#{50%} - 1.33px);
    }
  }
}

.multi-up-rail__eyebrow {
  background: $multi-up-rail-eyebrow-background;
  color: $multi-up-rail-eyebrow-color;
  background-color: $multi-up-rail-eyebrow-background-color;
  height: rem(32px);
  min-width: rem(130px);
  margin: rem(-32px) 0 0;
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  padding-left: rem(20px);
  padding-right: rem(20px);

  @include breakpoint-m {
    float: none;
    border-top: 5px solid;
    height: 60px;
    margin: rem(-60px) 0 0;
    padding-left: rem(30px);
  }

  @include breakpoint-l {
    padding: rem(16px 0 4px 40px);
  }

  @include breakpoint-x {
    float: left;
    border-top: none;
    height: rem(32px);
    padding: rem(16px 16px 4px 16px);
  }

  .multi-up-rail__icon {
    background: $multi-up-rail-icon-background;
    width: rem(32px);
    height: rem(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 100%;
    top: 0;
    font-size: rem(8px);
    color: $white;

    @include breakpoint-m {
      top: rem(-5px);
      left: auto;
      right: 100%;
      width: rem(40px);
      height: rem(40px);
    }

    @include breakpoint-x {
      width: rem(32px);
      height: rem(32px);
      left: 100%;
      top: 0;
    }
  }
}

/* stylelint-disable */
// Tease Card TwoUp
.multi-up-rail__tease-card--two-up {
  .tease-card__title {
    @include responsive-variations(font-size, $multi-up-rail-tease-card-two-up-title-font-sizes);
    @include responsive-variations(line-height, $multi-up-rail-tease-card-two-up-title-line-heights);
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $multi-up-rail-tease-card-two-up-featured-author-font-sizes);
    @include responsive-variations(line-height, $multi-up-rail-tease-card-two-up-featured-author-line-heights);
  }
}

//Tease Card threeUp
.multi-up-rail__tease-card--three-up {
  .tease-card__picture {
    position: relative;
  }

  .tease-card__content {
    width: 100%;

    @include breakpoint-x {
      margin-bottom: 0;
    }
  }

  .tease-card__title {
    @include responsive-variations(font-size, $multi-up-rail-tease-card-three-up-title-font-sizes);
    @include responsive-variations(line-height, $multi-up-rail-tease-card-three-up-title-line-heights);
  }

  &.tease-card__main-tease .tease-card__title {
    margin: rem(0 0 25px);

    @include breakpoint-m {
      font-size: rem(30px);
      line-height: rem(30px);
      margin-bottom: rem(12px);
      flex: 1 1 50%;
    }

    @include breakpoint-l {
      margin-right: rem(40px);
      width: 100%;
    }

    @include breakpoint-x {
      margin-right: 0;
    }
  }

  &.tease-card--no-main-image .tease-card__title {
    font-size: rem(22px);
    line-height: rem(30px);
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $multi-up-rail-tease-card-three-up-featured-author-font-sizes);
    @include responsive-variations(line-height, $multi-up-rail-tease-card-three-up-featured-author-line-heights);
  }

  &.tease-card__main-tease .tease-card__eyebrow {
    @include breakpoint-m {
      border-color: $tease-card-eyebrow-border-color;
      position: absolute;
      height: rem(55px);
      width: calc(100% - 88px);
      left: rem(88px);
      bottom: 0;
      padding: rem(16px 0 0 30px);
      border-top: 5px solid;

      .icon {
        position: absolute;
        top: rem(-5px);
        left: rem(-40px);
        width: rem(40px);
        height: rem(40px);
      }
    }

    @include breakpoint-l {
      width: calc(100% - 40px);
      left: rem(40px);
    }

    @include breakpoint-x {
      left: 0;
      bottom: 0;
      height: inherit;
      width: inherit;
      border-top: none;
      padding: rem(16px 16px 4px);

      .icon {
        left: 100%;
        top: 0;
        bottom: 0;
        width: rem(32px);
        height: rem(32px);
      }
    }
  }

  .tease-card__info {
    &.tease-card__headline:only-child {
      margin-bottom: 0;
    }
  }

  &.tease-card__main-tease .tease-card__info {
    @include breakpoint-m {
      width: calc(100% - 118px);
      margin-left: 118px;
      padding: 0;
    }

    @include breakpoint-l {
      width: calc(100% - (#{$multi-up-rail-gutter-desktop} + 30px));
      margin-left: $multi-up-rail-gutter-desktop + 30px;
    }

    @include breakpoint-x {
      width: auto;
      margin: 0;
      padding: rem(20px 16px 0);
    }
  }
}

// Tease Card fourUp
.multi-up-rail__tease-card--four-up {
  .tease-card__content {
    width: 100%;

    @include breakpoint-x {
      margin-bottom: 0;
    }
  }

  .tease-card__title {
    @include responsive-variations(font-size, $multi-up-rail-tease-card-four-up-title-font-sizes);
    @include responsive-variations(line-height, $multi-up-rail-tease-card-four-up-title-line-heights);
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $multi-up-rail-tease-card-four-up-featured-author-font-sizes);
    @include responsive-variations(line-height, $multi-up-rail-tease-card-four-up-featured-author-line-heights);
  }
}

/* stylelint-enable */
