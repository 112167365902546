.lead-type--collectionsLead .layout-index-1 .pkg-index-2:first-of-type {
  background: $collection-lead-next-package-background-color;
  position: relative;

  & > .collectionLead-border {
    border-top: $collection-lead-next-package-border-color 5px solid;
  }
}

// the amount the next package in the layout will be pulled up into this one
$next-package-pull-amount: 60px;

.collection-lead__header-spacing-container {
  padding-top: $navbar-mobile-height;

  @include breakpoint-m {
    padding-top: $navbar-default-height;
    padding-bottom: $next-package-pull-amount;
  }
}

.collection-lead {
  @include breakpoint-m {
    margin-bottom: -$next-package-pull-amount;
  }
}

.collection-lead__text-header {
  @include responsive-variations(font-size, $collection-lead-text-header-font-size);
  @include responsive-variations(margin-top, $collection-lead-text-header-margin-top);
  margin-bottom: 0;
  font-style: $collection-lead-text-header-font-style;
  font-weight: $collection-lead-text-header-font-weight;
  line-height: $collection-lead-text-header-line-height;
  text-transform: $collection-lead-text-header-text-transform;
  font-family: $collection-lead-text-header-font-family;
}

// Logo
.collection-lead__logo-wrapper {
  @include breakpoint-m {
    width: auto;
    height: auto;
  }

  @include breakpoint-l {
    max-width: 500px;
  }
}

.collectionLead-border__logo {
  @include object-fit(contain);
  max-height: 100px;
  max-width: 66%;

  @include breakpoint-l {
    max-height: 150px;
    max-width: 100%; // 100% of the 500px `.logoWrapper` container above
  }
}

// Description
.collection-lead__text-description {
  @include responsive-variations(font-size, $collection-lead-text-description-font-size);
  line-height: $leading-150;
}

// CTA button
.collection-lead__updates-button {
  font-family: $founders-semibold-font-family;
  font-size: 16px;
  line-height: 16px;
  color: $white;
  padding: rem(22px 0);
  border: 1px solid $white;
  width: rem(183px);
  background: none;
  position: relative;
  z-index: 1;

  &:hover {
    color: $default-color;
    background: $white;
  }
}

.collection-lead__link-list-wrapper {
  @include responsive-variations(font-size, $collection-lead-link-list-wrapper-font-size);
  color: $white;
}

.collection-lead__link-list {
  overflow-x: auto;

  @include breakpoint-x {
    justify-content: center;
  }
}

.collection-lead__link-list-item {
  display: inline;
  padding-right: rem(30px);
  white-space: nowrap;

  @include breakpoint-m {
    padding-right: rem(40px);
  }

  &:last-child {
    padding: 0;
  }

  .stackCommerceFront & {
    color: $black;
  }
}
