$magazine-lead-text-color: $default-text-color !default;

$magazine-lead-bg-image: none !default;

$magazine-lead-inner-padding-top: ( s: rem(112px), m: rem(188px), l: rem(198px), x: rem(190px)) !default;

$magazine-lead-wrapper-border: 1px solid $default-color !default;

$magazine-lead-see-all-wrapper-border: 1px solid $default-color !default;
$magazine-lead-see-all-btn-text-color: $default-color !default;
$magazine-lead-see-all-btn-border-color: $default-color !default;
$magazine-lead-see-all-btn-bg-color: $default-color !default;
$magazine-lead-see-all-btn-hover-text-color: $white !default;

$magazine-lead-title-color: $default-text-color !default;
$magazine-lead-title-font-family: $default-headline-font-family !default;
$magazine-lead-title-font-size: (s: rem(24px), m: rem(32px)) !default;
$magazine-lead-title-font-weight: 600 !default;
$magazine-lead-title-font-style: normal !default;
$magazine-lead-title-transform: uppercase !default;
$magazine-lead-title-line-height: (s: $leading-150, m: $leading-100) !default;

$magazine-lead-tease-bg-image: none !default;

$magazine-lead-headline-color: $default-headline-color !default;
$magazine-lead-headline-font-family: $founders-cond !default;
$magazine-lead-headline-font-size: (s: rem(18px), m: rem(16px), x: rem(18px)) !default;
$magazine-lead-headline-line-height: (s: $leading-100) !default;

$magazine-lead-unibrow-font-family: $founders-mono !default;
$magazine-lead-unibrow-color: $default-color !default;

$magazine-lead-tease-image-border-radius: none !default;

@include override-tokens-for("today") {
  $magazine-lead-text-color: $white !global;
  $magazine-lead-bg-image: linear-gradient(104deg, #ff503c, #a1127e 82%, #8d048d), linear-gradient(106deg, #ff503c, #8d048d) !global;
  $magazine-lead-inner-padding-top: ( s: rem(112px), m: rem(188px), l: rem(198px), x: rem(190px)) !global;
  $magazine-lead-wrapper-border: 1px solid $white !global;

  $magazine-lead-title-color: $white !global;
  $magazine-lead-title-font-size: (s: rem(24px), m: rem(32px)) !global;
  $magazine-lead-title-line-height: (s: $leading-100) !global;
  $magazine-lead-title-font-weight: 300 !global;
  $magazine-lead-title-font-style: italic !global;
  $magazine-lead-title-transform: normal !global;

  $magazine-lead-see-all-wrapper-border: 1px solid $white !global;
  $magazine-lead-see-all-btn-text-color: $white !global;
  $magazine-lead-see-all-btn-border-color: $white !global;
  $magazine-lead-see-all-btn-bg-color: $white !global;
  $magazine-lead-see-all-btn-hover-text-color: $default-color !global;

  $magazine-lead-headline-color: $white !global;
  $magazine-lead-headline-font-family: $publico-hed !global;
  $magazine-lead-headline-font-size: (s: rem(16px), m: rem(14px), x: rem(16px)) !global;
  $magazine-lead-headline-line-height: (s: rem(20px), m: rem(17px), x: rem(20px)) !global;

  $magazine-lead-unibrow-font-family: $founders-mono !global;
  $magazine-lead-unibrow-color: $white !global;
  $magazine-lead-tease-bg-image: linear-gradient(to right, $white 10%, transparent 20%) !global;

  $magazine-lead-tease-image-border-radius: 50% !global;
}

@include override-tokens-for("think") {
  $magazine-lead-title-font-style: italic !global;
  $magazine-lead-title-font-weight: 300 !global;
  $magazine-lead-title-transform: none !global;
  $magazine-lead-title-font-size: (s: rem(24px), m: rem(32px)) !global;
  $magazine-lead-title-line-height: (s: $leading-125, m: $leading-100 ) !global;
}

@include override-tokens-for("telemundoentretenimiento") {
  $magazine-lead-inner-padding-top: (s: 0) !global;
  $magazine-lead-bg-image: none !global;
  $magazine-lead-text-color: $blue-60 !global;
  $magazine-lead-title-color: $blue-60 !global;
  $magazine-lead-headline-color: $blue-60 !global;
  $magazine-lead-headline-font-size: (s: rem(18px), m: rem(16px), x: rem(18px)) !global;
  $magazine-lead-unibrow-color: $default-color !global;
  $magazine-lead-tease-image-border-radius: 50% !global;
}
