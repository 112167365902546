// Still needed for XL breakpoint and 2 items, since image and info are side-by-side
.news-tease__col-2 {
  @include breakpoint-x {
    .news-tease__image-container + .news-tease__info {
      margin-left: rem(20px);

      .news-tease__headline {
        margin-top: 0;
      }
    }
  }
}

.news-tease__container {
  margin-bottom: rem(32px);
}

.news-tease__timestamp {
  font-size: rem(12px);
  line-height: 1;
  font-family: $founders-mono;
  font-weight: 100;
}

.news-tease__unibrow {
  color: $news-tease-unibrow-color;
  font-family: $publico-txt;
  font-size: rem(16px);
  font-weight: bold;
  padding-left: rem(20px);
  margin: 0;

  @include breakpoint-m {
    padding-left: 0;
  }
}

.news-tease__headline {
  color: $news-tease-headline-color;
  font-family: $news-tease-headline-font-family;
  font-size: $news-tease-headline-font-size;
  line-height: $news-tease-headline-line-height;
  margin: rem(10px 0);
}

.news-tease__live-title {
  color: $breaking-red;
}

.news-tease__description {
  color: $news-tease-description-text-color;
  font-family: $news-tease-description-font-family;
  font-size: $news-tease-description-font-size;
  line-height: $news-tease-description-line-height;
  margin: 0;
}

.news-tease__ruler {
  border-bottom: 1px solid $black;
  opacity: 0.2;
  margin-bottom: rem(12px);
  margin-top: rem(8px);
}

.news-tease__info-wrapper {
  &--one-col {
    @include breakpoint-m {
      display: flex;
    }
  }

  &--two-col {
    @include breakpoint-l {
      display: flex;
      flex-direction: column;
    }

    @include breakpoint-x {
      flex-direction: row;
    }
  }
}

.news-tease__info--multi-col {
  padding-left: rem(20px);
  padding-right: rem(20px);

  @include breakpoint-m {
    padding-left: 0;
    padding-right: 0;
  }
}

.news-tease__info--one-col--no-image {
  padding-left: rem(20px);

  @include breakpoint-m {
    padding-left: 0;
  }
}

.news-tease__info--one-col--with-image {
  padding-left: rem(20px);
}

.news-tease__image-container {
  z-index: 1;
  position: relative;
  vertical-align: top;

  picture {
    vertical-align: text-bottom;
  }
}

.news-tease__image-container--first-item {
  @include breakpoint-m {
    display: none;
  }

  @include breakpoint-x {
    display: block;
  }
}
