$bacon-package-button-width: 50px !default;
$bacon-package-border-color: $grey-40 !default;
$bacon-package-background-color: $white !default;
$bacon-package-margin-left: rem(20px) !default;

$bacon-header-top-border-display: block !default;
$bacon-header-top-border-background-color: $default-color !default;
$bacon-header-top-border-line-color: $grey-40 !default;

$bacon-header-headline-title-color: $grey-70 !default;
$bacon-header-headline-title-font: $default-headline-font-family !default;
$bacon-header-headline-title-border-color: transparent !default;
$bacon-header-headline-title-style: normal !default;
$bacon-header-headline-title-size: (s: 1.5rem) !default;
$bacon-header-headline-title-font-weight: normal !default;
$bacon-header-headline-title-link-color: $default-color !default;
$bacon-header-headline-title-link-font: $founders-cond !default;

$bacon-header-headline-title-before-content: none !default;
$bacon-header-headline-title-before-border-color: inherit !default;

$bacon-header-headline-description-font-family: $default-text-font-family !default;
$bacon-header-headline-description-font-size: rem($text-14) !default;
$bacon-header-headline-description-line-height: 1 !default;
$bacon-header-headline-description-color: $grey-60 !default;

$bacon-disclaimer-font: $default-text-font-family !default;
$bacon-paging-dot-color: $default-color !default;
$bacon-paging-dot-border-color: $grey-50 !default;
$bacon-paging-dot-background-color: transparent !default;

$bacon-prev-btn-container-bg-color: $white !default;
$bacon-next-btn-container-bg-color: $white !default;

$bacon-page-btn-color: $default-color !default;
$bacon-page-btn-border-color: $grey-40 !default;
$bacon-page-btn-background-color: transparent !default;
$bacon-page-btn-hover-color: $white !default;
$bacon-page-btn-hover-bg-color: $default-color !default;

@include override-tokens-for('today') {
  $bacon-package-margin-left: inherit !global;
  $bacon-package-background-color: $neutral-10 !global;
  $bacon-header-top-border-display: none !global;
  $bacon-header-headline-title-color: $purple-70 !global;
  $bacon-header-headline-title-border-color: $orange-30 !global;
  $bacon-header-headline-title-style: italic !global;
  $bacon-header-headline-title-size: (s: 1.5rem, m: 2rem) !global;
  $bacon-header-headline-title-font-weight: 200 !global;
  $bacon-header-headline-description-font-family: $default-headline-font-family !global;
  $bacon-header-headline-description-line-height: 1.5em !global;
  $bacon-prev-btn-container-bg-color: $neutral-10 !global;
  $bacon-next-btn-container-bg-color: $neutral-10 !global;
  $bacon-header-headline-title-before-content: '' !global;
  $bacon-header-headline-title-before-border-color: inherit !global;
}

@include override-tokens-for('think') {
  $bacon-header-headline-description-font-family: $default-headline-font-family !global;
  $bacon-header-headline-description-line-height: $leading-150 !global;
}

@include override-tokens-for('knowyourvalue') {
  $bacon-header-headline-description-font-family: $default-headline-font-family !global;
  $bacon-header-headline-description-line-height: $leading-150 !global;
}

@include override-tokens-for('telemundo') {
  $bacon-header-top-border-display: none !global;
  $bacon-header-headline-title-color: $blue-60 !global;
  $bacon-header-headline-title-link-color: $default-color !global;
  $bacon-header-headline-title-before-content: '' !global;
  $bacon-header-headline-title-before-border-color: $default-color !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $bacon-header-top-border-display: none !global;
  $bacon-header-headline-title-color: $blue-60 !global;
  $bacon-header-headline-title-link-color: $default-color !global;
  $bacon-header-headline-title-before-content: '' !global;
  $bacon-header-headline-title-before-border-color: $default-color !global;
}

@include override-tokens-for('telemundoshows') {
  $bacon-header-top-border-display: none !global;
  $bacon-header-headline-title-color: $blue-60 !global;
  $bacon-header-headline-title-link-color: $default-color !global;
  $bacon-header-headline-title-before-content: '' !global;
  $bacon-header-headline-title-before-border-color: $default-color !global;
}
