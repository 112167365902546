$list-item-bg-color: (s: transparent, l: $grey-70) !default;
$list-item-content-line-bg-color: $white !default;
$list-item-hover-bg-color: $grey-60 !default;
$list-item-active-bg-color: (s: transparent, l: $grey-60) !default;
$list-item-playing-image-bg-color: $default-color !default;
$list-item-playing-image-bg-opacity: .7 !default;
$list-item-content-right-padding: (s: rem(20px), l: rem(24px), x: rem(32px)) !default;
$list-item-title-font-family: $founders-cond !default;
$list-item-title-font-size: rem(18px) !default;
$list-item-title-padding-bottom: (s: rem(5px), l: 0) !default;
$list-item-title-color: (s: $white) !default;

@include override-tokens-for('today') {
  $list-item-bg-color: (s: transparent, l: $white) !global;
  $list-item-content-line-bg-color: $grey-50 !global;
  $list-item-hover-bg-color: #f2f0f5 !global;
  $list-item-active-bg-color: (s: transparent, l: #f2f0f5) !global;
  $list-item-playing-image-bg-color: linear-gradient(112deg, $orange-30, #8d048d) !global;
  $list-item-content-right-padding: (s: rem(60px), l: rem(24px), x: rem(32px)) !global;
  $list-item-title-font-family: $publico-hed !global;
  $list-item-title-font-size: rem(14px) !global;
  $list-item-title-padding-bottom: 0 !global;
  $list-item-title-color: (s: $white, l: $default-headline-color) !global;
}

@include override-tokens-for('msnbc') {
  $list-item-content-line-bg-color: $yellow-40 !global;
  $list-item-playing-image-bg-color: $yellow-40 !global;
  $list-item-playing-image-bg-opacity: .75 !global;
}

@include override-tokens-for('knowyourvalue') {
  $list-item-title-font-family: $publico-hed !global;
}
