$space-32: 32px;
$space-40: 40px;
$space-60: 60px;
$space-80: 80px;
$space-90: 90px;
$space-100: 100px;
$space-150: 150px;
$space-160: 160px;
$space-200: 200px;
$space-220: 220px;
$space-300: 300px;
$space-500: 500px;

.titleLogo {
  @include object-fit(contain);

  .bacon &,
  .pancake & {
    max-width: $space-160;
    max-height: $space-32;
  }

  .multiUp &,
  .multiUpRail &,
  .twoUp &,
  .threeUp &,
  .fourUp & {
    max-width: $space-160;
    max-height: $space-40;
  }

  .videoPkg & {
    max-width: $space-160;
    max-height: $space-40;

    @include breakpoint-x {
      max-width: $space-220;
      max-height: $space-60;
    }
  }

  .collectionLead &,
  .collectionBillboard & {
    max-width: $space-200;
    max-height: $space-60;

    @include breakpoint-m {
      max-height: $space-100;
    }
  }

  .collectionTitle & {
    display: inline;
    max-width: $space-160;
    max-height: $space-40;

    @include breakpoint-m {
      max-width: $space-220;
      max-height: $space-80;
    }
  }

  .collectionPoster & {
    max-width: $space-200;
    max-height: $space-60;

    @include breakpoint-m {
      max-width: $space-160;
      max-height: $space-80;
    }

    @include breakpoint-l {
      max-width: $space-200;
      max-height: $space-100;
    }

    @include breakpoint-x {
      max-width: $space-160;
      max-height: $space-80;
    }
  }

  .waffleHeader & {
    margin: auto;

    @include breakpoint-m-max {
      max-width: 66%;
      max-height: $space-100;
    }

    @include breakpoint-l {
      max-width: $space-500;
      max-height: $space-150;
    }
  }

  .magazineLead & {
    max-width: $space-160;
    max-height: $space-40;

    @include breakpoint-m {
      max-width: $space-160;
      max-height: $space-60;
    }
  }

  .leadSectionFront & {
    max-width: $space-160;
    max-height: $space-40;

    @include breakpoint-m {
      max-width: $space-220;
      max-height: $space-80;
    }
  }

  .showLead & {
    max-width: $space-160;
    max-height: $space-60;

    @include breakpoint-m {
      max-width: $space-300;
      max-height: $space-90;
    }
  }

  .teaseList & {
    max-width: $space-160;
    max-height: $space-32;
  }

  .stickyAdTrack.stickyAdTrack--7up & {
    max-width: $space-160;
    max-height: $space-60;
  }
}
