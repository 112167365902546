.three-up__articles {
  @include clearfix;
  background-color: $three-up-articles-background-color;
  position: relative;

  // TODO: remove when package spacing is standardized for all teases
  & > article {
    // include spacing on the bottom of all teases on mobile
    @include package-spacing;

    // last child on mobile should not have spacing due to layout-applied spacing
    &:last-child {
      margin-bottom: 0;
    }

    // all other breakpoints handle spacing at layout-level
    @include breakpoint-x {
      margin-bottom: 0;
    }
  }

  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include style-only-for("msnbc") {
    &.three-up__accent-line {
      border-top: 5px solid #ffbe03;
    }
  }
}

.slideshow__pictures {
  background-color: $default-color;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{$slideshow-outer-gutter});

  @include breakpoint-m {
    position: relative;
    width: auto;
    padding-left: 0;
  }

  &::before {
    font-family: $founders-semibold-font-family;
    font-weight: normal;
    font-size: rem(22px);
    line-height: rem(22px);
    background-color: $default-color;
    content: attr(data-count);
    color: $white;
    flex-basis: 50%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint-m {
      order: 3;
    }

    @include breakpoint-x {
      order: 2;
    }
  }
}

.slideshow__picture {
  flex-basis: 50%;

  &:first-child {
    order: 1;

    @include breakpoint-m {
      position: absolute;
      left: 0;
      width: 50%;
    }

    @include breakpoint-x {
      position: static;
    }
  }

  &:nth-child(2) {
    order: 3;

    @include breakpoint-m {
      order: 2;
    }

    @include breakpoint-x {
      order: 3;
    }
  }

  &:nth-child(3) {
    order: 4;

    @include breakpoint-m {
      flex-basis: 100%;
    }

    @include breakpoint-x {
      flex-basis: 50%;
    }
  }
}

.tease-card--slideshow {
  .tease-card__content {
    .tease-card__title {
      .sponsoredcontent & {
        font-family: $founders-semibold-font-family;
      }

      @include responsive-variations(font-size, $tease-card-slideshow-title-font-sizes);
      @include responsive-variations(line-height, $tease-card-slideshow-title-line-heights);
    }

    .tease-card__featured-author {
      @include responsive-variations(font-size, $tease-card-slideshow-featured-author-font-sizes);
      @include responsive-variations(line-height, $tease-card-slideshow-featured-author-line-heights);
    }

    .tease-card__dek {
      font-size: rem(15px);
      line-height: rem(22px);
    }

    @include breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{50%} - 1px);
    }

    @include breakpoint-x {
      flex-basis: calc(#{1/3 * 100%} - 1.33px);
    }

    &:last-child {
      .tease-card__title {
        @include responsive-variations(font-size, $tease-card-slideshow-title-last-child-font-sizes);
        @include responsive-variations(line-height, $tease-card-slideshow-title-last-child-line-heights);

        .tease-card--no-main-image {
          font-size: rem(22px);
          line-height: rem(30px);
        }
      }

      .tease-card__featured-author {
        @include responsive-variations(font-size, $tease-card-slideshow-last-child-featured-author-font-sizes);
        @include responsive-variations(line-height, $tease-card-slideshow-last-child-featured-author-line-heights);
      }

      .tease-card__dek {
        @include breakpoint-x {
          font-size: rem(14px);
          line-height: rem(22px);
        }
      }
      flex-basis: 100%;

      @include breakpoint-x {
        flex-basis: calc(#{1/3 * 100%} - 1.33px);
      }
    }

    &:nth-child(n+2) {
      .tease-card__dek {
        @include breakpoint-m {
          font-size: rem(14px);
          line-height: rem(22px);
        }
      }
    }

    &.tease-card--no-main-image {
      .tease-card__title {
        @include responsive-variations(font-size, $tease-card-slideshow-no-main-image-title-font-sizes);
        @include responsive-variations(line-height, $tease-card-slideshow-no-main-image-title-line-heights);
      }
    }
  }
}

// stylelint-disable no-descending-specificity

.tease-card--with-paid-content-slideshow {
  .tease-card__content {
    .tease-card__featured-author {
      @include responsive-variations(font-size, $tease-card--with-paid-content-slideshow-featured-author-font-sizes);
      @include responsive-variations(line-height, $tease-card--with-paid-content-slideshow-featured-author-line-heights);
    }

    .tease-card__title {
      @include responsive-variations(font-size, $tease-card-with-paid-content-slideshow-title-font-sizes);
      @include responsive-variations(line-height, $tease-card-with-paid-content-slideshow-title-line-heights);
    }

    .tease-card__dek {
      font-size: rem(15px);
      line-height: rem(22px);
    }

    @include breakpoint-m {
      @include flex-tease-space(50%, 2);

      &:nth-of-type(2) {
        .tease-card__title {
          @include responsive-variations(font-size, $tease-card-slideshow-title-last-child-font-sizes);
          @include responsive-variations(line-height, $tease-card-slideshow-title-last-child-line-heights);

          .tease-card--no-main-image {
            font-size: rem(22px);
            line-height: rem(30px);
          }
        }

        .tease-card__featured-author {
          @include responsive-variations(font-size, $tease-card-slideshow-last-child-featured-author-font-sizes);
          @include responsive-variations(line-height, $tease-card-slideshow-last-child-featured-author-line-heights);
        }

        .tease-card__dek {
          @include breakpoint-x {
            font-size: rem(14px);
            line-height: rem(22px);
          }
        }
        order: 3;
        flex-basis: 100%;
        margin-bottom: 0;

        .imageCredit {
          display: block;
        }
      }

      &:nth-child(n+2) {
        .dek {
          @include breakpoint-m {
            font-size: rem(14px);
            line-height: rem(22px);
          }
        }
      }
    }

    @include breakpoint-x {
      @include flex-tease-space(1/3 * 100%, 3);

      &:nth-of-type(2) {
        flex-basis: calc(33.33% - 1.33px);

        .imageCredit {
          display: none;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;

      @include breakpoint-m {
        order: 2;
        flex-basis: 50%;
        margin-bottom: rem(48px) !important;
      }

      @include breakpoint-x {
        @include flex-tease-space(1/3 * 100%, 3);
        order: 3;
        flex-basis: calc(33.33% - 1.33px);
        margin-bottom: 0 !important;
      }
    }

    .tease-card--no-main-image {
      .tease-card__title {
        font-size: rem(22px);
        line-height: rem(30px);
      }
    }
  }
}
