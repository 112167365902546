.tve__cta {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  line-height: 1;
  width: 100%;
  height: rem(44px);
  z-index: 100;

  @include breakpoint-l {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__text-wrap {
    flex: 1 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 rem(16px);

    @include breakpoint-l {
      justify-content: flex-end;
      min-width: rem(80px);
    }
  }

  &__countdown {
    font-family: $founders-cond;
    font-size: rem($text-20);
    display: block;
    margin: rem(-4px) 0 0;
    width: rem(40px);

    @include breakpoint-l {
      font-size: rem($text-24);
      width: rem(48px);
      text-align: center;
    }
  }

  &__text {
    &__anonymous {
      font-family: $founders-cond;
      font-size: rem($text-20);

      @include breakpoint-l {
        font-size: rem($text-24);
      }
    }

    &__temppass {
      font-family: $founders-mono;
      font-size: rem($text-12);
      letter-spacing: -0.04em;
      text-transform: uppercase;
      margin-right: rem(12px);

      &__label--small {
        display: block;

        @include breakpoint-l {
          display: none;
        }
      }

      &__label--large {
        display: none;

        @include breakpoint-l {
          display: block;
        }
      }

      @include breakpoint-l {
        transition-property: opacity, margin, width;
        transition-duration: 0.4s, 0.1s, 0.1s;
        transition-delay: 0s, 0.3s, 0.3s;
        opacity: 1;
        width: 200px;

        &--hidden {
          opacity: 0;
          width: 0;
          margin: 0;
        }
      }
    }
  }

  // Similar positioning between anonymous and tempPass call to actions
  &__anonymous,
  &__authed-temporary {
    color: $grey-70;
    background-color: $white;

    @include breakpoint-l {
      position: absolute;
      top: 0;
      right: 40%;
      width: auto;
      height: rem(60px);
      background-color: rgba($white, 0.8);
    }

    @include breakpoint-x {
      height: rem(80px);
    }
  }

  // Secondary action button positioned over info box
  &__anonymous-secondary {
    display: none;

    @include breakpoint-l {
      display: block;
      position: absolute;
      top: rem(16px);
      right: rem(40px);
      z-index: 1;
    }
  }

  &__authed-full {
    justify-content: flex-end;
    padding-right: rem(30px);

    @include breakpoint-l {
      position: absolute;
      top: rem(16px);
      right: rem(40px);
      height: auto;
      padding: 0;
    }
  }
}
