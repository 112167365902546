$border-width: $space-1;
$border-styles: solid $border-width $menu-title-border-color;

.menu-embed {
  @include breakpoint-x {
    width: calc(100% + #{$space-100});
    margin-left: -$space-100;
  }

  &__title {
    @include responsive-variations(color, $menu-title-color);
    font-family: $menu-title-font-family;
    font-weight: $menu-title-font-weight;
    font-style: $menu-title-font-style;
    line-height: $menu-title-leading;
    margin: 0;
    text-transform: capitalize;
    white-space: nowrap;

    @include breakpoint-s-only {
      display: flex;
      font-size: $text-20;
      margin-bottom: 10px;

      &::after {
        content: " ";
        align-self: center;
        background-color: $menu-title-border-color;
        flex: 0 1 auto;
        height: $space-1;
        margin-left: $space-12;
        width: 100%;
      }
    }

    @include breakpoint-m {
      font-size: $text-24;
      padding: $menu-title-padding;
      min-width: $menu-title-min-width;
      display: inline-block;
      background-color: $menu-title-background-color;

      @if $menu-title-has-decorative-border {
        border-top: $border-styles;
        border-left: $border-styles;
        position: relative;

        &::after {
          content: "";
          display: block;
          border-right: $border-styles;
          border-bottom: $border-styles;
          height: 50%;
          position: absolute;
          top: 0;
          right: 0;
          width: $space-16;
        }
      }
    }
  }

  &__link a {
    color: $menu-items-color;

    &:hover {
      opacity: $default-hover-opacity;
    }

    &:active {
      opacity: $default-active-opacity;
    }
  }

  &__items {
    font-family: $menu-items-font-family;
    font-weight: $menu-items-font-weight;
    font-style: $menu-items-font-style;

    @include breakpoint-s-only {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: $space-20 0;
      font-size: $menu-items-font-size-small;
      line-height: $menu-items-line-height;

      li {
        margin-bottom: $menu-items-spacing;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    @include breakpoint-m {
      background-color: $menu-items-background-color;
      font-size: $menu-items-font-size;
      line-height: $menu-items-line-height;
      padding: $space-40;
      position: relative;
      margin-left: $space-40;

      li {
        margin-bottom: $menu-items-spacing-m;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &::after {
        content: "";
        display: block;
        height: $space-40;
        left: -$space-40;
        position: absolute;
        top: 0;
        width: $space-40;

        @if $menu-title-has-decorative-border {
          border-left: $border-styles;
          border-bottom: $border-styles;
          height: 33%;
        }

        @else {
          background-color: $menu-title-background-color;
        }
      }
    }
  }

  .animated-ghost-button {
    height: rem(54px);
    width: rem(158px);
    padding: 18px 8px;
    margin-top: 20px;

    @include breakpoint-m {
      width: rem(193px);
      margin-top: 30px;
    }
  }
}
