.ontheshow__call-to-action {
  margin: 0 auto;
  max-width: 348px;

  @include breakpoint-l {
    bottom: 0;
    left: 0;
    margin: 0 40px;
    max-width: none;
    position: absolute;
    right: 0;
    z-index: 100;
  }
}

.ontheshow__call-to-action-gradient {
  display: none;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 87.32%);
  height: 140px;

  @include breakpoint-l {
    display: block;
  }
}

.ontheshow__call-to-action-content {
  @include breakpoint-l {
    /* Prevent overflow content from showing */
    background: #fff;
    padding-bottom: 40px;
  }
}
