.ad.ad--sticky {
  position: sticky;
  width: 100%;
  top: $sticky-ad-top-offset;

  @include style-only-for('today') {
    // Today cover has additional padding around ads
    &.ad--front {
      top: rem(112px);
    }
  }
}

div[data-mps-slot^="interstitial"] {
  height: 0;
}

.amp-ad {
  text-align: center;
}

@include style-only-for('today') {
  .ad--front {
    div[data-mps-slot^="boxrail"],
    div[data-mps-slot^="boxinline"],
    div[data-mps-slot^="boxflex"] {
      text-align: center;

      @include breakpoint-m {
        transform: none;
        width: auto;
        margin: 0;
        overflow-x: auto;
        -ms-overflow-x: hidden;
        -ms-overflow-y: hidden;
      }

      & > div[style*="width:300"],
      & > div[style*="width: 300"] {
        background-color: $white;
        // Override ad iframe wrapper div inline dimensions
        width: auto !important;
        height: auto !important;

        iframe {
          @include responsive-variations(margin, $today-ad-padding);
        }
      }

      & > div:not([style*="width"]) {
        background-color: $white;

        iframe:not([width="360"]) {
          @include responsive-variations(margin, $today-ad-padding);
        }
      }
    }

    div[data-mps-slot^="boxflex"] {
      @include breakpoint-l {
        padding-top: rem(30px);
      }
    }
  }
}
