.cover-spread-featured-video {
  position: relative;
  // make proportional div with overflow hidden to prevent jumpiness when video is inserted
  $videoAspectRatio: .5625; // 16:9
  overflow: hidden;
  background-color: $black;

  &:focus {
    outline: none;
  }

  &.supra-wrapper {
    width: 100%;
  }

  &__headline {
    background-color: $white;
    box-sizing: border-box;
    color: $grey-70;
    font-family: $founders-cond;
    font-size: $text-24;
    line-height: $leading-100;
    margin: 0;
    padding: $space-12 $space-20 $space-32 $space-20;
    position: relative;
    z-index: 2;

    &:active span {
      opacity: 0.5;
    }

    &:hover span {
      opacity: 0.7;
    }

    &__flag {
      align-items: center;
      color: $live-flag-default-background-color;
      font-family: $publico-txt;
      font-size: $text-20;
      font-style: normal;
      font-weight: normal;
      position: relative;
      z-index: 2;

      &__dot {
        height: $space-8;
        width: $space-8;
        background-color: $live-flag-active-background-color;
        border-radius: 50%;
        display: inline-flex;
        margin-right: $space-4;
        animation: live-dot-blink 1.5s ease-in-out infinite;
        vertical-align: middle;
      }

      &--live {
        color: $live-flag-active-background-color;
      }

      @include breakpoint-m {
        font-size: $text-24;
      }
    }

    &__text {
      color: $grey-70;
      position: relative;

      @include breakpoint-m {
        font-size: $text-28;
      }
    }

    &__wrapper {
      @include breakpoint-m {
        padding-bottom: $space-20;
      }
    }

    @include breakpoint-s-only {
      background-color: $white;
    }

    @include breakpoint-m {
      padding: $space-12 0;
    }
  }

  &::after {
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    width: 100%;
    background-image: linear-gradient(0deg, rgba($black, 0.8) 20%, rgba($black, 0) 100%);
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }

  &--playing::after {
    content: none;
  }

  &__info {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &--live-flag {
    position: absolute;
    top: 0;
    left: 0;
  }
}
