$one-up-background-color: $white !default;
$one-up-accent-line-border-top: none !default;
$one-up-slideshow-pictures-background: transparent !default;

@include override-tokens-for("news") {
  $one-up-slideshow-pictures-background: $blue-70 !global;
}

@include override-tokens-for("noticias") {
  $one-up-slideshow-pictures-background: $blue-60 !global;
}

@include override-tokens-for("leftfield") {
  $one-up-slideshow-pictures-background: $black !global;
}

@include override-tokens-for("globalcitizen") {
  $one-up-slideshow-pictures-background: $teal-40 !global;
}

@include override-tokens-for("better") {
  $one-up-slideshow-pictures-background: $teal-30 !global;
}

@include override-tokens-for("mach") {
  $one-up-slideshow-pictures-background: $purple-50 !global;
}

@include override-tokens-for("think") {
  $one-up-slideshow-pictures-background: $red-40 !global;
}

@include override-tokens-for("msnbc") {
  $one-up-accent-line-border-top: 5px solid $yellow-40 !global;
  $one-up-slideshow-pictures-background: $blue-40 !global;
}

@include override-tokens-for("today") {
  $one-up-background-color: $white !global;
  $one-up-slideshow-pictures-background: $orange-30 !global;
}

@include override-tokens-for("knowyourvalue") {
  $one-up-background-color: $white !global;
  $one-up-slideshow-pictures-background: $orange-30 !global;
}
