.newsletter-signup__container {
  // This component is shown/hidden via conductrics
  display: none;

  .newsletter-signup__title {
    color: $newsletter-signup-title-color;
    font-style: $newsletter-signup-title-font-style;
    font-family: $newsletter-signup-title-font-family;
    text-transform: $newsletter-signup-title-text-transform;
    font-size: rem(24px);
    margin-top: $newsletter-signup-title-margin-top;

    @include responsive-variations(margin-top, (s: 0, m: $space-4));
  }

  @include responsive-variations(margin-top, $newsletter-signup-container-margin-top);
  margin-bottom: $newsletter-signup-container-margin-bottom;

  @include style-only-for(("news","think","noticias")) {
    position: relative;
    border-top: 1px solid $grey-40;

    &::before {
      content: "";
      width: 5rem;
      height: 0.5rem;
      position: absolute;
      left: 0;
      background-color: $newsletter-signup-before-color;
    }
  }
}

.newsletter-signup__descr {
  padding-top: $newsletter-signup-descr-padding-top;
  font-size: rem(14px);
  padding-bottom: $space-12;
  margin-bottom: 0;
  margin-top: 0;
  font-family: $default-text-font-family;
}

.newsletter-signup__submitted-message {
  opacity: 1;
  animation: slide-up 0.3s ease-in;

  @include style-only-for("news") {
    border-bottom: solid 1px $grey-40;
  }

  p {
    color: $newsletter-signup-submitted-message-color;
    width: 75%;

    @include style-only-for("news") {
      margin-top: 12px;
    }

    @include style-only-for("today") {
      margin-top: 4px;
    }
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
