.show-lead {
  position: relative;
  margin-bottom: rem(48px);
}

.show-lead__wrapper {
  background-color: $show-lead-wrapper-bg;
}

.show-lead__content {
  padding-top: $navbar-mobile-height;
  height: rem(204px);

  @include breakpoint-m {
    padding-top: $navbar-default-height;
    height: rem(316px);
  }
}

.show-lead__meta--wrap {
  height: 100%;
  margin: 0 rem(20px);

  @include breakpoint-m {
    margin: 0;
  }
}

.show-lead__meta-data {
  z-index: 4;
  color: $show-lead-meta-data-color;
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include breakpoint-m {
    height: calc(100% - 32px);
  }
}

.show-lead__show--name {
  font-size: rem(25px);
  line-height: 1em;
  font-family: $show-lead-show-name-font;
  margin: 0;

  @include breakpoint-m {
    font-size: rem(55px);
    line-height: rem(50px);
  }

  img {
    max-width: rem(160px);
    max-height: rem(60px);

    @include breakpoint-m {
      max-width: rem(300px);
      max-height: rem(90px);
    }
  }
}

.show-lead__air-time {
  @include responsive-variations(font-size, $show-lead-air-time-font-size);
  font-family: $show-lead-air-time-font-family;
  font-weight: $show-lead-air-time-font-weight;
  line-height: $show-lead-air-time-line-height;
  margin: rem(12px) 0 0 0;

  @include breakpoint-m {
    line-height: 1em;
    margin-top: rem(24px);
  }
}

.show-lead__link-list--wrapper {
  font-family: $show-lead-link-list-wrapper-title-font;
  margin-top: rem(12px);

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: rem(175px);
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    @include breakpoint-m {
      width: rem(230px);
    }
  }
}

.show-lead__link-list {
  min-height: 25px;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: grab;
  line-height: 0;
  padding: rem(8px) 0 0 0;
  display: block;
  white-space: nowrap;

  @include breakpoint-m {
    overflow: visible;
    padding: rem(12px) 0 0 0;
    cursor: unset;
  }
}

.show-lead__link-list--item {
  display: inline-block;
  padding-right: rem(30px);
  white-space: nowrap;
  line-height: 0;

  &:last-of-type {
    padding: 0;
  }

  a {
    display: block;
    font-size: rem(16px);
    font-weight: $show-lead-link-list-item-font-weight;
    line-height: rem(16px);
    text-transform: $show-lead-link-list-item-text-transform;

    @include breakpoint-m {
      font-size: rem(18px);
    }
  }

  a:hover {
    opacity: $default-hover-opacity;
  }
}
