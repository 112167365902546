$twelve-col-percentage: (100% / 12);

$outer-gutter: 20px;
$-outer-gutter: -20px;

$gutter-mobile: 2vw;
$gutter-tablet: 4vw;
$gutter-desktop: 40px;

$grid-col-width-mobile: calc(#{$twelve-col-percentage} - #{$gutter-mobile});
$grid-col-width-tablet: calc(#{$twelve-col-percentage} - #{$gutter-tablet});
$grid-col-width-desktop: calc(#{$twelve-col-percentage} - #{$gutter-desktop});

$grid-margin-mobile: calc(#{$outer-gutter} - #{$gutter-mobile});
$grid-margin-tablet: calc(#{$outer-gutter} - #{$gutter-tablet});
$-grid-margin-mobile: calc((#{$outer-gutter} - #{$gutter-mobile}) * -1);
$-grid-margin-tablet: calc((#{$outer-gutter} - #{$gutter-tablet}) * -1);

$grid-m-width: 758px;
$grid-l-width: 1000px;
$grid-xl-width: 1240px;
