.ecommerce-disclaimer {
  background: $ecommerce-disclaimer-background-color;
  color: $grey-60;
  font-size: 16px;
  line-height: 24px;
  margin: 24px -20px 30px;

  @include breakpoint-m {
    margin-bottom: 48px;
    margin-left: -40px;
    margin-right: 0;
  }

  @include breakpoint-l {
    margin-left: 0;
  }

  @include breakpoint-x {
    margin-left: -40px;
  }
}

.ecommerce-disclaimer__byline {
  color: $grey-70;
  font-family: 'FoundersGroteskMono', Courier, 'Courier New', monospace;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 0;
  text-transform: uppercase;
}

.ecommerce-disclaimer__content {
  font-style: italic;
  margin: 0;
  padding: 20px;

  @include breakpoint-m {
    padding: 40px;
  }
}

.ecommerce-disclaimer__content a {
  color: $ecommerce-disclaimer-link-color;
  border-bottom: $ecommerce-disclaimer-link-border-bottom;
}

.ecommerce-disclaimer--alternate {
  background: inherit;
  margin-bottom: 46px;
  margin-left: 0;
  margin-right: 0;
}

.ecommerce-disclaimer--alternate .ecommerce-disclaimer__content {
  border-bottom: 1px solid $grey-40;
  border-top: 1px solid $grey-40;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  padding: 24px 0;
}
