.signup-input__input-wrapper {
  position: relative;
  flex: 1;

  @include style-only-for("today") {
    &::before {
      border-top: 1px solid $signup-input-design-color;
      border-left: 1px solid $signup-input-design-color;
      display: block;
      position: absolute;
      content: " ";
      height: 50%;
      top: 0;
      left: -40px;
      right: 0;
      bottom: 0;
    }

    &::after {
      content: " ";
      position: absolute;
      border-bottom: 1px solid $signup-input-design-color;
      width: 20px;
      left: -40px;
      bottom: 50%;
    }
  }
}

.signup-input__input {
  height: 100%;
  width: 100%;

  @include style-only-for(("news","think","noticias")) {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: solid 1px $grey-40;
  }

  @include style-only-for("news") {
    .dateline & {
      font-family: $founders-mono;
      font-size: rem(14px);
      width: rem(248px);
    }
  }
}

.signup-input-form {
  display: flex;

  @include style-only-for("news") {
    .dateline & {
      align-items: flex-end;
      background: $white;
      display: inline-flex;
      width: rem(330px);

      @include breakpoint-m {
        align-items: inherit;
        width: rem(330px);
      }

      @include breakpoint-l {
        width: 100%;
      }
    }
  }
}

.signup-input__signup-button {
  width: 30%;
  min-width: rem(83px);
  max-width: rem(207px);

  @include style-only-for("today") {
    background-color: $white;
  }

  @include style-only-for("noticias") {
    background-color: $white;
  }
}

.signup-input__recaptcha-links {
  display: block;

  @include breakpoint-l {
    display: inline;
  }
}

.signup-input__recaptcha-link {
  color: $signup-input-recaptcha-link-color;

  @include style-only-for("news") {
    .dateline & {
      color: $white;
    }
  }

  &:hover {
    opacity: $default-hover-opacity;
  }

  &:active {
    opacity: $default-active-opacity;
  }
}
