$collection-lead-next-package-border-color: $default-color !default;
$collection-lead-next-package-background-color: $white !default;

$collection-lead-text-header-font-style: normal !default;
$collection-lead-text-header-font-family: $default-headline-font-family !default;
$collection-lead-text-header-font-weight: normal !default;
$collection-lead-text-header-line-height: $leading-100 !default;
$collection-lead-text-header-text-transform: uppercase !default;

$collection-lead-text-header-font-size: (
  s: rem($text-32),
  m: rem($text-60),
  l: rem($text-80),
  x: rem($text-96),
) !default;

$collection-lead-text-header-margin-top: (
  s: rem(-$space-4),
  m: rem(-$space-12),
  l: rem(-$space-16),
  x: rem(-$space-20),
) !default;

$collection-lead-text-description-font-size: (s: rem($text-14), m: rem($text-16)) !default;
$collection-lead-link-list-wrapper-font-size: (s: rem($text-16), m: rem($text-20), l: rem($text-24)) !default;

@include override-tokens-for("think") {
  $collection-lead-text-header-font-family: $founders-semibold-font-family !global;
}

@include override-tokens-for("today") {
  $collection-lead-text-header-font-style: italic !global;
  $collection-lead-text-header-font-weight: $light !global;
  $collection-lead-text-header-line-height: $leading-125 !global;
  $collection-lead-text-header-text-transform: none !global;
  $collection-lead-text-header-margin-top: (s: 0) !global;
}

@include override-tokens-for("knowyourvalue") {
  $collection-lead-text-header-font-family: $founders-semibold-font-family !global;
  $collection-lead-text-header-text-transform: lowercase !global;
}
