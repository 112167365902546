.ontheshow {
  max-height: 100%;
  overflow: hidden;
  padding: 0 20px;
  position: relative;
  width: 100%;

  @include breakpoint-m {
    padding: 0;
  }

  @include breakpoint-l {
    background: $white;
    padding: 40px;
    width: 400px;
  }

  @include breakpoint-x {
    width: 440px;
  }
}
