.dek {
  color: $dek-text-color;
  font-size: 14px;
  font-family: $publico-txt;
  line-height: 1.5;
  margin-bottom: 0;

  @include breakpoint-m {
    flex: 1 1 50%;
  }

  @include breakpoint-l {
    padding-right: rem(20px);
  }

  @include breakpoint-x {
    padding-right: rem(0);
  }
}
