.cover-spread-tease {
  border-top: solid $space-1 $cs-tease-border-color;
  padding-top: $space-16;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }

  @include breakpoint-m-to-l-only {
    &:first-child {
      border-top: solid $space-1 $cs-tease-border-color;
      padding-top: $space-16;
    }
  }

  @include breakpoint-x {
    &:first-child {
      padding-top: 0;
    }
  }

  &__image-link {
    position: relative;
    display: block;
  }

  .cover-spread-tease__image {
    margin-bottom: $space-12;

    &.dn {
      margin-bottom: 0;
    }

    @include breakpoint-m {
      &.dn-m {
        margin-bottom: 0;
      }

      &.db-m {
        margin-bottom: $space-12;
      }
    }

    @include breakpoint-l {
      &.dn-l {
        margin-bottom: 0;
      }

      &.db-l {
        margin-bottom: $space-12;
      }
    }

    @include breakpoint-x {
      &.dn-xl {
        margin-bottom: 0;
      }

      &.db-xl {
        margin-bottom: $space-12;
      }
    }
  }

  &__unibrow {
    color: $cs-tease-unibrow-color;
    font-size: $text-12;
    font-family: $founders-mono;
    font-weight: $light;
    text-transform: uppercase;
    line-height: $leading-100;
    margin: 0 0 $space-8;

    &--opinion {
      color: $cs-tease-unibrow-opinion-color;
    }
  }

  &__headline {
    color: $grey-70;
    font-family: $founders-cond;
    line-height: $leading-100;
    margin: 0 0 $space-16;
    position: relative;

    .media-label__type-icon--inline {
      top: 7px;

      @include breakpoint-m-only {
        top: 4px;
      }

      @include breakpoint-x {
        top: 5.5px;
      }
    }

    .icon.icon-gallery.media-label__type-icon--inline {
      font-size: 9px;
    }
  }
}
