// OneByOne
.bacon-cards-onebyone {
  .unibrow,
  .icon {
    transition: transform linear 150ms;
  }

  .icon {
    transform-origin: bottom right;
    font-size: $bacon-cards-onebyone-icon-font-size; //1rem;
  }
}

// OneByOneProduct
.bacon-cards-onebyone__product-headline {
  position: relative;
  overflow-y: hidden;

  @supports (-webkit-line-clamp: $bacon-cards-onebyone-product-headline-number-of-lines-to-display) and
    (-webkit-box-orient: vertical) and (display: -webkit-box) {
    overflow: initial;
  }

  &.bacon-cards-onebyone__product-headline-publico-headline-brands {
    max-height: $bacon-cards-onebyone-product-headline-line-height-publico-brands * $bacon-cards-onebyone-product-headline-number-of-lines-to-display;
  }

  &.bacon-cards-onebyone__product-headline-non-publico-headline-brands {
    max-height: ($bacon-cards-onebyone-product-headline-font-size-non-publico-brands + 1) * $bacon-cards-onebyone-product-headline-number-of-lines-to-display;
  }
}

.bacon-cards-onebyone__product-headline-link {
  overflow-y: hidden;

  &.bacon-cards-onebyone__product-headline-link-publico-headline-brands {
    @include line-clamp($bacon-cards-onebyone-product-headline-number-of-lines-to-display, $bacon-cards-onebyone-product-headline-line-height-publico-brands, #f8f8f8, false);
    font-size: $bacon-cards-onebyone-product-headline-font-size-publico-brands;
    line-height: $bacon-cards-onebyone-product-headline-line-height-publico-brands;
  }

  &.bacon-cards-onebyone__product-headline-link-non-publico-headline-brands {
    @include line-clamp($bacon-cards-onebyone-product-headline-number-of-lines-to-display, $bacon-cards-onebyone-product-headline-font-size-non-publico-brands, #f8f8f8);
    font-size: $bacon-cards-onebyone-product-headline-font-size-non-publico-brands;
  }
}

.bacon-cards-onebyone__product-description {
  @include line-clamp($bacon-cards-onebyone-product-description-number-of-lines-to-display, $bacon-cards-onebyone-product-description-font-size, #f8f8f8);
  font-size: $bacon-cards-onebyone-product-description-font-size;
  overflow-y: hidden;
  max-height: $bacon-cards-onebyone-product-description-font-size * $bacon-cards-onebyone-product-description-line-height * $bacon-cards-onebyone-product-description-number-of-lines-to-display;
}

.bacon-cards-onebyone__product-bacon-shop-button {
  width: rem(190px);
}

// TwoByThree
.bacon-cards-twobythree__container {
  position: relative;
  width: 100%;
  height: 375px;

  & .bacon-cards-twobythree__bg-content {
    height: 100%;
  }

  & .bacon-cards-twobythree__bg-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .bacon-cards-twobythree__gradient {
    background: $bacon-cards-twobythree-gradient;
  }

  & .bacon-cards-twobythree__additional-gradient {
    opacity: 0.3;
    background: $bacon-cards-twobythree-additional-gradient;
  }

  &:hover {
    & .bacon-cards-twobythree__overlay {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      border-color: $bacon-cards-twobythree-overlay-border-color;
      opacity: 0.9;

      &.cardOverlayHover {
        transition: transform 350ms ease-out, opacity 100ms linear 100ms;
        transform: translateY(0);
      }
    }
  }

  & .bacon-cards-twobythree__title-wrapper {
    height: 100%;
  }

  & .bacon-cards-twobythree__title-link {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    z-index: 1;
  }

  & .bacon-cards-twobythree__title {
    display: flex;
    justify-content: center;
    font-family: $bacon-cards-twobythree-title-font;
    font-style: $bacon-cards-twobythree-title-font-style;
    color: $white;
    font-size: rem($text-24);
    line-height: $leading-100;
    text-transform: $bacon-cards-twobythree-title-transform;
    padding-bottom: rem($space-24);
    margin-left: rem($space-20);
    margin-right: rem($space-20);

    @include style-only-for('today') {
      letter-spacing: -0.2px;
    }
  }

  & .bacon-cards-twobythree__description {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: rem($text-14);
    font-family: $publico-txt;
    color: $grey-60;
    padding-top: rem($space-48);
    padding-right: rem($space-32);
    padding-left: rem($space-32);
    line-height: $leading-150;
  }

  & .bacon-cards-twobythree__button {
    width: rem(186px);
    height: rem(32px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $bacon-cards-twobythree-button-color;
    font-family: $founders-cond;
    font-size: rem($text-16);
    border-width: 1px;
    border-style: solid;
    margin-top: rem($space-16);
  }
}

// TwoByOne
.bacon-cards-twobyone__icon-background {
  background: $bacon-cards-twobyone-icon-bg;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transform-origin: bottom right;
}

.bacon-cards-twobyone {
  width: 100%;
  overflow: hidden;

  .unibrow,
  .bacon-cards-twobyone__icon,
  .bacon-cards-twobyone__icon-background {
    transition: transform linear 150ms;
  }

  .bacon-cards-twobyone__icon {
    transform-origin: center;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    height: 100%;
    transform: scale(0.375);
    color: $white;
  }

  // gallery & shop icons need special handling due to size differences
  .icon-gallery,
  .icon-shop {
    transform: scale(0.5) translateX(-10px);
  }

  .bacon-cards-twobyone__unibrow__section-name {
    display: inline-block;
    font-family: $founders-mono;
    text-transform: uppercase;
    color: $bacon-cards-twobyone-unibrow-font-color;

    &.bacon-cards-twobyone__unibrow__section-name--think {
      color: $think-red-40;
    }
  }

  .bacon-cards-twobyone__tease-picture__link {
    display: block;
    position: relative;

    &:hover {
      .bacon-cards-twobyone__icon {
        transform: translate(-2.2rem, -0.5rem) scale(1);
      }

      .bacon-cards-twobyone__icon-background {
        transform:
          scaleX(strip-unit(100px / $bacon-cards-twobyone-type-icon-width))
          scaleY(strip-unit(50px / $bacon-cards-twobyone-type-icon-height));
      }

      // gallery & shop icons need special handling due to size differences
      .bacon-cards-twobyone__icon.icon-gallery,
      .bacon-cards-twobyone__icon.icon-shop {
        transform: translate(-#{($bacon-cards-twobyone-slideshow-unibrow-width + $bacon-cards-twobyone-type-icon-width / 2) / 2}, -1rem) scale(1);

        & + .bacon-cards-twobyone__icon-background {
          transform:
            scaleX(strip-unit(128px / $bacon-cards-twobyone-type-icon-width))
            scaleY(strip-unit(64px / $bacon-cards-twobyone-type-icon-height));
        }
      }
    }
  }

  .bacon-cards-twobyone__tease-category {
    position: relative;
    display: flex;
  }

  .bacon-cards-twobyone__eyebrow {
    position: relative;
    height: rem(32px);
    font-size: rem($text-12);
    line-height: $leading-100;
    font-family: $founders-mono-font-family;
    font-weight: $bacon-cards-twobyone-eyebrow-font-weight;
    color: $bacon-cards-twobyone-eyebrow-color;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    background-color: $white;
    padding: 10px 0;
    margin: rem(-32px) 0 0;

    &.slideshow {
      width: $bacon-cards-twobyone-slideshow-unibrow-width;
    }

    &.video {
      width: $bacon-cards-twobyone-video-unibrow-width;
    }
  }
}

.bacon-cards-twobyone__font-style {
  font-size: rem($text-12);
  font-weight: $normal;
  margin-bottom: 0;
  margin-top: rem($space-8);
}

.bacon-cards-twobyone__timestamp-style {
  color: $grey-60;
}

.bacon-cards-twobyone__header {
  margin-bottom: 0;
  margin-right: rem($space-16);
  margin-top: rem($space-12);
}

.bacon-cards-twobyone__header-link {
  color: $bacon-cards-twobyone-header-link-color;
  font-family: $bacon-cards-twobyone-header-link-font-family;
  font-size: $bacon-cards-twobyone-header-link-font-size;
  line-height: $bacon-cards-twobyone-header-link-font-lh;
  font-weight: $bacon-cards-twobyone-header-link-font-weight;
  margin-bottom: rem($space-4);
  margin-top: rem($space-16);
}

.bacon-cards-twobyone__icon-container {
  display: block;
  position: relative;
  margin-top: -2rem;
  z-index: 2;
  width: $bacon-cards-twobyone-type-icon-width;
  padding: 0 rem($space-8);
}
