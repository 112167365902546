.videoPkg {
  .animated-ghost-button {
    color: $video-package-button-text-color;
    background-image: linear-gradient(35deg, $video-package-button-background-color 50%, transparent 50%);
    border: 1px solid $video-package-button-border-color;

    &:hover {
      color: $video-package-button-background-color--hover;
    }
  }
}

.video-package {
  position: relative;

  &.video-package__lead-package--adjacent {
    @include breakpoint-m {
      margin-top: rem(-130px);
    }

    @include breakpoint-l {
      margin-top: rem(-100px);
    }

    @include breakpoint-x {
      margin-top: rem(-120px);
    }
  }

  .leadType-collectionsLead &.video-package__lead-package--adjacent {
    margin-top: rem(5px);

    @include breakpoint-m {
      margin-top: rem(-130px);
    }

    @include breakpoint-l {
      margin-top: rem(-100px);
    }

    @include breakpoint-x {
      margin-top: rem(-120px);
    }
  }
}

.video-package__wrapper-outer {
  clear: right;
  position: relative;
}

.video-package__grid {
  @include breakpoint-l {
    display: flex;
  }
}

.video-package__columns {
  @include breakpoint-l {
    &:first-child {
      flex: 0 0 rem(560px);
    }

    &:last-child {
      flex: 0 0 rem(400px);
    }
  }

  @include breakpoint-x {
    &:first-child {
      flex: 0 0 rem(680px);
    }

    &:last-child {
      flex: 0 0 rem(520px);
    }
  }
}

.video-package__title-and-desc--container {
  color: $video-package-title-and-description-text-color;
  background-color: $video-package-title-and-description-bg-color;

  @include breakpoint-m {
    width: rem(482px);
    min-height: rem(130px);
    margin-left: 0;
  }

  @include breakpoint-l {
    width: rem(400px);
    min-height: rem(100px);
  }

  @include breakpoint-x {
    width: rem(480px);
    min-height: rem(120px);
  }
}

.video-package__title-and-desc--heading {
  color: $video-package-title-and-description-heading-color;
  font-style: $video-package-title-and-description-heading-font-style;
  font-weight: $video-package-title-and-description-heading-font-weight;
  border-color: $video-package-title-and-description-heading-border-color;
}

.video-package__title-and-desc--title-with-line {
  position: relative;

  &::before {
    // style the line
    content: '';
    display: block;
    position: absolute;
    border-top: 1px solid;
    border-color: inherit;
    top: 0.5em; // approx. half a line at current font size
    right: 100%;
    width: 130px;
    margin-right: 12px;
  }
}

.video-package__video-wrapper {
  position: relative;
  display: none;

  @include breakpoint-m {
    background-color: $video-package-video-wrapper-bg-color;
    color: $video-package-video-wrapper-text-color;
    display: block;
    width: 100%;
  }

  @include breakpoint-l {
    height: rem(315px);
  }

  @include breakpoint-x {
    height: rem(383px);
  }
}

.video-package__scroll {
  @include breakpoint-m {
    height: rem(177px);
    overflow: hidden;

    &:hover {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  @include breakpoint-l {
    height: auto;
    width: auto;
    overflow: auto;
  }
}

.video-package__list {
  @include breakpoint-m {
    display: flex;
  }

  @include breakpoint-l {
    display: block;
  }
}

.video-package__video-meta {
  display: none;
  pointer-events: none;

  @include breakpoint-m {
    display: block;
    position: absolute;
    width: 100%;
    height: rem(160px);
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: $video-package-video-meta-bg;
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .video-package__video-title {
    width: calc(100% - 60px);
    color: $video-package-video-meta-title-color;
    margin: 0;

    a {
      pointer-events: all;
    }

    @include style-only-for('today') {
      font-size: rem(20px);
      line-height: rem(30px);

      @include breakpoint-l {
        font-size: rem(18px);
        line-height: rem(27px);
      }
    }
  }

  .video-package__share {
    pointer-events: all;
  }

  &.video-package__info-hidden {
    opacity: 0;
    transition: opacity 500ms ease-out;

    // Set share to pointer-events none so adControl pause can be clicked
    .video-package__share {
      pointer-events: none;
    }
  }

  .video-package__duration {
    padding-top: rem(8px);
    color: $video-package-video-meta-duration-text-color;
  }
}

.video-package__see-all--wrapper {
  background-color: $video-package-see-all-wrapper-bg-color;
  padding: rem(30px 40px);

  @include breakpoint-m {
    display: none;
  }

  @include breakpoint-l {
    display: block;
  }
}

.video-package__title-and-description--info {
  line-height: $video-package-description-line-height;
  margin: $video-package-description-margin;
  font-family: $video-package-description-font;
}
