.ontheshow__video-list-item {
  align-items: center;
  border-top: 1px dashed $grey-50;
  display: flex;
  padding: 20px 0;
  width: 100%;
  flex-shrink: 0;

  @include breakpoint-m {
    width: 50%;
    padding-right: 5px;
  }

  @include breakpoint-l {
    width: 100%;
  }
}

.ontheshow__video-list-item-title {
  color: #380538;
  font-family: $publico-hed;
  font-size: $text-18;
  line-height: 20px;
  margin-bottom: 15px;
}

.ontheshow__video-list-item-timestamp {
  color: $grey-60;
  font-family: $founders-mono;
  font-size: $text-12;
  line-height: 12px;
  text-transform: uppercase;
}

.ontheshow__video-list-item-link {
  transition: opacity 0.25s ease-in-out;
}

.ontheshow__video-list-item-link:hover {
  opacity: .7;
}
