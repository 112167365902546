.tease-category__eyebrow {
  color: $tease-category-eyebrow-color;
  background-color: $tease-category-eyebrow-background-color;
  height: rem(30px);
  min-width: rem(130px);
  margin: rem(-30px) 0 0;
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;

  @include breakpoint-x {
    height: rem(32px);
    margin: rem(-32px) 0 0;
  }

  @each $vertical, $color in $collection-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }

  &.sponsored {
    background: $grey-70;
    color: $white;
  }

  .tease-card--no-main-image & {
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: absolute;
    top: rem(20px);
    left: rem(20px);
    height: auto;
    float: none;
    z-index: 3;
  }

  @include style-only-for('news') {
    &.tease-category__eyebrow--large {
      min-width: rem(160px);
    }
  }
}

.tease-category__typeIcon {
  left: auto;
  top: 0;
  right: 0;
}

.icon-gallery {
  font-size: rem(14px);

  @include breakpoint-m {
    font-size: rem(8px);
  }
}

.tease-card--no-main-image:not(.tease-card--has-featured-author) .tease-category__eyebrow {
  margin-bottom: auto;
}
