.cover-spread {
  border-top: solid $space-4 $cover-spread-border-top-color;
  background-color: $white;
  position: relative; // Required for MSNBC fronts

  &__wrapper {
    button {
      margin: 0;
      border: 0;
      background-color: transparent;
      padding: 0;
      text-align: left;

      &:focus {
        outline: none;
      }
    }
  }

  /* Change the play button to solid when user hovers anywhere over the cover spread column */
  &__feature {
    cursor: pointer;
    width: 100%;

    &:hover .cover-spread-featured-video__video__play-button {
      opacity: 1;
    }
  }

  &--stuck {
    position: relative;
    z-index: get-z-index('sticky-video-parent');
  }

  &--breaking {
    border-top-color: $cover-spread-border-top-color-breaking;
  }

  &__headline {
    color: $grey-70;
    line-height: $leading-100;
    margin: $space-20 $space-8;
    font-family: $founders-cond;
    font-size: $text-32;
    text-align: center;

    &__wrapper {
      @include breakpoint-m {
        margin-bottom: $space-20;
      }
    }

    @include breakpoint-m {
      font-size: 40px;
    }

    @include breakpoint-x {
      font-size: $text-48;
    }
  }
}
