// This package has a class of "live-blog" as CardList already uses "live-blog-pkg".
// This could be refactored to combine LiveBlog and CardList, as the shared components they
// are responsible for rendering are always used (i.e. title, button,)
.live-blog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // required for IE

  &--standalone {
    padding: 0 $space-20;

    @include breakpoint-m {
      padding: 0;
    }

    &.live-blog--in-rail {
      @include style-only-for('today') {
        padding: 0;
      }
    }
  }

  &__top-decoration {
    display: none;
    border-top: 1px solid $live-blog-package-top-decoration-border-color;
    margin: 0 $space-20; // migrated from outer-gutter

    // Enable top decoration in rail only
    .live-blog--in-rail & {
      display: block;
    }

    @include breakpoint-m {
      margin: 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: $live-blog-package-top-decoration-tab-width;
      height: $live-blog-package-top-decoration-tab-height;
      background-color: $live-blog-package-top-decoration-tab-color;
      top: $live-blog-package-top-decoration-tab-top;
      left: $space-20;

      @include breakpoint-m {
        left: 0;
      }
    }
  }

  &__content-wrapper {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    // Decorations only in rail
    .live-blog--in-rail & {
      @include style-only-for('today') {
        background-color: $live-blog-package-body-decoration-color;
        padding: $space-20 0;
        margin-right: $space-20;
        width: calc(100% - #{$live-blog-package-body-decoration-offset}); // decoration offset

        @include breakpoint-m-only {
          width: calc(100% - #{$live-blog-package-body-decoration-offset-m}); // decoration offset
        }

        &::before {
          @include pattern-dots($live-blog-package-body-decoration-color);
          content: '';
          position: absolute;
          opacity: $opacity-50;
          width: calc(100% - #{$space-124});
          height: 75%; // hard-coded due to design spec
          top: -$space-20;
          right: -$live-blog-package-body-decoration-offset; // decoration offset
          z-index: 1;

          @include breakpoint-m-only {
            width: 40%; // hard-coded due to design spec
            right: -$live-blog-package-body-decoration-offset-m;  // decoration offset
          }

          @include breakpoint-l {
            width: calc(100% - #{$space-68});
            right: -$live-blog-package-body-decoration-offset; // decoration offset
          }
        }
      }
    }
  }

  &__content {
    position: relative;
    flex-grow: 1; // grow to fill extra space so button is at the bottom

    @include style-only-for('today') {
      .live-blog--in-rail & {
        padding: $space-20;
        background-color: $white;
        z-index: 2;
        left: $space-20;
        // width = 100% + decoration offset - gutter (20) - right spacing (20)
        width: calc(100% + #{$live-blog-package-body-decoration-offset} - #{$space-40}); // decoration offset

        @include breakpoint-m-only {
          display: flex;
          flex-wrap: wrap;
          // width = 100% + decoration offset - gutter (20) - right spacing (20)
          width: calc(100% + #{$live-blog-package-body-decoration-offset-m} - #{$space-40}); // decoration offset
        }

        @include breakpoint-l {
          padding: $space-28 $space-20;
        }
      }
    }
  }

  &__button-wrapper {
    padding-top: $space-20;

    .live-blog--in-rail & {
      display: flex;
      justify-content: center; // center button horizontally
    }

    @include style-only-for('today') {
      .live-blog--in-rail & {
        padding-top: 0; // padding above is handled by content bottom
        padding-bottom: $space-20;
        margin-left: $space-20;
        background: $white;

        // width = 100% + decoration offset - gutter (20) - right spacing (20)
        width: calc(100% + #{$live-blog-package-body-decoration-offset} - #{$space-40}); // decoration offset

        @include breakpoint-m-only {
          // width = 100% + decoration offset - gutter (20) - right spacing (20)
          width: calc(100% + #{$live-blog-package-body-decoration-offset-m} - #{$space-40}); // decoration offset
        }

        // makes sure to overlap background decoration
        position: relative;
        z-index: 2;
      }
    }
  }

  &__button {
    color: $live-blog-package-button-color;
    border: 1px solid $live-blog-package-button-border-color;
    width: 100%;

    @include breakpoint-m {
      width: $space-192;
    }

    @include breakpoint-l {
      width: $space-120;
    }

    .live-blog--in-rail & {
      @include breakpoint-m {
        margin: 0 $space-20;
        width: $space-192;
      }

      @include breakpoint-l {
        width: 100%;
      }

      @include style-only-for('today') {
        width: calc(100% - #{$space-40});
      }
    }
  }
}
