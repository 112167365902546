.one-up {
  background-color: $one-up-background-color;
  position: relative;

  @include breakpoint-l {
    background-color: rgba($one-up-background-color, 0);
  }

  &.one-up--has-accent-line {
    border-top: $one-up-accent-line-border-top;
  }
}

.one-up__slideshow-pictures {
  background-color: $one-up-slideshow-pictures-background;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;

  @include breakpoint-m {
    position: relative;
    padding-left: 50%;
    width: auto;
  }

  &::before {
    font-family: $founders-semibold-font-family;
    font-weight: normal;
    font-size: rem(22px);
    line-height: rem(22px);
    background-color: $one-up-slideshow-pictures-background;
    content: attr(data-count);
    color: $white;
    flex-basis: 50%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint-m {
      order: 3;
    }
  }
}

.one-up__slideshow-picture {
  flex-basis: 50%;

  &:first-child {
    order: 1;

    @include breakpoint-m {
      position: absolute;
      left: 0;
      width: 50%;
    }
  }

  &:nth-child(2) {
    order: 3;

    @include breakpoint-m {
      order: 2;
    }
  }

  &:nth-child(3) {
    order: 4;

    @include breakpoint-m {
      flex-basis: 100%;
    }
  }
}

.tease-card--one-up {
  .tease-card__title {
    .sponsoredcontent {
      font-family: $founders-semibold-font-family;
      font-weight: normal;
    }
  }
}
