.multi-up__content {
  .multi-up__has-title & {
    position: relative;
  }
}

.multi-up__articles {
  position: relative;
  z-index: 2;

  @include breakpoint-m {
    display: flex;
    justify-content: space-between;
  }

  .multi-up__four-up & {
    @include breakpoint-m {
      flex-wrap: wrap;
    }
  }

  .multi-up__has-title & {
    background-color: $multi-up-has-title-background-color;
  }

  @include style-only-for('msnbc') {
    &.multi-up__accent-line {
      border-top: 5px solid #ffbe03;
    }
  }
}

.multi-up__article {
  @include package-spacing;

  @include breakpoint-m {
    margin-left: 0;
  }

  .multi-up__has-title & {
    width: 100%;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .multi-up__two-up & {
    background: none;

    @include breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{50%} - 1px);
      margin-bottom: 0;
    }
  }

  .multi-up__three_up & {
    @include breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{1/3 * 100%} - 1.33px);
      margin-bottom: 0;
    }

    @include breakpoint-l {
      flex-basis: calc(#{25%} - 1.33px);

      &:first-of-type {
        flex-basis: calc(#{50%} - 1.33px);
      }
    }
  }

  .multi-up__four-up & {
    @include breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{50%} - 1px);

      &:nth-of-type(n+3) {
        margin-bottom: 0;
      }
    }

    @include breakpoint-l {
      flex-basis: calc(#{25%} - 1.5px);

      &:first-of-type {
        margin-right: unset;
      }

      &:last-of-type {
        margin-left: unset;
      }
      margin-bottom: 0;
    }
  }
}

.multi-up__no-main-image,
.multi-up__has-featured-author {
  background-color: transparent;
}

// Tease Card TwoUp
.multi-up__tease-card--two-up {
  .tease-card__title {
    @include responsive-variations(font-size, $multi-up-tease-card-two-up-title-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-two-up-title-line-heights);
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $multi-up-tease-card-two-up-featured-author-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-two-up-featured-author-line-heights);
  }
}

//Tease Card threeUp
.multi-up__tease-card--three-up {
  .tease-card__title {
    @include responsive-variations(font-size, $multi-up-tease-card-three-up-title-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-three-up-title-line-heights);

    .tease-card--no-main-image {
      font-size: rem(22px);
      line-height: rem(30px);
    }
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $multi-up-tease-card-three-up-featured-author-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-three-up-featured-author-line-heights);
  }
}

//Tease Card threeUpMain
.multi-up__tease-card--three-up-main {
  .tease-card__title {
    @include responsive-variations(font-size, $multi-up-tease-card-three-up-main-title-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-three-up-main-title-line-heights);

    .tease-card--no-main-image {
      @include breakpoint-l {
        font-size: 50px;
        line-height: 50px;
      }
    }
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $multi-up-tease-card-three-up-main-featured-author-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-three-up-main-featured-author-line-heights);
  }
}

// Tease Card fourUp
.multi-up__tease-card--four-up {
  .tease-card__title {
    @include responsive-variations(font-size, $multi-up-tease-card-four-up-title-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-four-up-title-line-heights);
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $multi-up-tease-card-four-up-featured-author-font-sizes);
    @include responsive-variations(line-height, $multi-up-tease-card-four-up-featured-author-line-heights);
  }
}
