$breaking-tag-offset: 48px;

.big-headline__container {
  background-color: $big-headline-background-color;
  border-bottom: 4px solid;
  border-color: $big-headline-border-color;

  &.big-headline__container--is-breaking {
    border-color: $big-headline-border-color--breaking;
  }
}

.big-headline__breaking-eyebrow {
  height: $breaking-tag-offset;
}

.big-headline__unibrow {
  color: $big-headline-unibrow-color;
  font-weight: $light;
  font-size: $text-12;
  font-family: $founders-mono-font-family;
  margin-top: 0;
  line-height: $leading-100;
  margin-bottom: $space-4;
  text-transform: uppercase;

  @include breakpoint-m {
    margin-bottom: 0;
  }

  &.big-headline__unibrow--breaking {
    color: $big-headline-unibrow-color--breaking;
  }
}

.big-headline__text {
  font-family: $big-headline-text-font-family;
  color: $big-headline-text-color;

  @include responsive-variations(line-height, $big-headline-text-line-height);

  @include breakpoint-l {
    &:hover {
      text-decoration: underline;
    }
  }
}

.big-headline__dek {
  font-family: $default-text-font-family;
  color: $big-headline-dek-color;
  line-height: $leading-150;
}

// resolving spacing issue under the package when the image overlaps background
// as per design
.big-headline--has-tease {
  @include breakpoint-l {
    &.big-headline--hasDek {
      &.big-headline--breaking {
        min-height: 232px + $big-headline-tease-top-offset + $breaking-tag-offset;
      }

      &.big-headline--non-breaking {
        min-height: 180px + $big-headline-tease-top-offset;
      }
    }

    &.big-headline--noDek {
      &.big-headline--breaking {
        min-height: 218px + $big-headline-tease-top-offset + $breaking-tag-offset;
      }

      &.big-headline--non-breaking {
        min-height: 218px + $big-headline-tease-top-offset;
      }
    }
  }

  @include breakpoint-x {
    &.big-headline--non-breaking {
      // important tag needed since lg breakpoint has greater specificity
      min-height: 292px + $big-headline-tease-top-offset !important;
    }

    &.big-headline--breaking {
      min-height: 292px + $big-headline-tease-top-offset + $breaking-tag-offset !important;
    }
  }
}

.big-headline__right-column {
  align-self: center;

  @include breakpoint-l {
    align-self: flex-start;
  }

  .big-headline--breaking & {
    @include responsive-variations(padding-top, $big-headline-right-column-padding-top--breaking);
    align-self: $big-headline-right-column-alignment--breaking;
  }
}
