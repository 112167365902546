$border-width: $space-1;
$unibrow-height: $space-32;
$border-styles: solid $border-width $related-title-border-color;

.related {
  clear: both;
  margin-bottom: 8px;

  &:not(&--trending) {
    margin: $space-48 0;

    @include breakpoint-x {
      width: calc(100% + #{$space-100});
      margin-left: -$space-100;
    }
  }

  &--sticky {
    position: sticky;
    top: $space-112;
  }

  &__title {
    @include responsive-variations(color, $related-title-color);
    font-family: $related-title-font-family;
    font-style: $related-title-font-style;
    font-weight: $normal;
    font-size: $text-20;
    line-height: $related-title-leading;
    margin: 0;
    text-transform: capitalize;
    white-space: nowrap; // title should never wrap to two lines

    &--nbcnews-commerce {
      color: $related-title-nbcnews-commerce-color;
      background-color: $white;
    }

    // TODO: Remove use of breakpoint-s and design mobile first
    @include breakpoint-s-only {
      display: flex;

      &::after {
        content: " ";
        align-self: center;
        background-color: $related-title-border-color;
        flex: 0 1 auto;
        height: $space-1;
        margin-left: $space-12;
        width: 100%;
      }

      &--nbcnews-commerce::after {
        background-color: $related-title-nbcnews-commerce-background-color;
      }
    }

    @include breakpoint-m {
      font-size: $text-24;
      padding: $related-title-padding;
      min-width: 240px;
      display: inline-block;
      background-color: $related-title-background-color;

      &--trending {
        min-width: auto;
      }

      &--betternews-commerce {
        min-width: 240px;
      }

      &--nbcnews-commerce {
        color: $white;
        background-color: $related-title-nbcnews-commerce-background-color;
        border: $related-title-nbcnews-commerce-border-styles;
      }

      @if $related-title-has-decorative-border {
        border-top: $border-styles;
        border-left: $border-styles;
        position: relative;

        &::after {
          content: "";
          display: block;
          border-right: $border-styles;
          border-bottom: $border-styles;
          height: 50%;
          position: absolute;
          top: 0;
          right: 0;
          width: $space-16;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include ie {
      max-height: $related-items-max-height;
    }

    &--vertical {
      flex-direction: column;
    }

    .related--single & {
      padding: 0;
    }

    // Boxed frame for m & larger
    @include breakpoint-m {
      background-color: $related-item-background-color;
      padding: $space-20 $space-20 0;
      position: relative;
      margin-left: $space-40;
      min-height: $space-100;

      &::after {
        content: "";
        display: block;
        height: calc(100% - #{$unibrow-height});
        left: -$space-40;
        position: absolute;
        top: 0;
        width: $space-40;

        @if $related-title-has-decorative-border {
          border-left: $border-styles;
          border-bottom: $border-styles;
          height: $related-item-eyebrow-height;
        }

        @else {
          background-color: $related-title-background-color;
        }
      }

      &--nbcnews-commerce::after {
        background-color: $related-title-nbcnews-commerce-background-color;
        max-height: $related-title-nbcnews-commerce-background-max-height;
      }

      &--betternews-commerce::after {
        max-height: $related-title-betternews-commerce-background-max-height;
      }
    }
  }
}

@include style-only-for("today") {
  .related {
    &--trending {
      .related {
        &Item {
          &__title a {
            line-height: 125%;
          }

          &__thumb {
            picture {
              width: 60px;
              height: 60px;

              @include breakpoint-m {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }
  }
}
