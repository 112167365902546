$embed-pkg-label-font-family: $publico-txt !default;
$embed-pkg-label-font-size: $text-20 !default;
$embed-pkg-label-xl-font-size: $text-28 !default;
$embed-pkg-label-color: $default-color !default;
$embed-pkg-label-leading: $leading-150 !default;
$embed-pkg-label-font-weight: $light !default;
$embed-pkg-label-breaking-color: $news-red-30 !default;

$embed-pkg-headline-font-family: $founders-cond !default;
$embed-pkg-headline-font-size: $text-24 !default;
$embed-pkg-headline-xl-font-size: $text-32 !default;
$embed-pkg-headline-color: $grey-70 !default;
$embed-pkg-headline-leading: $leading-100 !default;
$embed-pkg-headline-font-weight: $normal !default;
$embed-pkg-headline-font-style: normal !default;

$embed-pkg-dek-font-family: $publico-txt !default;
$embed-pkg-dek-font-size: $text-14 !default;
$embed-pkg-dek-color: $grey-60 !default;

@include override-tokens-for('today') {
  $embed-pkg-label-leading: $leading-100 !global;
  $embed-pkg-label-font-family: $founders-cond !global;
  $embed-pkg-label-font-size: $text-28 !global;
  $embed-pkg-label-xl-font-size: $text-40 !global;
  $embed-pkg-label-font-weight: $normal !global;
  $embed-pkg-label-color: $purple-70 !global;
  $embed-pkg-label-breaking-color: $default-color !global;

  $embed-pkg-headline-color: $purple-70 !global;
  $embed-pkg-headline-xl-font-size: $text-32 !global;
  $embed-pkg-headline-font-family: $publico-hed !global;
  $embed-pkg-headline-leading: $leading-150 !global;
  $embed-pkg-headline-font-style: italic !global;
}

@include override-tokens-for('think') {
  $embed-pkg-label-leading: $leading-100 !global;
  $embed-pkg-label-font-family: $founders-cond !global;
  $embed-pkg-label-font-size: $text-28 !global;
  $embed-pkg-label-xl-font-size: $text-40 !global;
  $embed-pkg-label-font-weight: $normal !global;
  $embed-pkg-label-color: $grey-70 !global;
  $embed-pkg-label-breaking-color: $default-color !global;

  $embed-pkg-headline-font-family: $publico-hed !global;
  $embed-pkg-headline-leading: $leading-150 !global;
  $embed-pkg-headline-xl-font-size: $text-32 !global;
}

@include override-tokens-for('knowyourvalue') {
  $embed-pkg-label-leading: $leading-100 !global;
  $embed-pkg-label-font-family: $founders-cond !global;
  $embed-pkg-label-font-size: $text-28 !global;
  $embed-pkg-label-xl-font-size: $text-32 !global;
  $embed-pkg-label-font-weight: $normal !global;

  $embed-pkg-headline-font-family: $publico-hed !global;
  $embed-pkg-headline-leading: $leading-150 !global;
  $embed-pkg-headline-xl-font-size: $text-32 !global;
}

@include override-tokens-for('noticias') {
  $embed-pkg-label-color: $blue-40 !global;
  $embed-pkg-label-breaking-color: $red-40 !global;
}
