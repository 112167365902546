.waffle-header {
  @include breakpoint-m {
    &__collection-lead.pkg:not(.lead) {
      margin-bottom: -60px;
    }

    .pkg & {
      &__collection-lead.pkg:last-child {
        margin-bottom: -60px;
      }
    }
  }
}

.waffle-header__description {
  font-family: $waffle-header-description-font;
  font-weight: normal;
}

.waffle-header__filters {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include breakpoint-l {
    overflow-y: visible;
    -webkit-overflow-scrolling: unset;
  }

  & > * {
    flex-shrink: 0;
  }

  &.waffle-header__filter--open {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.waffle-header__filter--open__overlay-btn {
  border: 1px solid #f71c1c;
  border-radius: 100px;
  width: rem(135px);
  height: rem(40px);
  color: #f71c1c;
}

.waffle-header__clear-all-btn {
  padding-bottom: rem(2px);
  border-width: 0;
  border-bottom: 1px solid rgba($white, .3);

  &:hover {
    border-bottom: 1px solid $white;
  }
}

.waffle-header__overlay-btn--mobile {
  border-width: 0;
  flex: 0 1 50%;

  @include breakpoint-m {
    flex-basis: rem(220px);
  }
}

.waffle-header__overlay-btn--mobile--clear-all {
  border: 1px solid $waffle-header-overlay-btn-clear-all-border-color;
}

.waffle-header__tag-list-btn {
  border: none;
  background: transparent;
}

.waffle-header__overlay-button-wrapper {
  top: 0;

  @include breakpoint-l {
    top: unset;
  }
}

.waffle-header__icon {
  &.waffle-header__icon--delete {
    line-height: 1.9;

    @include breakpoint-l {
      line-height: 1.6;
    }
  }
}

.waffle-header__icon--angle-down {
  top: rem(2px);
}

.waffle-header__loading {
  min-width: rem(80px);
}

@include style-only-for("today") {
  .waffle-header {
    &__filters__cta {
      margin: 16px auto 32px;
      text-align: center;
    }

    &__filters__cta-taglist {
      margin: 12px auto 32px;
    }

    &__collection-lead.pkg:not(.lead) {
      margin-bottom: 16px;
    }

    @include breakpoint-s-only {
      margin-bottom: 0;

      &__filter-overlay--open.waffle-header__layout-grid-item {
        top: 0;
      }

      &__mobile-filter {
        button {
          @include responsive-variations(width, $waffle-header__mobile-filter-button-width);
          margin: auto;
          text-align: center;
        }
      }
    }

    @include breakpoint-m {
      &__filters__cta {
        margin: 32px auto 48px;
      }

      &__mobile-filter {
        button {
          @include responsive-variations(width, $waffle-header__mobile-filter-button-width);
          margin: auto;
          text-align: center;
        }
      }

      &-filter-overlay--open.waffle-header__layout-grid-item {
        top: 0;
      }
    }

    @include breakpoint-l {
      &__filters__cta {
        display: none;
      }

      &__collection-lead.pkg:not(.lead) {
        margin-bottom: -60px;
      }
    }
  }
}

.waffle-card {
  overflow: visible;
  position: relative;
  margin-bottom: 48px;
  flex-basis: calc(100% - 2px);

  &.waffle-card__card-in-ad-layout {
    @include breakpoint-m {
      flex-basis: calc((100% / 3) - 2.33px);
    }

    @include breakpoint-l {
      flex-basis: calc((100% / 2) - 20.33px);
    }
  }

  // 3 cards across
  @include breakpoint-m {
    flex-basis: calc((100% / 3) - 2.33px);
    margin-bottom: $waffle-card-gutter-desktop;
  }

  // 3 cards across plus grid spacing in between
  @include breakpoint-l {
    flex-basis: calc((100% / 3) - 27px);
    transition: box-shadow 0.2s;
  }

  &:hover {
    box-shadow: none;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
      pointer-events: none;
    }

    @include breakpoint-l {
      box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);

      &::after {
        content: '';
        display: none;
      }
    }
  }

  &.waffle-card__disabled:hover {
    box-shadow: none;
  }

  .spaceLast &:last-child {
    @include breakpoint-m {
      margin-left: 2px;
      margin-right: auto;
    }

    @include breakpoint-l {
      margin-left: $waffle-card-gutter-desktop;
      margin-right: auto;
    }
  }

  .waffle-list__space-last.waffle-list__ads-included &:last-child {
    @include breakpoint-l {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include breakpoint-m {
    overflow: hidden;
  }

  &.overlayState {
    border: none;
    transition: none;
    box-shadow: none;
  }
}

.waffle-card__scrollable-more-info {
  overflow-y: auto;
  height: calc(100% - 50px);

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    background: $waffle-card-scrollable-more-info-background;
    bottom: 45px;
    left: 0;
  }
}

.waffle-card__sticky-ad {
  top: rem(90px);

  &:hover {
    box-shadow: none;
    transition: none;
  }

  // adding negative margin top because on IE top is pushing down the ad so we need to offset it.
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: rem(-90px);
  }
}

.waffle-card__ad {
  padding: 0;
  margin-bottom: 48px;
  width: unset;

  @include breakpoint-m {
    margin-bottom: 0;
    padding: 27px;
  }

  @include breakpoint-l {
    padding: 0;
  }

  @include breakpoint-x {
    padding: 27px;
  }

  @include style-only-for('today') {
    div[data-mps-slot^="boxrail"],
    div[data-mps-slot^="boxflex"] {
      // ads returned on today already has margin set from Ad component
      padding-top: $waffle-card-boxrail-boxflex-padding;

      iframe {
        @include breakpoint-l {
          margin: $waffle-card-boxrail-boxflex-iframe-margin !important;
        }
      }
    }
  }
}

.waffle-card__mid-ad {
  margin-top: -40px;
  padding: 40px;
}

.waffle-card__mid-responsive-mobile {
  margin-bottom: rem(48px);

  @include breakpoint-m {
    margin-bottom: 0;
  }
}

// Waffle Filter
.filter-dropdown {
  column-count: 2;
  z-index: 3;
  text-align: left;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @include breakpoint-m {
    column-count: 3;
  }
}

.filter-dropdown--option {
  display: inline-block;
  opacity: 1;
  width: 100%;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.filter-dropdown--option .filter-dropdown--option-label {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  user-select: none;
}

.filter-dropdown--option-label::before {
  content: '';
  box-sizing: border-box;
  display: block;
  vertical-align: baseline;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(56, 5, 56, 0.4);
  background: $white;
  margin-right: 12px;
  flex-shrink: 0;
}

.filter-dropdown--disable {
  pointer-events: none;
}

.filter-dropdown--disable .filter-dropdown--label {
  opacity: .5;

  &::before {
    background: $grey-40;
  }
}

.filter-dropdown--option input:checked + .filter-dropdown--option-label::before {
  background: $filter-dropdown-option-label-get-background;
}

.filter-dropdown--filter {
  margin-bottom: 48px;
  margin-bottom: 3rem;

  @include breakpoint-m {
    margin-bottom: 4rem;
  }
}

.filter-dropdown--filter-mame {
  border-bottom: 1px solid $grey-40;
}

@include breakpoint-l {
  .filter-dropdown--filter-mame {
    border: 2px solid $white;
    border-radius: 100px;
    min-width: rem(240px);

    .filter-dropdown--filter-open & {
      background: $filter-dropdown-filter-open-background;
    }
  }

  .filter-dropdown {
    column-count: auto;
    background: rgba(255, 255, 255, 0.9);
    visibility: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    padding: .75rem 0;
    display: block;

    .filter-dropdown--filter-open & {
      visibility: visible;
    }
  }

  .filter-dropdown--filter {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    position: relative;
    margin-bottom: 0;

    &:nth-child(3) {
      margin-left: rem(60px);
      margin-right: rem(60px);
    }

    .icon-angle-down {
      transition: transform .2s;
    }

    &.filter-dropdown--filter-open {
      background: rgba(255, 255, 255, 0.9);

      .icon-angle-down {
        transform: rotateX(-180deg);
      }
    }

    &:not(.filter-dropdown--filter-open) {
      transition: background 0s $filter-dropdown-animation-delay;

      .filter-dropdown-filter--open-name {
        transition: background 0s $filter-dropdown-animation-delay;
      }

      .filter-dropdown {
        transition: visibility 0s $filter-dropdown-animation-delay;
      }

      .icon-angle-down {
        transition-delay: $filter-dropdown-animation-delay;
      }
    }
  }

  .filter-dropdown--option {
    width: 100%;
    display: flex;
    margin: 1.5rem;
  }

  .filter-dropdown--option-label:hover {
    opacity: .8;
  }

  .filter-dropdown--option-label:hover::before {
    background-color: rgba(56, 5, 56, 0.4);
  }
}

// Waffle Tease Image
.waffle-tease-image {
  position: relative;
}

.waffle-tease-image__wrapper {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.waffle-tease-image__background {
  position: relative;
  padding-top: 100%;
  background: url('/waffleBackground.svg') no-repeat center center;
  background-size: 101%; // use 101% to cover 1px on the right of card
}

// Waffle List
.waffle-list__container {
  align-items: center;

  .waffle-list__article-layout {
    @include breakpoint-m {
      width: calc(100% + 40px);
    }

    @include breakpoint-l {
      width: calc(8.33333% * 8);
    }
  }

  @include breakpoint-m {
    align-items: stretch;
  }
}
