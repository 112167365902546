$lead-one-up-background-color: $default-color !default;
$lead-one-up-background-content: '' !default;
$lead-one-up-icon-link-display: none !default;
$lead-one-up-quote-display: none !default;
$lead-one-up-quote-text-color: $default-color !default;
$lead-one-up-unibrow-position: static !default;

$lead-one-up-eyebrow-text-color: $default-color !default;
$lead-one-up-eyebrow-position: absolute !default;
$lead-one-up-eyebrow-position-bottom: (s: rem(50px), m: rem(35px)) !default;
$lead-one-up-eyebrow-position-left: rem(20px) !default;
$lead-one-up-eyebrow-padding: 0 !default;
$lead-one-up-eyebrow-order: 0 !default;

$lead-one-up-featured-author-image-display: block !default;
$lead-one-up-featured-author-font-size: (s: rem(18px), m: rem(20px), l: rem(24px)) !default;

$lead-one-up-sponsored-info-text-color: $white !default;
$lead-one-up-sponsored-info-bg-color: $grey-70 !default;
$lead-one-up-sponsored-quote-text-color: $white !default;

$lead-one-up-info-height: (s: rem(260px), m: rem(330px)) !default;
$lead-one-up-info-color: #f1f0ed !default;
$lead-one-up-info-top-border-image: none !default;
$lead-one-up-info-pseudo-bg-color: $white !default;
$lead-one-up-outer-gutter: 20px !default;

$lead-one-up-title-font-family: $publico-hed !default;
$lead-one-up-title-font-size: (s: rem(24px), m: rem(32px), l: rem(20px), x: rem(24px)) !default;
$lead-one-up-title-line-height: 1.25 !default;
$lead-one-up-title-font-weight: 400 !default;
$lead-one-up-title-position: relative !default;
$lead-one-up-title-color: $grey-70 !default;
$lead-one-up-title-top-margin: 0.83em !default;

$lead-one-up-picture-width: (s: 100%, m: 65%, l: rem(680px), x: rem(870px)) !default;
$lead-one-up-picture-top-margin: (s: auto, m: rem(40px)) !default;
$lead-one-up-picture-right-margin: (s: auto) !default;
$lead-one-up-picture-zindex: (s: auto) !default;

@include override-tokens-for('better') {
  $lead-one-up-quote-display: block !global;
}

@include override-tokens-for('news') {
  $lead-one-up-background-color: $blue-70 !global;
  $lead-one-up-quote-text-color: $blue-70 !global;
  $lead-one-up-eyebrow-text-color: $blue-70 !global;
}

@include override-tokens-for('noticias') {
  $lead-one-up-background-color: $blue-60 !global;
  $lead-one-up-quote-text-color: $blue-60 !global;
  $lead-one-up-eyebrow-text-color: $blue-60 !global;
}

@include override-tokens-for('today') {
  $lead-one-up-icon-link-display: block !global;
  $lead-one-up-quote-display: none !global;
  $lead-one-up-title-color: $purple-70 !global;
  $lead-one-up-background-content: none !global;
  $lead-one-up-unibrow-position: relative !global;
  $lead-one-up-featured-author-image-display: none !global;

  $lead-one-up-eyebrow-position: relative !global;
  $lead-one-up-eyebrow-position-bottom: (s: auto, m: auto) !global;
  $lead-one-up-eyebrow-position-left: auto !global;
  $lead-one-up-eyebrow-padding: rem(24px) 0 rem(4px) !global;

  $lead-one-up-info-height: (s: auto) !global;
  $lead-one-up-info-top-border-image: linear-gradient(to right, $default-color, #a508a5) 1 !global;
  $lead-one-up-picture-width: (s: 100%, m: rem(420px), l: rem(320px), x: rem(460px)) !global;
  $lead-one-up-picture-top-margin: (s: 0) !global;
  $lead-one-up-picture-right-margin: (s: auto, l: 0) !global;
  $lead-one-up-picture-zindex: (s: 1, m: 2) !global;
}

@include override-tokens-for('think') {
  $lead-one-up-quote-display: block !global;
  $lead-one-up-title-font-size: (s: rem(18px), m: rem(20px), x: rem(24px)) !global;
  $lead-one-up-title-font-weight: 300 !global;
  $lead-one-up-title-position: static !global;
  $lead-one-up-title-top-margin: 0 !global;
  $lead-one-up-eyebrow-order: 2 !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $lead-one-up-info-pseudo-bg-color: $neutral-30 !global;
  $lead-one-up-title-font-family: $founders-cond !global;
  $lead-one-up-title-color: $blue-60 !global;
  $lead-one-up-title-font-size: (s: rem(24px), m: rem(40px), l: rem(28px), x: rem(40px)) !global;
  $lead-one-up-title-line-height: 1 !global;
  $lead-one-up-title-font-weight: 600 !global;
  $lead-one-up-title-top-margin: rem(12px) !global;

  $lead-one-up-icon-link-display: block !global;
  $lead-one-up-quote-display: none !global;
  $lead-one-up-background-content: none !global;
  $lead-one-up-unibrow-position: relative !global;
  $lead-one-up-featured-author-image-display: none !global;

  $lead-one-up-eyebrow-position: relative !global;
  $lead-one-up-eyebrow-position-bottom: (s: auto, m: auto) !global;
  $lead-one-up-eyebrow-position-left: auto !global;
  $lead-one-up-eyebrow-padding: rem(24px) 0 0 !global;

  $lead-one-up-info-height: (s: auto) !global;
  $lead-one-up-info-top-border-image: linear-gradient(to right, $default-color, #a508a5) 1 !global;
  $lead-one-up-picture-width: (s: 100%, m: rem(420px), l: rem(320px), x: rem(460px)) !global;
  $lead-one-up-picture-top-margin: (s: 0) !global;
  $lead-one-up-picture-right-margin: (s: auto, l: 0) !global;
  $lead-one-up-picture-zindex: (s: 1, m: 2) !global;
}
