// NOTE: this is a full migration of Tease List to themes with the exception of:
// 1. Timestamps (.timestamp) and corresponding item padding
// 2. Non-TODAY tease images (.teasePicture)
// 3. Paid content (.adSource, .paid, .trendingPaid)
// 4. Disclaimer information (.disclaimerBox, .disclaimerButton) [no longer needed, no StackCommerce]
// 5. showNewsTitle/hideNewsTitle which handles removing title on tabbed layouts
//
// It is recommended to re-use this CSS or perhaps build the above
// upon this cleaned-up component upon full migration of Tease List.
// Both A La Carte and Tease List are exactly the same with the exception of styling in a rail.
// To divide the styling between rail with timestamps and rail basic items, the package can utilize
// a bool prop to swap design + a CSS modifier like .${pkg}--latest-stories

.a-la-carte {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%; // required for IE

  &--shrink-to-fit {
    flex: 0 1 auto !important; // must override parent's direct descendant selector (0 0 auto)
    min-height: 0; // required for Firefox/IE

    @include breakpoint-l {
      @include ie-flex-basis;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .a-la-carte--shrink-to-fit & {
      flex: 0 1 auto;
      min-height: 0; // required for Firefox/IE

      & > * {
        flex: 0 0 auto;
      }

      @include breakpoint-l {
        height: 100%;
      }

      @include breakpoint-l {
        @include ie-flex-basis;
      }
    }
  }

  &__top-decoration {
    display: none;
    border-top: 1px solid $a-la-carte-top-decoration-border-color;
    margin: 0 $space-20; // migrated from outer-gutter

    // Enable top decoration in rail only
    .a-la-carte--in-rail & {
      display: block;
    }

    @include breakpoint-m {
      margin: 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: $a-la-carte-top-decoration-tab-width;
      height: $a-la-carte-top-decoration-tab-height;
      background-color: $a-la-carte-top-decoration-tab-color;
      top: $a-la-carte-top-decoration-tab-top;
      left: $space-20;

      @include breakpoint-m {
        left: 0;
      }
    }
  }

  &__title {
    @include responsive-variations(margin-left, $a-la-carte-title-margin-left);
    font-family: $a-la-carte-title-font-family;
    font-size: $text-24;
    font-weight: $a-la-carte-title-font-weight;
    font-style: $a-la-carte-title-font-style;
    line-height: $a-la-carte-title-line-height;
    letter-spacing: $a-la-carte-title-letter-spacing;
    color: $a-la-carte-title-color;
    margin: 0 $space-20 0 $space-20;

    @include breakpoint-l {
      margin: 0;
    }

    // Top decoration only in rail
    .a-la-carte--in-rail & {
      margin-top: $a-la-carte-title-margin-top;
    }
  }

  &__description {
    // Re-use title left spacing responsive variations as it should match
    @include responsive-variations(margin-left, $a-la-carte-title-margin-left);
    margin-top: $space-4;
    margin-bottom: 0;
    color: $grey-60; // using design token value as older toolkit (3.x) uses different number syntax
  }

  &__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    // When the items wrapper has a sibling above, add spacing
    &:nth-child(n+2) {
      margin-top: $space-20;
    }

    // Decorations only in rail
    .a-la-carte--in-rail & {
      margin-top: $a-la-carte-content-margin-top;

      @include style-only-for('today') {
        background-color: $a-la-carte-body-decoration-color;
        padding: $space-20 0;
        margin-right: $space-20;
        width: calc(100% - #{$a-la-carte-body-decoration-offset}); // decoration offset

        @include breakpoint-m-only {
          width: calc(100% - #{$a-la-carte-body-decoration-offset-m}); // decoration offset
        }

        &::before {
          @include pattern-dots($a-la-carte-body-decoration-color);
          content: '';
          position: absolute;
          opacity: $opacity-50;
          width: calc(100% - #{$space-124});
          height: 75%; // hard-coded due to design spec
          top: -$space-20;
          right: -$a-la-carte-body-decoration-offset; // decoration offset
          z-index: 1;

          @include breakpoint-m-only {
            width: 40%; // hard-coded due to design spec
            right: -$a-la-carte-body-decoration-offset-m;  // decoration offset
          }

          @include breakpoint-l {
            width: calc(100% - #{$space-68});
            right: -$a-la-carte-body-decoration-offset; // decoration offset
          }
        }
      }
    }

    .a-la-carte--shrink-to-fit & {
      flex: 0 1 auto !important; // must override parent's direct descendant selector (0 0 auto)
      min-height: 0; // required for Firefox/IE

      @include breakpoint-l {
        @include ie-flex-basis;
      }
    }
  }

  &__items-wrapper {
    position: relative;
    flex-grow: 1;

    .a-la-carte--shrink-to-fit & {
      min-height: 0; // required for Firefox/IE
      flex: 0 1 auto;

      @include breakpoint-l {
        @include ie-flex-basis;
        overflow: hidden;
      }

      // Overflow curtain
      &::after {
        @include breakpoint-l {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;
          width: 100%;
          height: $space-80;
          background-image: linear-gradient(to bottom, rgba($white, 0), $white);
        }
      }
    }

    @include style-only-for('today') {
      .a-la-carte--in-rail & {
        background-color: $white;
        z-index: 2;
        left: $space-20;
        padding: $space-20 0;
        // width = 100% + decoration offset - gutter (20) - right spacing (20)
        width: calc(100% + #{$a-la-carte-body-decoration-offset} - #{$space-40}); // decoration offset

        @include breakpoint-m-only {
          display: flex;
          flex-wrap: wrap;
          // width = 100% + decoration offset - gutter (20) - right spacing (20)
          width: calc(100% + #{$a-la-carte-body-decoration-offset-m} - #{$space-40}); // decoration offset
        }

        @include breakpoint-l {
          padding-top: $space-28;
        }
      }
    }
  }

  &__items {
    width: 100%; // needed on IE

    @include breakpoint-m-only {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    position: relative;
    width: 100%;

    // Use margin when not in rail to collapse double spacing
    margin: $space-12 0;

    &--no-art {
      margin: $space-20 0;
    }

    @include breakpoint-m-only {
      // flexbox does not collapse margins so remove bottom margin
      margin-bottom: 0;
    }

    // Borders only in rail
    .a-la-carte--in-rail & {
      margin: 0;
      border-bottom: 1px dashed $grey-50;
      padding: $space-12 0;

      &--no-art {
        padding: $space-20 0;
      }
    }

    &:first-child {
      margin-top: 0;

      @if ($theme != 'today') {
        padding-top: 0;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    @include style-only-for('today') {
      .a-la-carte--in-rail & {
        width: auto; // reset above
        display: flex;
        margin: rem(0 20px);

        // Borders only in rail
        border-bottom: none; // reset above
        background-image: linear-gradient(to right, $grey-60 17%, $white 0%);
        background-position: top;
        background-size: 6px 1px;
        background-repeat: repeat-x;

        // Breakpoint `m` has alternate item layout for body variation
        @include breakpoint-m-only {
          // Every child after first is side-by-side on M
          &:nth-child(n+2) {
            flex-basis: calc(50% - #{$space-32});

            .a-la-carte__headline {
              font-size: $text-16;
            }
          }

          // Right side of side-by-side items
          &:nth-child(2n+1) {
            margin-left: 0;
          }

          &:first-child {
            flex-basis: 100%;
            margin-left: $space-20;

            .a-la-carte__headline {
              font-size: $text-20;
            }
          }
        }
      }
    }
  }

  &__unibrow {
    color: $a-la-carte-unibrow-color;
    margin-bottom: $a-la-carte-unibrow-margin-bottom;

    &--opinion {
      color: $think-red-40;
    }
  }

  &__image-wrapper {
    @include responsive-variations(width, $a-la-carte-tease-image-size);
    @include responsive-variations(height, $a-la-carte-tease-image-size);
    @include responsive-variations(min-width, $a-la-carte-tease-image-size);
    position: relative;

    @include style-only-for('today') {
      .a-la-carte--in-rail & {
        margin-right: $space-8;
      }
    }
  }

  &__image {
    // Rounded image style
    @include style-only-for('today') {
      .a-la-carte--in-rail & {
        display: block;
        overflow: auto;
        border-radius: 50%;
      }
    }
  }

  &__type-icon {
    left: 0;
    bottom: 0;
    z-index: 3; // supercede Vilynx image
  }

  &__headline {
    @include responsive-variations(font-size, $a-la-carte-tease-headline-font-size);
    font-family: $a-la-carte-tease-headline-font-family;
    font-weight: $a-la-carte-tease-headline-font-weight;
    line-height: $a-la-carte-tease-headline-line-height;
    color: $a-la-carte-tease-headline-color;
    margin: 0;

    &:hover {
      opacity: $default-hover-opacity;
    }
  }

  &__button-wrapper {
    padding-top: $space-20;

    .a-la-carte--in-rail & {
      flex: 0 0 auto;
      padding-top: $space-20;
      justify-content: center;
    }

    @include style-only-for('today') {
      .a-la-carte--in-rail & {
        padding-top: 0; // spacing handled by content bottom

        // width = 100% + decoration offset - gutter (20) - right spacing (20)
        width: calc(100% + #{$a-la-carte-body-decoration-offset} - #{$space-40}); // decoration offset
        padding-bottom: $space-20;
        margin-left: $space-20;
        background: $white;

        @include breakpoint-m-only {
          // width = 100% + decoration offset - gutter (20) - right spacing (20)
          width: calc(100% + #{$a-la-carte-body-decoration-offset-m} - #{$space-40}); // decoration offset
        }

        // makes sure to overlap background decoration
        position: relative;
        z-index: 2;
      }
    }
  }

  &__button {
    color: $a-la-carte-button-color;
    border: 1px solid $a-la-carte-button-border-color;
    width: 100%;
    margin: 0 $space-20;

    @include breakpoint-m {
      margin: 0;
      width: $space-192;
    }

    @include breakpoint-l {
      width: $space-120;
    }

    .a-la-carte--in-rail & {
      @include breakpoint-l {
        width: 100%;

        @include style-only-for('today') {
          width: calc(100% - #{$space-40});
        }
      }
    }
  }
}
