.tabbed-content__tab-list-item {
  background: $tabbed-content-internal-tab-list-item-background-color;
  border: none;
  color: $tabbed-content-internal-tab-list-item-font-color;
  font-family: $founders-semibold-font-family;
  font-size: 16px;
  outline: none;
  padding: 16px 0;
  text-transform: uppercase;
  width: 50%;

  @include breakpoint-m {
    font-size: 20px;
    padding: 25px 0;
  }
}

.tabbed-content__tab-list-item--active {
  background: $tabbed-content-internal-tab-list-item-active-background-color;
  color: $tabbed-content-internal-tab-list-item-active-font-color;
}
