.typeicon {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $typeicon-color;
  width: rem(30px);
  height: rem(30px);
  background: $typeicon-background;
  position: absolute;
  left: auto;
  bottom: 0;
  font-size: 6px;
  line-height: 1;
  z-index: 10;

  @include breakpoint-x {
    width: rem(32px);
    height: rem(32px);
  }

  &__radius {
    left: $typeicon-radius-position-left;
    bottom: $typeicon-radius-position-bottom;
  }

  &.icon-video.pointer-events-initial {
    pointer-events: initial;
  }
}
