.article-hero__container {
  position: relative;

  &::before {
    content: '';
    display: block;
    height: $article-hero-top-border-height;
    background-color: $article-hero-top-border-bg-color;
    background-image: $article-hero-top-border-bg-img;
    position: absolute;
    right: 0;
    top: rem(20px);
    z-index: 2;
    width: calc(100% - 20px);

    @include breakpoint-m {
      top: rem(40px);
    }

    @include breakpoint-l {
      width: calc(920px + ((100% - 920px) / 2));
    }

    @include breakpoint-x {
      width: calc(1160px + ((100% - 1160px) / 2));
    }

    .chromeless {
      display: none;
    }
  }

  &.article-hero__shopping-section-container::before {
    background-color: $grey-50;
  }

  .article-hero__header {
    @include clearfix;
    position: relative;
    padding: rem(45px $article-hero-outer-gutter-width-mobile 40px $article-hero-outer-gutter-width-mobile);
    z-index: 1;

    @include breakpoint-m {
      padding: rem($navbar-mobile-height 0 $navbar-mobile-height 0);
    }

    @include breakpoint-l {
      padding-bottom: rem(110px);
    }

    @include breakpoint-x {
      padding-top: rem($navbar-default-height);
    }

    &.article-hero__is-tagged {
      padding-top: rem(65px);

      @include breakpoint-m {
        padding-top: rem(85px);
      }

      // Reset to initial values
      @include breakpoint-l {
        padding-top: rem($navbar-mobile-height);
      }

      @include breakpoint-x {
        padding-top: rem($navbar-default-height);
      }
    }

    &.article-hero__ecommerce-enabled {
      padding-top: rem(20px);

      @include breakpoint-m {
        padding-top: rem(40px);
      }

      &.article-hero__shopping-section {
        &::before {
          background-color: $white;
        }
      }
    }
  }

  .article-hero__bg-container {
    position: relative;

    &::before {
      content: '';
      background-color: $article-hero-header-bg-color;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100vw;
    }
  }

  &.article-hero__shopping-section-container .article-hero__bg-container::before {
    background-color: $white;
  }

  .article-hero__unibrow-grid {
    @include breakpoint-l {
      padding-top: rem(35px);

      .articleTitleSection {
        margin: rem(5px) 0 rem(5px) 0;
      }
    }

    &.article-hero__ecommerce-enabled {
      margin-top: rem(35px);

      .article-hero__live-blog {
        margin-top: rem(55px);
      }
    }
  }

  .article-hero__tax-term {
    position: relative;
    font-family: $founders-mono;
    color: $article-hero-taxonomy-term-color;
    font-size: rem(12px);
    text-transform: uppercase;
    letter-spacing: -0.04em;
    font-weight: 400;
    z-index: 3;

    &.article-hero__shopping-section {
      color: $grey-70;
    }
  }

  .article-hero__headline {
    color: $article-hero-headline-color;

    &.article-hero__shopping-section {
      color: $grey-70;
    }
  }

  .article-hero__featured {
    display: inline-block;
    margin-right: rem(5px);

    @include breakpoint-m {
      margin-right: rem(10px);
    }

    @include style-only-for('think') {
      font-family: $founders-cond;
      font-size: rem(32px);
      line-height: 1.25;
      font-weight: 300;

      @include breakpoint-m {
        font-size: rem(48px);
      }
    }
  }
}

.article-hero__media-holder {
  position: relative;
  z-index: 1;
}

.article-hero__caption {
  margin-top: rem(16px);
  padding-left: $article-hero-outer-gutter-width-mobile;
  padding-right: $article-hero-outer-gutter-width-mobile;

  @include breakpoint-m {
    padding-left: 0;
    padding-right: 0;
  }
}

.article-hero__sponsor {
  width: 100%;
}

.spread {
  .article-hero__media-holder {
    @include breakpoint-l {
      float: right;
      width: calc(100% - ((100% - 920px) / 2));
      margin-top: rem(-175px);
    }

    @include breakpoint-x {
      width: calc(100% - ((100% - 1160px) / 2));
    }
  }

  .article-hero__main {
    @include breakpoint-l {
      position: relative;
    }

    &::before {
      @include breakpoint-l {
        @include pattern-dots($white);
        content: '';
        display: block;
        width: rem(80px);
        height: rem(40px);
        position: relative;
        left: -2px;
        top: -2px;
      }
    }

    &::after {
      @include breakpoint-l {
        content: '';
        background-color: $article-hero-spread-hero-bg-color;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: rem(65px);
        top: rem(113px);
        left: calc(-100% + 920px);
      }

      @include breakpoint-x {
        height: rem(80px);
        left: calc(-100% + 1160px);
      }
    }

    .article-hero__main-image {
      &::before {
        @include breakpoint-l {
          content: '';
          background-color: $article-hero-spread-hero-main-image-bg-color;
          position: absolute;
          left: rem(120px);
          height: rem(80px);
          width: calc(100% - 120px);
        }

        @include breakpoint-x {
          width: calc(100% - 100px);
          left: rem(100px);
        }
      }
    }
  }
}

.default {
  .article-hero__header {
    @include breakpoint-m {
      padding-bottom: rem(112px);
    }

    @include breakpoint-l {
      padding-bottom: rem(118px);
    }

    @include breakpoint-x {
      padding-bottom: rem(106px);
    }
  }

  .article-hero__media-container {
    @include clearfix;
  }

  .article-hero__main,
  .article-hero__video {
    @include breakpoint-m {
      width: 100%;
      margin-left: 0;
      margin-top: rem(-80px);
    }

    @include breakpoint-l {
      margin-top: rem(-100px);
    }

    .article-hero__video-outer {
      position: relative;

      &::before {
        content: '';
        display: block;
        padding-top: 56.25%;
      }

      .article-hero__video-inner {
        background-color: $black;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.none {
  .article-hero__header {
    @include breakpoint-l {
      padding-bottom: rem(47px);
    }

    @include breakpoint-x {
      padding-bottom: rem(60px);
    }
  }

  & .article-hero__shopping-section {
    padding-bottom: 0;
  }
}

.article-hero__sticky-video {
  position: relative;
  // use sticky-video-hero-parent so in liveblog
  // sticky video doesn't go under article video content
  z-index: get-z-index('sticky-video-hero-parent');
}
