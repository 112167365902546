$z-global: (
  "container": 0,
  "page": 1,
  "article-ad": 10, // incrementing by 10 to avoid component local z-index
  "article-content": 20,
  "sticky-video-parent": 30,
  "sticky-video-hero-parent": 40,
  "sticky-ad": 1000,
  "sticky-video-player": 1010,
  "dev-tools": 11000,
  "header": 12000, //set in HFS service
);

@function get-z-index($value) {
  $result: map-get($z-global, $value);

  @if ($result != null) {
    @return $result;
  }

  @warn "No matching key for #{$value} in $z-global.";

  // Returning a value - when null is returned
  // z-index isn't shown as property in the DOM
  @return 'FAILED_Z_INDEX';
}
