$show-lead-background-image-content-after: '' !default;
$show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), #000 80%) !default;

$show-lead-background-gradient: (
  s: linear-gradient(111deg, rgba($default-gradient-value-1, 1), rgba($default-gradient-value-2, 1)),
  l: linear-gradient(111deg, rgba($default-gradient-value-1, 1) 20%, rgba($default-gradient-value-2, 1)),
  x: linear-gradient(111deg, rgba($default-gradient-value-1, 1) 25%, rgba($default-gradient-value-2, 1))
) !default;
$show-lead-background-gradient-with-image: (
  s: linear-gradient(111deg, rgba($default-gradient-value-1, 0.7), rgba($default-gradient-value-2, 0.7)),
  m: linear-gradient(111deg, rgba($default-gradient-value-1, 0.8) 10%, rgba($default-gradient-value-2, 0.7)),
  x: linear-gradient(111deg, rgba($default-gradient-value-1, 0.8) 25%, rgba($default-gradient-value-2, 0.7))
) !default;

@include override-tokens-for('today') {
  $show-lead-background-gradient: (
    s: linear-gradient(111deg, rgba($gradient-value-1, 1), rgba($gradient-value-2, 1)),
    l: linear-gradient(111deg, rgba($gradient-value-1, 1) 20%, rgba($gradient-value-2, 1)),
    x: linear-gradient(111deg, rgba($gradient-value-1, 1) 25%, rgba($gradient-value-2, 1))
  ) !global;
  $show-lead-background-gradient-with-image: (
    s: linear-gradient(111deg, rgba($gradient-value-1, 0.7), rgba($gradient-value-2, 0.7)),
    m: linear-gradient(111deg, rgba($gradient-value-1, 0.8) 20%, rgba($gradient-value-2, 0.7)),
    x: linear-gradient(111deg, rgba($gradient-value-1, 0.8) 25%, rgba($gradient-value-2, 0.7))
  ) !global;
}

@include override-tokens-for('msnbc') {
  $show-lead-background-gradient: (
    s: linear-gradient(111deg, rgba($gradient-value-1, 1), rgba($gradient-value-2, 1)),
    l: linear-gradient(111deg, rgba($gradient-value-1, 1) 20%, rgba($gradient-value-2, 1)),
    x: linear-gradient(111deg, rgba($gradient-value-1, 1) 25%, rgba($gradient-value-2, 1))
  ) !global;
  $show-lead-background-gradient-with-image: (
    s: linear-gradient(111deg, rgba($gradient-value-1, 0.8), rgba($gradient-value-2, 0.46)),
    m: linear-gradient(111deg, rgba($gradient-value-1, 0.8) 20%, rgba($gradient-value-2, 0.46)),
    x: linear-gradient(111deg, rgba($gradient-value-1, 0.8) 25%, rgba($gradient-value-2, 0.46))
  ) !global;
}

@include override-tokens-for('noticias') {
  $show-lead-background-gradient: (
    s: linear-gradient(72.88deg, rgba($gradient-value-1, 1) 31.65%, rgba($gradient-value-2, 0.1) 89.57%),
    l: linear-gradient(100.35deg, rgba($gradient-value-1, 1) 25.84%, rgba($gradient-value-2, 0.1) 95.45%),
    x: linear-gradient(102.77deg, rgba($gradient-value-1, 1) 25.84%, rgba($gradient-value-2, 0.1) 95.45%)
  ) !global;
  $show-lead-background-gradient-with-image: (
    s: linear-gradient(72.88deg, rgba($gradient-value-1, 1) 31.65%, rgba($gradient-value-2, 0.1) 89.57%),
    m: linear-gradient(103.08deg, rgba($gradient-value-1, 1) 24.46%, rgba($gradient-value-2, 0.1) 94.95%),
    x: linear-gradient(102.77deg, rgba($gradient-value-1, 1) 25.84%, rgba($gradient-value-2, 0.1) 95.45%)
  ) !global;
}

@include override-tokens-for('telemundo') {
  $show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), $blue-70 80%) !global;
  $show-lead-background-gradient: (
    s: linear-gradient(72.88deg, rgba($blue-70, 1) 35%, rgba($blue-70, 0.1) 70%),
    l: linear-gradient(103.35deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%),
    x: linear-gradient(102.77deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%)
  ) !global;
  $show-lead-background-gradient-with-image: (
    s: linear-gradient(72.88deg, rgba($blue-70, 1) 35%, rgba($blue-70, 0.1) 70%),
    m: linear-gradient(103.08deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%),
    x: linear-gradient(102.77deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%)
  ) !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), $blue-70 80%) !global;
  $show-lead-background-gradient: (
    s: linear-gradient(72.88deg, rgba($blue-70, 1) 35%, rgba($blue-70, 0.1) 70%),
    l: linear-gradient(103.35deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%),
    x: linear-gradient(102.77deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%)
  ) !global;
  $show-lead-background-gradient-with-image: (
    s: linear-gradient(72.88deg, rgba($blue-70, 1) 35%, rgba($blue-70, 0.1) 70%),
    m: linear-gradient(103.08deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%),
    x: linear-gradient(102.77deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%)
  ) !global;
}

@include override-tokens-for('telemundoshows') {
  $show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), $blue-70 80%) !global;
  $show-lead-background-gradient: (
    s: linear-gradient(72.88deg, rgba($blue-70, 1) 35%, rgba($blue-70, 0.1) 70%),
    l: linear-gradient(103.35deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%),
    x: linear-gradient(102.77deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%)
  ) !global;
  $show-lead-background-gradient-with-image: (
    s: linear-gradient(72.88deg, rgba($blue-70, 1) 35%, rgba($blue-70, 0.1) 70%),
    m: linear-gradient(103.08deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%),
    x: linear-gradient(102.77deg, rgba($blue-70, 1) 25%, rgba($blue-70, 0.1) 70%)
  ) !global;
}
