.with-sticky {
  position: relative;

  &--stuck {
    position: fixed;
    z-index: get-z-index('sticky-video-parent');
    top: auto;
    right: 0;
    bottom: rem(20px);
    left: auto;
    padding-right: rem(30px);
    background-color: $black;
    width: rem(282px);
    height: rem(147px);

    @include breakpoint-m {
      bottom: rem(40px);
      width: rem(393px);
      height: rem(205px);

      // Account for potentially displayed, undetectible, 'smart app' banner on ios.
      @supports (-webkit-overflow-scrolling: touch) {
        bottom: rem(110px);
      }
    }

    @include breakpoint-l {
      width: rem(362px);
      height: rem(188px);
    }

    @include breakpoint-x {
      width: rem(400px);
      height: rem(208px);
    }
  }

  &__close {
    display: none;
    position: absolute;
    top: rem(8px);
    right: rem(8px);

    .with-sticky--stuck & {
      display: block;
    }

    &-button {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;

      &::before {
        font-size: rem(14px);
        color: $white;
      }
    }

    &-label {
      display: none;
    }
  }
}
