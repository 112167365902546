.collection__container {
  position: relative;
}

.collection__header {
  border-color: $collection-header-border-color;
  border-width: 5px 0 0;
  border-style: solid;
  position: relative;

  @include breakpoint-m {
    margin: 0;
    width: auto;
  }

  @each $vertical, $color in $collection-modulealternate-color-map {
    .#{$vertical}-module & {
      border-color: $color;
    }
  }
}

.collection__headings {
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  padding-bottom: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  padding-left: rem(40px);
  padding-right: rem(40px);

  @include breakpoint-l {
    padding-bottom: 8%;
  }

  @include breakpoint-x {
    padding-bottom: 25%;
  }
}

.collection__placeholder {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding-top: 100%;

  @include breakpoint-l {
    padding-top: rem(250px);
  }

  @include breakpoint-x {
    padding-top: 100%;
  }

  .collection__wide & {
    @include breakpoint-m {
      padding-top: 46%;
    }

    @include breakpoint-l {
      padding-top: rem(323px);
    }

    @include breakpoint-x {
      padding-top: rem(375px);
    }
  }
}

.collection__background {
  position: relative;
  z-index: 2;
  display: block;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 19.74%, rgba(0, 0, 0, 0.5) 70.79%), rgba(0, 0, 0, 0.2);

    @each $vertical, $color in $collection-primary-color-map {
      .#{$vertical}-module & {
        background: transparentize($color: $color, $amount: 0.5);
      }
    }
  }

  & img {
    filter: grayscale(30%);
  }
}

.collection__heading {
  font-family: $founders-semibold-font-family;
  font-size: 48px;
  font-weight: normal;
  line-height: 48px;
  text-transform: uppercase;
  color: $white;
  margin: rem(0 40px 10px);
  text-align: center;
  width: 100%;

  .collectionPoster & {
    @include responsive-variations(font-size, $collection-collectionPoster-heading-responsive-font-sizes);
    @include responsive-variations(line-height, $collection-heading-responsive-line-heights);
    font-family: $founders-semibold-font-family;
  }
}

.collection__subheading {
  @include responsive-variations(font-size, $collection-subheading-responsive-font-sizes);
  @include responsive-variations(line-height, $collection-subheading-responsive-line-heights);
  font-family: $publico-font-family;
  font-weight: normal;
  color: $white;
  margin: rem(0 20px);
  width: 100%;
  text-align: center;
}

.collection__articles {
  margin: calc(-25% - #{$collection-articles-outer-gutter}) #{$collection-articles-outer-gutter} 0 #{$collection-articles-outer-gutter};
  padding: rem(20px 20px 0);
  position: relative;
  background: $collection-articles-background;
  z-index: 3;

  @include breakpoint-l {
    margin-top: calc(-8% - 20px);
  }

  @include breakpoint-x {
    margin-top: calc(-25% - 20px);
  }
}

.collection__picture {
  flex: 0 0 rem(80px);
  height: rem(80px);
  margin-right: rem(20px);
  position: relative;
  font-size: 0;
}

.collection__icon {
  background: $collection-icon-background-color;
  width: rem(30px);
  height: rem(30px);

  @each $vertical, $color in $collection-modulealternate-color-map {
    .#{$vertical}-module & {
      background: $color;
    }
  }
}

.collection__info {
  flex-grow: 1;
}

.collection__eyebrow {
  color: $collection-eyebrow-color;

  @each $vertical, $color in $collection-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }
}

.collection__title {
  color: $collection-title-color;
  font-family: $collection-title-font-family;
  font-size: $collection-title-font-size;
  font-weight: $collection-title-font-weight;
  line-height: 20px;
  margin: 0;
}

.collection__article {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: rem(20px 0);

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background: $grey-40;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:last-child::after {
    height: rem(80px);
  }

  &.collection__article--hasFeaturedAuthor {
    .collection__title {
      font-family: $collection-featured-author-title-font-family;
      font-size: $collection-featured-author-title-font-size;
      font-weight: $collection-featured-author-title-font-weight;
      line-height: 18px;
    }

    .collection__info {
      margin-left: rem(10px);
    }

    .collection__quote {
      color: $collection-featured-author-quote-color;
      font-size: rem(20px);
      margin-top: rem(4px);

      @each $vertical, $color in $collection-modulealternate-color-map {
        .#{$vertical}-module & {
          color: $color;
        }
      }
    }
  }
}

.collection__author {
  color: $collection-author-color;
  font-family: $publico-font-family;
  font-size: 15px;
  font-weight: bold;
  line-height: 17px;

  &::after {
    content: " ";
  }

  @each $vertical, $color in $collection-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }
}

.collection__more {
  color: $collection-more-color;
  font-family: $founders-semibold-font-family;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  position: relative;
  margin-bottom: rem(30px);
  margin-right: $collection-articles-outer-gutter;
  text-transform: $collection-more-text-transform;

  @include breakpoint-m {
    margin: rem(0 40px 0 0);
  }

  &:hover {
    a {
      position: relative;

      &::before {
        background: currentColor;
        content: "";
        display: block;
        left: 0;
        width: 100%;
        height: rem(3px);
        position: absolute;
        bottom: rem(-2px);
      }
    }
  }

  @each $vertical, $color in $collection-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }
}

.collection__wide {
  .collection__headings {
    @include breakpoint-m {
      padding-bottom: 8%;
    }
  }

  @include style-only-for("today") {
    .collection__heading {
      font-size: rem(32px);
      text-transform: none;
      font-family: $publico-light-font-family;
      line-height: 1.25;

      @include breakpoint-m {
        font-size: rem(60px);
      }

      @include breakpoint-l {
        font-size: rem(32px);
      }

      @include breakpoint-x {
        font-size: rem(60px);
      }
    }
  }

  .collection__subheading {
    @include breakpoint-m {
      font-size: 14px;
      line-height: 20px;
    }

    @include breakpoint-x {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .collection__articles {
    @include breakpoint-m {
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(-8% - 20px);
    }

    @include breakpoint-l {
      display: block;
    }

    @include breakpoint-x {
      display: flex;
      padding: rem(30px 30px 0);
      margin-left: rem(30px);
      margin-right: rem(30px);
    }

    &::after {
      @include breakpoint-x {
        width: calc(100% - 60px);
        left: rem(30px);
      }
    }
  }

  .collection__article {
    @include breakpoint-m {
      flex-shrink: 1;
      flex-basis: calc(50% - 20px);
    }

    &::after {
      left: rem(-30px);
      width: calc(100% + 60px);
    }

    &:nth-child(even) {
      @include breakpoint-m {
        margin-right: rem(40px);
      }

      @include breakpoint-l {
        margin-right: 0;
      }

      @include breakpoint-x {
        margin-right: rem(40px);
      }
    }

    &:first-child {
      @include breakpoint-m {
        flex: 0 0 100%;
        padding-top: 0;

        &::before {
          content: none;
        }
      }

      @include breakpoint-x {
        padding-bottom: rem(30px);
      }

      .collection__picture {
        @include breakpoint-m {
          flex-basis: rem(320px);
          height: rem(160px);
        }

        @include breakpoint-l {
          flex-basis: rem(160px);
        }

        @include breakpoint-x {
          flex-basis: rem(360px);
          height: rem(180px);
        }
      }

      .collection__title {
        @include breakpoint-m {
          font-family: $collection-first-article-title-font-family;
          font-size: $collection-first-article-title-font-size;
          font-weight: $collection-first-article-title-font-weight;
          line-height: 30px;
        }
      }
    }

    &:nth-last-child(-n + 2) {
      &::after {
        @include breakpoint-m {
          height: rem(80px);
        }
      }
    }

    &:nth-last-child(2) {
      &::after {
        @include breakpoint-l {
          height: calc(100% + 40px);
        }

        @include breakpoint-x {
          height: rem(80px);
        }
      }
    }

    &.collection__article--hasFeaturedAuthor:first-child {
      .collection__quote {
        margin-top: rem(6px);
      }

      .collection__author {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        font-family: $publico-font-family;

        @include breakpoint-m {
          font-size: 24px;
          line-height: 30px;
        }
      }

      .collection__title {
        @include responsive-variations(font-size, $collection-article-featured-author-title-font-sizes);
        font-family: $collection-article-featured-author-title-font-family;
        font-weight: normal;
        line-height: 25px;

        @include breakpoint-m {
          line-height: 30px;
        }
      }
    }
  }

  .collection__more {
    @include breakpoint-x {
      margin: 0 rem(60px);
    }
  }
}
