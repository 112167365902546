$waffle-header-description-font: $default-text-font-family !default;
$waffle-header__mobile-filter-button-width: (s: rem(135px), m: rem(240px)) !default;
$waffle-header-overlay-btn-clear-all-border-color: $default-color !default;

$waffle-card-scrollable-more-info-background: linear-gradient(rgba(#fff, 0), rgba(#fff, 1)) !default;
$waffle-card-boxrail-boxflex-padding: auto !default;
$waffle-card-boxrail-boxflex-iframe-margin: auto !default;

$waffle-card-gutter-desktop: 40px !default;

$filter-dropdown-filter-open-background: $default-color !default;
$filter-dropdown-animation-delay: 0.2s !default;
$filter-dropdown-option-label-get-background: $default-color !default;

@include override-tokens-for("today") {
  $waffle-header-overlay-btn-clear-all-border-color: $purple-70 !global;

  $waffle-card-boxrail-boxflex-padding: 0 !global;
  $waffle-card-boxrail-boxflex-iframe-margin: 0 !global;
  $filter-dropdown-filter-open-background: $purple-70 !global;
  $filter-dropdown-option-label-get-background: $purple-70 !global;
}
