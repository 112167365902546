// TODO: remove when package spacing is standardized for all teases
.pkg.sticky-ad-track__container {
  .sticky-ad-track__content-items > section .tease-card {
    @include package-spacing;
  }

  .sticky-ad-track__content-items > section:last-child .tease-card:last-child {
    margin-bottom: 0;
  }
}

@include style-only-for('today') {
  .sticky-ad-track__in-front {
    .sticky-ad-track__main-column,
    .sticky-ad-track__ad-column--right {
      @include breakpoint-l {
        margin-left: 0;
      }
    }
  }
}

.sticky-ad-track__ad-column {
  margin-top: rem(30px);
  position: relative;
  order: 1;
  display: flex;
  justify-content: center;

  @include breakpoint-m {
    margin-top: rem(50px);
  }

  @include breakpoint-l {
    margin-top: 0;
    justify-content: left;
    align-items: flex-start;
    order: 0;

    &:first-child {
      .sticky-ad-track__ad {
        float: left;
      }
    }
  }
}

.sticky-ad-track__content-column {
  position: relative;
  order: 2;

  @include breakpoint-l {
    order: 0;
  }

  &::before {
    @include breakpoint-m {
      background-color: $sticky-ad-rail-content-column-bg-color;
      content: '';
      display: block;
      height: calc(100% - 1px);
      position: absolute;
      top: 0;
      left: rem(-40px);
      width: 63.6vw;
    }

    @include breakpoint-l {
      content: none;
    }
  }
}

.sticky-ad-track__content-items {
  @include clearfix;
}

.sticky-ad-track__ad {
  display: none;
  width: rem(300px);
  margin-bottom: rem(48px);
  float: right;

  @include breakpoint-m {
    width: auto;
  }

  @include breakpoint-l {
    display: block;
  }
}

.sticky-ad-track__columns-wrapper {
  @include clearfix;

  &:first-child {
    .sticky-ad-track__content-column {
      @include clearfix;
    }

    .sticky-ad-track__ad-column {
      margin-top: 0;
    }

    .sticky-ad-track__title-and-text {
      background-color: $sticky-ad-rail-column-wrapper-title-bg-color;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: rem(30px 20px 30px 40px);
      margin: 0 0 0 rem(40px);

      @include breakpoint-m {
        display: inline-block;
        flex-direction: row;
        padding: rem(30px 20px 40px 0);
        margin-left: 0;
      }
    }

    .sticky-ad-track__text-link {
      @include responsive-variations(font-size, $sticky-ad-rail-column-wrapper-text-font-size);
      font-family: $founders-cond;
      line-height: 1;
      color: $sticky-ad-rail-column-wrapper-text-link-color;
      text-transform: uppercase;
      position: relative;

      @include responsive-variations(padding-bottom, $sticky-ad-rail-column-wrapper-text-padding);
      margin-bottom: 0;
      z-index: 2;

      @include breakpoint-m {
        align-self: flex-end;
      }
    }

    .sticky-ad-track__text-underline {
      position: relative;

      &::after {
        content: $sticky-ad-rail-column-wrapper-text-underline-content;
        display: $sticky-ad-rail-column-wrapper-text-underline-display;
        background-color: $sticky-ad-rail-column-wrapper-text-underline-bg-color;
        height: rem(3px);
        width: 100%;
        position: absolute;
        bottom: rem(-3px);
        left: 0;
      }
    }

    .sticky-ad-track__latest-title {
      @include responsive-variations(font-size, $sticky-ad-track-latest-title-font-size);
      max-width: calc(100% - 40px);
      color: $white;
      font-family: $sticky-ad-track-latest-title-font-family;
      font-style: $sticky-ad-track-latest-title-font-style;
      font-weight: $sticky-ad-track-latest-title-font-weight;
      line-height: 1;
      text-transform: capitalize;
      margin-bottom: rem(10px);

      @include breakpoint-m {
        align-self: flex-end;
        background-color: transparent !important;
        margin: 0 rem(20px) 0 0;
      }
    }

    &:not(.sticky-ad-track__has-title) .sticky-ad-track__latest-title {
      @include breakpoint-l {
        display: none;
      }
    }
  }

  &:first-child.sticky-ad-track__has-title {
    .sticky-ad-track__ad-column {
      @include breakpoint-l {
        padding-top: rem(150px);
      }
    }

    .sticky-ad-track__content-column {
      position: relative;

      @include breakpoint-l {
        padding-top: 0;
      }

      &::before {
        @include breakpoint-l {
          content: '';
          width: rem(480px);
          height: rem(333px);
          left: rem(-40px);
        }
      }
    }
  }

  // special design for title
  @include style-only-for(('today', 'telemundo', 'telemundoentretenimiento', 'telemundoshows')) {
    &:first-child.sticky-ad-track__has-title {
      $outline-width-s: 60px;
      $outline-width-m: 80px;
      $outline-height-s: 65px;
      $outline-height-m: 72px;

      .sticky-ad-track__title-and-text {
        min-width: 270px;
        position: relative;
        display: inline-block;
        background-color: transparent !important;
        padding: rem(40px 0 40px 20px);
        margin-top: 0;
        margin-left: 0;
        border-top: 1px solid $sticky-ad-track-special-title-design-color;

        @include breakpoint-m {
          padding: rem(40px 0 40px 40px);
          margin-left: -4vw;
        }

        @include breakpoint-l {
          margin-left: rem(-40px);
        }

        &::after {
          content: '';
          position: absolute;
          width: $outline-width-s;
          height: $outline-height-s;
          top: -1px;
          right: 0;
          border-right: 1px solid $sticky-ad-track-special-title-design-color;
          border-bottom: 1px solid $sticky-ad-track-special-title-design-color;

          @include breakpoint-m {
            width: $outline-width-m;
            height: $outline-height-m;
          }
        }

        &.sticky-ad-track__best-of {
          @include breakpoint-m {
            padding-right: rem(120px);
          }

          @include breakpoint-l {
            border-top: none;
          }

          &::after {
            @include breakpoint-l {
              content: none;
            }
          }
        }

        &.sticky-ad-track__no-text-link {
          @include breakpoint-m {
            padding-right: rem(120px);
          }
        }
      }

      .sticky-ad-track__text-link {
        color: $sticky-ad-track-special-title-design-color;
        margin-right: rem(80px);
        padding-left: rem(10px);

        @include breakpoint-m {
          margin-right: rem(120px);
          padding-left: rem($space-20);
        }
      }

      .sticky-ad-track__latest-title,
      .sticky-ad-track__latest-title::after,
      .sticky-ad-track__content-column::before {
        border: 0 solid $sticky-ad-track-special-title-design-color;
      }

      .sticky-ad-track__latest-title {
        @include responsive-variations(font-size, $sticky-ad-track-special-title-design-font-size);
        position: relative;
        background-color: $sticky-ad-track-special-title-design-background-color;
        color: $sticky-ad-track-special-title-design-color;
        line-height: 40px;
        margin: auto auto rem(10px) 0;

        @include breakpoint-m {
          line-height: $sticky-ad-track-special-title-line-height;
          border: none;
          margin: 0;
          padding: 0;
          border-top-width: 1px;
        }
      }

      // outline on the left
      // only seen on large+
      .sticky-ad-track__content-column::before {
        background: transparent;

        @include breakpoint-l {
          z-index: 1;
          background: transparent;
          border-left-width: 1px;
          border-bottom-width: 1px;
          height: 369px;
        }

        @include breakpoint-x {
          height: 362px;
        }
      }
    }
  }

  &:last-child {
    .sticky-ad-track__content-items > article:last-child {
      // stylelint-disable-next-line no-descending-specificity
      .tease-info,
      > a > h2 {
        border-left: rem(40px) solid $white;
        margin-left: rem(-40px);
      }

      // stylelint-disable-next-line no-descending-specificity
      .tease-info {
        width: calc(100% + 80px);

        @include breakpoint-m {
          width: 100%;
        }
      }

      .sponsored {
        > a > h2,
        .tease-info {
          border-left: none;
          margin-left: 0;
        }
      }

      .has-featured-author {
        &::before {
          background-color: $sticky-ad-rail-columns-wrapper-author-bg-color;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: rem(-40px);
          width: rem(40px);
          height: 100%;
          z-index: 1;

          @include breakpoint-l {
            content: none;
          }
        }
      }
    }
  }
}
