.article-dek {
  color: $article-dek-text-color;
  font-family: $publico-hed;
  font-size: rem(16px);
  line-height: 1.5;
  font-weight: 300;
  letter-spacing: normal;

  @include breakpoint-m {
    font-size: rem(18px);
  }

  @include breakpoint-l {
    font-size: rem(20px);
  }

  @include breakpoint-x {
    font-size: rem(24px);
  }
}

.article-dek__shopping-section {
  color: $grey-70;
}

.article-dek__with-image {
  margin-bottom: rem(12px);

  @include breakpoint-m {
    margin-bottom: rem(24px);
  }
}

.article-dek__no-image {
  margin-bottom: 0;
}
