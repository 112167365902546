.article-hero-headline {
  @include breakpoint-l {
    padding-top: rem(34px);

    .article-hero-headline__shopping-section-hero {
      padding-top: rem(35px);

      .article-dek {
        padding-top: rem(5px);
      }
    }
  }
}
