
$newsletter-signup-container-margin-top: (s: rem(48px), m: rem(8px)) !default;
$newsletter-signup-container-margin-bottom: rem(48px) !default;
$newsletter-signup-before-color: $default-color !default;
$newsletter-signup-submitted-message-color: $grey-60 !default;
$newsletter-signup-descr-padding-top: 2% !default;

$newsletter-signup-title-color: $black !default;
$newsletter-signup-title-font-family: $founders-cond !default;
$newsletter-signup-title-font-style: 'inherit' !default;
$newsletter-signup-title-margin-top: $space-4 !global !default;
$newsletter-signup-title-text-transform: 'inherit' !default;

@include override-tokens-for("today") {
  $newsletter-signup-title-color: $default-color !global;
  $newsletter-signup-title-font-style: 'italic' !global;
  $newsletter-signup-title-text-transform: 'none' !global;
  $newsletter-signup-font-family: $publico-hed !global;
  $newsletter-signup-margin-top: 'inherit' !global;
}

@include override-tokens-for("noticias") {
  $newsletter-signup-submitted-message-color: $grey-70 !global;
}

@include override-tokens-for("news") {
  $newsletter-signup-container-margin-top: (s: 0) !global;
}
