.live-flag {
  position: relative;
  z-index: 3;
  display: inline-block;

  &__dot {
    height: $space-8;
    width: $space-8;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    margin-right: $space-8;
    animation: live-dot-blink 1.5s ease-in-out infinite;
  }

  &__text {
    position: relative;
    background-color: $live-flag-default-background-color;
    font-size: $text-12;
    color: $white;
    font-family: $founders-cond;
    padding: $space-12;
    text-transform: uppercase;
    z-index: 2;
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  .stripe-pattern line {
    stroke: $live-flag-default-stripe-color;
  }

  &--active {
    .live-flag__text {
      background-color: $live-flag-active-background-color;
    }

    .stripe-pattern line {
      stroke: $live-flag-active-stripe-color;
    }
  }
}
