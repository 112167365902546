.today-cover-tabbed-layout {
  @include breakpoint-l {
    margin-top: -170px;

    /*
      Overflow hidden prevents the rail background from
      causing horizontal scroll on mobile browsers.
      It is applied explicitly to the large breakpoint
      as the rail only displays at this breakpoint.
      Also overflow hidden impacts position: sticky
      which is used at smaller breakpoints.
    */
    overflow: hidden;
  }
}
