.lead-one-up {
  position: relative;

  &::before {
    content: $lead-one-up-background-content;
    display: block;
    width: 100%;
    height: 100vw;
    min-height: rem(340px);
    max-height: rem(475px);
    background-color: $lead-one-up-background-color;
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint-m {
      height: rem(350px);
    }
  }

  &.lead-one-up--rail-adjacent {
    @include clearfix;
  }
}

// Only remove top space on today where this follows the magazineLead.
.leadType-magazineLead {
  @include style-only-for(('today', 'telemundoentretenimiento')) {
    .lead-one-up {
      margin-bottom: rem(2px);
      margin-top: rem(-48px);

      &:not(.lead-one-up--rail-adjacent) {
        margin-top: rem(-48px);

        @include breakpoint-l {
          margin-bottom: rem(2px);
        }
      }
    }
  }
}

.lead-one-up__wrapper,
.lead-one-up__content {
  @include clearfix;
  position: relative;
}

.lead-one-up__picture {
  position: relative;
  display: block;
  font-size: 0;

  @include responsive-variations(width, $lead-one-up-picture-width);
  @include responsive-variations(margin-top, $lead-one-up-picture-top-margin);
  @include responsive-variations(margin-right, $lead-one-up-picture-right-margin);
  @include responsive-variations(z-index, $lead-one-up-picture-zindex);

  @include breakpoint-m {
    float: right;
  }

  // Today FullWidth
  .lead-one-up:not(.lead-one-up--rail-adjacent) & {
    @include style-only-for(('today', 'telemundoentretenimiento')) {
      width: 100%;
      float: none;

      @include breakpoint-m {
        width: calc(100% + #{2 * $lead-one-up-outer-gutter});
        margin-right: 0;
        margin-left: -$lead-one-up-outer-gutter;
      }

      @include breakpoint-l {
        width: rem(680px);
        float: right;
      }

      @include breakpoint-x {
        width: rem(900px);
      }
    }
  }
}

.lead-one-up__info {
  position: relative;

  @include responsive-variations(height, $lead-one-up-info-height);
  background: $lead-one-up-info-color;
  padding: rem(20px 40px 20px 20px);
  margin: rem(-20px $lead-one-up-outer-gutter 0 (2 * $lead-one-up-outer-gutter));
  z-index: 2;

  @include breakpoint-m {
    width: 45%;
    margin: rem(80px) -13% 0 rem(20px);
  }

  @include breakpoint-l {
    width: rem(340px);
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: rem(-20px);
    left: rem(-20px);
    border: 1px solid darken($lead-one-up-info-color, 5.5%);
    pointer-events: none;
  }

  &::after {
    border-image: $lead-one-up-info-top-border-image;

    // NOTE: hiding to see if this is the issue
    //left: rem(20px);

    // @include breakpoint-m {
    //   left: auto;
    // }
  }

  // Today railAdjacent
  @include style-only-for(('today', 'telemundoentretenimiento')) {
    width: calc(100% - #{$lead-one-up-outer-gutter});
    background: none;
    padding: rem(0 $lead-one-up-outer-gutter 40px 0);
    margin-top: 0;
    margin-left: $lead-one-up-outer-gutter;

    @include breakpoint-m {
      width: calc(100% - 420px);
      min-height: rem(352px);
      margin-left: 0;
      margin-top: rem(70px);
      float: left;
    }

    @include breakpoint-l {
      width: calc(100% - 320px);
      min-height: rem(290px);
      margin-top: rem(70px);
      margin-bottom: rem(-40px);
    }

    @include breakpoint-x {
      width: calc(100% - 460px);
      min-height: rem(352px);
      margin-bottom: 0;
    }

    &::before {
      background: $lead-one-up-info-pseudo-bg-color;
      border: 0;
      width: calc(100% - 20px);
      height: 100%;
      top: rem(-20px);
      left: rem(20px);

      @include breakpoint-m {
        width: calc(100% - 30px);
        top: rem(-30px);
        left: rem(30px);
      }

      @include breakpoint-l {
        top: rem(-40px);
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      border-width: 0;
      border-top-style: solid;
      border-top-width: rem(1px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .unibrow {
    position: $lead-one-up-unibrow-position;
  }
}

.lead-one-up__info-inner {
  display: flex;
  flex-flow: column;
}

.lead-one-up__title {
  font-family: $lead-one-up-title-font-family;
  font-weight: $lead-one-up-title-font-weight;
  line-height: $lead-one-up-title-line-height;

  @include responsive-variations(font-size, $lead-one-up-title-font-size);
  color: $lead-one-up-title-color;
  margin-top: $lead-one-up-title-top-margin;
  position: $lead-one-up-title-position;
}

.lead-one-up__quote {
  display: $lead-one-up-quote-display;
  font-size: rem(20px);
  color: $lead-one-up-quote-text-color;
  margin-bottom: rem(25px);
}

.lead-one-up__eyebrow {
  font-size: rem(12px);
  line-height: 1;
  font-family: $founders-mono;
  font-weight: 100;
  text-transform: uppercase;
  color: $lead-one-up-eyebrow-text-color;
  position: $lead-one-up-eyebrow-position;
  order: $lead-one-up-eyebrow-order;

  @include responsive-variations(bottom, $lead-one-up-eyebrow-position-bottom);
  left: $lead-one-up-eyebrow-position-left;
  padding: $lead-one-up-eyebrow-padding;
  margin: 0;
}

.lead-one-up__featured-author {
  color: $default-color;
  font-family: $founders-cond;

  @include responsive-variations(font-size, $lead-one-up-featured-author-font-size);
  line-height: 1.25;
}

.lead-one-up__featured-author__image-wrapper {
  display: $lead-one-up-featured-author-image-display;
}

.lead-one-up__featured-author__image {
  position: absolute;
  bottom: rem(20px);
  right: rem(20px);
  font-size: 0;
  width: rem(80);
  height: rem(80);

  @include breakpoint-m {
    width: rem(100px);
    height: rem(100px);
  }

  @include breakpoint-l {
    width: rem(120px);
    height: rem(120px);
  }
}

.lead-one-up--sponsored .lead-one-up__info {
  background-color: $lead-one-up-sponsored-info-bg-color;
  color: $lead-one-up-sponsored-info-text-color;

  .lead-one-up__quote,
  .lead-one-up__eyebrow {
    color: $lead-one-up-sponsored-quote-text-color;
  }
}

.lead-one-up__icon {
  font-size: 8px;
  width: rem(40px);
  height: rem(40px);
  position: relative;
  z-index: 2;
}

.lead-one-up__icon-link {
  display: $lead-one-up-icon-link-display;
}

.lead-one-up--with-sibling {
  margin-bottom: rem(48px);
}

// Today FullWidth
.lead-one-up:not(.lead-one-up--rail-adjacent) .lead-one-up__info {
  @include style-only-for(('today', 'telemundoentretenimiento')) {
    width: calc(100% - #{$lead-one-up-outer-gutter});
    min-height: rem(215px);
    margin-top: 0;
    margin-left: $lead-one-up-outer-gutter;
    z-index: 2;

    @include breakpoint-m {
      width: 100%;
      min-height: rem(209px);
      padding-right: $lead-one-up-outer-gutter;
    }

    @include breakpoint-l {
      width: rem(240px);
      min-height: rem(352px);
      margin-top: rem(70px);
      z-index: 1;
    }

    @include breakpoint-x {
      width: rem(260px);
    }

    &::before {
      width: calc(100% - #{$lead-one-up-outer-gutter});
      height: 100%;

      @include breakpoint-m {
        width: calc(100% - 30px);
      }
    }
  }
}
