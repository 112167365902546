.tve__info {
  line-height: 1;

  &__live {
    font-family: $founders-cond;
    font-size: rem($text-16);
    text-transform: uppercase;

    &::before {
      content: '';
      height: $space-12;
      width: $space-12;
      background-color: $news-red-30;
      border-radius: 50%;
      display: inline-block;
      animation: blink 1.5s ease-in-out infinite;
      margin-right: $space-4;
      margin-bottom: -1px;
    }
  }

  &__title {
    font-family: $founders-cond;
    font-size: rem($text-24);
    margin: rem(20px 0 12px);

    @include breakpoint-m {
      font-size: rem($text-32);
    }

    @include breakpoint-l {
      font-size: rem($text-24);
    }

    @include breakpoint-l {
      font-size: rem($text-24);
    }

    @include breakpoint-x {
      margin-bottom: rem(4px);
    }

    @media only screen and (min-width: 1440px) {
      font-size: rem($text-32);
    }
  }

  &__description {
    color: $grey-40;
    font-size: rem(14px);
    line-height: 1.5;
    margin: rem(0 0 12px);
  }

  &__share {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-top: rem(12px);

    &__label {
      font-family: $founders-mono;
      font-size: rem(10px);
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: $grey-40;
      margin-top: $space-4;

      &::after {
        content: '';
        display: inline-block;
        width: rem(6px);
        margin: 0 rem(30px) 0 rem(6px);
        border-top: 1px solid $grey-50;
        height: $space-4;
      }
    }

    > a {
      margin-right: $space-24;
      font-size: rem($text-20);
      outline: none;

      &:hover {
        opacity: $default-hover-opacity;
      }

      &:active {
        opacity: $default-active-opacity;
      }
    }
  }

  @keyframes blink {
    0%,
    100% {
      opacity: 0;
    }

    40%,
    70% {
      opacity: 1;
    }
  }
}
