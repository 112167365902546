.breaking-marquee {
  font-family: $breaking-marquee-text-font-family;
  font-size: $breaking-marquee-font-size-s;
  line-height: $breaking-marquee-text-leading;
  background-color: $breaking-marquee-background-color;
  color: $white;
  position: relative;
  z-index: 11;
  overflow: hidden;
  padding: rem($space-20 $space-40 $space-20 $space-20);
  width: 100vw;

  //TODO: Themes cleanup
  .sponsoredcontent & {
    font-family: $publico-hed;
  }

  @include breakpoint-m {
    padding: rem($space-24 $space-20);
  }

  @include breakpoint-l {
    font-size: $breaking-marquee-font-size-l;
  }

  &__text,
  &__link {
    margin: 0;
  }

  &__link {
    display: inline;
  }

  &__link a,
  a.breaking-marquee__link {
    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    line-height: $leading-100;
    color: $white;
    margin: rem(2px) 0 0 rem($space-12);
    font-size: $text-16;

    @include breakpoint-l {
      font-size: $text-24;
      margin-top: 0;
    }

    // override icon vertical-alignment
    &.icon::before {
      vertical-align: unset;
    }
  }

  &__close-button {
    position: absolute;
    top: rem($space-12);
    right: rem($space-12);
    font-size: rem($text-14);
    cursor: pointer;
    background: none;
    border: none;
    border-radius: none;
    color: $white;

    @include breakpoint-m {
      display: none;
    }
  }
}
