.default-cover-spread {
  &__wrapper {
    display: flex;
    align-items: flex-start;

    button {
      border: 0;
      background-color: transparent;
      padding: 0;
      text-align: left;

      &:focus {
        outline: none;
      }
    }
  }

  /* Change the play button to solid when user hovers anywhere over the cover spread column */
  &__feature {
    cursor: pointer;
    width: 100%;

    &:hover .cover-spread-featured-video__video__play-button {
      opacity: 1;
    }
  }

  @include breakpoint-s-only {
    &__wrapper {
      flex-wrap: wrap;
    }

    &__feature {
      margin-bottom: $space-20;
      order: 1;
    }

    &__column--1 {
      order: 2;
    }

    &__column--2 {
      order: 3;
    }

    &__teases {
      margin: 0 $space-20;
    }
  }

  @include breakpoint-m-only {
    &__column--1 {
      padding-left: $space-32;
    }
  }

  @include breakpoint-m-to-l-only {
    &__wrapper {
      @include clearfix;
      flex-wrap: wrap;
    }

    &__feature {
      order: 1;
      float: left;
      width: 65%; // to make it more in line with grid layout
      margin-bottom: $space-8;
    }

    &__column--1 {
      order: 2;
      width: 35%;
    }

    &__column--2 {
      order: 3;
      width: 100%;
    }

    &__teases {
      float: left;
      display: flex;
    }
  }

  @include breakpoint-l-only {
    &__column--1 {
      padding-left: $space-40;
    }
  }

  @include breakpoint-x {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__feature {
      margin-bottom: 0;
      flex-basis: calc(60% - 40px);
    }

    &__column--1 {
      flex-basis: calc(33% - 40px);
      padding-right: $space-40;
    }

    &__column--2 {
      flex-basis: calc(33% - 40px);
      padding-left: $space-40;
    }
  }
}
