$pancake-title-font-size: (s: rem($text-16)) !default;
$pancake-key-update-title-font-size: (s: rem($text-16)) !default;
$pancake-title-font-family: $publico-txt !default;
$pancake-title-font-weight: $bold !default;
$pancake-title-font-style: normal !default;
$pancake-title-text-transform: none !default;
$pancake-title-color: $default-color !default;
$pancake-key-updates-title-color: $default-color !default;
$pancake-title-before-content: none !default;
$pancake-title-before-content-top: (s: rem(12px), x: rem(16px)) !default;
$pancake-title-border-bottom: none !default;

$pancake-title-margin: 0 0 0.83em 0 !default;
$pancake-title-padding: 0 !default;

$pancake-unibrow-color: $default-color !default;
$pancake-headline-color: $default-headline-color !default;
$pancake-headline-font-family: $default-headline-font-family !default;
$pancake-duration-color: $default-color !default;
$pancake-tease-border-color: $grey-40 !default;
$pancake-background--layout-index-1: $white !default;

$pancake-list-color: $grey-70 !default;

@include override-tokens-for("think") {
  $pancake-headline-font-family: $founders-cond !global;
}

@include override-tokens-for("news") {
  $pancake-key-updates-title-color: $news-red-30 !global;
  $pancake-title-border-bottom: 1px solid $grey-40 !global;
  $pancake-title-padding: 0 0 rem(10px) 0 !global;
  $pancake-title-margin: 0 0 rem($space-12) 0 !global;
}

@include override-tokens-for("msnbc") {
  $pancake-key-updates-title-color: $news-red-30 !global;
  $pancake-title-border-bottom: 1px solid $grey-40 !global;
  $pancake-title-margin: 0 0 rem($space-12) 0 !global;
  $pancake-title-padding: 0 0 rem(10px) 0 !global;
}

@include override-tokens-for("today") {
  $pancake-title-font-size: (s: rem($text-24), x: rem($text-32)) !global;
  $pancake-key-update-title-font-size: (s: rem($text-20), x: rem($text-24)) !global;
  $pancake-title-font-family: $publico-hed !global;
  $pancake-title-font-weight: 100 !global;
  $pancake-title-font-style: italic !global;
  $pancake-title-margin: (s: 0 0 rem($space-24) 0, m: 0 0 rem($space-24) rem($space-60)) !global;
  $pancake-title-padding: (s: 0 0 0 rem($space-20), m: 0 0 0 rem($space-16)) !global;
  $pancake-tease-border-color: $grey-30 !global;
  $pancake-background--layout-index-1: $neutral-10 !global;
  $pancake-title-before-content: '' !global;
}

@include override-tokens-for("knowyourvalue") {
  $pancake-title-font-size: (s: rem($text-24), x: rem($text-32)) !global;
  $pancake-key-update-title-font-size: (s: rem($text-20), x: rem($text-24)) !global;
  $pancake-title-font-family: $publico-hed !global;
  $pancake-title-font-weight: 100 !global;
  $pancake-title-font-style: italic !global;
}

@include override-tokens-for("leftfield") {
  $pancake-title-color: $grey-50 !global;
  $pancake-unibrow-color: $grey-50 !global;
  $pancake-duration-color: $grey-50 !global;
}

@include override-tokens-for("globalcitizen") {
  $pancake-title-color: $red-40 !global;
  $pancake-unibrow-color: $red-40 !global;
  $pancake-duration-color: $red-40 !global;
}

@include override-tokens-for("telemundo") {
  $pancake-title-font-size: (s: rem($text-28), m: rem($text-32)) !global;
  $pancake-key-update-title-font-size: (s: rem($text-20), m: rem($text-24)) !global;
  $pancake-title-font-family: $founders-cond !global;
  $pancake-title-font-weight: $semibold !global;
  $pancake-title-margin: (s: 0 0 rem($space-24) 0, m: 0 0 rem($space-24) rem($space-60)) !global;
  $pancake-title-padding: (s: 0 0 0 rem($space-20), m: 0 0 0 rem($space-16)) !global;
  $pancake-title-text-transform: uppercase !global;
  $pancake-title-color: $blue-60 !global;
  $pancake-title-before-content: '' !global;
  $pancake-title-before-content-top: (s: rem(16px)) !global;
  $pancake-headline-color: $blue-60 !global;
  $pancake-list-color: $grey-60 !global;
}

@include override-tokens-for("noticias") {
  $pancake-title-border-bottom: 1px solid $grey-40 !global;
  $pancake-title-margin: 0 0 rem($space-12) 0 !global;
  $pancake-title-padding: 0 0 rem(10px) 0 !global;
}

@include override-tokens-for("telemundoentretenimiento") {
  $pancake-title-font-size: (s: rem($text-28), m: rem($text-32)) !global;
  $pancake-key-update-title-font-size: (s: rem($text-20), m: rem($text-24)) !global;
  $pancake-title-font-family: $founders-cond !global;
  $pancake-title-font-weight: $semibold !global;
  $pancake-title-margin: (s: 0 0 rem($space-24) 0, m: 0 0 rem($space-24) rem($space-60)) !global;
  $pancake-title-padding: (s: 0 0 0 rem($space-20), m: 0 0 0 rem($space-16)) !global;
  $pancake-title-text-transform: uppercase !global;
  $pancake-title-color: $blue-60 !global;
  $pancake-title-before-content: '' !global;
  $pancake-title-before-content-top: (s: rem(16px)) !global;
  $pancake-headline-color: $blue-60 !global;
  $pancake-list-color: $grey-60 !global;
}

@include override-tokens-for("telemundoshows") {
  $pancake-title-font-size: (s: rem($text-28), m: rem($text-32)) !global;
  $pancake-key-update-title-font-size: (s: rem($text-20), m: rem($text-24)) !global;
  $pancake-title-font-family: $founders-cond !global;
  $pancake-title-font-weight: $semibold !global;
  $pancake-title-margin: (s: 0 0 rem($space-24) 0, m: 0 0 rem($space-24) rem($space-60)) !global;
  $pancake-title-padding: (s: 0 0 0 rem($space-20), m: 0 0 0 rem($space-16)) !global;
  $pancake-title-text-transform: uppercase !global;
  $pancake-title-color: $blue-60 !global;
  $pancake-title-before-content: '' !global;
  $pancake-title-before-content-top: (s: rem(16px)) !global;
  $pancake-headline-color: $blue-60 !global;
  $pancake-list-color: $grey-60 !global;
}
