@mixin breakpoint-s-only {
  @media only screen and (max-width: $grid-m-width - 1) {
    @content;
  }
}

@mixin breakpoint-m {
  @media only screen and (min-width: $grid-m-width) {
    @content;
  }
}

@mixin breakpoint-m-only {
  @media only screen and (min-width: $grid-m-width) and (max-width: $grid-l-width - 1) {
    @content;
  }
}

@mixin breakpoint-m-max {
  @media only screen and (max-width: $grid-l-width - 1) {
    @content;
  }
}

@mixin breakpoint-l {
  @media only screen and (min-width: $grid-l-width) {
    @content;
  }
}

@mixin breakpoint-l-only {
  @media only screen and (min-width: $grid-l-width) and (max-width: $grid-x-width - 1) {
    @content;
  }
}

@mixin breakpoint-m-to-l-only {
  @media only screen and (min-width: $grid-m-width) and (max-width: $grid-x-width - 1) {
    @content;
  }
}

@mixin breakpoint-x {
  @media only screen and (min-width: $grid-x-width) {
    @content;
  }
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// Reusable IE-only styling, flex-basis is prioritized over flex-shrink in IE (matches spec)
// https://stackoverflow.com/questions/34352140/what-are-the-differences-between-flex-basis-and-width
@mixin ie-flex-basis {
  @include ie {
    flex: 1 1 0% !important; // must override parent's direct descendant selector (0 0 auto)
  }
}

@mixin pattern-dots($color, $background-color: transparent) {
  background-color: $background-color;
  background-image: radial-gradient($color 1px, transparent 1px);
  background-size: 5px 5px;
  background-position: 1px 1px;
}

@mixin pattern-dots-border($background-image-color: $grey-60, $background-position: top) {
  background-image: linear-gradient(to right, $background-image-color 10%, transparent 20%);
  background-size: 5px 1px;
  background-repeat: repeat-x;
  background-position: $background-position;
}

@mixin border-gradient($colors, $direction: right) {
  border-image: linear-gradient(to $direction, $colors) 1;
}

@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin fade-on-hover {
  &:hover {
    opacity: $default-hover-opacity;
  }

  &:active {
    opacity: $default-active-opacity;
  }

  &:visited {
    opacity: 1;
  }
}

/// override-tokens-for
/// @param {String|list} $themeNames - One or more of the brand names from the list in src/assets/styles/design-tokens/shared/_brands.scss

@mixin override-tokens-for($themeNames) {
  @each $themeName in $themeNames {
    @if $themeName == $theme {
      @content;
    }

    @else if (not index($list: $brandNames, $value: $themeName)) {
      @error 'Invalid $themeName "#{$themeName}", should be one of: #{$brandNames}';
    }
  }
}

/// style-for-all-except
/// Exclude content for specified brands
/// @param {list} $themeNames - One or more of the brand names from the list in src/assets/styles/design-tokens/shared/_brands.scss

@mixin style-for-all-except($themeNames) {
  @if (not index($list: $themeNames, $value: $theme)) {
    @content;
  }

  @each $themeName in $themeNames {
    @if (not index($list: $brandNames, $value: $themeName)) {
      @error 'Invalid $themeName "#{$themeName}", should be one of: #{$brandNames}';
    }
  }
}

@mixin style-only-for($themeNames) {
  @include override-tokens-for($themeNames) {
    @content;
  }
}

@mixin responsive-variations($rule, $variationMap) {
  @if is-map($variationMap) {
    @each $size, $variation in $variationMap {
      @if $size == 's' {
        #{$rule}: map-get($variationMap, 's');
      }

      @else if $size == 'm' {
        @include breakpoint-m {
          #{$rule}: map-get($variationMap, 'm');
        }
      }

      @else if $size == 'l' {
        @include breakpoint-l {
          #{$rule}: map-get($variationMap, 'l');
        }
      }

      @else if $size == 'x' {
        @include breakpoint-x {
          #{$rule}: map-get($variationMap, 'x');
        }
      }

      @else {
        @warn "Unexpected size #{$size} passed to mixin";
      }
    }
  }

  @else {
    #{$rule}: $variationMap;
  }
}

/*
  Used for the object-fit-images polyfill: https://github.com/fregante/object-fit-images
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;

  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: "object-fit: #{$fit}; object-position: #{$position}";
  }

  @else {
    font-family: "object-fit: #{$fit}";
  }
}

/// Ellipsify content after a certain number of lines
/// Fallback uses gradient to fade out content instead of ellipses
/// !Note that you will likely need to manually handle the overflow rules
/// !to achieve your desired result
/// @param {number} $maxNumberOfLines - The maximum number of lines to display
/// @param {px} $fontSize - The font size (or line-height) of the content (necessary for descenders and the fallback calculation)
/// @param {color} $fontSize - The font size of the content (necessary for descenders and the fallback calculation)

@mixin line-clamp($maxNumberOfLines, $fontSize, $background, $overrideLineHeight: true) {
  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: $fontSize * $maxNumberOfLines; // always place at start of second line
    width: 3em;
    height: 1em;
    margin-top: 0.2em;
    background: linear-gradient(90deg, rgba($background, 0) 10%, rgba($background, 1) 50%, rgba($background, 1));
  }

  @supports (-webkit-line-clamp: $maxNumberOfLines) and (-webkit-box-orient: vertical) and (display: -webkit-box) {
    // stylelint-disable comment-empty-line-before
    //* autoprefixer removes the -webkit-box-orient rule, but it's necessary for the line-clamp effect

    /*! autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $maxNumberOfLines;
    -webkit-box-orient: vertical;
    // stlyelint-enable comment-empty-line-before
    text-overflow: ellipsis;

    @if ($overrideLineHeight) {
      //* Add 1px to handle descendors (portions of letters that descend below type baseline)
      line-height: $fontSize + 1px;
    }

    &::after {
      display: none;
    }
  }
}

@mixin direct-headings {
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    @content;
  }
}

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

@mixin package-spacing {
  margin-bottom: $package-spacing;
}

@mixin flex-tease-space($width, $teases) {
  @include breakpoint-m {
    &:first-of-type {
      margin-right: auto;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }

  @if $teases == 4 {
    flex-basis: calc(#{$width} - 1.5px);

    &:first-of-type {
      margin-right: unset;
    }

    &:last-of-type {
      margin-left: unset;
    }
  }

  @else if $teases == 3 {
    flex-basis: calc(#{$width} - 1.33px);
  }

  @else {
    flex-basis: calc(#{$width} - 1px);
  }
}
