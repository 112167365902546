$sticky-ad-rail-content-column-bg-color: $default-color !default;
$sticky-ad-rail-column-wrapper-title-bg-color: $default-color !default;
$sticky-ad-rail-column-wrapper-text-link-color: $white !default;
$sticky-ad-rail-column-wrapper-text-padding: rem(4px) !default;
$sticky-ad-rail-column-wrapper-text-padding: (m: rem(4px), l: rem(5px)) !default;
$sticky-ad-rail-column-wrapper-text-font-size: (s: rem(14px), m: rem(20px)) !default;

$sticky-ad-rail-column-wrapper-text-underline-content: none !default;
$sticky-ad-rail-column-wrapper-text-underline-display: none !default;
$sticky-ad-rail-column-wrapper-text-underline-bg-color: $white !default;

$sticky-ad-rail-columns-wrapper-author-bg-color: $default-color !default;

$sticky-ad-track-latest-title-font-size: (s: rem(24px), m: rem(32px), l: rem(48px)) !default;
$sticky-ad-track-latest-title-font-family: $founders-cond !default;
$sticky-ad-track-latest-title-font-style: normal !default;
$sticky-ad-track-latest-title-font-weight: 400 !default;

$sticky-ad-track-special-title-design-color: $default-color !default;
$sticky-ad-track-special-title-design-background-color: $neutral-10 !default;
$sticky-ad-track-special-title-design-font-size: (s: rem(48px), m: rem(60px)) !default;
$sticky-ad-track-special-title-line-height: 60px !default;

@include override-tokens-for('news') {
  $sticky-ad-rail-content-column-bg-color: $blue-70 !global;
  $sticky-ad-rail-column-wrapper-title-bg-color: $blue-70 !global;
  $sticky-ad-rail-columns-wrapper-author-bg-color: $blue-70 !global;
  $sticky-ad-rail-column-wrapper-text-underline-content: '' !global;
  $sticky-ad-rail-column-wrapper-text-underline-display: inline-block !global;
  $sticky-ad-rail-column-wrapper-text-underline-bg-color: $default-color !global;
}

@include override-tokens-for('noticias') {
  $sticky-ad-rail-content-column-bg-color: $blue-60 !global;
  $sticky-ad-rail-column-wrapper-title-bg-color: $blue-60 !global;
  $sticky-ad-rail-columns-wrapper-author-bg-color: $blue-60 !global;
  $sticky-ad-rail-column-wrapper-text-underline-content: '' !global;
  $sticky-ad-rail-column-wrapper-text-underline-display: inline-block !global;
  $sticky-ad-rail-column-wrapper-text-underline-bg-color: $default-color !global;
}

@include override-tokens-for('mach') {
  $sticky-ad-rail-content-column-bg-color: $purple-50 !global;
  $sticky-ad-rail-column-wrapper-title-bg-color: $purple-50 !global;
  $sticky-ad-rail-column-wrapper-text-underline-content: '' !global;
  $sticky-ad-rail-column-wrapper-text-underline-display: inline-block !global;
  $sticky-ad-rail-columns-wrapper-author-bg-color: $purple-50 !global;
}

@include override-tokens-for('better') {
  $sticky-ad-rail-column-wrapper-text-underline-content: '' !global;
  $sticky-ad-rail-column-wrapper-text-underline-display: inline-block !global;
}

@include override-tokens-for('msnbc') {
  $sticky-ad-rail-column-wrapper-text-link-color: $yellow-40 !global;
  $sticky-ad-rail-column-wrapper-text-padding: rem(3px) !global;
}

@include override-tokens-for('think') {
  $sticky-ad-rail-column-wrapper-text-underline-content: '' !global;
  $sticky-ad-rail-column-wrapper-text-underline-display: inline-block !global;
}

@include override-tokens-for('today') {
  $sticky-ad-track-latest-title-font-size: (s: rem(48px), m: rem(60px)) !global;
  $sticky-ad-track-latest-title-font-family: $publico-hed !global;
  $sticky-ad-track-latest-title-font-style: italic !global;
}

@include override-tokens-for('telemundo') {
  $sticky-ad-track-latest-title-font-size: (s: rem(40px), m: rem(80px)) !global;
  $sticky-ad-track-special-title-design-font-size: (s: rem(40px), m: rem(80px)) !global;
  $sticky-ad-track-special-title-design-background-color: transparent !global;
  $sticky-ad-track-special-title-line-height: $leading-100 !global;
  $sticky-ad-rail-column-wrapper-text-font-size: (s: rem(20px), m: rem(20px)) !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $sticky-ad-track-latest-title-font-size: (s: rem(40px), m: rem(80px)) !global;
  $sticky-ad-track-special-title-design-font-size: (s: rem(40px), m: rem(80px)) !global;
  $sticky-ad-track-special-title-design-background-color: transparent !global;
  $sticky-ad-track-special-title-line-height: $leading-100 !global;
  $sticky-ad-rail-column-wrapper-text-font-size: (s: rem(20px), m: rem(20px)) !global;
}

@include override-tokens-for('telemundoshows') {
  $sticky-ad-track-latest-title-font-size: (s: rem(40px), m: rem(80px)) !global;
  $sticky-ad-track-special-title-design-font-size: (s: rem(40px), m: rem(80px)) !global;
  $sticky-ad-track-special-title-design-background-color: transparent !global;
  $sticky-ad-track-special-title-line-height: $leading-100 !global;
  $sticky-ad-rail-column-wrapper-text-font-size: (s: rem(20px), m: rem(20px)) !global;
}
