.promo3Panel {
  position: relative;
  display: flex;
  flex-direction: column;
  border-width: 1px 0;
  border-style: solid;
  border-color: $promo3Panel-color-border;
  margin-right: $space-20;
  margin-left: $space-20;

  @include breakpoint-m {
    margin-right: 0;
    margin-left: 0;
  }

  @include breakpoint-l {
    flex-direction: row;
  }

  @include style-only-for("today") {
    @include pattern-dots-border($promo3Panel-color-border, 0 top);
    background-clip: padding-box;
    border: none;

    &::after {
      display: block;
      content: '\00a0';

      @include pattern-dots-border($promo3Panel-color-border, 0 bottom);
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
