$ecommerce-disclaimer-background-color: $grey-20 !default;
$ecommerce-disclaimer-link-color: inherit !default;
$ecommerce-disclaimer-link-border-bottom: none !default;

@include override-tokens-for('news') {
  $ecommerce-disclaimer-link-color: $blue-40 !global;
}

@include override-tokens-for('today') {
  $ecommerce-disclaimer-background-color: $grey-30 !global;
  $ecommerce-disclaimer-link-border-bottom: 1px solid #ff503c !global;
}

@include override-tokens-for('better') {
  $ecommerce-disclaimer-link-color: $teal-30 !global;
}

@include override-tokens-for('mach') {
  $ecommerce-disclaimer-link-color: $purple-50 !global;
}

@include override-tokens-for('think') {
  $ecommerce-disclaimer-link-color: $red-40 !global;
}
