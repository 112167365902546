%bacon__page-btn {
  display: block;
  width: 38px;
  height: 100%;
  color: $bacon-page-btn-color;
  border-width: 0;
  border-color: $bacon-page-btn-border-color;
  background: $bacon-page-btn-background-color;
  outline: none;

  &:hover {
    color: $bacon-page-btn-hover-color;
    background-color: $bacon-page-btn-hover-bg-color;
  }

  .bacon__page-btn--icon {
    display: block;
    line-height: 1;
    font-size: rem(32px);
  }
}

.bacon__package {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-color: $bacon-package-border-color;
  background-color: $bacon-package-background-color;

  @include breakpoint-s-only {
    margin-left: $bacon-package-margin-left;
  }
}

.bacon__header--top-border {
  display: $bacon-header-top-border-display;
  position: relative;
  color: $bacon-header-top-border-background-color;
  border-top: 1px solid $bacon-header-top-border-line-color;
  padding-top: rem(24px);

  &::before {
    content: '';
    display: block;
    width: 80px;
    height: 8px;
    background-color: currentcolor;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.bacon__header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(12px);
}

.bacon__header-headline {
  flex: 1 1 auto;
  width: 0;
  margin-right: rem(20px);

  @include breakpoint-m-to-l-only {
    margin-left: $bacon-package-margin-left;
  }

  @include breakpoint-m {
    margin-left: 0;
    margin-right: 0;
  }
}

.bacon__title {
  color: $bacon-header-headline-title-color;
  font-family: $bacon-header-headline-title-font;
  font-style: $bacon-header-headline-title-style;

  @include responsive-variations(font-size, $bacon-header-headline-title-size);
  font-weight: $bacon-header-headline-title-font-weight;
  line-height: 1;
  border-color: $bacon-header-headline-title-border-color;
  margin: 0;

  &::before {
    content: $bacon-header-headline-title-before-content;
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 1rem;
    border-color: $bacon-header-headline-title-before-border-color;
    border-top-width: 1px;
    border-top-style: solid;
  }
}

.bacon__title__link {
  color: $bacon-header-headline-title-link-color;
  font-family: $bacon-header-headline-title-link-font;
  font-size: rem(16px);
  font-style: normal;
  text-transform: uppercase;
  margin-left: rem(12px);
}

.bacon__description {
  color: $bacon-header-headline-description-color;
  font-family: $bacon-header-headline-description-font-family;
  font-size: $bacon-header-headline-description-font-size;
  line-height: $bacon-header-headline-description-line-height;
  margin: 0;
  margin-top: rem(12px);
}

.bacon__disclaimer {
  font-size: rem(12px);
  font-family: $bacon-disclaimer-font;
}

.bacon__paging-dots {
  display: none;
  align-self: flex-end;
  flex-shrink: 0;
  padding-left: rem(32px);
  padding-bottom: rem(4px);
  margin-top: rem(12px);
  margin-right: $bacon-package-button-width;
  margin-left: auto;

  @include breakpoint-l {
    display: flex;
  }
}

.bacon__paging-dot {
  display: block;
  width: 9px;
  height: 9px;
  color: $bacon-paging-dot-color;
  border-radius: 100%;
  border: 1px solid $bacon-paging-dot-border-color;
  background: $bacon-paging-dot-background-color;
  padding: 0;
  outline: none;

  & + & {
    margin-left: 5px;
  }

  &:hover,
  &.bacon__paging-dot--is-current {
    border-color: currentcolor;
  }

  &.bacon__paging-dot--is-current {
    background-color: currentcolor;
  }
}

.bacon__scroll__wrapper {
  position: relative;
  display: flex;
  align-items: stretch;
}

.bacon__scroll__container {
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint-m {
    padding-left: 0;
  }
}

.bacon__scroll__translate-container {
  display: flex;

  &.bacon__scroll--is-scrolling {
    transition: transform 0.5s ease-in-out;
  }
}

.bacon__card-wrapper {
  flex-shrink: 0;

  & + & {
    margin-left: 2px;
  }
}

.bacon__prev-btn__container {
  height: 100%;
  background-color: $bacon-prev-btn-container-bg-color;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;

  @include breakpoint-m {
    opacity: 0;
    width: 0;
    transition: opacity 0.5s ease-in-out, width 0s linear 0.5s;

    &.bacon__prev-btn--active {
      opacity: 1;
      width: $bacon-package-button-width;
      // remove width transition to make it `$bacon-package-button-width` immediately
      transition: opacity 0.5s ease-in-out;
    }
  }
}

.bacon__next-btn__container {
  flex-shrink: 0;
  width: $bacon-package-button-width;
  background-color: $bacon-next-btn-container-bg-color;
  padding-left: rem(12px);
}

.bacon__next-btn__container--no-pagination {
  display: none;
}

.bacon__page-btn--next {
  @extend %bacon__page-btn;
  width: auto;
  border-left-style: solid;
  border-left-width: 1px;

  .bacon__page-btn--icon {
    transform: rotate(270deg);
  }
}

.bacon__page-btn--prev {
  @extend %bacon__page-btn;
  border-right-style: solid;
  border-right-width: 1px;

  .bacon__page-btn--icon {
    transform: rotate(90deg);
  }
}
