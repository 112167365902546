// Border top
$live-blog-pkg-border-top-color: $default-color !default;
$live-blog-pkg-border-top-color-breaking: $news-red-30 !default;

// Headline
$live-blog-pkg-headline-font-family: $default-headline-font-family !default;
$live-blog-pkg-headline-font-style: normal !default;
$live-blog-pkg-headline-font-weight: normal !default;
$live-blog-pkg-headline-line-height: $leading-100 !default;
$live-blog-pkg-headline-letter-spacing: normal !default;
$live-blog-pkg-headline-color: $black !default;

// Headline >> Label
$live-blog-pkg-label-font-family: $publico-txt !default;
$live-blog-pkg-label-font-weight: normal !default;
$live-blog-pkg-label-font-size: $text-20 !default;
$live-blog-pkg-label-color: $default-color !default;
$live-blog-pkg-label-color-breaking: $news-red-30 !default;

@include override-tokens-for('today') {
  // Headline
  $live-blog-pkg-headline-font-size: $text-24 !global;
  $live-blog-pkg-headline-font-style: italic !global;
  $live-blog-pkg-headline-font-weight: $light !global;
  $live-blog-pkg-headline-line-height: $leading-125 !global;
  $live-blog-pkg-headline-letter-spacing: rem(0.2px) !global; // TODO add to design-tokens
  $live-blog-pkg-headline-color: $purple-70 !global;

  // Headline >> Label
  $live-blog-pkg-label-font-family: $founders-cond !global;
  $live-blog-pkg-label-font-weight: $semibold !global;
  $live-blog-pkg-label-font-size: $text-28 !global;
}

@include override-tokens-for('think') {
  // Headline
  $live-blog-pkg-headline-font-size: $text-24 !global;
  $live-blog-pkg-headline-line-height: $leading-125 !global;

  // Headline >> Label
  $live-blog-pkg-label-font-family: $founders-cond !global;
  $live-blog-pkg-label-font-weight: $semibold !global;
  $live-blog-pkg-label-font-size: $text-28 !global;
}
