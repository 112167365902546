.ad--midresponsive {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  clear: both;
  text-align: center;

  &.ad--loaded {
    margin-bottom: rem(60px);

    @include breakpoint-l {
      margin-bottom: rem(80px);
    }
  }

  // Fallback if loaded class not applied on ad load
  &:not(.ad--loaded) {
    div[data-mps-slot^="midresponsive"] {
      &:not([style*="display: none"]) {
        margin-bottom: rem(60px);

        @include breakpoint-l {
          margin-bottom: rem(80px);
        }
      }
    }
  }
}
