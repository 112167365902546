.playlist-drawer__video {
  overflow: hidden;
  height: 50vw;
  max-height: rem(270px);
  cursor: pointer;
  position: relative;

  @include breakpoint-l {
    display: flex;
    height: rem(112px);
    width: 100%;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: $playlist-drawer-video-background-gradient;

    @include breakpoint-l {
      content: '';
      height: 1px;
      width: 43px;
      opacity: 0.5;
      background: $white;
      position: absolute;
      bottom: 0;
      right: auto;
      top: auto;
      left: 15em;
      z-index: 2;
    }
  }

  & .vilynx_wrapperImg {
    width: 100%;
  }
}

.playlist-drawer__thumbnail {
  position: relative;
  display: block;

  @include breakpoint-l {
    flex-shrink: 0;
    width: rem(224px);
  }
}

.playlist-drawer__title {
  font-family: $playlist-drawer-title-font-family;
  line-height: $playlist-drawer-title-font-leading;
  font-size: $playlist-drawer-title-font-size--small;
  margin: 0 0 rem(8px);
  opacity: 1;
  transition: opacity 0.3s;
  max-width: 85%;
  display: inline-block;
  padding-right: rem(15px);

  @include style-only-for('today') {
    letter-spacing: 0.5px;
  }

  @include style-only-for('today') {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: subpixel-antialiased;
  }

  @include style-only-for('think') {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: subpixel-antialiased;
  }

  @include breakpoint-l {
    font-weight: normal;
    padding-right: 0;
    order: 1;
    margin-bottom: rem(10px);
    max-width: none;
    font-size: $playlist-drawer-title-font-size--large;
  }
}

.playlist-drawer__info {
  color: $playlist-drawer-info-text-color;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @include breakpoint-l {
    width: 100%;
    background-color: $playlist-drawer-info-background-color;
    position: relative;
    display: flex;
    flex-direction: column;

    * {
      box-sizing: content-box;
    }
  }
}

.playlist-drawer__video:hover {
  .playlist-drawer__info {
    background-color: $playlist-drawer-info-background-color--hover;
  }
}

.playlist-drawer__duration {
  font-family: $founders-mono-font-family;
  font-size: 10px;
  line-height: 10px;
  opacity: 0.5;
  display: inline-block;
  text-align: right;

  @include breakpoint-l {
    order: 2;
    text-align: left;
    font-size: rem(11px);
  }
}

.playlist-drawer__up-next:not(.active)::before {
  background-color: $playlist-drawer-up-next-background-color;
  font-family: $playlist-drawer-eyebrow-font-family;
  font-size: 13px;
  line-height: 15px;
  content: attr(data-active-text);
  color: $white;
  position: absolute;
  left: 0;
  top: 0;
  padding: rem(12px 20px);
  z-index: 2;
}

.playlist-drawer__active {
  &::before {
    font-size: 10px;
    line-height: 10px;
    font-family: $founders-mono-font-family;
    background-color: $default-color;
    color: $white;
    content: attr(data-active-text);
    text-transform: uppercase;
    position: absolute;
    z-index: 10;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: rem(20px);
    opacity: 0.7;
    box-sizing: border-box;
    cursor: initial;
    justify-content: center;
    align-items: center;

    @supports (mix-blend-mode: screen) {
      mix-blend-mode: screen;
      opacity: 1;
    }

    @include style-only-for("news") {
      mix-blend-mode: unset;
      opacity: 1;
      z-index: 2;
      background-color: rgba(48, 98, 255, 0.7);
    }

    @include style-only-for("msnbc") {
      mix-blend-mode: unset;
      opacity: 1;
      z-index: 2;
      background-color: rgba(255, 190, 3, 0.7);
    }

    @include style-only-for("today") {
      background: linear-gradient(127deg, rgba(255, 80, 60, 0.7), rgba(141, 4, 141, 0.7));
    }
  }

  .playlist-drawer__title,
  .playlist-drawer__duration {
    opacity: 1;
  }
}

.playlist-drawer__video:not(.playlist-drawer__active):hover {
  .playlist-drawer__thumbnail {
    transform: none;
  }

  .playlist-drawer__title {
    opacity: 1;
  }
}
