$collection-title-background-color: $default-color !default;
$collection-title-after-background-color: $default-color !default;
$collection-title-button-text-color: $white !default;
$collection-title-button-background-color: $white !default;
$collection-title-button-border-color: $white !default;
$collection-title-button-background-color--hover: $default-color !default;
$collection-title-gutter-desktop: 40px !default;
$collection-title-title-color: $white !default;
$collection-title-dek-color: $white !default;
$collection-title-breakpoint-l-padding-bottom: rem(64px) !default;
$collection-title-mirror-breakpoint-l-after-right: auto !default;
$collection-title-title-font-size: rem(16px) !default;
$collection-title-title-line-height: normal !default;
$collection-title-title-breakpoint-m-line-height: 2px !default;

@include override-tokens-for("today") {
  $collection-title-background-color: $white !global;
  $collection-title-button-text-color: $default-color !global;
  $collection-title-button-border-color: $default-color !global;
  $collection-title-button-background-color: $default-color !global;
  $collection-title-button-background-color--hover: $white !global;
  $collection-title-dek-color: $grey-70 !global;
  $collection-title-breakpoint-l-padding-bottom: rem(18px) !global;
}

@include override-tokens-for("news") {
  $collection-title-background-color: #f6f6f6 !global;
  $collection-title-after-background-color: #f6f6f6 !global;
  $collection-title-button-text-color: $default-color !global;
  $collection-title-button-border-color: $default-color !global;
  $collection-title-button-background-color: $default-color !global;
  $collection-title-button-background-color--hover: $white !global;
  $collection-title-title-color: $black !global;
  $collection-title-dek-color: $grey-60 !global;
  $collection-title-mirror-breakpoint-l-after-right: 0 !global;
  $collection-title-title-font-size: rem(26px) !global;
  $collection-title-title-line-height: rem(30px) !global;
  $collection-title-title-breakpoint-m-line-height: rem(-0.6px) !global;
}

@include override-tokens-for("msnbc") {
  $collection-title-mirror-breakpoint-l-after-right: 0 !global;
  $collection-title-title-font-size: rem(26px) !global;
  $collection-title-title-line-height: rem(30px) !global;
  $collection-title-title-breakpoint-m-line-height: rem(-0.6px) !global;
}

@include override-tokens-for("noticias") {
  $collection-title-button-text-color: $default-color !global;
  $collection-title-button-border-color: $default-color !global;
  $collection-title-button-background-color: $default-color !global;
  $collection-title-button-background-color--hover: $white !global;
  $collection-title-title-color: $black !global;
  $collection-title-dek-color: $grey-60 !global;
  $collection-title-mirror-breakpoint-l-after-right: 0 !global;
  $collection-title-title-font-size: rem(24px) !global;
  $collection-title-title-line-height: rem(24px) !global;
  $collection-title-title-breakpoint-m-line-height: rem(-0.6px) !global;
}
