.embed-pkg {
  &--lead-pkg-adjacent {
    background-color: $embed-pkg-background-color;
  }

  &--top-border {
    border-top: solid $space-1 $grey-40;
  }

  &__related-content {
    // NOTE: Give related content a little more space since embeds are often square
    margin-top: $space-4;
  }

  &:not(.embed-pkg--custom-classes) {
    @include breakpoint-s-only {
      padding-top: $space-20;
      padding-left: $space-20;
      padding-right: $space-20;
    }

    &.embed-pkg--lead-pkg-adjacent {
      @include breakpoint-m {
        padding-top: $space-24;
        padding-left: $space-24;
        padding-right: $space-24;
        margin-left: -#{$space-20};
        margin-right: -#{$space-20};
      }
    }

    &.embed-pkg--top-border {
      padding-top: $space-24;
    }
  }
}
