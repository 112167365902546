$mini-player-default-font-size: $text-18 !default;
$mini-player-default-font-color: $grey-40 !default;
$mini-player-action-color: $default-color !default;
$mini-player-typeicon-color: $white !default;
$mini-player-max-width-medium-bp: 400px !default;
$mini-player-logo-margin-bottom: 5px !default;

@include override-tokens-for('msnbc') {
  $mini-player-default-font-size: $text-16 !global;
  $mini-player-default-font-color: $white !global;
  $mini-player-action-color: $yellow-40 !global;
  $mini-player-typeicon-color: $black !global;
  $mini-player-max-width-medium-bp: auto !global;
  $mini-player-logo-margin-bottom: 8px !global;
}
