// Setting this list allows the style-only-for & style-for-all-except mixins
// to be complementary (i.e. kept in sync)
$alternateTeaseListDisplayBrands: ('today', 'telemundoentretenimiento', 'telemundo', 'telemundoshows');

.tease-list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto !important; // must override parent's direct descendant selector (0 0 auto)
  min-height: 0; // required for Firefox/IE
  z-index: 1;

  // IE-only styling, flex-basis is prioritized over flex-shrink in IE (matches spec)
  // https://stackoverflow.com/questions/34352140/what-are-the-differences-between-flex-basis-and-width
  @include ie {
    flex: 1 1 0% !important; // must override parent's direct descendant selector (0 0 auto)
  }

  @include style-only-for(('today', 'telemundoentretenimiento', 'telemundo', 'telemundoshows')) {
    .articleTitleSection {
      margin: 0 0 rem(4px);
    }
  }
}

.tease-list__top-decoration {
  // on mobile, news have tabs - no top border needed
  .hideNewsTitle & {
    border-top: 1px solid transparent;

    @include breakpoint-l {
      border-top: 1px solid $grey-40;
    }
  }

  .stackCommerceList & {
    border-top: 0 !important;
  }

  @include style-only-for("msnbc") {
    margin: 0 $outer-gutter;
    border-top: 1px solid $grey-40;

    @include breakpoint-m {
      margin: 0;
    }
  }

  @include style-only-for("news") {
    .showNewsTitle & {
      margin: 0 $outer-gutter;
      border-top: 1px solid $grey-40;

      @include breakpoint-m {
        margin: 0;
      }
    }
  }

  &::before {
    background: $tease-list-top-decoration-background-color;
    content: '';
    width: $tease-list-top-decoration-width;
    height: $tease-list-top-decoration-height;
    position: absolute;

    .stackCommerceList & {
      content: none;
    }

    @include style-only-for("msnbc") {
      & {
        left: 20px;

        @include breakpoint-m {
          left: 0;
        }
      }
    }

    @include style-only-for("news") {
      .showNewsTitle {
        left: 20px;

        @include breakpoint-m {
          left: 0;
        }
      }
    }

    @include style-only-for('today') {
      & {
        content: none;
      }
    }

    // on mobile, news have tabs - no top embelishment needed
    .hideNewsTitle & {
      content: none;

      @include breakpoint-l {
        content: '';
      }
    }

    @include style-only-for(("telemundo", "telemundoentretenimiento", "telemundoshows")) {
      // hide top decoration when there's no List Title
      content: none;

      .tease-list__has-list-title & {
        content: '';
      }

      // hide top decoration when tabs are shown
      .right-rail-tabbed__tabs.right-rail-tabbed__tabs--visible + .rail__container & {
        content: none;
      }
    }
  }
}

.tease-list__wrapper {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0; // required for Firefox/IE

  & > * {
    flex: 0 0 auto;
  }

  @include breakpoint-l {
    height: 100%;
  }

  // IE-only styling, flex-basis is prioritized over flex-shrink in IE (matches spec)
  // https://stackoverflow.com/questions/34352140/what-are-the-differences-between-flex-basis-and-width
  @include breakpoint-l {
    @include ie {
      flex: 1 1 0%;
    }
  }

  @include style-only-for(("telemundoentretenimiento", "telemundo", "telemundoshows")) {
    @include breakpoint-m-only {
      width: calc(100% - #{$outer-gutter});
      margin: auto;
    }

    @include breakpoint-l {
      width: calc(100% + #{$outer-gutter});
    }
  }
}

.tease-list__articles-and-button {
  position: relative;
  flex: 0 1 auto !important; // must override parent's direct descendant selector (0 0 auto)
  min-height: 0; // required for Firefox/IE
  display: flex;
  flex-direction: column;

  // IE-only styling, flex-basis is prioritized over flex-shrink in IE (matches spec)
  // https://stackoverflow.com/questions/34352140/what-are-the-differences-between-flex-basis-and-width
  @include breakpoint-l {
    @include ie {
      flex: 1 1 0% !important; // must override parent's direct descendant selector (0 0 auto)
    }
  }

  @include breakpoint-m {
    width: calc(100% - #{$outer-gutter});
  }

  @include style-only-for('today') {
    & {
      background: $orange-30;
      padding: rem(20px 0);
      margin-right: $outer-gutter;
    }
  }

  &::before {
    @include style-only-for('today') {
      & {
        @include pattern-dots($orange-30);
        opacity: 0.3;
        content: '';
        height: rem(485px);
        width: calc(100% - 40px);
        position: absolute;
        top: rem(-20px);
        left: rem(60px);

        @include breakpoint-m {
          width: rem(315px);
          height: calc(100% - 100px);
          right: rem($-outer-gutter);
          left: auto;
        }

        @include breakpoint-l {
          height: rem(485px);
          width: rem(315px);
          right: rem(-40px);
        }
      }
    }
  }

  .tease-list__stripe-pattern {
    display: none;
  }

  @include style-only-for(("telemundoentretenimiento", "telemundo", "telemundoshows")) {
    @include breakpoint-m {
      width: 100%;
    }

    .tease-list__stripe-pattern {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden; // fix overflow on Safari
      background-image: url('~assets/images/telemundo-stripe-pattern.svg');
    }
  }
}

.tease-list__articles-wrapper {
  position: relative;
  min-height: 0; // required for Firefox/IE
  flex: 0 1 auto;
  padding-bottom: rem(28px);

  // IE-only styling, flex-basis is prioritized over flex-shrink in IE (matches spec)
  // https://stackoverflow.com/questions/34352140/what-are-the-differences-between-flex-basis-and-width
  @include breakpoint-l {
    @include ie {
      flex: 1 1 0%;
    }
  }

  @include breakpoint-l {
    padding-top: rem(8px);
    overflow: hidden;
  }

  @include style-only-for('today') {
    & {
      position: relative;
      z-index: 2;
      left: rem(20px);
      padding-top: rem(20px);
      background: $white;
      width: calc(100% - #{$outer-gutter});

      @include breakpoint-m {
        display: flex;
        flex-wrap: wrap;
      }

      @include breakpoint-l {
        width: 100%;
        padding-top: rem(28px);
        display: block;
      }
    }
  }

  @include style-only-for(("telemundoentretenimiento", "telemundo", "telemundoshows")) {
    background-color: $white;
    margin: 20px;

    &.tease-list__articles-wrapper--limit7 {
      padding-bottom: 0;
    }
  }

  &::after {
    @include breakpoint-l {
      content: '';
      width: 100%;
      height: rem(80px);
      background-image: linear-gradient(to bottom, rgba($white, 0), $white);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
}

.tease-list__articles {
  width: 100%; // needed on IE

  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
  }

  @include breakpoint-l {
    display: block;
  }

  // Top border above first item
  .tease-list__has-list-title & {
    border-top: $tease-list-list-border-top;
  }

  @include style-only-for(('news', 'noticias')) {
    .hasListTitle .hideNewsTitle & {
      border-top: none;

      @include breakpoint-l {
        border-top: $tease-list-list-border-top;
      }
    }
  }
}

.tease-list__item {
  position: relative;
}

@include style-for-all-except($alternateTeaseListDisplayBrands) {
  .tease-list__article {
    width: 100%;
    border-bottom: 1px dashed $grey-50;

    // Dot of brand color on non-TODAY tease lists
    &.tease-list__article--show-dot {
      &::before {
        background-color: $tease-list-brand-dot-color;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: rem(2px);
        height: rem(8px);
        width: rem(8px);
        border-radius: 50%;
      }

      &.tease-list__article--align-dot-to-hed::before {
        margin-top: rem(5px);

        @include breakpoint-m {
          margin-top: rem(11px);
        }

        @include breakpoint-l {
          margin-top: rem(5px);
        }
      }
    }

    @include style-only-for("msnbc") {
      &:nth-of-type(n+8) {
        display: none;

        @include breakpoint-l {
          display: flex;
        }
      }
    }

    &.tease-list__article--hidden {
      display: none;
    }
  }
}

// TODO: Make stylelint smart enough to ignore list variables
// stylelint-disable-next-line
@include style-only-for($alternateTeaseListDisplayBrands) {
  .tease-list__article {
    padding: $tease-list-article-padding;
    margin: rem(0 20px);
    background-image: linear-gradient(to right, $grey-50 17%, $white 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    display: flex;

    &:last-child {
      padding-bottom: 0;
    }

    &:nth-child(2n+1) {
      @include breakpoint-m {
        margin-left: 0;
      }

      @include breakpoint-l {
        margin-left: rem(20px);
      }
    }

    &:first-child {
      @include breakpoint-m {
        flex-basis: 100%;
        margin-left: rem(20px);
      }

      @include style-only-for(("telemundoentretenimiento", "telemundo", "telemundoshows")) {
        margin-top: 20px;
      }
    }

    &:nth-child(n+2) {
      @include breakpoint-m {
        flex-basis: calc(50% - 30px);
      }
    }

    &.tease-list__article--hidden {
      display: none;
    }
  }
}

// List item count limit
.tease-list__item-limit7 {
  .tease-list__item:nth-of-type(1n+8) {
    display: none;

    @include breakpoint-l {
      display: flex;
    }
  }
}

.tease-list__eyebrow {
  color: $tease-list-eyebrow-color;

  @include style-only-for('news') {
    & {
      display: none;
    }
  }
}

.tease-list__paid {
  color: $tease-list-paid-color;
  background: $tease-list-paid-background-color;
  padding: rem(0 5px);
}

.tease-list__trending-paid {
  color: $black;
}

.tease-list__title {
  @include responsive-variations(font-size, $tease-list-title-font-size);
  @include responsive-variations(line-height, $tease-list-title-line-heights);
  font-weight: normal;
  font-family: $tease-list-title-font-family;
  color: $tease-list-title-color;
  margin: 0;

  @include breakpoint-m {
    // Headline has metadata above, add top margin
    .tease-list__timestamp ~ &,
    .eyebrow ~ & {
      margin-top: rem(10px);
    }
  }

  @include breakpoint-l {
    margin: 0;
  }

  @include style-only-for(('today', 'telemundoentretenimiento', "telemundo", "telemundoshows")) {
    @include breakpoint-m {
      & {
        margin-top: 0;
      }
    }
  }
}

.tease-list__timestamp::first-letter {
  text-transform: capitalize;
}

.tease-list__ad-source {
  font-family: $founders-semibold-font-family;
  font-size: rem($text-14);
  line-height: rem($leading-100);
  color: $grey-50;
}

.tease-list__type-icon {
  left: -4px;
  bottom: -4px;
  z-index: 2;

  @include style-only-for(("telemundoentretenimiento", "telemundo", "telemundoshows")) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@include style-for-all-except($alternateTeaseListDisplayBrands) {
  .tease-list__tease-picture {
    margin-top: rem(10px);
    display: block;

    @include breakpoint-m {
      margin-top: rem(30px);
    }

    @include breakpoint-l {
      margin-top: rem(10px);
    }
  }
}

// stylelint-disable-next-line
@include style-only-for($alternateTeaseListDisplayBrands) {
  .tease-list__tease-picture {
    width: rem(60px);
    height: rem(60px);
    margin: 0 rem(8px) 0 0;

    @include breakpoint-m {
      width: rem(80px);
      height: rem(80px);
    }

    .tease-list__image {
      display: block;
      overflow: auto;
      border-radius: 50%;
    }
  }
}

.tease-list__button-wrapper {
  flex: 0 0 auto;

  @include style-only-for(('today')) {
    & {
      padding: rem(20px 0);
      background: $white;
      margin-left: rem(20px);
      width: calc(100% - #{$outer-gutter});
      position: relative;
      z-index: 2;

      @include breakpoint-l {
        width: 100%;
      }
    }
  }

  @include style-only-for(("telemundoentretenimiento", "telemundo", "telemundoshows")) {
    margin-left: rem(20px);
    margin-right: rem(20px);
    margin-bottom: rem(20px);
    margin-top: rem(-20px);
    width: calc(100% - 40px);
    background-color: $white;
    padding: rem(20px);
    position: relative;
    z-index: 2;
  }
}

.tease-list__list-title {
  font-family: $tease-list-list-title-font-family;
  font-style: $tease-list-list-title-font-style;
  font-size: $tease-list-list-title-font-size;
  line-height: $tease-list-list-title-line-height;
  margin: $tease-list-list-title-margin;
  position: relative;

  @include breakpoint-l {
    margin-bottom: rem(12px);
  }

  .hideNewsTitle & {
    display: none;

    @include breakpoint-l {
      display: block;
    }
  }

  @include style-only-for('today') {
    & {
      color: $purple-70;
      font-weight: 300;
      margin-top: rem(26px);
      margin-bottom: rem(30px);
      letter-spacing: rem(0.2px);
      margin-left: rem(1px);

      @include breakpoint-l {
        margin-bottom: rem(27px);
      }

      &::before {
        background-color: $today-orange-30;
        content: '';
        display: block;
        width: rem(31px);
        height: rem(1px);
        position: absolute;
        top: -20px;
        left: 0;
      }
    }
  }

  .stackCommerceList & {
    font-family: $founders-txt;
    margin: 0;
  }

  @include style-only-for("msnbc") {
    & {
      margin-left: $outer-gutter;

      @include breakpoint-m {
        margin-left: 0;
      }
    }
  }

  @include style-only-for("news") {
    .showNewsTitle & {
      margin-left: $outer-gutter;

      @include breakpoint-m {
        margin-left: 0;
      }
    }
  }

  //* This approach for preventing duplicate TeaseList titles should probably
  //* be used for all brands (and replace the showNewsTitle/hideNewsTitle class
  //* that currently is toggled)
  @include style-only-for(("telemundo", "telemundoentretenimiento", "telemundoshows")) {
    .right-rail-tabbed__tabs.right-rail-tabbed__tabs--visible + .rail__container & {
      display: none;
    }
  }
}

.disclaimerBox {
  background: rgba($white, 0.9);
  border: 1px solid $grey-40;
  top: rem(52px);
  border-radius: rem(4px);

  @include breakpoint-m {
    width: rem(267px);
  }

  @include breakpoint-l {
    width: 100%;
  }

  button {
    right: rem(10px);
    top: rem(10px);

    &:focus {
      outline: none;
    }
  }
}

.disclaimerButton {
  border-bottom: 1px solid $grey-40;

  button:focus {
    outline: none;
  }

  button svg {
    top: rem(35px);
  }

  .more {
    right: rem(20px);
    top: rem(30px);

    @include breakpoint-m {
      right: 0;
    }

    &:hover {
      opacity: $opacity-70;
    }
  }
}

.teaseList__seeAllButton {
  color: $tease-list-see-all-button-color;
  border: 1px solid $tease-list-see-all-button-border-color;
  width: rem(240px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @include style-only-for('today') {
    @include ie {
      margin: 0;
    }

    @include breakpoint-m {
      width: rem(300px);
    }

    @include breakpoint-l {
      width: calc(100% - 40px);
    }

    @include breakpoint-x {
      width: calc(100% - 40px);
    }
  }
}
