$article-body-anchor-heading-color: inherit !default;

$article-body-selection-color: $grey-20 !default;
$article-body-content-strong: $grey-70 !default;

$article-body-grid-container-background: transparent !default;

$article-body-content-font-family: $founders-semibold-font-family !default;

$article-body-sponsored-by-font-family: $founders-semibold-font-family !default;
$article-body-sponsored-by-leading: $default-text-leading !default;
$article-body-sponsored-by-color: $grey-70 !default;

$article-body-bullet-color: $default-color !default;

$article-body-list-link-color: $default-color !default;
$article-body-list-link-hover-color: $default-color !default;
$article-body-list-hover-opacity: $default-hover-opacity !default;
$article-body-list-active-opacity: $default-active-opacity !default;
$article-body-list-link-underline-color: $default-color !default;

$article-body-heading-font-family: $default-headline-font-family !default;
$article-body-heading-font-weight: normal !default;
$article-body-heading-color: $default-color !default;
$article-body-heading-link-underline-color: $default-color !default;
$article-body-heading-link-underline: 1px solid $article-body-heading-link-underline-color !default;
$article-body-heading-link-hover-color: $default-color !default;

$article-body-pre-font-family: $default-text-font-family !default;
$article-body-pre-leading: $default-text-leading !default;
$article-body-pre-text-color: $grey-20 !default;

$article-body-box-inline-ad-background: $grey-20 !default;

$article-body-ecommerce-enabled-text: $default-color !default;

$article-body-last-section-bg-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI3MHB4IiBoZWlnaHQ9IjQwLjJweCIgdmlld0JveD0iMCAwLjM1OSA3MCA0MC4yIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMC4zNTkgNzAgNDAuMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTIwLjA5LDE0LjcxbDEwLjEzLDIzLjE4OWwtMjAuOS0xNC4zM0M0LjA4LDIwLjEsNS4xNCwxNC4xNCw4LjA0LDExLjUyQzEyLjE2LDguMDUsMTcuOTYsOS4yNSwyMC4wOSwxNC43MXogTTM0LjA1LDkuNDJsLTMuNjksMjUuOTVMMTkuODgsMTAuN2MtMi4yNy02LjE3LDIuODMtMTAuMjEsNi4yMy0xMC4yOEMzMC4wMS0wLjAxLDM0LjksMi45NiwzNC4wNSw5LjQyeiBNNTAuMDYxLDEwLjY4bC0xMC40MiwyNC43NGwtMy4zMy0yMi4zNGMtMC4yODEtMy40LDEuODQtMy40OCwyLjYxOS00LjExYy0wLjE0LTAuNS0xLjE5OS0wLjUtMS4xOTktMC41aC0xLjk4Yy0wLjIxLTUuODEsNC4zMi04LjM3LDgtOC4wOUM0Ny43MjEsMC41Myw1Mi4xOCw1LjE0LDUwLjA2MSwxMC42OHogTTYwLjYyLDIzLjYyTDM5LjcyMSwzOC4wMWw5Ljg0LTIyLjljMi45NzktNy4yMyw5LjkyLTUuODEsMTIuMzk5LTMuMDVDNjQuNjUsMTQuNzUsNjUuNDMsMjAuMjEsNjAuNjIsMjMuNjJ6IE02Mi44OCw0MC41SDM4LjcyMUw1OC4zNCwyNi44MTljNC44Mi0zLjE4OSw5LjkyLTAuMjc5LDExLjI2LDMuOUM3MS4wMjksMzUuMzksNjcuODQsNDAuNSw2Mi44OCw0MC41eiBNMC4yNiwzMC41YzEuODQtNC44MTksNi45NC02LjUzLDExLjQ4LTMuNjJsMTkuNjIsMTMuNjhINy41NUMyLjU5LDQwLjU2OS0xLjMsMzYuMDMsMC4yNiwzMC41eiIvPjwvc3ZnPg==") !default;
$article-body-last-section-bg-image-width: rem(24px) !default;
$article-body-last-section-bg-image-height: rem(15px) !default;

$article-body-contributors-border-color: $grey-40 !default;
$article-body-contributors-line-bg-color: $default-color !default;

@include override-tokens-for('today') {
  $article-body-anchor-heading-color: $purple-70 !global;
  $article-body-grid-container-background: $default-neutral !global;
  $article-body-list-link-color: inherit !global;
  $article-body-list-hover-opacity: inherit !global;
  $article-body-list-active-opacity: $default-hover-opacity !global;
  $article-body-heading-color: $purple-70 !global;
  $article-body-heading-link-underline: none !global;
  $article-body-heading-font-family: $founders-semibold-font-family !global;
  $article-body-last-section-bg-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyNSAxMyI+ICAgIDxkZWZzPiAgICAgICAgPHBhdGggaWQ9ImEiIGQ9Ik0wIDYuNTQyVi4zMDVoMjQuODQ3djEyLjQ3NEgweiIvPiAgICA8L2RlZnM+ICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8Zz4gICAgICAgICAgICA8bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+ICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4gICAgICAgICAgICA8L21hc2s+ICAgICAgICAgICAgPHBhdGggZmlsbD0iI0ZGNTAzQyIgZD0iTTI0LjgzNyAxMi4yNjhDMjQuNTcgNS42MTYgMTkuMTE1LjMwNCAxMi40MjQuMzA0IDUuNzguMzA0LjM1MiA1LjU0NC4wMTcgMTIuMTMzYy0uMDEyLjIxNS0uMDE3LjQzLS4wMTcuNjQ2aDMuMjIxYzAtLjIwOS4wMDgtLjQxOS4wMjItLjYyNS4zMjEtNC44MDUgNC4zMTYtOC42MTUgOS4xODEtOC42MTUgNC44ODMgMCA4Ljg5IDMuODQgOS4xODUgOC42Ny4wMTIuMTg5LjAxOC4zNzguMDE4LjU3aDMuMjJjMC0uMTctLjAwMi0uMzQyLS4wMS0uNTExIiBtYXNrPSJ1cmwoI2IpIi8+ICAgICAgICA8L2c+ICAgICAgICA8cGF0aCBmaWxsPSIjRkY1MDNDIiBkPSJNMTIuNDI0IDQuNDYyYy00LjM5IDAtNy45ODMgMy40MzItOC4yNjQgNy43Ny0uMDEzLjE4LS4wMTguMzYyLS4wMTguNTQ3aDMuMjJjMC0uMTk4LjAxMi0uMzk1LjAzNS0uNTg4LjI5LTIuNTI1IDIuNDM1LTQuNDk1IDUuMDI3LTQuNDk1IDIuNjA2IDAgNC43NiAxLjk4OSA1LjAzMiA0LjUzMy4wMi4xODEuMDMuMzYzLjAzLjU1aDMuMjJjMC0uMTc5LS4wMDUtLjM1Mi0uMDE2LS41MjgtLjI3MS00LjM0OC0zLjg2OC03Ljc4OS04LjI2Ni03Ljc4OSIvPiAgICAgICAgPHBhdGggZmlsbD0iI0ZGNTAzQyIgZD0iTTEyLjQyNCA4LjYyMWE0LjE0NSA0LjE0NSAwIDAgMC00LjE0IDQuMTZoOC4yODFhNC4xNDYgNC4xNDYgMCAwIDAtNC4xNDEtNC4xNiIvPiAgICA8L2c+PC9zdmc+") !global;
}

@include override-tokens-for('noticias') {
  $article-body-last-section-bg-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjgwMjUgMS4xNDQ4OWUtMDVIMTMuMjYyOEMxMS4zNDc4IC0wLjAwMzE3OTIxIDkuNDg4NyAwLjY2MDYxIDcuOTkxNTMgMS44ODIxNUM2LjQ5NDM3IDAuNjYwNjEgNC42MzUzMiAtMC4wMDMxNzkyMSAyLjcyMDI4IDEuMTQ0ODllLTA1SDAuMTk3NTMxQzAuMTQ1MTQyIDEuMTQ0ODllLTA1IDAuMDk0ODk5NyAwLjAyMTMwMSAwLjA1Nzg1NTUgMC4wNTkxOTY1QzAuMDIwODExMiAwLjA5NzA5MjEgMCAwLjE0ODQ5IDAgMC4yMDIwODJMMCA2LjE3MTgyQzAuMDA1NTY1MDIgNi4yMjEzMiAwLjAyODgwODYgNi4yNjY5NyAwLjA2NTI2NzIgNi4zQzAuMTAxNzI2IDYuMzMzMDMgMC4xNDg4MjkgNi4zNTExMyAwLjE5NzUzMSA2LjM1MDhINC43OTcxOFYxNi43OTVDNC43OTU4MSAxNi44MjY0IDQuODAxODUgMTYuODU3NyA0LjgxNDc5IDE2Ljg4NjNDNC44Mjc3MyAxNi45MTQ4IDQuODQ3MiAxNi45Mzk4IDQuODcxNTUgMTYuOTU5QzQuODk1ODkgMTYuOTc4MyA0LjkyNDQxIDE2Ljk5MTIgNC45NTQ2OCAxNi45OTY5QzQuOTg0OTYgMTcuMDAyNSA1LjAxNjExIDE3LjAwMDUgNS4wNDU1IDE2Ljk5MTNDNi4xMTg5NSAxNi42Nzk4IDcuMTIwNjIgMTYuMTUxNyA3Ljk5MTUzIDE1LjQzODJDOC44NjI0NSAxNi4xNTE3IDkuODY0MTIgMTYuNjc5OCAxMC45Mzc2IDE2Ljk5MTNDMTAuOTY3IDE3LjAwMDUgMTAuOTk4MSAxNy4wMDI1IDExLjAyODQgMTYuOTk2OUMxMS4wNTg3IDE2Ljk5MTIgMTEuMDg3MiAxNi45NzgzIDExLjExMTUgMTYuOTU5QzExLjEzNTkgMTYuOTM5OCAxMS4xNTUzIDE2LjkxNDggMTEuMTY4MyAxNi44ODYzQzExLjE4MTIgMTYuODU3NyAxMS4xODczIDE2LjgyNjQgMTEuMTg1OSAxNi43OTVWNi4zNTA4SDE1LjgwMjVDMTUuODU0OSA2LjM1MDggMTUuOTA1MSA2LjMyOTUxIDE1Ljk0MjEgNi4yOTE2MUMxNS45NzkyIDYuMjUzNzIgMTYgNi4yMDIzMiAxNiA2LjE0ODczVjAuMjAyMDgyQzE2IDAuMTQ4NDkgMTUuOTc5MiAwLjA5NzA5MjEgMTUuOTQyMSAwLjA1OTE5NjVDMTUuOTA1MSAwLjAyMTMwMSAxNS44NTQ5IDEuMTQ0ODllLTA1IDE1LjgwMjUgMS4xNDQ4OWUtMDVaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K") !global;
  $article-body-last-section-bg-image-width: rem(16px) !global;
  $article-body-last-section-bg-image-height: rem(16px) !global;
}
