.tabbedcontent__tab-list {
  display: flex;
  position: sticky;
  top: $navbar-mobile-height;
  z-index: 1000;

  @include breakpoint-m {
    top: $navbar-default-height;
  }
}
