$show-lead-wrapper-bg: $black !default;
$show-lead-meta-data-color: $white !default;
$show-lead-show-name-font: $default-headline-font-family !default;
$show-lead-air-time-font-size: (s: rem(16px), m: rem(20px)) !default;
$show-lead-air-time-font-family: $default-headline-font-family !default;
$show-lead-air-time-font-weight: $bold !default;
$show-lead-air-time-line-height: $leading-100 !default;
$show-lead-link-list-wrapper-title-font: $default-headline-font-family !default;
$show-lead-link-list-item-font-weight: $normal !default;
$show-lead-link-list-item-text-transform: none !default;

@include override-tokens-for('today') {
  $show-lead-show-name-font: $founders-cond !global;
  $show-lead-air-time-font-family: $founders-cond !global;
}

@include override-tokens-for('telemundo') {
  $show-lead-wrapper-bg: $blue-70 !global;
  $show-lead-air-time-font-weight: $semibold !global;
  $show-lead-link-list-item-font-weight: $semibold !global;
  $show-lead-link-list-item-text-transform: uppercase !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $show-lead-wrapper-bg: $blue-70 !global;
  $show-lead-air-time-font-weight: $semibold !global;
  $show-lead-link-list-item-font-weight: $semibold !global;
  $show-lead-link-list-item-text-transform: uppercase !global;
}

@include override-tokens-for('telemundoshows') {
  $show-lead-wrapper-bg: $blue-70 !global;
  $show-lead-air-time-font-weight: $semibold !global;
  $show-lead-link-list-item-font-weight: $semibold !global;
  $show-lead-link-list-item-text-transform: uppercase !global;
}
