.lead-section-front {
  &::before {
    background-color: $lead-section-front-background-color;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(290px + $navbar-mobile-height);

    @include breakpoint-m {
      height: rem(420px + $navbar-mobile-height);
    }

    @include breakpoint-l {
      height: rem(300px + $navbar-default-height);
    }

    @include breakpoint-x {
      height: rem(290px + $navbar-default-height);
    }
  }

  &.lead-section-front__paid-hub::before {
    background-color: $grey-70;

    @include breakpoint-m {
      height: rem(500px + $navbar-mobile-height);
    }

    @include breakpoint-l {
      height: rem(390px + $navbar-default-height);
    }

    @include breakpoint-x {
      height: rem(397px + $navbar-default-height);
    }
  }

  &.lead-section-front__has-image::before {
    background-color: $lead-section-front-background-color;
    height: $lead-section-front-has-image-header-bg-image-height;

    @include breakpoint-m {
      height: $lead-section-front-has-image-header-bg-image-height-m;
    }
  }

  & > .ad-container {
    margin-top: $package-spacing;
  }
}

.lead-section-front__paid-hub-container {
  position: relative;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 55px;

  @include breakpoint-m {
    margin-bottom: 0;
    margin-left: 40px;
    margin-top: 48px;
    width: 300px;
  }

  @include breakpoint-l {
    padding-top: 16px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: inherit;
    width: inherit;
  }

  .lead-section-front__paid-header {
    border-bottom: 2px solid $grey-60;
  }

  h3 {
    color: $lead-section-front-paid-hub-container-color;
    margin-bottom: 10px;
  }

  .lead-section-front__icons {
    margin-top: 16px;

    svg:first-child {
      margin-right: 20px;
    }

    div:nth-child(3) {
      margin-left: 20px;
    }

    .lead-section-front__line {
      height: 21px;
      border-right: 1px solid $grey-60;
      margin-top: 5px;
      opacity: 0.5;
    }
  }
}

.pkg.lead-section-front:first-child {
  padding-top: $lead-section-front-navbar-mobile-height;

  @include breakpoint-m {
    padding-top: $lead-section-front-navbar-default-height;
  }
}

.lead-section-front:not(.lead-section-front__no-grid-container) .lead-section-front__rail-container {
  margin-left: calc(#{$lead-section-front-outer-gutter} - #{$lead-section-front-gutter-mobile});
  margin-right: calc(#{$lead-section-front-outer-gutter} - #{$lead-section-front-gutter-mobile});

  @include breakpoint-m {
    position: relative;
    margin-left: calc(#{$lead-section-front-outer-gutter} - #{$lead-section-front-gutter-tablet});
    margin-right: calc(#{$lead-section-front-outer-gutter} - #{$lead-section-front-gutter-tablet});
  }

  @include breakpoint-l {
    width: rem(1000px);
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint-x {
    width: rem(1240px);
  }
}

.lead-section-front.lead-section-front__no-grid-container {
  @include clearfix;

  .lead-section-front__rail-container {
    @include breakpoint-l {
      position: relative;
    }
  }
}

.lead-section-front__header-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: $lead-section-front-outer-gutter;
  margin-right: $lead-section-front-outer-gutter;
  color: $lead-section-front-header-content-color;

  &.lead-section-front--has-img {
    color: $lead-section-front-header-content-color-img;
  }

  @include breakpoint-m {
    margin-left: 0;
    margin-right: 0;
    height: rem(262px);
  }

  @include breakpoint-l {
    height: rem(300px);
  }

  @include breakpoint-x {
    height: rem(290px);
  }

  &.lead-section-front__paid-hub {
    margin: 45px 40px 40px;
    height: auto;

    @include breakpoint-m {
      margin-left: 30px;
      margin-right: $lead-section-front-outer-gutter;
    }

    @include breakpoint-l {
      margin-left: inherit;
    }

    h1 {
      margin-bottom: 10px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.lead-section-front__has-image .lead-section-front__header-content {
  height: rem(245px);

  @include breakpoint-m {
    height: rem(292px);
  }

  @include breakpoint-l {
    height: calc(#{$lead-section-front-has-image-header-bg-image-height-m} - #{$lead-section-front-has-image-header-bg-image-height});
  }
}

.lead-section-front__header-image,
.lead-section-front__today-big-heading,
.lead-section-front__today-big-heading-dots {
  position: absolute;
}

.lead-section-front__today-big-heading {
  overflow: hidden;
  top: -50px;
  left: $lead-section-front-outer-gutter;
  font-size: rem(180px);
  line-height: 1.2;
  white-space: nowrap;
  width: calc(100% - #{2 * $lead-section-front-outer-gutter});
  overflow-x: hidden;

  @include breakpoint-l {
    font-size: rem(225px);
    top: #{-1 * ($navbar-default-height + 80px)};
  }

  @include breakpoint-x {
    width: 100%;
    font-size: rem(300px);
    top: #{-1 * ($navbar-default-height + 150px)};
  }
}

.lead-section-front__today-big-heading-solid,
.lead-section-front__today-big-heading-dots {
  opacity: 0.05;
}

.lead-section-front__today-big-heading-dots {
  display: none;

  @supports (-webkit-background-clip: text) {
    background-image: radial-gradient($default-headline-color 34%, transparent 35%);
    background-size: 5px 5px;
    -webkit-background-clip: text;
    display: block;
    color: transparent;
    top: 5px;
    left: 5px;

    @include breakpoint-x {
      top: 10px;
    }
  }
}

.lead-section-front__header-image {
  display: block;
  max-height: $lead-section-front-has-image-header-bg-image-height;
  top: 0;
  left: 0;
  width: 100%;

  @include breakpoint-m {
    width: auto;
    max-width: 100%;
    height: $lead-section-front-has-image-header-bg-image-height-m;
    max-height: none;
  }

  .lead-section-front:not(.lead-section-front__no-grid-container) & {
    @include breakpoint-m {
      top: #{-1 * $navbar-default-height};
      left: $lead-section-front-grid-margin-tablet;
    }

    @include breakpoint-l {
      left: 0;
    }
  }

  .lead-section-front.lead-section-front__no-grid-container & {
    @include breakpoint-l {
      top: #{-1 * $navbar-default-height};
    }
  }

  img {
    opacity: $lead-section-front-header-image-opacity;
    height: auto;
    max-height: 100%;
    width: 100%;

    @supports (mix-blend-mode: multiply) {
      mix-blend-mode: multiply;
    }

    @include breakpoint-m {
      height: 100%;
      width: auto;
    }
  }
}

// the "dots"
@include style-only-for(('mach','better')) {
  .lead-section-front__rail::before {
    @include pattern-dots($lead-section-front-beige);
    content: '';
    position: absolute;
    top: $navbar-mobile-height;
    left: #{$lead-section-front-outer-gutter};
    display: block;
    width: rem(80px);
    height: rem(40px);

    @include breakpoint-m {
      top: 0;
      left: calc(#{$lead-section-front-gutter-tablet} + #{rem(32px)});
    }

    @include breakpoint-l {
      left: 0;
    }
  }
}

.lead-section-front__header-title {
  @include responsive-variations(font-size, $lead-section-front-header-title-font-size);
  line-height: $lead-section-front-header-title-line-height;
  text-transform: $lead-section-front-header-title-text-transform;
  font-family: $lead-section-front-header-title-font-family;
  font-weight: $lead-section-front-header-title-font-weight;
  font-style: $lead-section-front-header-title-font-style;
  margin-top: 0;
  margin-bottom: rem(10px);
  position: relative;

  @include breakpoint-l {
    width: rem(320px);
  }

  &::before {
    content: $lead-section-front-header-title-before-content;
    display: block;
    position: absolute;
    top: rem(17px);
    left: rem(-20px);
    width: rem(10px);
    border-color: $lead-section-front-header-title-before-border-color;
    border-top-width: rem(1px);
    border-top-style: solid;

    @include breakpoint-m {
      width: rem(40px);
      left: rem(-48px);
    }

    @include breakpoint-l {
      top: rem(30px);
      width: rem(60px);
      left: rem(-80px);
    }
  }
}

.lead-section-front__header-dek {
  font-family: $publico-txt;
  font-size: rem($text-14);
  font-style: normal;
  line-height: $leading-150;
  margin-top: rem($space-12);
  margin-bottom: rem($space-20);
  color: $lead-section-front-header-dek-color;

  .lead-section-front--has-img & {
    color: $lead-section-front-header-dek-color-img;
  }

  @include breakpoint-l {
    margin-bottom: 0;
  }
}

.lead-section-front__hero {
  clear: left;

  @include breakpoint-m {
    margin-right: 0;
  }

  @include breakpoint-l {
    margin-top: rem(40px);
    clear: none;
  }
}

.lead-section-front__navigation {
  width: calc(100% + #{$lead-section-front-outer-gutter});
  overflow-x: auto;
  padding-right: $lead-section-front-outer-gutter;
  border-top: rem(1px) solid;
  border-color: $lead-section-front-navigation-border-color;

  @include breakpoint-l {
    width: 100%;
    padding-right: 0;
    border-top-width: 0;
  }

  li {
    margin-right: rem(30px);
    flex-shrink: 0;
    color: $lead-section-front-navigation-color;

    &:last-child {
      padding-right: $lead-section-front-outer-gutter;
    }

    @include breakpoint-m {
      margin-right: rem(40px);
    }

    @include breakpoint-l {
      padding-bottom: rem(20px);
      margin-bottom: rem(20px);
      margin-right: 0;
      border-bottom: rem(1px) solid;
      border-bottom-color: $lead-section-front-navigation-border-bottom-color;

      .lead-section-front__has-image & {
        padding-bottom: rem(12px);
        margin-bottom: rem(12px);
      }

      &:last-child {
        padding-bottom: 0;
        padding-right: 0;
        margin-bottom: 0;
        border-bottom-width: 0;
      }
    }
  }
}

// TODO: remove when package spacing is standardized for all teases
.lead-section-front__two-up {
  @include package-spacing;
}

.tease-card__lead-section-front {
  .tease-card__content { padding-bottom: 3rem; }
}
