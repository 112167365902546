.package-title {
  background-color: $package-title-background;

  &.package-title--alternate-display-format {
    background-color: $package-title-background--alternate;
  }
}

.package-title__description {
  line-height: $package-title-description-lineheight;
  margin: $package-title-description-margin;
  font-family: $package-title-description-fontfamily;
  color: $package-title-description-color;

  .package-title.package-title--alternate-display-format & {
    color: $package-title-description-color--alternate;
  }
}

.package-title__title-text {
  @include responsive-variations(font-size, $package-title-title-text-font-size);
  position: relative;
  border-color: inherit;
  font-style: $package-title-title-text-font-style;
  font-weight: $package-title-title-text-font-weight;
  color: $package-title-title-text-color;
  background-color: $package-title-title-text-background;

  @include style-only-for(('today', 'telemundo', 'telemundoentretenimiento', 'telemundoshows')) {
    .package-title.package-title--alternate-display-format & {
      color: $package-title-title-text-color--alternate;
      background-color: $package-title-title-text-background--alternate;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      border-color: $package-title-title-text-line-color;
      border-top-width: 1px;
      border-top-style: solid;
      top: 0.5em; // approximately half a line
      right: 100%;
      width: 134px;
      margin-right: 12px;
    }

    .package-title.package-title--alternate-display-format &::before {
      border-color: $white;
    }
  }
}

.package-title__see-all-link {
  font-size: rem(18px);
  font-family: $package-title-see-all-link-font;
  font-style: normal;
  color: $package-title-see-all-link-color;
  text-transform: uppercase;
  border-bottom: $package-title-see-all-link-underline;
  border-bottom-color: $package-title-see-all-link-underline-color;
  order: 1;
  padding-bottom: $package-title-see-all-link-bottom-spacing;
  margin-top: rem(12px);

  @include breakpoint-m {
    font-size: rem(20px);
    order: 0;
    margin-top: 0;
    margin-left: rem(12px);
  }
}

.package-title__see-all-link--alt-color {
  color: $package-title-see-all-link-alt-color;
}
