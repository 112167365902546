.liveblog-cover-spread {
  border-top: solid $space-4 $cover-spread-border-top-color;
  background-color: $white;
  position: relative; // Required for MSNBC fronts

  &__wrapper {
    button {
      margin: 0;
      border: 0;
      background-color: transparent;
      padding: 0;
      text-align: left;

      &:focus {
        outline: none;
      }
    }
  }

  /* Change the play button to solid when user hovers anywhere over the cover spread column */
  &__feature {
    cursor: pointer;
    width: 100%;

    &:hover .cover-spread-featured-video__video__play-button {
      opacity: 1;
    }
  }

  @include breakpoint-s-only {
    &__feature {
      margin-bottom: $space-20;
    }

    &__teases {
      margin: 0 $space-20;
    }

    &__live-blog {
      border-top: 4px solid $cover-spread-live-blog-border-top-color;
      margin: 0 $space-20;
      padding: $space-20 0;

      &--breaking {
        border-top-color: $cover-spread-live-blog-border-top-color-breaking;
      }
    }
  }

  @include breakpoint-m-to-l-only {
    &__wrapper {
      @include clearfix;
    }

    &__live-blog {
      float: right;
      width: 25%;
    }

    &__feature {
      float: left;
      width: calc(75% - #{$space-28});
      margin-bottom: $space-16;
    }

    &__teases {
      float: left;
      display: flex;
      width: calc(75% - #{$space-28});
    }
  }

  @include breakpoint-l-only {
    &__live-blog {
      width: 30%;
      margin-bottom: $space-16;
    }

    &__feature {
      width: calc(70% - #{$space-28});
    }

    &__teases {
      width: 100%;
    }
  }

  @include breakpoint-x {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__column {
      padding: 0 $space-20;
    }

    &__teases {
      order: 1;
      padding-left: 0;
      flex-basis: calc(30% - 20px);
    }

    &__feature {
      order: 2;
      margin-bottom: 0;
      flex-basis: calc(60% - 40px);
    }

    &__live-blog {
      order: 3;
      padding-right: 0;
      flex-basis: calc(30% - 20px);
    }
  }
}
