$article-hero-top-border-bg-color: rgba(255, 255, 255, 0.3) !default;
$article-hero-top-border-bg-img: none !default;
$article-hero-top-border-height: 1px !default;
$article-hero-header-bg-color: $default-color !default;
$article-hero-spread-hero-bg-color: $default-color !default;
$article-hero-spread-hero-main-image-bg-color: $default-color !default;
$article-hero-taxonomy-term-color: $white !default;
$article-hero-headline-color: $white !default;
$article-hero-outer-gutter-width-mobile: 20px !default;

@include override-tokens-for('news') {
  $article-hero-header-bg-color: $blue-70 !global;
  $article-hero-spread-hero-bg-color: $blue-70 !global;
  $article-hero-spread-hero-main-image-bg-color: $blue-70 !global;
}

@include override-tokens-for('today') {
  $article-hero-top-border-bg-img: linear-gradient(to right, $default-color, #8d048d) !global;
  $article-hero-top-border-height: 2px !global;
  $article-hero-spread-hero-bg-color: $white !global;
  $article-hero-spread-hero-main-image-bg-color: $white !global;
  $article-hero-header-bg-color: $white !global;
  $article-hero-taxonomy-term-color: $default-color !global;
  $article-hero-headline-color: $purple-70 !global;
}

@include override-tokens-for('noticias') {
  $article-hero-header-bg-color: $blue-60 !global;
  $article-hero-spread-hero-bg-color: $blue-60 !global;
  $article-hero-spread-hero-main-image-bg-color: $blue-60 !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $article-hero-header-bg-color: $blue-60 !global;
  $article-hero-spread-hero-bg-color: $blue-60 !global;
  $article-hero-spread-hero-main-image-bg-color: $blue-60 !global;
}

@include override-tokens-for('knowyourvalue') {
  $article-hero-top-border-bg-color: $default-color !global;
  $article-hero-header-bg-color: $neutral-10 !global;
  $article-hero-taxonomy-term-color: $default-color !global;
  $article-hero-headline-color: $grey-70 !global;
}
