.collection-title {
  width: 100%;
  padding: rem(40px);
  margin-bottom: rem(48px);
  background-color: $collection-title-background-color;

  @include breakpoint-l {
    @include package-spacing;
    padding-top: rem(64px);
    padding-bottom: $collection-title-breakpoint-l-padding-bottom;
    margin-left: rem(-40px);

    &.collection-title__mirror {
      margin-left: 0;
    }

    &.collection-title__mirror::after {
      right: $collection-title-mirror-breakpoint-l-after-right;
      left: auto;
    }
  }

  &::after {
    background-color: $collection-title-after-background-color;
  }

  .animated-ghost-button {
    color: $collection-title-button-text-color;
    background-image: linear-gradient(35deg, $collection-title-button-background-color 50%, transparent 50%);
    border: 1px solid $collection-title-button-border-color;
    width: rem(180px);
    margin: rem(40px) 0 0 auto;

    &:hover {
      color: $collection-title-button-background-color--hover;
    }

    @include breakpoint-l {
      display: block;
    }
  }

  @if ($theme != "today") {
    position: relative;
    padding: rem(34px 40px 38px);

    @include breakpoint-m {
      padding: rem(40px 60px 60px);
    }

    @include breakpoint-l {
      width: calc(100% + (2 * #{$collection-title-gutter-desktop}));
      margin-left: -$collection-title-gutter-desktop;
      padding: rem(60px 40px 40px);

      &.collection-title__mirror {
        margin-left: -$collection-title-gutter-desktop;
      }
    }

    @include breakpoint-x {
      padding: rem(60px 60px 40px);
    }

    &::after {
      @include breakpoint-l {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        height: rem(60px);
        width: rem(780px);
        z-index: 0;
      }

      @include breakpoint-x {
        height: rem(50px);
      }
    }
  }
}

.collection-title__title,
.collection-title__dek,
.collection-title__button {
  position: relative;
  z-index: 1;
}

.collection-title__title {
  color: $collection-title-title-color;
  margin: 0;
  font-family: $default-headline-font-family;
  font-weight: normal;
  font-size: $collection-title-title-font-size;
  line-height: $collection-title-title-line-height;

  @include breakpoint-m {
    font-size: rem(60px);
    line-height: rem(60px);
    letter-spacing: $collection-title-title-breakpoint-m-line-height;
  }

  @include breakpoint-l {
    text-align: right;

    .collection-title__mirror & {
      text-align: left;
    }
  }

  @include style-only-for("today") {
    font-style: italic;
    color: $purple-70;
    position: relative;

    &::before {
      content: '';
      width: rem(50px);
      height: rem(1px);
      display: inline-block;
      vertical-align: middle;
      margin: rem(0 10px 0 -60px);
      background: $default-color;
    }

    &.collection-title__title--is-left-mounted::before {
      @include breakpoint-m {
        width: rem(70px);
        margin-left: rem(-80px);
      }

      @include breakpoint-l {
        position: absolute;
        top: rem(30px);
        right: rem(-80px);
        margin-right: 0;
      }
    }

    &.collection-title__mirror::before {
      @include breakpoint-l {
        right: auto;
        left: rem(-80px);
        margin-left: 0;
      }
    }
  }
}

.collection-title__dek {
  font-family: $publico-font-family;
  font-size: 12px;
  line-height: 20px;
  color: $collection-title-dek-color;
  padding: 0;
  margin: 0;

  &:not(:empty) {
    margin: rem(10px) 0 0;
  }

  @include breakpoint-m {
    font-family: $publico-font-family;
    font-size: 14px;

    &:not(:empty) {
      margin-top: rem(20px);
    }
  }

  @include breakpoint-l {
    text-align: right;

    .collection-title__mirror & {
      text-align: left;
    }
  }
}
