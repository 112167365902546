.layout-header {
  .hfsh {
    // Allow global nav to pass under banner advert
    // Allow nav to appear above Taboola elements
    z-index: 12000;

    &.search-open {
      // Search overlay appears over banner advert
      z-index: 200000;
    }
  }

  &__sponsor-text {
    font-size: rem(10px) !important;
    margin: 0 rem(10px) !important;
    color: $black;

    .transparent &,
    .dark &,
    .vertical &,
    .light.active & {
      opacity: $opacity-70;
      color: $white;
    }
  }

  &__default-navbar {
    background-color: $layout-header-default-background-color;
    line-height: rem($navbar-mobile-height);
    height: rem($navbar-mobile-height);

    @include breakpoint-m {
      line-height: rem($navbar-default-height);
      height: rem($navbar-default-height);
    }
  }

  &__default-logo::after {
    background-color: $layout-header-default-background-color;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
  }
}
