$beige: #f1f0ed !default;
$collection-articles-outer-gutter: 20px !default;

$collection-featured-author-title-font-family: $founders-semibold-font-family !default;
$collection-featured-author-title-font-size: 18px !default;
$collection-featured-author-title-font-weight: normal !default;

$collection-featured-author-quote-color: $default-color !default;

$collection-article-featured-author-title-font-family: $founders-semibold-font-family !default;
$collection-article-featured-author-title-font-sizes: (s: 24px, m: 30px) !default;

$collection-articles-background: $beige !default;

$collection-author-color: $default-color !default;

$collection-collectionPoster-heading-responsive-font-sizes: ( s: 50px, m: 35px, l: 50px, x: 35px ) !default;

$collection-eyebrow-color: $default-color !default;

$collection-first-article-title-font-family: $founders-semibold-font-family !default;
$collection-first-article-title-font-size: 30px !default;
$collection-first-article-title-font-weight: normal !default;

$collection-header-border-color: $default-color !default;

$collection-heading-responsive-line-heights: ( s: 40px, m: 30px, l: 40px, x: 30px ) !default;

$collection-more-color: $default-color !default;
$collection-more-hover-border-color: $default-color !default;
$collection-more-text-transform: uppercase !default;

$collection-icon-background-color: $default-color !default;

$collection-subheading-responsive-font-sizes: ( s: 12px, l: 14px, x: 12px ) !default;
$collection-subheading-responsive-line-heights: ( s: 15px, l: 20px, x: 15px ) !default;

$collection-title-color: $grey-70 !default;
$collection-title-font-family: $founders-semibold-font-family !default;
$collection-title-font-size: 18px !default;
$collection-title-font-weight: normal !default;

$collection-primary-color-map: (
  better: $better-teal-30,
  news: $news-blue-70,
  think: $think-red-40,
  mach: $mach-purple-50,
  globalcitizen: $global-teal-40,
  knowyourvalue: $knowyourvalue-orange-30,
  leftfield: $black,
  msnbc: $news-blue-40,
  noticias: $noticias-blue-60,
) !default;

$collection-modulealternate-color-map: (
  better: $better-teal-30,
  news: $news-blue-40,
  think: $think-red-40,
  mach: $mach-purple-50,
  globalcitizen: $global-red-40,
  knowyourvalue: $knowyourvalue-orange-30,
  leftfield: $grey-50,
  msnbc: $news-blue-40,
  noticias: $noticias-red-50,
) !default;

@include override-tokens-for("globalcitizen") {
  $collection-author-color: $red-40 !global;
  $collection-icon-background-color: $red-40 !global;
  $collection-eyebrow-color: $red-40 !global;
  $collection-featured-author-quote-color: $red-40 !global;
}

@include override-tokens-for("leftfield") {
  $collection-author-color: $grey-50 !global;
  $collection-icon-background-color: $grey-50 !global;
  $collection-eyebrow-color: $grey-50 !global;
  $collection-more-color: $grey-50 !global;
  $collection-featured-author-quote-color: $grey-50 !global;
}

@include override-tokens-for("news") {
  $collection-author-color: $blue-40 !global;
  $collection-icon-background-color: $blue-40 !global;
  $collection-eyebrow-color: $blue-40 !global;
  $collection-more-color: $blue-40 !global;
  $collection-featured-author-quote-color: $blue-40 !global;
}

@include override-tokens-for("noticias") {
  $collection-author-color: $red-50 !global;
  $collection-icon-background-color: $red-50 !global;
  $collection-eyebrow-color: $red-50 !global;
  $collection-more-color: $red-50 !global;
  $collection-featured-author-quote-color: $red-50 !global;
}

@include override-tokens-for("today") {
  $collection-featured-author-title-font-family: $publico-light-font-family !global;
  $collection-featured-author-title-font-size: 13.5px !global;
  $collection-article-featured-author-title-font-family: $publico-light-font-family !global;
  $collection-article-featured-author-title-font-sizes: (s: 18px, m: 22.5px) !global;
  $collection-articles-background: $white !global;
  $collection-first-article-title-font-family: $publico-light-font-family !global;
  $collection-first-article-title-font-size: 22.5px !global;
  $collection-title-color: $purple-70 !global;
  $collection-title-font-family: $publico-light-font-family !global;
  $collection-title-font-size: 13.5px !global;
}

@include override-tokens-for("think") {
  $collection-featured-author-title-font-family: $publico-light-font-family !global;
  $collection-featured-author-title-font-size: 13.5px !global;
  $collection-article-featured-author-title-font-family: $publico-light-font-family !global;
  $collection-article-featured-author-title-font-sizes: (s: 18px, m: 22.5px) !global;
  $collection-first-article-title-font-family: $publico-light-font-family !global;
  $collection-first-article-title-font-size: 22.5px !global;
  $collection-title-font-family: $publico-light-font-family !global;
  $collection-title-font-size: 13.5px !global;
}

@include override-tokens-for("knowyourvalue") {
  $collection-featured-author-title-font-family: $publico-light-font-family !global;
  $collection-featured-author-title-font-size: 13.5px !global;
  $collection-article-featured-author-title-font-family: $publico-light-font-family !global;
  $collection-article-featured-author-title-font-sizes: (s: 18px, m: 22.5px) !global;
  $collection-first-article-title-font-family: $publico-light-font-family !global;
  $collection-first-article-title-font-size: 22.5px !global;
  $collection-more-text-transform: lowercase !global;
  $collection-title-color: $grey-70 !global;
  $collection-title-font-family: $publico-light-font-family !global;
  $collection-title-font-size: 13.5px !global;
}

@include override-tokens-for("telemundo") {
  $collection-title-color: $blue-70 !global;
  $collection-title-font-size: rem(20px) !global;
}

@include override-tokens-for("telemundoentretenimiento") {
  $collection-title-color: $blue-70 !global;
  $collection-title-font-size: rem(20px) !global;
}

@include override-tokens-for("telemundoshows") {
  $collection-title-color: $blue-70 !global;
  $collection-title-font-size: rem(20px) !global;
}
