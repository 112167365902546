// lbp = live-blog-pkg
$dashed-line: linear-gradient(180deg, $grey-40, $grey-40 60%, transparent 60%, transparent 100%);

.lbp-card {
  $card-padding: $space-16;
  $vertical-card-padding: $card-padding / 2;
  display: block;
  padding: $vertical-card-padding 0 $vertical-card-padding $card-padding;
  position: relative;

  &:hover {
    opacity: $default-hover-opacity;
  }

  &:active {
    opacity: $default-active-opacity;
  }

  &.lbp-card--animate-in:first-child {
    opacity: $opacity-0;
    animation: fade-in 1000ms ease-out forwards;

    .lbp-card__link {
      display: block;
      height: auto;
      max-height: 0;
      overflow-y: hidden;
      animation: expand-in 1000ms ease-out forwards;
    }
  }

  &::before {
    content: '';
    display: block;
    height: $space-8 + $space-1; //+1 to compensate for border size
    width: $space-8 + $space-1; //+1 to compensate for border size
    background-color: $lbp-card-bullet-background-color;
    border-radius: 50%;
    position: absolute;
    left: -$space-4;
    top: $space-12;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: $space-1;
    position: absolute;
    left: 0;
    top: $space-12;
    background-image: $dashed-line;
    background-size: $space-1 5px;
  }

  // Hide dashed line for last child
  &:last-child {
    &::after {
      display: none;
    }
  }

  // change bullet color when breaking
  &--breaking::before {
    background-color: $lbp-card-bullet-background-color-breaking;
  }

  &__time {
    position: relative;
    height: $space-12;
    font-size: $text-12;
    color: $black;
    font-family: $founders-mono;
    line-height: $leading-100;
    margin-bottom: 2px; //TODO: Update with variable once added to design system
    display: block;

    &--just-updated {
      color: $lbp-card-time-color-just-updated;

      .lbp-card--breaking & {
        color: $lbp-card-time-color-just-updated-breaking;
      }
    }

    &--show.lbp-card__time__text,
    &--show.lbp-card__time__just-now {
      animation: fade-in 300ms linear forwards;
    }

    &__text,
    &__just-now {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }

  &__headline {
    @include responsive-variations(font-size, $lbp-card-headline-font-size);
    font-family: $lbp-card-headline-font-family;
    font-weight: $lbp-card-headline-font-weight;
    line-height: $lbp-card-headline-line-height;
    color: $lbp-card-headline-color;
    margin: 0;

    // Override M font-size when within Cover Spread package
    @include breakpoint-m-only {
      .live-blog--coverSpread & {
        font-size: $text-16;
      }
    }
  }
}
