$tabbed-content-internal-tab-list-item-background-color: #333 !default;
$tabbed-content-internal-tab-list-item-font-color: #fff !default;

$tabbed-content-internal-tab-list-item-active-background-color: #fff !default;
$tabbed-content-internal-tab-list-item-active-font-color: #333 !default;

@include override-tokens-for('today') {
  $tabbed-content-internal-tab-list-item-background-color: $orange-30 !global;
  $tabbed-content-internal-tab-list-item-font-color: $white !global;

  $tabbed-content-internal-tab-list-item-active-background-color: #f8f8f8 !global;
  $tabbed-content-internal-tab-list-item-active-font-color: $orange-30 !global;
}
