$playlist-drawer-title-font-family: $default-headline-font-family !default;
$playlist-drawer-title-font-size--small: 18px !default;
$playlist-drawer-title-font-size--large: 16px !default;
$playlist-drawer-title-font-leading: 20px !default;

$playlist-drawer-eyebrow-font-family: $founders-semibold-font-family !default;

$playlist-drawer-up-next-background-color: $default-color !default;

$playlist-drawer-info-text-color: $white !default;
$playlist-drawer-info-background-color: $grey-70 !default;
$playlist-drawer-info-background-color--hover: $grey-60 !default;

$playlist-drawer-video-background-gradient: linear-gradient(to top, $black, rgba(0, 0, 0, 0)) !default;

@include override-tokens-for("msnbc") {
  $playlist-drawer-up-next-background-color: $yellow-40 !global;
}

@include override-tokens-for("today") {
  $playlist-drawer-title-font-size--small: 13.5px !global;
  $playlist-drawer-title-font-size--large: 14px !global;
  $playlist-drawer-duration-font-family: $founders-mono-font-family !global;
}

@include override-tokens-for("think") {
  $playlist-drawer-title-font-size--small: 13.5px !global;
  $playlist-drawer-title-font-size--large: 14px !global;
}

@include override-tokens-for("knowyourvalue") {
  $playlist-drawer-title-font-size--small: 13.5px !global;
  $playlist-drawer-title-font-size--large: 14px !global;
  $playlist-drawer-duration-font-family: $founders-mono-font-family !global;
}
