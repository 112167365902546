.play {
  background-color: $video-slate-flag-default-background-color;
  height: calc(20vw * 0.56);
  opacity: 0.8;
  pointer-events: none;
  transition: opacity 200ms linear;
  width: 20vw;
  z-index: 10;

  &--live {
    background-color: $video-slate-flag-live-background-color;
  }

  @include breakpoint-m {
    height: calc(120px * 0.56);
    width: 120px;
  }

  @include breakpoint-x {
    height: calc(160px * 0.56);
    width: 160px;
  }
}

.videoSlate {
  cursor: pointer;

  &:hover .play {
    opacity: 1;
  }
}

.playButtonOuter {
  pointer-events: none;
  padding-top: 56.25%;
}

.replayIconContainer {
  pointer-events: none;
  z-index: 2;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.playIcon {
  font-size: calc(20vw * 0.2);

  @include breakpoint-m {
    font-size: rem(24px);
  }

  @include breakpoint-x {
    font-size: rem(30px);
  }
}

.replayIcon {
  font-size: 10vw; // 13px;

  @include breakpoint-m {
    font-size: 60px;
  }
}

.expiredOverlay {
  background-color: rgba(0, 0, 0, 0.8);
}
