
$video-package-background-color: $default-neutral !default;
$video-package-button-background-color--hover: $grey-70 !default;
$video-package-button-background-color: $white !default;
$video-package-button-border-color: $white !default;
$video-package-button-text-color: $white !default;

$video-package-see-all-bg-image: linear-gradient(35deg, $white 50%, transparent 50%) !default;
$video-package-see-all-border-color: $white !default;
$video-package-see-all-text-color-hover: $grey-70 !default;
$video-package-see-all-text-color: $white !default;
$video-package-see-all-wrapper-bg-color: $grey-70 !default;
$video-package-title-and-description-bg-color: $default-color !default;
$video-package-title-and-description-heading-border-color: $default-color !default;
$video-package-title-and-description-heading-color: $white !default;
$video-package-title-and-description-heading-font-style: normal !default;
$video-package-title-and-description-heading-font-weight: normal !default;
$video-package-title-and-description-text-color: $white !default;
$video-package-video-meta-bg: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)) !default;
$video-package-video-meta-duration-text-color: $white !default;
$video-package-video-meta-title-color: $white !default;
$video-package-video-wrapper-bg-color: $black !default;
$video-package-video-wrapper-text-color: $neutral-10 !default;
$video-package-description-line-height: 125% !default;
$video-package-description-margin: 6px 0 0 !default;
$video-package-description-font: $publico-hed !default;

@include override-tokens-for("msnbc") {
  $video-package-button-text-color: $msnbc-yellow-40 !global;
  $video-package-button-border-color: $msnbc-yellow-40 !global;
  $video-package-button-background-color: $msnbc-yellow-40 !global;
}

@include override-tokens-for("today") {
  $video-package-button-background-color--hover: $white !global;
  $video-package-button-background-color: $default-color !global;
  $video-package-button-border-color: $default-color !global;
  $video-package-button-text-color: $default-color !global;
  $video-package-see-all-bg-image: linear-gradient(35deg, $default-color 50%, transparent 50%) !global;
  $video-package-see-all-border-color: $default-color !global;
  $video-package-see-all-text-color-hover: $white !global;
  $video-package-see-all-text-color: $default-color !global;
  $video-package-see-all-wrapper-bg-color: $white !global;
  $video-package-title-and-description-bg-color: $white !global;
  $video-package-title-and-description-heading-color: $purple-70 !global;
  $video-package-title-and-description-heading-font-style: italic !global;
  $video-package-title-and-description-text-color: #555 !global;
  $video-package-description-font: $publico-hed !global;
}

@include override-tokens-for('news') {
  $video-package-title-and-description-bg-color: $blue-70 !global;
}

@include override-tokens-for('today') {
  $video-package-description-margin: 12px 0 1px !global;
}

@include override-tokens-for('msnbc') {
  $video-package-description-margin: 12px 0 1px !global;
}

@include override-tokens-for('noticias') {
  $video-package-title-and-description-bg-color: $blue-60 !global;
}
