.slim-lead {
  position: relative;
  color: $white;
  background: $slim-lead-gradient1;
  width: 100%;

  @include breakpoint-x {
    height: $slim-lead-max-height-image-x;
  }

  @include breakpoint-l {
    height: $slim-lead-max-height-image-l;
    margin-top: rem(-80px);
  }

  @include breakpoint-m-only {
    height: $slim-lead-max-height-image-m;
    margin-top: rem(-80px);
  }

  @include breakpoint-s-only {
    height: $slim-lead-max-height-image-s;
    margin-top: rem(-60px);
  }
}

.slim-lead__background-image {
  background-repeat: no-repeat;
  background-position: rem(110px) 0%;
  background-size: auto $slim-lead-max-height-image;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;

  @include breakpoint-m-only {
    background-position-x: 50%;
    background-size: auto rem(221px);
  }

  @include breakpoint-x {
    background-position-x: calc(50% - 152px);
    background-size: auto $slim-lead-max-height-image-x;
  }

  @include breakpoint-l-only {
    background-position-x: calc(50% - 129px);
    background-size: auto $slim-lead-max-height-image-l;
  }

  @include breakpoint-s-only {
    background-position-x: right;
    background-size: auto $slim-lead-max-height-image-s;
  }
}

.slim-lead__background--desktop {
  @include breakpoint-l {
    display: block;
  }
}

.slim-lead__background--mobile {
  @include breakpoint-s-only {
    display: block;
  }

  @include breakpoint-m-only {
    display: block;
  }
}
