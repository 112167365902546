// Package Top Decoration
$a-la-carte-top-decoration-border-color: $grey-40 !default;
$a-la-carte-top-decoration-tab-color: $default-color !default;
$a-la-carte-top-decoration-tab-width: $space-80 !default;
$a-la-carte-top-decoration-tab-height: $space-8 !default;
$a-la-carte-top-decoration-tab-top: 0 !default;

// Package Title
$a-la-carte-title-font-family: $default-headline-font-family !default;
$a-la-carte-title-font-style: normal !default;
$a-la-carte-title-font-weight: normal !default;
$a-la-carte-title-line-height: $leading-100 !default;
$a-la-carte-title-letter-spacing: normal !default;
$a-la-carte-title-color: $black !default;
$a-la-carte-title-margin-top: $space-24 !default;
$a-la-carte-title-margin-left: (s: $space-20, m: 0) !default;

// Content (Items and Button)
$a-la-carte-content-margin-top: $space-20 !default;
$a-la-carte-body-decoration-color: $default-color !default;
$a-la-carte-body-decoration-offset: $space-80 !default;
$a-la-carte-body-decoration-offset-m: $space-100 !default;

// Item >> Tease Images
$a-la-carte-tease-image-size: $space-80 !default;

// Item >> Unibrows
$a-la-carte-unibrow-color: $default-color !default;
$a-la-carte-unibrow-opinion-color: $think-red-40 !default;
$a-la-carte-unibrow-margin-bottom: $space-8 !default;

// Item >> Headlines
$a-la-carte-tease-headline-font-family: $default-headline-font-family !default;
$a-la-carte-tease-headline-font-size: (s: $text-20, l: $text-18) !default;
$a-la-carte-tease-headline-color: $default-headline-color !default;
$a-la-carte-tease-headline-font-weight: normal !default;
$a-la-carte-tease-headline-line-height: $leading-100 !default;

// Button
$a-la-carte-button-color: $default-color !default;
$a-la-carte-button-border-color: $default-color !default;

@include override-tokens-for('today') {
  // Package Top Decoration
  $a-la-carte-top-decoration-border-color: transparent !global;
  $a-la-carte-top-decoration-tab-width: $space-32 !global;
  $a-la-carte-top-decoration-tab-height: $space-1 !global;
  $a-la-carte-top-decoration-tab-top: -$space-1 !global; // cover transparent border which prevents margin collapse

  // Package Title
  $a-la-carte-title-font-style: italic !global;
  $a-la-carte-title-font-weight: $light !global;
  $a-la-carte-title-line-height: $leading-125 !global;
  $a-la-carte-title-letter-spacing: rem(0.2px) !global; // TODO add to design-tokens
  $a-la-carte-title-color: $purple-70 !global;
  $a-la-carte-title-margin-top: $space-12 !global;

  // Content (Items and Button)
  $a-la-carte-content-margin-top: $space-32 !global; // top decoration height + 12px

  // Item >> Tease Images
  $a-la-carte-tease-image-size: (s: $space-60, m: $space-80) !global;

  // Item >> Unibrows
  $a-la-carte-unibrow-margin-bottom: $space-4 !global;

  // Item >> Headlines
  $a-la-carte-tease-headline-font-size: (s: $text-16, m: $text-18, l: $text-16) !global;
  $a-la-carte-tease-headline-line-height: $leading-125 !global;
}

@include override-tokens-for('think') {
  // Package Title
  $a-la-carte-title-line-height: $leading-125 !global;

  // Item >> Headlines
  $a-la-carte-tease-headline-font-size: (s: $text-16, m: $text-18, l: $text-16) !global;
  $a-la-carte-tease-headline-line-height: $leading-125 !global;
}

@include override-tokens-for("globalcitizen") {
  // Button
  $a-la-carte-button-color: $red-40 !global;
  $a-la-carte-button-border-color: $red-40 !global;
}

@include override-tokens-for("leftfield") {
  // Button
  $a-la-carte-button-color: $grey-50 !global;
  $a-la-carte-button-border-color: $grey-50 !global;
}
