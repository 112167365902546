.standard-lead {
  position: relative;
  z-index: 1;
}

.standard-lead__picture {
  display: block;
  z-index: 0;
  width: 100%;

  @include breakpoint-l {
    width: auto;
  }

  picture {
    display: block;
  }
}

.standard-lead__info {
  background: $standard-lead-info-background;
  position: relative;
  padding-top: rem(5px);
  padding-left: $space-20;
  padding-right: $space-20;
  width: 100%;

  @include breakpoint-m {
    width: calc(100% - 68px);
    margin-top: rem(-60px);
    margin-left: rem(88px);
    padding-left: rem(30px);
    padding-right: rem(30px);
  }

  @include breakpoint-l {
    width: auto;
    margin-left: rem($standard-lead-gutter-desktop);
    padding-left: $standard-lead-gutter-desktop;
    padding-right: 0;
  }

  @include breakpoint-x {
    margin-left: rem(60px);
  }

  .standard-lead--no-image & {
    margin-left: 0;
  }

  &::before {
    background: $standard-lead-info-background-before;
    content: '';
    width: 100%;
    height: rem(5px);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.standard-lead__eyebrow {
  color: $standard-lead-eyebrow-color;
  position: relative;
  float: none;
  font-family: $standard-lead-eyebrow-font-family;
  font-size: rem($text-12);
  font-weight: 100;
  line-height: $leading-100;
  text-transform: uppercase;
  margin-top: rem($space-16);
  margin-bottom: rem($space-8);

  span {
    color: $standard-lead-eyebrow-span-color;
  }
}

.standard-lead__title {
  @include responsive-variations(font-size, $standard-lead-title-font-size);
  font-family: $standard-lead-title-font-family;
  font-weight: normal;
  line-height: $leading-100;
  color: $standard-lead-title-color;
  margin-top: rem($space-8);
  margin-bottom: rem($space-8);
}

.standard-lead__icon {
  font-size: 5px;
  color: $white;
  display: flex;
  flex: 0 0 rem(20px);
  align-items: center;
  justify-content: center;
  height: rem(20px);
  margin-right: rem(10px);
}

.standard-lead__icon.standard-lead__icon--has-author {
  background: rgba($standard-lead-has-author-background, 0);
  color: $standard-lead-has-author-color;
  font-size: rem($text-14);
}

.standard-lead__info > .standard-lead__icon {
  background: $standard-lead-info-icon-background;
  position: absolute;
  top: 0;
  right: 0;
  font-size: rem(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  height: rem(32px);
  width: rem(32px);
  pointer-events: none;

  @include breakpoint-m {
    left: calc(-40px);
    right: auto;
    height: rem(40px);
    width: rem(40px);

    .standard-lead--no-image & {
      left: auto;
      right: 0;
    }
  }

  @include breakpoint-l {
    right: auto;

    .standard-lead--no-image & {
      left: rem(-40px);
      right: auto;
    }
  }
}
