$big-headline-background-color: $default-color !default;
$big-headline-border-color: $default-color !default;
$big-headline-unibrow-color: $white !default;
$big-headline-unibrow-color--breaking: $breaking-red !default;
$big-headline-border-color--breaking: $breaking-red !default;

$big-headline-text-color: $white !default;
$big-headline-text-font-family: $default-headline-font-family !default;
$big-headline-text-line-height: $leading-100 !default;

$big-headline-dek-color: $white !default;

$big-headline-right-column-alignment--breaking: flex-start !default;
$big-headline-right-column-padding-top--breaking: (s: 0, m: $space-48) !default;

@include override-tokens-for("telemundoentretenimiento") {
  $big-headline-text-color: $blue-60 !global;
  $big-headline-dek-color: $grey-60 !global;
  $big-headline-background-color: $neutral-30 !global;

  $big-headline-unibrow-color: $red-50 !global;
  $big-headline-unibrow-color--breaking: $red-50 !global;

  $big-headline-border-color: $red-40 !global;
}

@include override-tokens-for("noticias") {
  $big-headline-background-color: $blue-60 !global;
  $big-headline-border-color: $blue-60 !global;
  $big-headline-unibrow-color--breaking: $white !global;
}

@include override-tokens-for("today") {
  $big-headline-text-line-height: (s: $leading-125, l: $leading-100) !global;
}

@include override-tokens-for("news") {
  $big-headline-background-color: $blue-70 !global;
  $big-headline-border-color: $blue-40 !global;
}

@include override-tokens-for("msnbc") {
  $big-headline-unibrow-color--breaking: $white !global;
  $big-headline-right-column-alignment--breaking: auto !global;
  $big-headline-right-column-padding-top--breaking: inherit !global;
}
