.two-up__articles {
  @include clearfix;
  background-color: $two-up-articles-background-color;
  position: relative;

  // TODO: remove when package spacing is standardized for all teases
  & > article {
    // include spacing on the bottom of all teases on mobile
    @include package-spacing;

    // last child on mobile should not have spacing due to layout-applied spacing
    &:last-child {
      margin-bottom: 0;
    }

    // all other breakpoints handle spacing at layout-level
    @include breakpoint-m {
      margin-bottom: 0;
    }
  }

  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include style-only-for('msnbc') {
    &.two-up__accent-line {
      border-top: 5px solid #ffbe03;
    }
  }
}

.tease-card--two-up {
  .tease-card__content {
    @include breakpoint-m {
      flex-basis: 100%;

      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{50%} - 1px);
      display: flex;
      flex-direction: column;
    }
  }

  .tease-card__featured-author {
    @include responsive-variations(font-size, $tease-card-two-up-featured-author-font-sizes);
    @include responsive-variations(line-height, $tease-card-two-up-featured-author-line-heights);
  }

  .tease-card__title {
    @include responsive-variations(font-size, $tease-card-two-up-title-font-sizes);
    @include responsive-variations(line-height, $tease-card-two-up-title-line-heights);

    .sponsoredcontent {
      font-family: $founders-semibold-font-family;
    }
  }

  .galleryPictures {
    @include breakpoint-m {
      display: flex;
      flex-wrap: wrap;
    }

    &::before {
      @include breakpoint-m {
        width: 50%;
        padding-top: 22%;
      }
    }
  }

  .galleryPicture {
    @include breakpoint-m {
      flex-basis: 50%;
      position: static;
    }

    &:nth-child(1) {
      @include breakpoint-m {
        margin-right: 50%;
      }
    }

    &:nth-child(n+2) {
      @include breakpoint-m {
        position: static;
      }
    }
  }
}
