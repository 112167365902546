.list-item__item {
  margin: 0;
  padding: 0;
  position: relative;
  display: block;

  @include responsive-variations(background-color, $list-item-bg-color);

  @include breakpoint-m {
    flex-basis: rem(320px);
    flex-shrink: 0;
  }

  @include breakpoint-l {
    height: rem(100px);
    display: flex;
  }

  @include breakpoint-x {
    height: rem(120px);
  }

  &::after {
    content: '';
    background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, 100));
    display: block;
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    @include breakpoint-l {
      top: auto;
      left: rem(216px);
      right: auto;
      background-image: none;
      background-color: $list-item-content-line-bg-color;
      width: rem(40px);
      height: rem(1px);
      opacity: .5;
    }

    @include breakpoint-x {
      left: rem(256px);
    }
  }

  &:hover {
    background-color: $list-item-hover-bg-color;
    cursor: pointer;
  }
}

.lazyload-wrapper:last-child > .list-item__item::after {
  @include breakpoint-l {
    display: none;
  }
}

.list-item__image {
  @include breakpoint-l {
    position: relative;
    flex: 0 0 rem(200px);
  }

  @include breakpoint-x {
    flex: 0 0 rem(240px);
  }
}

.list-item__expired-overlay-playlist {
  background-color: rgba($black, 0.8);
}

.list-item__active {
  @include responsive-variations(background-color, $list-item-active-bg-color);

  &::after {
    @include breakpoint-l {
      content: none;
    }
  }
}

.list-item__playing {
  pointer-events: none;

  @include breakpoint-m {
    .list-item__image {
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &::before {
        background: $list-item-playing-image-bg-color;
        opacity: $list-item-playing-image-bg-opacity;
        z-index: 1;
      }

      &::after {
        font-family: $founders-mono;
        font-weight: normal;
        font-size: rem(12px);
        line-height: rem(12px);
        content: attr(data-active-text);
        text-transform: uppercase;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
      }
    }
  }

  @include breakpoint-l {
    .list-item__image {
      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.list-item__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: rem(20px);

  @include responsive-variations(padding-right, $list-item-content-right-padding);
  color: $white;
  z-index: 2;
  width: 100%;

  @include breakpoint-l {
    position: static;
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: rem(0 24px 0 16px);
  }

  @include breakpoint-x {
    padding-left: rem(20px);
  }
}

.list-item__title {
  font-family: $list-item-title-font-family;
  font-size: $list-item-title-font-size;
  line-height: rem(20px);
  font-weight: normal;

  @include responsive-variations(color, $list-item-title-color);
  margin: 0;
  padding: 0;

  @include responsive-variations(padding-bottom, $list-item-title-padding-bottom);

  @include breakpoint-l {
    min-height: rem(40px);
    max-height: rem(60px);
    margin-bottom: rem(9px);
    box-sizing: content-box;
    overflow: hidden;
  }
}

.list-item__duration {
  font-family: $founders-mono;
  font-weight: normal;
  font-size: rem(10px);
  line-height: rem(10px);
  margin: 0;
  padding: 0;
  opacity: 0.5;

  @include breakpoint-l {
    font-size: rem(11px);
  }

  @include style-only-for('today') {
    position: absolute;
    bottom: rem(20px);
    right: rem(20px);
    opacity: 1;

    @include breakpoint-l {
      position: static;
      color: $grey-50;
    }
  }
}
