
.article-byline {
  border-top: 1px solid $grey-40;
  border-bottom: 1px solid $grey-40;
  margin-bottom: -1px;
}

.article-byline__inner {
  @include breakpoint-m {
    display: flex;
  }
}

.article-byline__name {
  display: block;

  @include breakpoint-m {
    flex: 1;
  }
}

.article-byline__social {
  display: block;

  @include breakpoint-m {
    display: flex;
  }
}

.article-byline__social-link {
  &:not(:last-of-type) {
    margin-right: rem(18px);
  }

  &:hover {
    color: $byline-social-link-hover-color;
  }
}

.article-byline__image {
  display: block;
  width: rem(48px);
  float: left;

  @include breakpoint-m {
    float: none;
    flex: 0 0 48px;
  }

  &.article-byline__image--has-image {
    font-size: 0;
  }

  &.article-byline__image--no-image::before {
    background-color: $byline-image-fallback-line-color;
    content: ' ';
    width: 100%;
    height: rem(1px);
    display: block;
  }
}

.article-byline__headshot {
  height: rem(48px);
  width: rem(48px);
  border-radius: 50%;
  background-color: $beige;
  overflow: hidden;
}

.article-byline__bio {
  a {
    color: $byline-bio-link-color;
  }
}
