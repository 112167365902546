.right-rail-tabbed__tabs {
  display: none;
  position: sticky;
  top: rem($navbar-mobile-height);
  z-index: 18;

  @include breakpoint-m {
    top: rem($navbar-default-height);
  }

  &--visible {
    display: flex;

    @include breakpoint-l {
      display: none;
    }
  }
}

.right-rail-tabbed__tab {
  font-family: $right-rail-tabbed-tab-font-family;
  font-size: rem(17px);
  line-height: rem(50px);
  text-transform: uppercase;
  background-color: $right-rail-tabbed-tab-background-color;
  flex: 0 0 50%;
  height: rem(50px);
  color: rgba($right-rail-tabbed-tab-text-color, .4);
  text-align: center;
  cursor: pointer;

  @include breakpoint-m {
    height: rem(70px);
    font-size: rem(20px);
    line-height: rem(70px);
  }

  &:first-child {
    border-right: 1px solid rgba($right-rail-tabbed-tab-right-border-color, .2);
  }

  &--active {
    color: rgba($right-rail-tabbed-tab-text-color, 1);
  }
}

.right-rail-tabbed__container--left {
  position: relative;

  @include breakpoint-m {
    margin-bottom: $space-48;
  }

  & > *:not(.bg) {
    margin-bottom: $space-48;
  }
}

.right-rail-tabbed__has-title-is-pancake {
  @include responsive-variations(margin-top, $right-rail-tabbed-title-or-pancake-top-margin);
}
