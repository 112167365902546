$package-title-description-lineheight: 125% !default;
$package-title-description-margin: 6px 0 0 !default;
$package-title-description-fontfamily: $publico-hed !default;
$package-title-description-color: $white !default;
$package-title-description-color--alternate: $white !default;

$package-title-background: $default-color !default;
$package-title-background--alternate: $default-color !default;
$package-title-see-all-link-font: $founders-cond !default;
$package-title-see-all-link-color: $white !default;
$package-title-see-all-link-alt-color: $default-color !default;
$package-title-see-all-link-underline: 3px solid !default;
$package-title-see-all-link-underline-color: $white !default;
$package-title-see-all-link-bottom-spacing: 0 !default;

$package-title-title-text-color: $white !default;
$package-title-title-text-color--alternate: $white !default;
$package-title-title-text-background: $default-color !default;
$package-title-title-text-background--alternate: $default-color !default;
$package-title-title-text-font-size: (s: $text-24, m: $text-32, l: $text-48) !default;
$package-title-title-text-font-style: normal !default;
$package-title-title-text-font-weight: normal !default;
$package-title-title-text-text-transform: none !default;

$package-title-title-text-line-color: $default-color !default;

@include override-tokens-for('news') {
  $package-title-background: $blue-70 !global;
  $package-title-description-margin: 12px 0 1px 0 !global;
  $package-title-title-text-background: $blue-70 !global;
}

@include override-tokens-for('today') {
  $package-title-description-margin: 12px 0 1px 0 !global;
  $package-title-description-color: $grey-60 !global;

  $package-title-see-all-link-underline: none !global;
  $package-title-background: $white !global;
  $package-title-background--alternate: transparent !global;

  $package-title-title-text-color: $purple-70 !global;
  $package-title-title-text-background: $white !global;
  $package-title-title-text-background--alternate: transparent !global;
  $package-title-title-text-font-style: italic !global;
  $package-title-title-text-font-weight: 300 !global;
  $package-title-title-text-font-size: (s: $text-24, m: $text-32) !global;
}

@include override-tokens-for('msnbc') {
  $package-title-description-margin: 11px 0 2px 0 !global;
}

@include override-tokens-for("noticias") {
  $package-title-background: $blue-60 !global;
  $package-title-see-all-link-underline-color: $default-color !global;
  $package-title-see-all-link-bottom-spacing: 4px !global;
  $package-title-title-text-background: transparent !global;
}

@include override-tokens-for('telemundo') {
  $package-title-description-color: $blue-60 !global;
  $package-title-description-margin: 12px 0 1px 0 !global;
  $package-title-see-all-link-color: $default-color !global;
  $package-title-see-all-link-underline: none !global;
  $package-title-background: $neutral-30 !global;

  $package-title-title-text-color: $blue-60 !global;
  $package-title-title-text-background: $default-neutral !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $package-title-description-color: $blue-60 !global;
  $package-title-description-margin: 12px 0 1px 0 !global;
  $package-title-see-all-link-color: $default-color !global;
  $package-title-see-all-link-underline: none !global;
  $package-title-background: $neutral-30 !global;

  $package-title-title-text-color: $blue-60 !global;
  $package-title-title-text-background: $default-neutral !global;
}

@include override-tokens-for('telemundoshows') {
  $package-title-description-color: $blue-60 !global;
  $package-title-description-margin: 12px 0 1px 0 !global;
  $package-title-see-all-link-color: $default-color !global;
  $package-title-see-all-link-underline: none !global;
  $package-title-background: $neutral-30 !global;

  $package-title-title-text-color: $blue-60 !global;
  $package-title-title-text-background: $default-neutral !global;
}

@include override-tokens-for("knowyourvalue") {
  $package-title-title-text-text-transform: lowercase !global;
}
