.magazine-lead {
  position: relative;
  width: 100%;
  color: $magazine-lead-text-color;
  clear: both;
  text-align: center;
  background-image: $magazine-lead-bg-image;
}

.magazine-lead__inner {
  position: relative;

  @include responsive-variations(padding-top, $magazine-lead-inner-padding-top);
  padding-bottom: rem(20px);
}

.magazine-lead__background {
  content: "";
  opacity: 0.16;
  background-repeat: no-repeat;
  background-position: 75% 0%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.magazine-lead__content {
  flex-grow: 1;
}

.magazine-lead__title-wrapper {
  display: flex;
  margin: 0;
  margin-top: rem(5px);

  @include breakpoint-m {
    margin: rem(0 67px);
  }

  @include breakpoint-l {
    margin: rem(0 32px);
  }

  &::before,
  &::after {
    content: ' ';
    display: block;
    border-top: $magazine-lead-wrapper-border;
    flex: 0 1 auto;
    width: 100%;
    height: rem(40px);
    margin-top: rem(15px);

    @include breakpoint-m {
      height: rem(30px);
    }
  }

  &::before {
    border-left: $magazine-lead-wrapper-border;
  }

  &::after {
    border-right: $magazine-lead-wrapper-border;
  }
}

.magazine-lead__title {
  @include responsive-variations(font-size, $magazine-lead-title-font-size);
  @include responsive-variations(line-height, $magazine-lead-title-line-height);
  position: relative;
  color: $magazine-lead-title-color;
  font-family: $magazine-lead-title-font-family;
  font-weight: $magazine-lead-title-font-weight;
  letter-spacing: normal;
  font-style: $magazine-lead-title-font-style;
  text-transform: $magazine-lead-title-transform;
  text-align: center;
  white-space: nowrap;
  margin: rem(0 10px 0);
}

.magazine-lead__see-all-wrapper {
  display: flex;
  margin: rem(10px 0 0);

  @include breakpoint-m {
    margin: rem(10px 67px 0);
  }

  @include breakpoint-l {
    margin-left: rem(32px);
    margin-right: rem(32px);
    margin-top: rem(7px);
  }

  &::before,
  &::after {
    content: ' ';
    display: block;
    border-bottom: $magazine-lead-wrapper-border;
    flex: 0 1 auto;
    width: 100%;
    height: rem(40px);
    margin-top: rem(-18px);

    @include breakpoint-m {
      margin-top: rem(-15px);
    }
  }

  &::before {
    border-left: $magazine-lead-wrapper-border;
  }

  &::after {
    border-right: $magazine-lead-wrapper-border;
  }
}

.magazine-lead__see-all-btn {
  color: $magazine-lead-see-all-btn-text-color;
  border: 1px solid $magazine-lead-see-all-btn-border-color;
  background-image: linear-gradient(35deg, $magazine-lead-see-all-btn-bg-color 50%, transparent 50%);
  width: auto;
  min-width: rem(170px);

  &:hover {
    color: $magazine-lead-see-all-btn-hover-text-color;
  }

  @include breakpoint-m {
    min-width: rem(220px);
  }
}

.magazine-lead__outer-tease-wrapper {
  overflow-x: visible;

  @include breakpoint-m {
    width: 100%;
  }
}

.magazine-lead__teases {
  display: flex;
  align-items: space-between;
  overflow-x: scroll;
  -ms-overflow-style: none;
  cursor: grab;

  @include breakpoint-l {
    width: 100%;
    overflow-x: unset;
    margin-left: 0;
    cursor: default;
  }
}

.magazine-lead__tease {
  flex: 1 0 60%;
  background-image: $magazine-lead-tease-bg-image;
  background-size: 5px 1px;
  background-repeat: repeat-x;
  margin-top: rem(10px);
  margin-right: rem(25px);
  padding: rem(30px 0 0);
  clear: left;
  text-align: left;

  @include breakpoint-m {
    flex: 1 0 35%;
    margin-right: rem(27px);
    margin-top: rem(15px);
    padding-top: rem(15px);
  }

  @include breakpoint-l {
    flex: 1 1 20%;
    padding-top: rem(20px);
    margin-right: rem(32px);

    @include ie {
      flex: 1 1 25%;
    }
  }

  @include breakpoint-x {
    margin-right: rem(50px);
  }

  &:last-child {
    margin-right: 0;
  }

  .magazine-lead__headline {
    @include responsive-variations(font-size, $magazine-lead-headline-font-size);
    @include responsive-variations(line-height, $magazine-lead-headline-line-height);
    color: $magazine-lead-headline-color;
    font-family: $magazine-lead-headline-font-family;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    display: inline;
    margin: 0;
  }
}

.magazine-lead__tease-thumbnail {
  position: relative;
  display: block;
  width: rem(60px);
  height: rem(60px);
  float: left;
  margin: rem(0 8px 40px 0);

  @include breakpoint-x {
    width: rem(80px);
    height: rem(80px);
  }

  picture {
    display: block;
    overflow: auto;
    border-radius: $magazine-lead-tease-image-border-radius;
  }
}

.magazine-lead__tease-thumbnail__icon {
  position: absolute;
  left: -4px;
  bottom: -4px;
}

.magazine-lead__unibrow {
  color: $magazine-lead-unibrow-color;
  font-family: $magazine-lead-unibrow-font-family;
  font-size: rem(12px);
  line-height: 1;
  font-weight: 100;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: rem(8px);
  letter-spacing: -0.04em;
}
