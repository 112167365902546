$big-story-takeover-meta-bg-color: $default-color !default;
$big-story-takeover-tease-bg-color: $default-color !default;
$big-story-takeover-top-border-display: block !default;

$big-story-takeover-unibrow-color: $white !default;
$big-story-takeover-timestamp-color: $white !default;

$big-story-takeover-headline-font: $founders-cond !default;
$big-story-takeover-headline-font-size: (s: rem(32px), m: rem(60px), l: rem(48px), x: rem(60px)) !default;
$big-story-takeover-headline-font-weight: 400 !default;
$big-story-takeover-headline-color: $white !default;
$big-story-takeover-headline-text-transform: none !default;

$big-story-takeover-hero-link-top-border: 5px solid !default;
$big-story-takeover-hero-link-top-border-color: $default-color !default;

$big-story-takeover-play-button-bg: $default-color !default;
$big-story-takeover-play-icon-color: $white !default;
$big-story-takeover-video-bg: $black !default;
$big-story-takeover-breaking-news-color: $news-red-30 !default;

@include override-tokens-for('today') {
  $big-story-takeover-top-border-display: none !global;
  $big-story-takeover-play-button-bg: $purple-70 !global;
}

@include override-tokens-for('news') {
  $big-story-takeover-meta-bg-color: $blue-70 !global;
  $big-story-takeover-tease-bg-color: $blue-70 !global;
}

@include override-tokens-for('noticias') {
  $big-story-takeover-meta-bg-color: $blue-60 !global;
  $big-story-takeover-tease-bg-color: $blue-60 !global;
}

@include override-tokens-for('mach') {
  $big-story-takeover-hero-link-top-border-color: $white !global;
}

@include override-tokens-for('better') {
  $big-story-takeover-hero-link-top-border-color: $white !global;
}

@include override-tokens-for('leftfield') {
  $big-story-takeover-hero-link-top-border-color: $white !global;
}

@include override-tokens-for('globalcitizen') {
  $big-story-takeover-hero-link-top-border-color: $white !global;
}

@include override-tokens-for('leftfield') {
  $big-story-takeover-hero-link-top-border-color: $white !global;
}

@include override-tokens-for('msnbc') {
  $big-story-takeover-hero-link-top-border-color: $yellow-40 !global;
  $big-story-takeover-hero-link-top-border: none !global;
}

@include override-tokens-for('think') {
  $big-story-takeover-hero-link-top-border-color: $white !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $big-story-takeover-meta-bg-color: $neutral-30 !global;
  $big-story-takeover-tease-bg-color: $neutral-30 !global;
  $big-story-takeover-headline-color: $blue-60 !global;
  $big-story-takeover-headline-text-transform: uppercase !global;
  $big-story-takeover-headline-font-weight: 600 !global;
  $big-story-takeover-unibrow-color: $red-50 !global;
  $big-story-takeover-timestamp-color: $grey-60 !global;
  $big-story-takeover-breaking-news-color: $red-40 !global;
  $big-story-takeover-hero-link-top-border-color: $neutral-30 !global;
}
