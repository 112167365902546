$featured-tease-picture-outer-gutter: 20px !default;
$featured-tease-title-font-sizes: (s: 30px, m: 40px, l: 50px, x: 60px) !default;
$featured-tease-title-line-heights: (s: 30px, m: 40px, l: 50px, x: 60px) !default;
$featured-tease-category-background-color: $default-color !default;
$featured-tease-category-color: $white !default;
$featured-tease-title-font-family: $default-headline-font-family !default;

@include override-tokens-for('noticias') {
  $featured-tease-category-background-color: $white !global;
  $featured-tease-category-color: $default-color !global;
}

@include override-tokens-for('news') {
  $featured-tease-category-background-color: $blue-70 !global;
}

@include override-tokens-for('today') {
  $featured-tease-title-font-sizes: (s: 22.5px, m: 30px, l: 37.5px, x: 45px) !global;
}

@include override-tokens-for('think') {
  $featured-tease-title-font-sizes: (s: 22.5px, m: 30px, l: 37.5px, x: 45px) !global;
  $featured-tease-title-font-family: $publico-hed !global;
}

@include override-tokens-for('knowyourvalue') {
  $featured-tease-title-font-sizes: (s: 22.5px, m: 30px, l: 37.5px, x: 45px) !global;
}
