$front-display-visibility: auto !default;
$front-background-color: transparent !default;
$front-background-position: static !default;
$front-background-width: auto !default;
$front-background-height: auto !default;

@include override-tokens-for('msnbc') {
  $front-display-visibility: (s:none, l: block) !global;
  $front-background-color: $default-color !global;
  $front-background-position: absolute !global;
  $front-background-width: (l: 100%) !global;
  $front-background-height: (l: 90px) !global;
}
