.animated-ghost-button {
  font-family: $button-font-family;
  line-height: $leading-100;
  font-size: $text-16;
  padding: $space-24 $space-4;
  background-position: -400px 0;
  background-repeat: no-repeat;
  transition: background-position ease 0.2s;
  color: $button-text-color;
  border: 1px solid $button-border-color;
  background-image: linear-gradient(35deg, $button-background-color 50%, transparent 50%);
  text-transform: uppercase;
  display: block;
  text-align: center;
  width: 100%;

  &:hover {
    background-size: 325% 100%;
    background-position: 0 0;
    color: $button-text-hover-color;
  }

  &:active {
    opacity: $button-active-state;
  }

  .breaking &,
  .pancake__key-updates & {
    color: $pancake-key-updates-color;
    border: 1px solid $pancake-key-updates-border-color;
    background-image: linear-gradient(35deg, $pancake-key-updates-bg-gradient-color 50%, transparent 50%);
  }

  .breaking &:hover,
  .pancake__key-updates &:hover {
    color: $white;
  }
}

.animated-ghost-button--small {
  padding: $space-16 $space-4;
}

.animated-ghost-button--darkBackground {
  color: $button-text-color--darkBackground;
  background-image: linear-gradient(35deg, $button-text-background-color--darkBackground 50%, transparent 50%);
  border: 1px solid $button-border-color--darkBackground;

  &:hover {
    color: $button-text-hover-color--darkBackground;
  }
}
