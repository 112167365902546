.gift-guide {
  margin: 0 auto rem(48px);
  width: auto;

  &__title {
    display: block;
    width: 100%;
  }

  @include breakpoint-m {
    width: rem(758px);
  }

  @include breakpoint-l {
    width: rem(1000px);
  }

  @include breakpoint-x {
    width: rem(1240px);
  }

  &__wrapper {
    float: left;
    position: relative;
    display: block;
    width: 100%;

    &::after {
      content: "";
      width: 100vw;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -50vw;
      background: #fff;
      z-index: -1;
    }
  }

  &__guide {
    padding-bottom: $gift-guide-padding-bottom;
  }

  &__container {
    margin-bottom: $gift-guide-container-margin-bottom;
    padding-top: $gift-guide-container-padding-top;

    @include responsive-variations(padding-left, $gift-guide-left-padding);
    @include responsive-variations(padding-right, $gift-guide-right-padding);
  }

  &__items {
    @include breakpoint-m {
      width: 100%;
      padding: 0;
    }

    .relatedItem {
      @include breakpoint-m {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      @include breakpoint-m {
        &:first-child {
          padding-left: 32px;
        }

        &:last-child {
          padding-right: 32px;
        }
      }

      @include breakpoint-x {
        &:first-child {
          padding-left: 95px;
        }

        &:last-child {
          padding-right: 95px;
        }
      }

      &__title {
        a,
        h3 {
          line-height: 125%;
        }
      }

      &__thumb {
        picture {
          width: 100px;
          height: 100px;

          @include breakpoint-l {
            width: 134px;
            height: 134px;
          }
        }
      }
    }
  }
}
