.embed-info {
  &__link {
    display: inline-block;
    transition: opacity 150ms linear;

    &:hover {
      opacity: $default-hover-opacity;
    }
  }

  &__headline {
    font-family: $embed-pkg-headline-font-family;
    font-size: $embed-pkg-headline-font-size;
    font-style: $embed-pkg-headline-font-style;
    color: $embed-pkg-headline-color;
    font-weight: $embed-pkg-headline-font-weight;
    line-height: $embed-pkg-headline-leading;
    margin: 0 0 $space-12;
    display: inline-block;

    &__icon {
      line-height: $embed-pkg-headline-leading;
      color: currentColor;
      margin-left: $space-4;
      font-size: $embed-pkg-headline-font-size * .625;
    }

    // Need higher specificity to overwrite default icon styles
    .icon-arrow-link::before {
      vertical-align: -1px;
    }
  }

  &__label {
    font-family: $embed-pkg-label-font-family;
    font-size: $embed-pkg-label-font-size;
    color: $embed-pkg-label-color;
    line-height: $leading-150;
    font-weight: $embed-pkg-label-font-weight;
    font-style: normal;

    &--breaking {
      color: $embed-pkg-label-breaking-color;
    }
  }

  &__dek {
    font-family: $embed-pkg-dek-font-family;
    font-size: $embed-pkg-dek-font-size;
    color: $embed-pkg-dek-color;
    line-height: $leading-150;
    margin: 0 0 $space-20;
    max-width: 760px;
  }
}

@include breakpoint-x {
  .embed-info__headline--large-text {
    font-size: $embed-pkg-headline-xl-font-size;

    .embed-info__headline__icon {
      font-size: $embed-pkg-headline-xl-font-size * .625;
    }
  }

  .embed-info__label--large-text {
    font-size: $embed-pkg-label-xl-font-size;
  }
}
