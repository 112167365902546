.promo3Card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: rem(30px) 0;

  &:nth-child(1),
  &:nth-child(2) {
    border-bottom: solid 1px $promo3card-grey-border;
  }

  @include breakpoint-l {
    flex: 1 0;

    &:nth-child(1) {
      padding-right: $space-40;
      border-bottom: 0;
    }

    &:nth-child(2) {
      padding-left: $space-40;
      padding-right: $space-40;
      border-bottom: 0;
    }

    &:nth-child(3) {
      padding-left: $space-40;
    }

    &:nth-child(1)::after,
    &:nth-child(2)::after {
      width: 0;
      height: calc(100% - 35px);
      display: block;
      content: '';
      border-right: solid 1px $promo3card-grey-border;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.promo3Card__headline {
  font-family: $promo3card-headline-font-family;
  font-weight: $promo3card-headline-font-weight;
  color: $promo3card-headline-color;
  font-style: $promo3card-headline-font-style;
  font-size: $promo3card-headline-font-size;
  margin-top: $promo3card-headline-margin-top;
  margin-bottom: $promo3card-headline-margin-bottom;
}

.promo3card__dek {
  font-size: $promo3card-dek-font-size;
  line-height: $promo3card-dek-line-height;
  margin: rem(10px) 0 auto;
  color: $promo3card-dek-text-color;

  @include breakpoint-m {
    margin: 1rem 0 auto;
  }
}

.promo3panel__button {
  width: rem(120px);
  margin-top: rem(20px);
}

// increase specificity
.teasePicture.promo3card__image {
  width: rem(100px);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;

  @include breakpoint-m {
    width: rem(120px);
  }

  @include breakpoint-x {
    width: rem(155px);
  }
}

.promo3Card--has-image {
  .promo3card__dek {
    @include breakpoint-m {
      margin-right: 7rem;
    }

    @include breakpoint-l {
      margin-right: 6rem;
    }

    @include breakpoint-x {
      margin-right: 7rem;
    }
  }
}

@include style-only-for('today') {
  @include breakpoint-m-max {
    .promo3Card {
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: none;

        &::after {
          @include pattern-dots-border($grey-60, bottom);
          display: block;
          content: '\00a0';
          position: absolute;
          top: auto;
          right: auto;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}
