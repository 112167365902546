$lead-section-front-has-image-header-bg-image-height: rem(410px) !default;
$lead-section-front-has-image-header-bg-image-height-m: rem(630px) !default;
$lead-section-front-background-color: $default-color !default;
$lead-section-front-outer-gutter: 20px !default;
$lead-section-front-gutter-tablet: 4vw !default;
$lead-section-front-gutter-mobile: 2vw !default;
$lead-section-front-header-title-font-size: (s: rem($text-32), m: rem($text-48), l: rem($text-60) ) !default;
$lead-section-front-header-title-font-family: $founders-cond !default;
$lead-section-front-header-title-font-weight: $normal !default;
$lead-section-front-header-title-font-style: normal !default;
$lead-section-front-header-title-line-height: $leading-100 !default;
$lead-section-front-header-title-text-transform: uppercase !default;
$lead-section-front-header-title-before-content: none !default;
$lead-section-front-header-title-before-border-color: $default-color !default;
$lead-section-front-header-content-color: $white !default;
$lead-section-front-header-content-color-img: $black !default;
$lead-section-front-header-dek-color: $white !default;
$lead-section-front-header-dek-color-img: $grey-60 !default;
$lead-section-front-grid-margin-tablet: calc((#{$lead-section-front-outer-gutter} - #{$lead-section-front-gutter-tablet}) * -1) !default;
$lead-section-front-header-image-opacity: .4 !default;
$lead-section-front-navigation-border-color: rgba(255, 255, 255, 0.3) !default;
$lead-section-front-navigation-border-bottom-color: $grey-20 !default;
$lead-section-front-navigation-color: inherit !default;
$lead-section-front-beige: #f1f0ed !default;
$lead-section-front-paid-hub-container-color: #f6f6f6 !default;
$lead-section-front-navbar-mobile-height: 60px !default;
$lead-section-front-navbar-default-height: 80px !default;

@include override-tokens-for("noticias") {
  $lead-section-front-background-color: $blue-60 !global;
}

@include override-tokens-for("today") {
  $lead-section-front-background-color: $white !global;
  $lead-section-front-header-title-font-size: (s: rem($text-32), m: rem($text-60)) !global;
  $lead-section-front-header-title-font-family: $publico-hed !global;
  $lead-section-front-header-title-font-weight: $light !global;
  $lead-section-front-header-title-font-style: italic !global;
  $lead-section-front-header-title-text-transform: capitalize !global;
  $lead-section-front-header-title-before-content: '' !global;
  $lead-section-front-header-content-color: $purple-70 !global;
  $lead-section-front-header-dek-color: $grey-60 !global;
  $lead-section-front-header-image-opacity: .2 !global;
  $lead-section-front-navigation-border-color: rgba(56, 5, 56, 0.2) !global;
  $lead-section-front-navigation-border-bottom-color: rgba(56, 5, 56, 0.2) !global;
}

@include override-tokens-for("better") {
  $lead-section-front-header-dek-color: $grey-60 !global;
}

@include override-tokens-for("knowyourvalue") {
  $lead-section-front-header-title-text-transform: lowercase !global;
}

@include override-tokens-for("news") {
  $lead-section-front-background-color: $blue-70 !global;
  $lead-section-front-header-image-opacity: .2 !global;
  $lead-section-front-navigation-border-color: rgba(0, 0, 0, 0.2) !global;
  $lead-section-front-navigation-border-bottom-color: rgba(0, 0, 0, 0.2) !global;
}

@include override-tokens-for("telemundo") {
  $lead-section-front-header-title-font-weight: $semibold !global;
  $lead-section-front-background-color: $neutral-30 !global;
  $lead-section-front-header-title-text-transform: none !global;
  $lead-section-front-header-title-before-content: '' !global;
  $lead-section-front-header-content-color: $blue-60 !global;
  $lead-section-front-header-dek-color: $grey-60 !global;
  $lead-section-front-navigation-color: $default-color !global;
  $lead-section-front-navigation-border-color: rgba($blue-60, 0.2) !global;
  $lead-section-front-navigation-border-bottom-color: rgba($blue-60, 0.2) !global;
}

@include override-tokens-for("telemundoentretenimiento") {
  $lead-section-front-header-title-font-weight: $semibold !global;
  $lead-section-front-background-color: $neutral-30 !global;
  $lead-section-front-header-title-text-transform: none !global;
  $lead-section-front-header-title-before-content: '' !global;
  $lead-section-front-header-content-color: $blue-60 !global;
  $lead-section-front-header-dek-color: $grey-60 !global;
  $lead-section-front-navigation-color: $default-color !global;
  $lead-section-front-navigation-border-color: rgba($blue-60, 0.2) !global;
  $lead-section-front-navigation-border-bottom-color: rgba($blue-60, 0.2) !global;
}

@include override-tokens-for("telemundoshows") {
  $lead-section-front-header-title-font-weight: $semibold !global;
  $lead-section-front-background-color: $neutral-30 !global;
  $lead-section-front-header-title-text-transform: none !global;
  $lead-section-front-header-title-before-content: '' !global;
  $lead-section-front-header-content-color: $blue-60 !global;
  $lead-section-front-header-dek-color: $grey-60 !global;
  $lead-section-front-navigation-color: $default-color !global;
  $lead-section-front-navigation-border-color: rgba($blue-60, 0.2) !global;
  $lead-section-front-navigation-border-bottom-color: rgba($blue-60, 0.2) !global;
}
