.caption {
  position: relative;
  font-size: rem(14px);
  line-height: 1.5;
  color: $grey-70;
  font-family: $default-text-font-family;
}

.caption__container {
  margin-right: rem(12px);
}

.caption__source {
  display: inline-block;
  font-size: rem(12px);
  font-family: $founders-mono-font-family;
  letter-spacing: -0.04em;
  word-spacing: -0.1em;
  color: $grey-60;
}

.caption::before {
  border-bottom-color: $caption-border-bottom-color;
  content: '';
  display: inline-block;
  vertical-align: middle;
  border-bottom-style: solid;
  border-bottom-width: rem(1px);
  width: rem(20px);
  top: rem(10px);
  margin-right: rem(10px);

  @include breakpoint-m {
    width: rem(40px);
  }
}
