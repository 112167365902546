$promo3card-dek-font-size: rem(14px) !default;
$promo3card-dek-line-height: 1.5 !default;
$promo3card-dek-text-color: $grey-70 !default;
$promo3card-grey-border: $grey-40 !default;
$promo3card-headline-button-color--hover: $news-blue-40 !default;
$promo3card-headline-color: $default-color !default;
$promo3card-headline-font-family: $default-headline-font-family !default;
$promo3card-headline-font-size: rem(24px) !default;
$promo3card-headline-font-style: normal !default;
$promo3card-headline-font-weight: $normal !default;
$promo3card-headline-margin-bottom: 0 !default;
$promo3card-headline-margin-top: 0 !default;

@include override-tokens-for("news") {
  $promo3card-dek-text-color: $grey-70 !global;
  $promo3card-headline-color: $black !global;
}

@include override-tokens-for("today") {
  $promo3card-dek-text-color: $grey-70 !global;
  $promo3card-headline-color: $purple-70 !global;
  $promo3card-headline-font-family: $publico-light-italic-font-family !global;
  $promo3card-headline-font-size: rem(20px) !global;
  $promo3card-headline-font-style: italic !global;
  $promo3card-headline-font-weight: $light !global;
  $promo3card-headline-margin-bottom: 3px !global;
  $promo3card-headline-margin-top: 5px !global;
}

@include override-tokens-for("msnbc") {
  $promo3card-dek-text-color: $grey-70 !global;
  $promo3card-headline-color: $black !global;
}

@include override-tokens-for('telemundo') {
  $promo3card-headline-color: $blue-60 !global;
  $promo3card-dek-text-color: $grey-60 !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $promo3card-headline-color: $blue-60 !global;
  $promo3card-dek-text-color: $grey-60 !global;
}

@include override-tokens-for('telemundoshows') {
  $promo3card-headline-color: $blue-60 !global;
  $promo3card-dek-text-color: $grey-60 !global;
}
