@include style-only-for("today") {
  .mobile-banner {
    z-index: 100;
    height: 12rem;
    color: $default-text-color;

    &::before {
      background-image: linear-gradient(to right, $default-gradient);
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
    }

    &__close {
      top: 20px;
      right: 10px;
    }

    &__social-button {
      border-width: 1px;
      border-style: solid;
      border-color: $grey-50;
      box-sizing: border-box;
      width: 55%;
    }

    &__social-button--logo {
      height: 1.25rem;
    }

    &__input-wrapper {
      height: 1.75rem;
    }

    &__input {
      font-family: $founders-mono-font-family;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-width: 1px;
      border-style: solid;
      border-color: $grey-50;
      font-size: 12px;
    }

    &__email-subscribe-button {
      background-color: $today-purple-70;
      font-size: 14px;
    }

    &__email-subscribe-button--invalid {
      color: $today-purple-70;
      border-color: $today-purple-70;
      font-size: 14px;
    }

    &__footer {
      font-size: 6px;
    }

    &__recaptcha-links {
      color: $default-color;
      font-size: 6px;
    }

    &__dots {
      @include pattern-dots($black);
      height: rem(28px);
      width: rem(68px);
    }
  }
}
