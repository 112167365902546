.tve__signin {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  color: $grey-70;
  height: 100%;

  &__action {
    border: none;
    outline: none;
    -webkit-appearance: none;
    background-color: $tve__action-color-base;
    display: inline-block;
    height: 100%;
    font-family: $founders-cond;
    font-size: rem($text-16);
    line-height: 1;
    text-transform: uppercase;
    padding: rem(10px 8px);

    &:hover {
      background-color: $tve__action-color-hover;
    }

    &:active {
      background-color: $tve__action-color-active;
    }

    &--authed {
      font-size: rem($text-14);
      color: $tve__action-color-base;
      background-color: transparent;

      &:hover {
        color: $tve__action-color-hover;
        background-color: transparent;
      }

      &:active {
        color: $tve__action-color-active;
        background-color: transparent;
      }
    }

    &--withLock {
      padding: 0 rem(32px);

      @include breakpoint-l {
        padding: 0;
        width: rem(60px);
      }

      @include breakpoint-x {
        padding: 0;
        width: rem(80px);
      }
    }
  }

  &__lock {
    font-size: rem($text-18);

    @include breakpoint-l {
      font-size: rem($text-24);
    }
  }

  &__label {
    display: inline-block;

    .tve__signin__action--withLock & {
      margin-left: rem(6px);

      @include breakpoint-l {
        display: none;
      }
    }
  }
}
