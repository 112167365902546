.big-story-takeover {
  position: relative;
}

.big-story-takeover__unibrow {
  color: $big-story-takeover-unibrow-color;
  font-family: $founders-mono;
  font-size: rem(12px);
  line-height: 1;
  font-weight: 100;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: .5rem;
}

.big-story-takeover__headline {
  font-family: $big-story-takeover-headline-font;
  color: $big-story-takeover-headline-color;
  text-transform: $big-story-takeover-headline-text-transform;

  @include responsive-variations(font-size, $big-story-takeover-headline-font-size);
  line-height: 1;
  font-weight: $big-story-takeover-headline-font-weight;
  margin-bottom: 0;
  margin-top: .5rem;

  @include breakpoint-l {
    margin-right: 1.5rem;
  }
}

.big-story-takeover__timestamp {
  color: $big-story-takeover-timestamp-color;
  font-family: $founders-mono;
  font-size: rem(12px);
  line-height: 1;
  font-weight: 100;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.big-story-takeover__meta {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  z-index: 1;

  @include breakpoint-m {
    margin-left: 0;
    margin-right: 0;
  }

  &::before {
    content: '';
    width: 100vw;
    background-color: $big-story-takeover-meta-bg-color;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;

    @include breakpoint-l {
      left: calc(-50vw + 460px);
    }

    @include breakpoint-x {
      left: calc(-50vw + 580px);
    }
  }
}

.big-story-takeover__tease {
  position: relative;
  margin-bottom: 2rem;

  &::before {
    @include breakpoint-m {
      content: '';
      width: 100vw;
      height: rem(64px);
      background-color: $big-story-takeover-tease-bg-color;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    @include breakpoint-l {
      left: calc(-50vw + 460px);
    }

    @include breakpoint-x {
      left: calc(-50vw + 580px);
    }
  }
}

.big-story-takeover__border-top {
  display: $big-story-takeover-top-border-display;
  width: calc(100% - 20px);
  z-index: 1;
  position: absolute;
}

.big-story-takeover__hero-link {
  position: relative;
  display: block;
  border-top: $big-story-takeover-hero-link-top-border;
  border-top-color: $big-story-takeover-hero-link-top-border-color;
  overflow: visible;
  cursor: pointer;
  z-index: 3;

  .big-story-takeover--breaking-news & {
    background-color: $big-story-takeover-breaking-news-color;
    border-top-color: $big-story-takeover-breaking-news-color;
  }
}

.big-story-takeover__hero-image {
  position: relative;
  overflow: visible;
  display: block;
}

.big-story-takeover__play-button {
  width: rem(40px);
  height: rem(40px);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: rem(40px);
  border: none;
  box-shadow: none;
  background: $big-story-takeover-play-button-bg;
  transform-origin: top left;
  transition: width 0.2s, height 0.2s;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  .big-story-takeover--breaking-news & {
    background: $big-story-takeover-breaking-news-color;
  }

  .big-story-takeover__hero-link:hover & {
    @include breakpoint-l {
      height: rem(100px);
      width: rem(200px);

      .big-story-takeover__play-icon {
        font-size: rem(20px);
      }
    }
  }

  .big-story-takeover__play-icon {
    font-size: rem(6px);
    color: $big-story-takeover-play-icon-color;
    transition: font-size 0.2s;
  }
}

.big-story-takeover__video {
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 1000;
  background: $big-story-takeover-video-bg;
  height: 100%;
  width: 100%;

  @include breakpoint-l {
    height: rem(337.5px);
  }

  @include breakpoint-x {
    height: rem(484px);
  }
}
