.key-updates {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: rem(20px);
  margin-right: rem(20px);
  z-index: 1;

  @include breakpoint-m {
    margin-left: 0;
    margin-right: 0;
  }

  @include breakpoint-l {
    margin-top: rem(72px);
    height: calc(100% - 72px);
  }

  &::before {
    @include breakpoint-l {
      z-index: -1;
      content: "";
      position: absolute;
      display: block;
      width: calc(100% + 80px);
      top: rem(-40px);
      left: rem(-40px);
      bottom: 0;
      background: #fff;
    }
  }
}

.key-updates__title {
  font-size: $key-updates-title-font-size;
  line-height: $key-updates-title-font-line-height;
  border-top: 1px solid $key-updates-title-border-top-color;
  text-transform: uppercase;
  margin-top: 0;

  &::before {
    content: '';
    display: block;
    width: rem(80px);
    border-bottom: rem(8px) solid $key-updates-title-pseudo-bg-color;
    margin-bottom: rem(20px);
  }
}

.key-updates--dotted-line {
  margin-bottom: rem(20px);
  background-image: linear-gradient(to right, #223049 20%, transparent 0);
  background-size: 5px 100%;
  height: 1px;
}

.key-updates__list {
  color: $key-updates-list-color;
  font-size: rem(14px);
  line-height: 1.5;
  margin-left: 1rem;
  list-style: disc;
}

.key-updates__list-item {
  margin-bottom: rem(9px);
  display: list-item;
}

.key-updates__button {
  padding-top: rem(24px);
  padding-bottom: rem(24px);
  margin: rem(48px) auto;
}

.key-updates--breaking {
  .key-updates__title::before {
    border-bottom-color: $key-updates-breaking-color;
  }

  .key-updates__list-item::before {
    background-color: $key-updates-breaking-color;
  }

  .key-updates__button {
    color: $key-updates-breaking-color;
    border-color: $key-updates-breaking-color;
    background-image: linear-gradient(35deg, $key-updates-breaking-color 50%, transparent 50%) !important;

    &:hover {
      color: $white;
    }
  }
}
