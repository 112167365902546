$type-icon-width: 30px !default;
$type-icon-height: 32px !default;

$slideshow-unibrow-width: 96px !default;
$video-unibrow-width: 68px !default;

$type-icon-inline-font-size: 8px;

.media-label {
  display: flex;

  &__eyebrow {
    font-size: $text-12;
    font-family: $founders-mono;
    font-weight: $light;
    background-color: $white;
    color: $grey-60;
    height: rem($space-32);
    line-height: $leading-100;
    margin: rem(-$space-32) 0 0;
    padding: 10px 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;

    &--slideshow {
      width: $slideshow-unibrow-width;
    }

    &--video {
      width: $video-unibrow-width;
    }
  }

  &__type-icon {
    &--inline {
      color: $type-icon-inline-color;
      font-size: $text-10;
      position: absolute;
    }

    &--block {
      align-items: center;
      display: inline-flex;
      font-size: $type-icon-inline-font-size;
      color: $white;
      height: 100%;
    }
  }

  &__icon-container {
    margin-top: -$space-32;
    padding: 0 $space-8;
    position: relative;
    text-align: center;
    width: $type-icon-width;
    z-index: 2;
  }

  &__icon-background {
    background-color: $media-label-icon-background-color;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
}
