$width-m: 612px;
$width-l: 660px;
$width-x: 700px;

%tveProviderColumnWidth {
  width: 100%;

  @include breakpoint-m {
    width: $width-m;
    margin: 0 auto;
  }

  @include breakpoint-l {
    width: $width-l;
  }

  @include breakpoint-x {
    width: $width-x;
  }
}

.tve__provider-select {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($grey-70, 0.99);
  padding: rem(20px 20px 300px);
  text-align: center;
  overflow: scroll;
  font-size: rem($text-18);
  font-family: $publico-txt;
  color: $white;
  -moz-osx-font-smoothing: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;

  @supports (backdrop-filter: blur()) {
    background: rgba($grey-70, 0.92);
    backdrop-filter: blur(50px);
  }

  @include breakpoint-l {
    display: flex;
    flex-flow: column nowrap;
    padding: rem(20px);
  }

  &__heading-h1,
  &__heading-h3 {
    line-height: 100%;
    margin: 0;
    padding: 0;
  }

  &__heading-h1 {
    font-size: rem($text-32);
    margin: 0 0 rem(24px) 0;

    @include breakpoint-m {
      font-size: rem($text-32);
      margin-top: rem(20px);
    }
  }

  &__heading-h3 {
    font-size: rem($text-24);
    margin: 0 0 rem(16px) 0;

    @include breakpoint-m {
      font-size: rem($text-32);
    }

    &__text {
      margin: 0 auto;
    }
  }

  &__description {
    line-height: 125%;
    padding: 0;
    margin: rem(12px 24px 24px);
  }

  &__search-text {
    line-height: 125%;
    padding: 0;
    margin: rem(32px) auto rem(24px);
    width: 300px;

    @include breakpoint-m {
      width: auto;
    }
  }

  &__button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    outline: none;

    &__full-list {
      color: $tve-provider-select-action-color;

      &:hover {
        color: $tve-provider-select-action-hover;
      }

      &:active {
        color: $tve-provider-select-action-active;
      }
    }

    &__close {
      color: $white;
      font-size: rem($text-24);
      position: absolute;
      top: 20px;
      right: 20px;

      @include breakpoint-m {
        top: 40px;
        right: 40px;
      }

      &:hover {
        opacity: $default-hover-opacity;
      }

      &:active {
        opacity: $default-active-opacity;
      }
    }
  }

  iframe {
    min-height: 400px;

    @include breakpoint-s-only {
      width: 100% !important;
    }
  }

  //
  // Search Form component
  //
  &__search-form {
    @extend %tveProviderColumnWidth;
    position: relative;

    &__input-text {
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      appearance: none;

      &__search {
        width: 100%;
        background: transparent;
        font-family: $founders-cond;
        font-size: rem($text-24);
        line-height: 1;
        padding: 0 0 rem(12px);
        margin-bottom: rem(20px);
        border-bottom: 1px solid $white;
        border-radius: 0;
        color: $white;
      }
    }

    &__search-icon {
      position: absolute;
      top: rem(8px);
      right: 0;
      font-size: rem($text-20);
    }

    &__button {
      color: $white;

      &:hover {
        opacity: $default-hover-opacity;
      }

      &:active {
        opacity: $default-active-opacity;
      }
    }
  }

  //
  // Search Results component
  //
  &__search-results {
    @extend %tveProviderColumnWidth;
    max-height: rem(336px);
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    @include breakpoint-l {
      min-height: rem(336px);
    }

    &--fill {
      max-height: unset;
    }

    &__button {
      font-family: $founders-cond;
      font-size: rem($text-24);
      line-height: 1;
      display: block;
      color: $black;
      background-color: $white;
      padding: rem(20px);
      width: 100%;
      text-align: left;
      border-bottom: 1px solid $grey-40;

      &:hover {
        background-color: $grey-20;
      }

      &:active {
        background-color: $white;
      }
    }
  }

  //
  // Map component
  //
  &__map {
    max-width: rem(375px);
    margin: 0 auto rem(24px);
    font-family: $founders-cond;
    font-size: rem($text-16);
    background-color: rgba($black, 0.7);

    @include breakpoint-m {
      width: rem(375px);
    }

    &__item {
      display: inline-block;
      border-bottom: 3px solid $grey-50;
      padding: rem(10px 6px);
      width: 33.3%;

      &--active {
        border-bottom-color: $tve-provider-select-action-color;
      }
    }
  }

  //
  // Featured grid component
  //
  &__featured-grid {
    margin: 0 auto;
    display: inline-block;

    @include breakpoint-l {
      display: block;
    }

    &__provider {
      float: left;
      margin: 0 rem(3px 3px) 0;
      background-color: rgba(24, 24, 24, 1);

      @include breakpoint-s-only {
        &:nth-of-type(3n+1) {
          clear: left;
        }

        &:nth-of-type(3n+3) {
          margin: 0;
        }
      }

      @media only screen and (max-width: 375px) {
        width: calc(33% - ((3px * 2) / 3));
        height: rem(70px);
      }

      @media only screen and (min-width: 376px) and (max-width: $grid-m-width - 1) {
        width: rem(110px);
        height: rem(70px);
      }

      @include breakpoint-m {
        width: rem(121px);
        height: rem(69px);

        &:nth-of-type(5n+1) {
          clear: left;
        }

        &:nth-of-type(5n+5) {
          margin: 0;
        }
      }

      @include breakpoint-l {
        width: rem(130px);
        height: rem(74px);
      }

      @include breakpoint-x {
        width: rem(138px);
        height: rem(79px);
      }

      &__hide-mobile {
        display: none;

        @include breakpoint-m {
          display: block;
        }
      }
    }

    &__button {
      &__provider {
        width: 100%;
        height: 100%;
        padding: rem(10px);
        background-color: $black;

        @include breakpoint-m {
          padding: rem(12px);
        }

        @include breakpoint-x {
          padding: rem(14px);
        }

        &:hover {
          background-color: $grey-60;
        }

        &:active {
          background-color: $grey-50;
        }

        > img {
          max-width: 100%;
          height: auto;
          pointer-events: none;
        }
      }
    }
  }

  //
  // All Options Select component
  //
  &__select {
    @extend %tveProviderColumnWidth;
    position: relative;

    select {
      width: 100%;
      appearance: none;
      box-shadow: none !important;
      border-radius: 0;
      border: 1px solid $grey-50;
      padding: rem(8px);
      background: $white;
      font-family: $founders-mono;
      letter-spacing: -0.04em;
    }

    &__down {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $black;
      z-index: 1;
      pointer-events: none;
    }
  }

  //
  // Back Button component
  //
  &__back-button {
    @extend %tveProviderColumnWidth;
    padding: 0 0 rem(12px) 0;
    margin-bottom: rem(20px);
    border-bottom: 1px solid $grey-60;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: rem(50px);

    @include breakpoint-m {
      padding-right: rem(80px); // width of button
      margin-bottom: rem(40px);
    }

    &__button {
      font-family: $founders-cond;
      font-size: rem($text-16);
      text-transform: uppercase;
      background-color: $tve-provider-select-action-color;
      color: $black;
      padding: rem(10px 8px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      :first-child {
        margin: rem(2px) rem(4px) 0 0;
        font-size: rem(14px);
      }

      &:hover {
        background-color: $tve-provider-select-action-hover;
      }

      &:active {
        background-color: $tve-provider-select-action-active;
      }
    }

    &__text {
      flex: 1;
      padding: 0;
      margin: 0;
    }
  }

  //
  // Loader component
  //
  &__loader-icon {
    font-family: $founders-mono;
    font-size: rem(16px);
    letter-spacing: -0.04em;
    line-height: 1;
    position: relative;
    margin: rem(96px) 0;

    .icon {
      font-size: rem($text-20);
      display: inline-block;
      margin-right: rem(16px);
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: .5s;

      &:first-of-type {
        animation-delay: 0s;
      }

      &:last-of-type {
        animation-delay: 1s;
        margin-right: 0;
      }
    }
  }
}

@keyframes dotFlashing {
  0% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: .3;
  }
}
