.Playmaker {
  position: relative;

  .straightUp .live & {
    .title {
      color: $playmaker-flag-live-color;
    }
  }

  &__flag {
    align-items: center;
    color: $playmaker-flag-default-color;
    font-family: $publico-font-family;
    font-size: $text-20;
    font-style: normal;
    font-weight: normal;
    position: relative;
    z-index: 2;

    &--live {
      color: $playmaker-flag-live-color;
    }

    @include breakpoint-m {
      font-size: $text-28;
    }

    @include breakpoint-x {
      font-size: $text-32;
    }
  }

  &__flag-dot {
    height: $space-8;
    width: $space-8;
    background-color: $playmaker-flag-live-color;
    border-radius: 50%;
    display: inline-flex;
    margin-right: $space-4;
    margin-bottom: $space-4;
    animation: live-dot-blink 1.5s ease-in-out infinite;
    vertical-align: middle;
  }
}

.videoOuter {
  position: relative;

  @include breakpoint-l {
    flex: 0 0 56.5%;
  }

  @include breakpoint-x {
    flex-basis: 65.5%;
  }

  :global(.layout-leftRail) &,
  :global(.layout-rightRail) & {
    @include breakpoint-l {
      flex-basis: 100%;
    }
  }

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  .videoInner {
    background-color: $black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
