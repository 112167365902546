.stripe-pattern {
  position: absolute;
  left: 0;
  right: rem(-8px);
  top: 0;
  bottom: rem(-8px);
  z-index: 0;
  opacity: .8;

  line {
    stroke: $stripe-pattern-color;
  }
}
