$think-red-40: #ff5042;
$mach-purple-50: #5c00d8;
$better-teal-30: #21d1aa;
$news-blue-40: #3061ff;
$news-blue-70: #102039;
$news-red-30: #fa3a3a;
$global-teal-40: #7eaaad;
$global-red-40: #e40021;
$today-orange-30: #ff503c;
$today-purple-70: #380538;
$knowyourvalue-orange-30: #f6a383;
$noticias-red-50: #c31010;
$noticias-blue-60: #23283c;

$msnbc-yellow-40: #ffbe03;

$curator-yellow: #ff0;

$breaking-red: $news-red-30;

$brandNames: (
  "better",
  "globalcitizen",
  "knowyourvalue",
  "leftfield",
  "mach",
  "msnbc",
  "news",
  "noticias",
  "telemundo",
  "telemundoentretenimiento",
  "telemundoshows",
  "think",
  "today"
);

//Default gradient values
$default-gradient-value-1: #002273;
$default-gradient-value-2: #0160fa;

// Navbar
$navbar-mobile-height: 60px;
$navbar-default-height: 80px;
