.tve-view {
  color: $white;
  background-color: $grey-70;
  width: 100%;
  margin-bottom: rem(40px);

  &::after {
    content: "";
    clear: both;
    display: table;
  }

  // Layout
  &__inner {
    position: relative;
    max-width: 1600px;
    margin: 0 auto;
  }

  &__player-outer {
    position: relative;

    @include breakpoint-l {
      width: 60%;
      float: left;
    }
  }

  &__info-outer {
    position: relative;

    @include breakpoint-l {
      width: 40%;
      float: right;
    }
  }

  &__schedule-outer {
    position: relative;
    padding: rem(20px 20px 30px 20px);

    @include breakpoint-m {
      padding: rem(20px 30px 24px 30px);
    }

    @include breakpoint-l {
      clear: both;
      padding: rem(24px 40px);
    }

    @include breakpoint-x {
      clear: none;
      float: right;
      width: 40%;
      padding: rem(0 40px 18px 30px);
    }
  }

  // Margin/Spacing
  &__info-inner {
    margin: rem(18px 20px);

    @include breakpoint-m {
      margin: rem(20px 30px);
    }

    @include breakpoint-l {
      margin: rem(26px 40px 18px 30px);
    }

    @include breakpoint-x {
      margin-right: rem(40px);
    }
  }

  // Maintain player proportions
  &__player-outer::before {
    content: '';
    display: block;
    position: relative;
    padding-top: 56.25%;
    background: $black;
  }

  // TEMP: move sign in iframe out of the way
  &__player-outer > #mvpddiv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
