// stylelint-disable no-descending-specificity
.pancake {
  margin-left: 20px;
  margin-right: 20px;

  .layout-index-1 & {
    position: relative;
    padding-top: 20px; // Matches border for pancake package items
    background-color: $pancake-background--layout-index-1;
  }

  @include breakpoint-m {
    margin-left: unset;
    margin-right: unset;
  }

  &.railAdjacent {
    @include package-spacing;
  }
}

// Grid customization for pancake
@include breakpoint-l-only {
  .grid-col-12.grid-col-7-5-l {
    width: calc((100% / 12) * 7.5);
  }

  .grid-col-12.grid-col-4-5-l {
    width: calc((100% / 12) * 4.5);
  }
}

.pancake__unibrow {
  color: $pancake-unibrow-color;

  &.pancake__unibrow--think {
    color: $think-red-40;
  }
}

.pancake__headline {
  color: $pancake-headline-color;
  font-family: $pancake-headline-font-family;
}

.pancake__title {
  @include responsive-variations(font-size, $pancake-title-font-size);
  font-family: $pancake-title-font-family;
  font-weight: $pancake-title-font-weight;
  font-style: $pancake-title-font-style;
  line-height: $leading-100;
  text-transform: $pancake-title-text-transform;
  color: $pancake-title-color;
  border-bottom: $pancake-title-border-bottom;
  position: relative;

  @include responsive-variations(margin, $pancake-title-margin);
  @include responsive-variations(padding, $pancake-title-padding);

  &::before {
    content: $pancake-title-before-content;
    display: block;
    border-top: 1px solid $default-color;
    width: 30px;
    position: absolute;

    @include responsive-variations(top, $pancake-title-before-content-top);
    left: -20px;

    @include breakpoint-m {
      width: 60px;
      left: -60px;
    }
  }
}

.pk-fn {
  display: block;
}

.pk-fy {
  display: flex;
  align-items: flex-start;
}

@include breakpoint-m {
  .pk-fn-m {
    display: block;
  }

  .pk-fy-m {
    display: flex;
    align-items: flex-start;
  }
}

@include breakpoint-l {
  .pk-fn-l {
    display: block;
  }

  .pk-fy-l {
    display: flex;
    align-items: flex-start;
  }
}

@include breakpoint-x {
  .pk-fn-xl {
    display: block;
  }

  .pk-fy-xl {
    display: flex;
    align-items: flex-start;
  }
}

.pancake__info {
  flex: 1 0;
}

.pancake__tease {
  position: relative;
}

.pancake__key-updates {
  order: 1;

  @include breakpoint-m {
    order: 2;
  }

  .pancake__title {
    @include responsive-variations(font-size, $pancake-key-update-title-font-size);
    font-family: $pancake-title-font-family;
    font-weight: $pancake-title-font-weight;
    font-style: $pancake-title-font-style;
    line-height: $leading-100;
    text-transform: $pancake-title-text-transform;
    color: $pancake-key-updates-title-color;
    padding-left: 0;
    margin-left: 0;

    &::before {
      content: none;
    }
  }

  .pancake__buttonWrapper {
    flex: 0 0 auto;
  }

  .pancake__list {
    color: $pancake-list-color;
    position: relative;
    z-index: 1;
    list-style: disc;
    margin-left: 1rem;
  }

  .pancake__list-item {
    margin-bottom: rem(9px);
    display: list-item;
    align-items: baseline;
    position: relative;
    z-index: 1;
  }
}

.pancake__content-column {
  order: 2;

  @include breakpoint-m {
    order: 1;
  }
}

.pancake__tease-picture-link {
  position: relative;
  display: block;
  line-height: 0;
  margin-bottom: 12px;

  .pk-fn & {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .pk-fy & {
    margin-bottom: 0;
    margin-right: 16px;
  }

  @include breakpoint-m {
    .pk-fn-m & {
      margin-bottom: 12px;
      margin-right: 0;
    }

    .pk-fy-m & {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  @include breakpoint-l {
    .pk-fn-l & {
      margin-bottom: 12px;
      margin-right: 0;
    }

    .pk-fy-l & {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  @include breakpoint-x {
    .pk-fn-xl & {
      margin-bottom: 12px;
      margin-right: 0;
    }

    .pk-fy-xl & {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}

.pancake__duration {
  color: $pancake-duration-color;
  background-color: $grey-20;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: rem(20px);

  .pancake__typeIcon {
    width: rem(20px);
    height: rem(20px);
    position: relative;
  }

  &.pancake__duration--is-live {
    color: $white;
    background-color: #af2929;

    .pancake__typeIcon {
      background-color: $news-red-30;
    }
  }
}

//// Image size overrides ////
.pancake-items-3 {
  @include breakpoint-m {
    .pancake-S .pancake__tease-picture-link {
      display: none;
    }
  }

  &.fullWidth {
    @include breakpoint-l {
      .pancake-item-1 .pancake__tease-picture-link,
      .pancake-item-2 .pancake__tease-picture-link {
        display: block;
      }
    }

    @include breakpoint-x {
      .pancake-S .pancake__tease-picture-link img {
        width: 100px;
      }
    }
  }

  &.railAdjacent {
    @include breakpoint-l {
      .pancake-item-1 .pancake__tease-picture-link,
      .pancake-item-2 .pancake__tease-picture-link {
        display: none;
      }
    }
  }
}

.pancake-items-4 {
  .pancake-item-3 .pancake__tease-picture-link {
    display: none;
  }

  @include breakpoint-m {
    .pancake-item-2 .pancake__tease-picture-link {
      display: none;
    }
  }

  &.fullWidth {
    @include breakpoint-l {
      .pancake-item-1 .pancake__tease-picture-link {
        display: none;
      }

      .pancake-item-2 .pancake__tease-picture-link {
        display: block;
      }
    }

    @include breakpoint-x {
      .pancake-item-1 .pancake__tease-picture-link {
        display: block;
      }

      .pancake-item-2 .pancake__tease-picture-link,
      .pancake-item-3 .pancake__tease-picture-link {
        display: block;

        .pancake__tease-picture img {
          width: 100px;
        }
      }
    }
  }

  &.railAdjacent {
    .pancake-item-1 .pancake__tease-picture-link {
      display: none;

      @include breakpoint-m {
        display: block;
      }

      @include breakpoint-l {
        display: none;
      }

      @include breakpoint-x {
        display: block;
      }
    }
  }
}

.pancake-items-6 {
  .pancake-item-2 .pancake__tease-picture-link {
    @include breakpoint-m {
      display: none;
    }
  }

  &.fullWidth {
    .pancake-item-0 .pancake__tease-picture img,
    .pancake-item-1 .pancake__tease-picture img {
      @include breakpoint-l {
        width: 200px;
      }

      @include breakpoint-x {
        width: 100%;
      }
    }
  }
}

.pancake-items-7 {
  .pancake-item-3 .pancake__tease-picture-link {
    @include breakpoint-m {
      display: none;
    }
  }

  &.fullWidth {
    .pancake-item-1 .pancake__tease-picture img,
    .pancake-item-2 .pancake__tease-picture img {
      @include breakpoint-l {
        width: 200px;
      }

      @include breakpoint-x {
        width: 100%;
      }
    }
  }
}

.pancake-items-8 {
  .pancake-item-2 .pancake__tease-picture-link,
  .pancake-item-3 .pancake__tease-picture-link,
  .pancake-item-4 .pancake__tease-picture-link {
    @include breakpoint-m {
      display: none;
    }
  }

  &.fullWidth {
    .pancake-item-0 .pancake__tease-picture img,
    .pancake-item-1 .pancake__tease-picture img {
      @include breakpoint-l {
        width: 200px;
      }

      @include breakpoint-x {
        width: 160px;
      }
    }
  }

  &.railAdjacent {
    .pancake-item-2 .pancake__tease-picture-link,
    .pancake-item-3 .pancake__tease-picture-link {
      @include breakpoint-l {
        display: block;
      }

      @include breakpoint-x {
        display: none;
      }
    }
  }
}

//// Tease borders ////
@mixin pancakeTeaseBorderOn {
  margin-top: rem(16px);
  padding-top: rem(16px);
  border-top: solid 1px $pancake-tease-border-color;
}

@mixin pancakeTeaseBorderOff {
  margin: 0;
  padding: 0;
  border: none;
}

@mixin nthBorderOn($nth) {
  &:nth-child(#{$nth}) .pancake__tease {
    @include pancakeTeaseBorderOn;
  }
}

@mixin nthBorderOff($nth) {
  &:nth-child(#{$nth}) .pancake__tease {
    @include pancakeTeaseBorderOff;
  }
}

.pancake-S {
  .pancake__tease {
    @include pancakeTeaseBorderOn;
  }

  .pancake-items-2.fullWidth & {
    @include breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .pancake-items-2.railAdjacent & {
    @include breakpoint-m {
      @include nthBorderOff(n);
    }

    @include breakpoint-l {
      @include nthBorderOn(n);
    }

    @include breakpoint-x {
      @include nthBorderOff(n);
    }
  }

  .pancake-items-3.fullWidth & {
    @include breakpoint-l {
      @include nthBorderOff(n);
    }

    @include breakpoint-x {
      @include nthBorderOn(2);
    }
  }

  .pancake-items-4.fullWidth & {
    @include breakpoint-x {
      @include nthBorderOff(1);
    }
  }

  .pancake-items-5.fullWidth & {
    @include breakpoint-x {
      @include nthBorderOff(1);
    }
  }

  .pancake-items-6.fullWidth &,
  .pancake-items-7.fullWidth & {
    @include breakpoint-x {
      @include nthBorderOff('-n+2');
    }
  }

  .pancake-items-8 & {
    @include breakpoint-m {
      @include nthBorderOff(1);
    }
  }

  .pancake-items-8.fullWidth & {
    @include breakpoint-l {
      @include nthBorderOff('-n+2');
    }

    @include breakpoint-x {
      @include nthBorderOff('-n+3');
    }
  }

  .pancake-items-8.railAdjacent & {
    @include breakpoint-l {
      @include nthBorderOn(n);
    }

    @include breakpoint-x {
      @include nthBorderOff('-n+2');
    }
  }
}

.pancake-M {
  .pancake__tease {
    @include pancakeTeaseBorderOn;
  }

  .pancake-items-2 &,
  .pancake-items-4 & {
    &:nth-child(1) .pancake__tease {
      @include pancakeTeaseBorderOff;
    }

    @include breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .pancake-items-3 & {
    &:nth-child(1) .pancake__tease {
      @include pancakeTeaseBorderOff;
    }

    @include breakpoint-m {
      @include nthBorderOff(n);
    }

    @include breakpoint-l {
      @include nthBorderOn(2);
    }
  }

  .pancake-items-4.railAdjacent & {
    @include breakpoint-l {
      @include nthBorderOn(2);
    }

    @include breakpoint-x {
      @include nthBorderOff(2);
    }
  }

  .pancake-items-5.fullWidth & {
    @include breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .pancake-items-5.railAdjacent & {
    @include breakpoint-m {
      @include nthBorderOff(n);
    }

    @include breakpoint-l {
      @include nthBorderOn(2);
    }

    @include breakpoint-x {
      @include nthBorderOff(n);
    }
  }

  .pancake-items-6 & {
    @include breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .pancake-items-7 & {
    @include breakpoint-m {
      @include nthBorderOff(1);
    }
  }

  .pancake-items-7.fullWidth & {
    @include breakpoint-x {
      @include nthBorderOff(n);
    }
  }

  .pancake-items-8 & {
    &:nth-child(1) .pancake__tease {
      @include pancakeTeaseBorderOff;
    }

    @include breakpoint-m {
      @include nthBorderOn(2);
    }
  }

  .pancake-items-8.fullWidth & {
    @include breakpoint-l {
      @include nthBorderOff(1);
    }

    @include breakpoint-x {
      @include nthBorderOff(2);
    }
  }

  .pancake-items-8.railAdjacent & {
    @include breakpoint-l {
      @include nthBorderOff(2);
    }

    @include breakpoint-x {
      @include nthBorderOn(2);
    }
  }
}
