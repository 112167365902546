.three-featured-plus-list {
  background-color: $three-featured-plus-list-background-color;
  overflow: hidden;
  position: relative;
}

.three-featured-plus-list__articles {
  @include clearfix;
  position: relative;
  box-sizing: content-box; // use content-box so width doesn't include left border of grid

  @include breakpoint-l {
    width: calc((7 * #{$grid-col-width-desktop}) + (7 * #{$gutter-desktop}));
    border-left-width: calc(#{rem($gutter-desktop)} / 2);
    border-left-style: solid;
    border-left-color: transparent;
  }

  &::before {
    @include pattern-dots($three-featured-plus-list-articles-pattern-dots-color);
    content: '';
    display: block;
    width: rem(80px);
    height: rem(40px);
    margin-left: rem(20px);

    @include breakpoint-m {
      margin-left: 4vw;
    }

    @include breakpoint-l {
      margin-left: rem(40px);
      height: rem(80px);
    }
  }

  picture {
    display: block;
  }
}

.three-featured-plus-list__secondary-articles {
  position: relative;
  clear: both;
  background: $white;

  @include breakpoint-m {
    display: flex;
    justify-content: space-between;
  }

  @include breakpoint-l {
    width: 100%;
    margin: 0;
    padding-top: rem(2px);
  }

  &::before {
    @include breakpoint-m {
      content: '';
      display: block;
      position: absolute;
      background-color: $white;
      width: rem(40px);
      height: 100%;
      left: rem(-40px);
    }

    @include breakpoint-l {
      content: '';
      width: calc((100vw - 920px) / 2);
      height: calc(100% + 244px);
      position: absolute;
      left: calc(((100vw - 920px) / 2) * -1);
      bottom: 0;
      background: $white;
    }

    @include breakpoint-x {
      height: calc(100% + 316px);
    }
  }
}

.three-featured-plus-list__secondary-article {
  position: relative;

  &::after {
    content: ' ';
    background: $white;
    position: absolute;
    bottom: 0;
    right: rem(-48px);
    width: rem(48px);
    height: rem(48px);
  }

  @include breakpoint-m {
    float: left;
    display: flex;

    &::after {
      content: none;
    }
  }

  @include breakpoint-l {
    margin-left: 0;
  }
}

@include breakpoint-m {
  .three-featured-plus-list__secondary-article + .three-featured-plus-list__secondary-article {
    margin-left: rem(2px);
  }
}

.three-featured-plus-list__sidebar {
  background: $three-featured-plus-list-sidebar-background;
  position: relative;
  padding-top: rem(40px);
  box-sizing: content-box;
  width: calc(100% - (2 * #{$outer-gutter}));
  margin-left: $outer-gutter;

  @include breakpoint-m {
    width: 100%;
    margin-left: 0;
  }

  @include breakpoint-l {
    width: calc((5 * #{$grid-col-width-desktop}) + (4 * #{$gutter-desktop}));
    margin: rem(40px 0 0);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: rem(40px);
    height: 100%;
    top: 0;
    left: rem(-40px);
    background: $three-featured-plus-list-sidebar-after-background;
  }

  &::before {
    @include breakpoint-m {
      background-color: $white;
    }

    @include breakpoint-l {
      @include pattern-dots($three-featured-plus-list-sidebar-before-pattern-dots);
      content: '';
      width: rem(40px);
      height: rem(80px);
      position: absolute;
      top: rem(-2px);
      right: rem(-41px);
      left: auto;
    }
  }

  &::after {
    right: rem(-40px);
    left: auto;

    @include breakpoint-m {
      content: none;
    }

    @include breakpoint-l {
      background-color: $white;
      content: '';
      width: calc(((100vw - 920px) / 2) + 100%);
      height: 100%;
      position: absolute;
      top: auto;
      left: 0;
      bottom: -100%;
    }
  }
}

.three-featured-plus-list__sidebar-content {
  @include clearfix;
  padding-bottom: rem(20px);

  @include breakpoint-m {
    border-color: $three-featured-plus-list-sidebar-content-border-color;
    border-width: 1px 1px 0 0;
    border-style: solid;
    margin: rem(0 40px);
    padding-right: rem(40px);
  }

  @include breakpoint-l {
    padding-bottom: rem(50px);
  }
}

.three-featured-plus-list__sidebar-title {
  font-family: $default-headline-font-family;
  font-size: rem(30px);
  line-height: rem(30px);
  border-color: $three-featured-plus-list-sidebar-title-border-color;
  border-width: 1px 0 0;
  border-style: solid;
  margin-top: 0;

  @include breakpoint-m {
    font-size: rem(40px);
    line-height: rem(40px);
    width: 46.5%;
    margin: 0 7% 0 0;
    border: 0;
  }

  @include breakpoint-l {
    width: auto;
    margin: 0;
  }

  &::before {
    background-color: $three-featured-plus-list-sidebar-title-before-background-color;
    content: '';
    display: block;
    width: rem(100px);
    height: rem(7px);
    margin-bottom: rem(20px);
  }
}

.three-featured-plus-list__sidebar-vertical-name {
  color: $three-featured-plus-list-sidebar-vertical-name-color;
  margin-right: rem(8px);
}

.three-featured-plus-list__sidebar-info {
  @include breakpoint-m {
    float: left;
    width: 46.5%;
    margin-top: rem(17px);
  }

  @include breakpoint-l {
    float: none;
    width: auto;
    margin: rem(45px 0 0);
  }
}

.three-featured-plus-list__sidebar-subtitle {
  font-family: $default-headline-font-family;
  font-size: rem(20px);
  line-height: rem(20px);

  @include breakpoint-m {
    width: auto;
    margin-left: 0;
    float: none;
  }
}

.three-featured-plus-list__sidebar-dek {
  font-family: $publico-font-family;
  font-size: rem(14px);
  line-height: rem(20px);
  color: $three-featured-plus-list-sidebar-dek-color;

  @include breakpoint-m {
    width: auto;
    margin-left: 0;
    float: none;
  }
}

.three-featured-plus-list__sidebar-items {
  margin-top: rem(20px);

  @include breakpoint-m {
    border-top: 1px dashed $grey-60;
  }

  @include breakpoint-l {
    width: 100%;
    margin-left: 0;
    border: 0;
  }
}

.three-featured-plus-list__sidebar-item {
  @include clearfix;
  padding: rem(20px 0);
  border-top: 1px dashed $grey-60;

  @include breakpoint-m {
    float: left;
    width: 46.5%;
  }

  @include breakpoint-l {
    float: none;
    width: 100%;
    margin: 0;
  }

  &:nth-child(1),
  &:nth-child(2) {
    @include breakpoint-m {
      border-width: 0;
    }

    @include breakpoint-l {
      border-width: 1px;
    }
  }

  &:nth-child(2n+1) {
    @include breakpoint-m {
      margin-left: 7%;
    }

    @include breakpoint-l {
      margin-left: 0;
    }
  }

  &.three-featured-plus-list__sidebar-item-sponsored {
    background-color: $three-featured-plus-list-sidebar-item-sponsored-background-color;
    border-color: $three-featured-plus-list-sidebar-item-sponsored-border-color;
    border-style: solid;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;

    & + li {
      border-width: 0;
    }

    @include breakpoint-m {
      width: calc(46.5% + 40px);
      margin-right: calc(7% - 20px);

      & + li {
        border-width: 1px;
      }

      & + li + li {
        border-width: 0;
      }
    }

    @include breakpoint-l {
      width: calc(100% + 40px);

      & + li {
        border-width: 0;
      }

      & + li + li {
        border-width: 1px;
      }
    }
  }
}

.tease-card__three-featured-plus-list {
  .tease-card__content {
    @include package-spacing;

    @include breakpoint-l {
      margin-bottom: 0;
    }
  }

  .tease-card__title {
    @include responsive-variations(font-size, $tease-card-three-featured-plus-list-title-font-sizes);
    @include responsive-variations(line-height, $tease-card-three-featured-plus-list-title-line-heights);
  }

  .tease-card__featured-author {
    @include breakpoint-m {
      font-size: 26px;
      line-height: 30px;
    }

    @include breakpoint-l {
      font-size: 14px;
      line-height: 20px;
    }

    @include breakpoint-x {
      font-size: 26px;
      line-height: 30px;
    }
  }
}
