@include style-only-for(("news","msnbc", "today")) {
  // Specificity to override header styles
  .hfsh .mini-player {
    position: relative;
    width: 100%;
    height: 80px;
    overflow: hidden;
    line-height: 0;

    @include breakpoint-m {
      width: unset;
      max-width: $mini-player-max-width-medium-bp;
    }

    &__button {
      width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent;
      color: inherit;
      display: flex;
      flex-flow: row nowrap;
      text-align: unset;
    }

    &__tease {
      position: relative;
      flex-shrink: 0;
      width: rem(142px);
      height: rem(80px);
      display: block;
    }

    &__content {
      width: rem(200px);
      margin: rem(15px 15px 0 15px);

      @include breakpoint-m {
        margin-right: 0;
      }

      @include breakpoint-l {
        width: rem(280px);
      }
    }

    &__button:hover .mini-player__content {
      opacity: $opacity-70;
    }

    &__button:active .mini-player__content {
      opacity: $opacity-50;
    }

    &__logo {
      margin-bottom: rem($mini-player-logo-margin-bottom);
    }

    &__headline {
      @include line-clamp(2, $mini-player-default-font-size, #000);
      overflow: hidden;
      font-size: $mini-player-default-font-size;
      color: $mini-player-default-font-color;
      line-height: 1;
    }

    &__live-flag::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fa3a3a;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 2px;
      animation: live-dot-blink 1.5s ease-in-out infinite;
    }

    &__typeicon {
      color: $mini-player-typeicon-color;
      background: $mini-player-action-color;

      &::before {
        line-height: 1;
        font-size: rem(8px);
      }
    }

    &__sign-in {
      position: absolute;
      top: rem(10px);
      right: rem(15px);
      width: rem(62px);
      height: rem(16px);

      @include breakpoint-m {
        right: 0;
      }
    }

    &__sign-in-button {
      height: 100%;
      width: 100%;
      font-size: 0;
      background: transparent;
      color: $mini-player-action-color;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;

      &:focus {
        outline: 0;
      }

      &:hover {
        opacity: $opacity-70;
      }

      &:active {
        opacity: $opacity-50;
      }

      & .icon-lock {
        font-size: $mini-player-default-font-size;
      }
    }

    &__sign-in-button-text {
      text-transform: uppercase;
      margin: rem(0 0 -2px 3px);
      font-size: $mini-player-default-font-size;
    }

    // required to prevent tease image from jumping on page load
    img {
      display: block;
    }
  }
}
