.straight-up {
  @include clearfix;
  background-color: $white;
  margin-bottom: rem(48px);
}

.straight-up__inner {
  position: relative;
  margin-top: $straight-up-inner-margin-top;

  &::before {
    display: $straight-up-inner-display;
    content: '';
    width: 100%;
    height: 5px;
    background: $straight-up-inner-before-background-color;
    position: absolute;
    top: rem(-5px);
    left: 0;
    z-index: 2;
  }
}

.straight-up__live {
  &::before {
    background: $straight-up-live-background-color;
  }

  @include style-only-for("globalcitizen") {
    background: $default-color;
    margin-bottom: rem(60px);

    @include breakpoint-m {
      height: rem(440px);
      padding-top: rem(80px);
      margin-bottom: rem(60px);
    }

    @include breakpoint-l {
      height: rem(343px);
      margin-bottom: rem(180px);
      padding-top: rem(110px);
    }

    @include breakpoint-x {
      height: rem(442px);
      padding-top: rem(88px);
    }
  }
}

.straight-up__video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100%);
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @include breakpoint-l {
    width: rem(520px);
  }

  @include breakpoint-x {
    width: rem(760px);
  }

  &.straight-up__video-container--is-stuck {
    z-index: get-z-index('sticky-video-parent');
  }
}

.straight-up__video-resize {
  // Need to reset width for video to convert between
  // 2:1 ratio of tease to 16:9 of video
  $tease-height: 1 / 2;
  $video-ratio: ($tease-height * 16) / 9;
  $width-percentage: $video-ratio * 100%;
  width: $width-percentage; // 88.88889%
}

.tease-card__straight-up {
  .tease-card__content {
    @include breakpoint-l {
      display: flex;

      .layout-leftRail &,
      .layout-rightRail & {
        flex-wrap: wrap;
      }
    }
  }

  .tease-card__picture {
    @include breakpoint-l {
      .unibrow {
        width: rem(180px);
      }

      span.icon {
        width: rem(180px);
        height: rem(100px);
        top: rem(-68px);
        left: 0;
        font-size: rem(32px);
        transition: transform .2s;
        transform: scaleY(.32) scaleX(.1777);
        transform-origin: bottom right;
      }

      &:hover {
        span.icon {
          transform: none;
        }
      }
    }
  }

  .tease-card__picture-link {
    @include breakpoint-l {
      display: block;
      position: relative;
      width: rem(520px);
      overflow: hidden;
    }

    @include breakpoint-x {
      width: rem(760px);
    }

    video {
      position: absolute;
    }
  }

  .tease-card__live-text {
    color: $black;

    @include style-only-for('globalcitizen') {
      color: $white;
    }
  }

  .tease-card__info {
    padding-top: rem(30px) !important;

    @include breakpoint-m {
      display: flex;
    }

    @include breakpoint-l {
      display: block;
      padding: rem(40px) rem(40px) 0 !important;
    }

    @include style-only-for('globalcitizen') {
      background: $tease-card-outer-gutter;

      @include breakpoint-m {
        &.info {
          background-color: $white;
        }
      }

      @include breakpoint-l {
        background-color: $default-color;
        padding-top: rem(5px);
        height: rem(233px);
        width: rem(400px);
        position: absolute;
        top: 0;
        right: 0;
      }

      @include breakpoint-x {
        height: rem(354px);
      }
    }
  }

  .tease-card__title {
    @include breakpoint-m {
      margin-right: rem(40px);
    }

    @include breakpoint-l {
      margin-right: 0;
    }
  }

  .tease-card__dek {
    font-size: rem(14px);
    line-height: 1.5;
    margin-top: rem(10px);

    @include breakpoint-m {
      margin-right: rem(40px);
    }

    @include breakpoint-l {
      margin-right: 0;
      padding: 0;
    }
  }

  @include style-only-for('globalcitizen') {
    .tease-card__title,
    .tease-card__dek {
      color: $white;

      @include breakpoint-m {
        color: $black;
      }

      @include breakpoint-l {
        color: $white;
      }
    }
  }
}
