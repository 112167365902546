.ontheshow__header-heading {
  border-top: 1px solid $ontheshow-header-border-color;
}

.ontheshow__header-heading-bar {
  width: 100px;
  height: 7px;
  background-color: $ontheshow-header-border-color;
}

.ontheshow__header-heading-text {
  color: $ontheshow-header-heading-color;
  font-size: $text-40;
  font-family: $publico-hed;
  font-weight: normal;
  line-height: 40px;
  margin: 0;
  padding: 30px 0 0;
}

.ontheshow__header-heading-link,
.ontheshow__header-heading-link:hover > * {
  transition: opacity 0.25s ease-in-out;
}

.ontheshow__header-heading-link:hover,
.ontheshow__header-heading-link:hover > * {
  opacity: .7;
}
