.striped-eyebrow {
  display: inline-block;
  padding: rem(13px 20px);
  color: $white;
  background: $striped-eyebrow-background-color;
  position: relative;
  font-family: $striped-eyebrow-font-family;
  font-size: $striped-eyebrow-font-size;
  line-height: $striped-eyebrow-line-height;
  font-weight: normal;
}

.striped-eyebrow__text {
  position: relative;
  z-index: 1;
}
