// Button
$live-blog-package-button-color: $default-color !default;
$live-blog-package-button-border-color: $default-color !default;

// Package Top Decoration
$live-blog-package-top-decoration-border-color: $grey-40 !default;
$live-blog-package-top-decoration-tab-color: $default-color !default;
$live-blog-package-top-decoration-tab-width: $space-80 !default;
$live-blog-package-top-decoration-tab-height: $space-8 !default;
$live-blog-package-top-decoration-tab-top: 0 !default;

// Card list wrapper
$live-blog-package-body-decoration-color: $default-color !default;
$live-blog-package-body-decoration-offset: $space-80 !default;
$live-blog-package-body-decoration-offset-m: $space-100 !default;

@include override-tokens-for('today') {
  // Package Top Decoration
  $live-blog-package-top-decoration-border-color: transparent !global;
  $live-blog-package-top-decoration-tab-width: $space-32 !global;
  $live-blog-package-top-decoration-tab-height: $space-1 !global;
  $live-blog-package-top-decoration-tab-top: -$space-1 !global; // cover transparent border which prevents margin collapse
}

@include override-tokens-for("globalcitizen") {
  // Button
  $live-blog-package-button-color: $red-40 !global;
  $live-blog-package-button-border-color: $red-40 !global;
}

@include override-tokens-for("leftfield") {
  // Button
  $live-blog-package-button-color: $grey-50 !global;
  $live-blog-package-button-border-color: $grey-50 !global;
}
