.today-cover-tabbed-layout .today-cover-tabbed__grid {
  display: none;

  @include breakpoint-l {
    display: flex;

    /*
      Required for "rail" child to resize to adjacent "child" column
    */
    height: 100%;
    position: relative;
  }

  @include breakpoint-x {
    margin-right: 0;
  }

  @media only screen and (min-width: 1280px) {
    margin-right: auto;
  }
}
