.related-item {
  position: relative;
  display: flex;
  border-top: 1px solid $grey-40;

  button {
    border: none;
    background: none;
    -webkit-appearance: none;
    padding-left: 0;
    padding-right: 0;
  }

  a:nth-child(2) {
    line-height: 18px;
  }

  // don't want article body link treatment to be honored for thumbnails and titles
  div a:active,
  div a:hover {
    opacity: initial;
  }

  a:nth-child(2):hover {
    opacity: .7;
  }

  &.related-item__single {
    width: 100%;
    border-top: none;
    background-image: none;
    padding-top: 14px;

    @include breakpoint-m {
      padding-top: 0;
    }
  }

  @include style-only-for('today') {
    border-top: none;
    background-image: linear-gradient(to right, $grey-60 10%, $neutral-10 20%);
    background-size: 4px 1px;
    background-repeat: repeat-x;
    background-position: top;
  }

  &:not(.related-item__single) {
    padding: rem(20px) 0;
    flex-basis: 100%;

    &:first-of-type {
      margin-top: rem(12px);
    }

    @include ie {
      &.related-item__is-trending {
        flex: 0 0 auto;
        min-height: 205px;
        border-top: 0.1px dotted $grey-60;
      }

      &.related-item__is-nbc-news-commerce {
        min-height: auto;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }

    @include breakpoint-m {
      flex: 0 0 calc(50% - 16px);

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .related-item__thumb-is-amp {
    height: 60px;
    width: 60px;

    @include breakpoint-m {
      height: unset;
      width: unset;
    }
  }

  &.related-item__is-trending {
    @include style-only-for('today') {
      &:first-of-type {
        background-image: none;

        @include breakpoint-m {
          background-image: linear-gradient(to right, $grey-60 10%, $neutral-10 20%);
        }
      }
      // IE11 fix for spacing
      &:not(:first-of-type) {
        @include ie {
          margin-top: rem(-90px);
        }
      }
    }
  }
}

.related-item__link {
  display: block;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
}

.related-item__picture-link {
  font-size: 0;
}

.related-item__thumbnail-wrapper {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: rem(8px);

  &.related-item__gift-guide {
    margin-left: rem(8px);
  }

  @include breakpoint-m {
    flex-basis: rem(80px);

    .related-item__single & {
      margin-right: 0;
      flex-basis: rem(280px);
    }
  }

  @include breakpoint-x {
    .related-item__single & {
      flex-basis: rem(320px);
    }
  }
}

.related-item__thumbnail__image {
  display: block;
  overflow: auto;

  @include style-only-for('today') {
    border-radius: 50%;
  }

  .related-item__single & {
    @include breakpoint-m {
      border-radius: 0;
    }
  }
}

.related-item__thumbnail__image--raw {
  width: 80px;
  height: 80px;

  .related-item__single & {
    @include breakpoint-m {
      width: unset;
      height: auto;
      max-width: 280px;
    }

    @include breakpoint-x {
      max-width: 320px;
    }
  }
}

.related-item__info-wrapper {
  .related-item__single & {
    width: 100%;

    @include breakpoint-m {
      padding: rem(20px);
    }
  }

  &.related-item__gift-guide {
    padding-top: 20px;
  }
}

.related-item__eyebrow {
  font-family: $founders-mono;
  font-weight: normal;
  font-size: rem(12px);
  line-height: rem(12px);
  color: $related-item-eyebrow-color;
  display: block;
  position: relative;
  text-transform: uppercase;
  z-index: 2;

  .related-item__info-wrapper & {
    padding: rem(0 0 6px);
  }

  .related-item__single .related-item__info-wrapper & {
    @include breakpoint-m {
      display: none;
    }
  }

  .related-item__single .related-item__thumbnail-wrapper & {
    display: block;

    @include breakpoint-m {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.related-item__label {
  display: none;
}

.related-item__single .related-item__label {
  display: none;

  @include breakpoint-m {
    background-color: $related-item-single-label-bg-color;
    display: block;
    flex: 0 0 rem(132px);
    padding: rem(16px 16px 0);
    height: rem(32px);
    white-space: nowrap;
  }
}

.related-item__icon {
  bottom: 0;
  left: 0;

  .related-item__single & {
    @include breakpoint-m {
      position: relative;
      top: unset;
      left: unset;
      flex: 0 0 rem(32px);
    }
  }
}

.related-item__title {
  color: $related-item-title-color;
  font-family: $related-item-title-font;
  font-weight: normal;

  @include responsive-variations(font-size, $related-item-title-font-size);
  @include responsive-variations(line-height, $related-item-title-font-line-height);
  margin: 0;

  .related-item__single & {
    @include responsive-variations(font-size, $related-item-title-single-font-size);
    @include responsive-variations(line-height, $related-item-title-single-font-line-height);
  }
}

@include style-only-for('today') {
  .related-item.related-item__gift-guide {
    align-items: center;
    flex-direction: column;
    flex-basis: 50%;
    padding: 0 18px 18px;
    background-image: none;
    max-width: 250px;

    .related-item__link,
    .related-item__eyebrow {
      text-align: center;
    }

    @include breakpoint-m {
      background-image: linear-gradient(to top, $grey-60 10%, $neutral-10 20%);
      background-size: 1px 4px;
      background-repeat: repeat-y;
      background-position: left;
      flex-basis: 25%;
      max-width: unset;
    }

    @include breakpoint-l {
      padding: 0 30px 30px;
      max-width: unset;
    }

    @include breakpoint-x {
      padding: 0 48px 48px;
    }

    //IE11 border and width
    @include ie {
      @include breakpoint-m {
        border-left: 0.1px dotted $grey-60;
      }

      .related-item__info-wrapper {
        width: 100%;
      }
    }

    &:first-of-type {
      background-image: none;
      border-left: none;
      margin-top: 0;
    }

    .related-item__thumbnail-wrapper {
      padding-bottom: rem(10px);
      margin: 0;
      flex-basis: auto;

      @include breakpoint-l {
        padding-bottom: rem(20px);
      }
    }
  }
}
