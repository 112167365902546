.ontheshow__video-list-items {
  display: block;

  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint-l {
    display: block;
  }
}
