.today-cover-tabbed-layout .today-cover-tabbed__rail {
  /*
    This technique will size rail to be equal height of
     adjacent column
  */
  position: absolute;

  /*
    Subtracting 48px to create effect of standard package
    margin-bottom
  */
  height: calc(100% - 48px);
  right: 0;
}

.today-cover-tabbed-layout .today-cover-tabbed__rail::before {
  @include breakpoint-l {
    position: absolute;
    width: 100vw;
    background: $today-cover-tabbed-rail-background-color;
    height: 100%;
    content: '';
  }
}

.today-cover-tabbed__rail-icon {
  background-color: transparent;
  background-image: radial-gradient(#f1f0ed 1px, transparent 0);
  background-size: 5px 5px;
  background-position: 1px 1px;
  content: '';
  width: 2.5rem;
  height: 5rem;
  position: absolute;
  top: -.125rem;
  right: -2.5625rem;
  left: auto;
}
