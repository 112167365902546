$tease-list-see-all-button-color: $default-color !default;
$tease-list-see-all-button-border-color: $default-color !default;

$tease-list-top-decoration-background-color: $default-color !default;
$tease-list-top-decoration-width: 80px !default;
$tease-list-top-decoration-height: 8px !default;

$tease-list-brand-dot-color: $default-color !default;
$tease-list-eyebrow-color: $default-color !default;
$tease-list-paid-color: $white !default;
$tease-list-paid-background-color: $grey-70 !default;

$tease-list-title-color: $grey-70 !default;
$tease-list-title-font-family: $default-headline-font-family !default;
$tease-list-title-font-size: (
  s: rem(18px),
  m: rem(32px),
  l: rem(18px),
) !default;
$tease-list-title-line-heights: (
  s: rem(20px),
  m: rem(32px),
  l: rem(20px),
) !default;

$tease-list-list-title-font-family: $founders-semibold-font-family !default;
$tease-list-list-title-font-style: normal !default;
$tease-list-list-title-font-size: rem(24px) !default;
$tease-list-list-title-line-height: $leading-100 !default;
$tease-list-list-title-margin: rem(22px 0 20px) !default;
$tease-list-list-border-top: 1px dashed $grey-50 !default;

$tease-list-article-padding: rem(20px 0 20px) !default;

@include override-tokens-for("telemundoentretenimiento") {
  $tease-list-list-border-top: none !global;
  $tease-list-top-decoration-width: 30px !global;
  $tease-list-top-decoration-height: 2px !global;
  $tease-list-list-title-margin: rem(12px 0 20px) !global;
  $tease-list-title-font-size: (
    s: rem(18px),
  ) !global;
  $tease-list-title-line-heights: (
    s: rem(18px),
  ) !global;
  $tease-list-article-padding: rem(30px 0 30px) !global;
  $tease-list-list-title-font-size: rem(28px) !global;
}

@include override-tokens-for("telemundo") {
  $tease-list-list-border-top: none !global;
  $tease-list-top-decoration-width: 30px !global;
  $tease-list-top-decoration-height: 2px !global;
  $tease-list-list-title-margin: rem(12px 0 20px) !global;
  $tease-list-title-font-size: (
    s: rem(18px),
  ) !global;
  $tease-list-title-line-heights: (
    s: rem(18px),
  ) !global;
  $tease-list-article-padding: rem(30px 0 30px) !global;
  $tease-list-list-title-font-size: rem(28px) !global;
}

@include override-tokens-for("telemundoshows") {
  $tease-list-list-border-top: none !global;
  $tease-list-top-decoration-width: 30px !global;
  $tease-list-top-decoration-height: 2px !global;
  $tease-list-list-title-margin: rem(12px 0 20px) !global;
  $tease-list-title-font-size: (
    s: rem(18px),
  ) !global;
  $tease-list-title-line-heights: (
    s: rem(18px),
  ) !global;
  $tease-list-article-padding: rem(30px 0 30px) !global;
  $tease-list-list-title-font-size: rem(28px) !global;
}

@include override-tokens-for("think") {
  $tease-list-title-font-size: (
    s: rem(13.5px),
    m: rem(22.5px),
    l: rem(13.5px),
  ) !global;
  $tease-list-title-line-heights: (
    s: rem(20px),
    m: rem(30px),
    l: rem(20px),
  ) !global;
}

@include override-tokens-for("today") {
  $tease-list-title-color: $purple-70 !global;
  $tease-list-paid-color: $neutral-10 !global;
  $tease-list-title-font-family: $publico-light-font-family !global;
  $tease-list-title-font-size: (
    s: rem(16px),
  ) !global;
  $tease-list-title-line-heights: (
    s: rem(20px),
  ) !global;
  $tease-list-list-title-font-family: $publico-light-italic-font-family !global;
  $tease-list-list-title-font-style: italic !global;
  $tease-list-list-title-line-height: $leading-125 !global;
  $tease-list-list-border-top: none !global;
}

@include override-tokens-for("knowyourvalue") {
  $tease-list-paid-color: $neutral-10 !global;
  $tease-list-title-font-size: (
    s: rem(13.5px),
    m: rem(22.5px),
    l: rem(13.5px),
  ) !global;
  $tease-list-title-line-heights: (
    s: rem(20px),
    m: rem(30px),
    l: rem(20px),
  ) !global;
}

@include override-tokens-for("globalcitizen") {
  $tease-list-see-all-button-color: $red-40 !global;
  $tease-list-see-all-button-border-color: $red-40 !global;
  $tease-list-top-decoration-background-color: $red-40 !global;
  $tease-list-brand-dot-color: $red-40 !global;
  $tease-list-eyebrow-color: $red-40 !global;
}

@include override-tokens-for("leftfield") {
  $tease-list-see-all-button-color: $grey-50 !global;
  $tease-list-see-all-button-border-color: $grey-50 !global;
  $tease-list-top-decoration-background-color: $grey-50 !global;
  $tease-list-brand-dot-color: $grey-50 !global;
  $tease-list-eyebrow-color: $grey-50 !global;
}
