$bacon-cards-onebyone-product-type-icon-width: 32px !default;
$bacon-cards-onebyone-product-headline-font-size-publico-brands: 18px !default;
$bacon-cards-onebyone-product-headline-line-height-publico-brands: 22.5px !default;
$bacon-cards-onebyone-product-headline-font-size-non-publico-brands: 24px !default;
$bacon-cards-onebyone-product-headline-number-of-lines-to-display: 3 !default;
$bacon-cards-onebyone-product-description-font-size: 12px !default;
$bacon-cards-onebyone-product-description-line-height: 1.5 !default;
$bacon-cards-onebyone-product-description-number-of-lines-to-display: 2 !default;
$bacon-cards-onebyone-icon-font-size: 1rem !default;

$bacon-cards-onebyone-product-slideshow-unibrow-width: 96px !default;
$bacon-cards-onebyone-product-slideshow-type-icon-max-width: $bacon-cards-onebyone-product-slideshow-unibrow-width + $bacon-cards-onebyone-product-type-icon-width !default;
$bacon-cards-onebyone-product-slideshow-type-icon-max-height: $bacon-cards-onebyone-product-slideshow-type-icon-max-width / 2 !default;
$bacon-cards-onebyone-product-slideshow-type-icon-scaleX: $bacon-cards-onebyone-product-type-icon-width / $bacon-cards-onebyone-product-slideshow-type-icon-max-width !default;
$bacon-cards-onebyone-product-slideshow-type-icon-scaleY: $bacon-cards-onebyone-product-type-icon-width / $bacon-cards-onebyone-product-slideshow-type-icon-max-height !default;
$bacon-cards-onebyone-product-slideshow-type-icon-min-width: $bacon-cards-onebyone-product-slideshow-type-icon-max-width * $bacon-cards-onebyone-product-slideshow-type-icon-scaleX !default;

$bacon-cards-onebyone-product-video-unibrow-width: 68px !default;
$bacon-cards-onebyone-product-video-type-icon-max-width: $bacon-cards-onebyone-product-video-unibrow-width + $bacon-cards-onebyone-product-type-icon-width !default;
$bacon-cards-onebyone-product-video-type-icon-max-height: $bacon-cards-onebyone-product-video-type-icon-max-width / 2 !default;
$bacon-cards-onebyone-product-video-type-icon-scaleX: $bacon-cards-onebyone-product-type-icon-width / $bacon-cards-onebyone-product-video-type-icon-max-width !default;
$bacon-cards-onebyone-product-video-type-icon-scaleY: $bacon-cards-onebyone-product-type-icon-width / $bacon-cards-onebyone-product-video-type-icon-max-height !default;
$bacon-cards-onebyone-product-video-type-icon-min-width: $bacon-cards-onebyone-product-video-type-icon-max-width * $bacon-cards-onebyone-product-video-type-icon-scaleX !default;

$bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(16, 32, 57, 0) 49.87%, rgba(16, 32, 57, 0.6) 100%) !default;
$bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(48, 97, 255, 0.5) 0%, #3061ff 48.96%, #102039 100%) !default;
$bacon-cards-twobythree-title-font: $founders-cond !default;
$bacon-cards-twobythree-title-font-style: normal !default;
$bacon-cards-twobythree-title-transform: uppercase !default;
$bacon-cards-twobythree-button-color: $default-color !default;
$bacon-cards-twobythree-overlay-border-color: $default-color !default;

$bacon-cards-twobyone-type-icon-width: 30px !default;
$bacon-cards-twobyone-type-icon-height: 32px !default;
$bacon-cards-twobyone-slideshow-unibrow-width: 96px !default;
$bacon-cards-twobyone-unibrow-font-color: $default-color !default;
$bacon-cards-twobyone-eyebrow-font-weight: 100 !default;
$bacon-cards-twobyone-eyebrow-color: $grey-60 !default;
$bacon-cards-twobyone-video-unibrow-width: 68px !default;
$bacon-cards-twobyone-icon-bg: $default-color !default;
$bacon-cards-twobyone-header-link-color: $grey-70 !default;
$bacon-cards-twobyone-header-link-font-family: $founders-cond !default;
$bacon-cards-twobyone-header-link-font-size: rem($text-18) !default;
$bacon-cards-twobyone-header-link-font-lh: $leading-100 !default;
$bacon-cards-twobyone-header-link-font-weight: $normal !default;

@include override-tokens-for('today') {
  $bacon-cards-onebyone-icon-font-size: 0.75rem !global;
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(56, 5, 56, 0) 49.87%, rgba(56, 5, 56, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(255, 80, 60, 0.5) 0%, #ff503c 50%, #380538 100%) !global;
  $bacon-cards-twobythree-title-font: $publico-hed !global;
  $bacon-cards-twobythree-title-font-style: italic !global;
  $bacon-cards-twobythree-title-transform: none !global;
  $bacon-cards-twobythree-title-style: italic !global;
  $bacon-cards-twobyone-header-link-color: $purple-70 !global;
  $bacon-cards-twobyone-header-link-font-family: $publico-hed !global;
  $bacon-cards-twobyone-header-link-font-size: rem(14px) !global;
  $bacon-cards-twobyone-header-link-font-lh: 1.25 !global;
}

@include override-tokens-for('mach') {
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.87%, rgba(0, 0, 0, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(206, 30, 108, 0.5) 0%, #5c00d8 48.96%, #5c00d8 100%) !global;
}

@include override-tokens-for('think') {
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.87%, rgba(0, 0, 0, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(255, 80, 66, 0.5) 0%, #ff5042 50%, #380538 100%) !global;
  $bacon-cards-twobythree-title-font: $publico-hed !global;
  $bacon-cards-twobythree-title-font-style: italic !global;
  $bacon-cards-twobythree-title-transform: none !global;
  $bacon-cards-twobythree-title-style: italic !global;
  $bacon-cards-twobyone-header-link-font-family: $publico-hed !global;
  $bacon-cards-twobyone-header-link-font-size: rem($text-14) !global;
  $bacon-cards-twobyone-header-link-font-lh: $leading-125 !global;
}

@include override-tokens-for('globalcitizen') {
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.87%, rgba(0, 0, 0, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(182, 0, 26, 0.5) 0%, #b6001a 48.96%, #b2ccce 100%) !global;
  $bacon-cards-twobyone-icon-bg: $red-40 !global;
}

@include override-tokens-for('leftfield') {
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.87%, rgba(0, 0, 0, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(189, 195, 199, 0.5) 0%, #bdc3c7 48.96%, #000 100%) !global;
  $bacon-cards-twobyone-icon-bg: $grey-50 !global;
}

@include override-tokens-for('knowyourvalue') {
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.87%, rgba(0, 0, 0, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(246, 163, 131, 0.5) 0%, #f6a383 48.96%, #bdb4c9 100%) !global;
  $bacon-cards-twobyone-header-link-font-family: $publico-hed !global;
  $bacon-cards-twobyone-header-link-font-size: rem($text-14) !global;
  $bacon-cards-twobyone-header-link-font-lh: $leading-125 !global;
}

@include override-tokens-for('msnbc') {
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(19, 82, 112, 0) 49.87%, rgba(19, 82, 112, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(81, 241, 226, 0.5) 0%, #51f1e2 48.96%, #135270 100%) !global;
}

@include override-tokens-for('better') {
  $bacon-cards-twobythree-gradient: linear-gradient(180deg, rgba(42, 5, 71, 0) 49.87%, rgba(42, 5, 71, 0.6) 100%) !global;
  $bacon-cards-twobythree-additional-gradient: linear-gradient(146.31deg, rgba(33, 209, 170, 0.5) 0%, #21d19e 48.96%, #2a0547 100%) !global;
}

@include override-tokens-for('noticias') {
  $bacon-cards-twobythree-gradient: rgba(35, 40, 60, 0.8) !global;
  $bacon-cards-twobythree-additional-gradient: rgba(35, 40, 60, 0.8) !global;
}

@include override-tokens-for('telemundo') {
  $bacon-cards-twobyone-header-link-color: $blue-60 !global;
}

@include override-tokens-for('telemundoentretenimiento') {
  $bacon-cards-twobyone-header-link-color: $blue-60 !global;
}

@include override-tokens-for('telemundoshows') {
  $bacon-cards-twobyone-header-link-color: $blue-60 !global;
}
