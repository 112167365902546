.rail__main {
  @include breakpoint-l {
    position: relative;
  }
}

.rail__container {
  @include breakpoint-l {
    display: flex;
    width: 100%;
  }
}

.rail__wrapper {
  @include breakpoint-l {
    position: absolute; // to clip height
    width: 100%;
    height: 100%;
  }
}

.rail__content {
  @include breakpoint-l {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > * {
      flex: 0 0 auto;
    }
  }

  > * {
    @include package-spacing;
  }

  .ad-container {
    @include breakpoint-l {
      margin-bottom: 0;
    }
  }

  // Packages after collection title use package spacing.
  .has-title & > :nth-child(n+2) {
    @include breakpoint-l {
      @include package-spacing;
    }
  }
}
