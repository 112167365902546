.sidebar-tease__container {
  display: flex;
  align-items: center;
}

.sidebar-tease__picture {
  flex: 0 0 rem(80px);
  border-radius: 50%;
  overflow: hidden;

  @include breakpoint-x {
    flex-basis: rem(120px);
  }
}

.sidebar-tease__info {
  margin-left: rem(20px);
  flex-grow: 1;
}

.sidebar-tease__category {
  color: $sidebar-tease-category-color;
}

.sidebar-tease__title {
  color: $sidebar-tease-title-color;
  font-family: $sidebar-tease-title-font-family;
  font-size: rem(16px);
  line-height: rem(20px);
  margin: 0;

  @include breakpoint-x {
    font-size: rem(18px);
    line-height: rem(20px);
  }
}

.sidebar-tease__sponsored {
  .sidebar-tease__picture,
  .sidebar-tease__title {
    color: $white;
  }
}
