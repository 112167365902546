.error-message {
  display: flex;
  flex-direction: column;

  @if $error-message-video-is-inline == true {
    background: linear-gradient(180deg, $error-message-inline-background 0%, $error-message-inline-background 35%, $white 35%);
  }

  &__code {
    position: relative;
    z-index: 1;
    margin: 0;
    font-family: $error-message-code-font-family;
    font-size: $error-message-code-font-size;
    color: $white;
    text-align: center;

    @if $error-message-video-is-inline == true {
      height: $space-40;

      @include breakpoint-l {
        font-size: $error-message-code-font-size * 2;
      }

      &::after {
        @include pattern-dots($error-message-decorative-pattern-color);
        content: '';
        position: absolute;
        width: rem($space-80);
        height: rem($space-40);
        bottom: 0;
        right: 0;
        top: rem($space-60);

        @include breakpoint-m {
          right: rem($space-100);
        }

        @include breakpoint-l {
          right: rem($space-196);
        }
      }
    }

    @if $error-message-video-is-full-screen == true {
      height: 0;

      @include breakpoint-l {
        font-size: $error-message-code-font-size * 1.5;
      }
    }

    span {
      position: relative;
      top: 35px;

      @include breakpoint-l {
        top: -28px;
      }
    }
  }

  &__background {
    position: relative;
    background-color: $white;
    overflow: hidden;

    @if $error-message-video-is-full-screen == true {
      height: rem(380px);
    }

    @if $error-message-video-is-inline == true {
      height: rem(160px);

      @include breakpoint-m {
        height: rem(380px);
      }
    }

    @include breakpoint-l {
      height: rem(600px);
    }

    @if $error-message-has-background-image == true {
      background-image: $error-message-background-image;
      background-size: cover;
    }

    @if $error-message-video-has-gradient == true {
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: linear-gradient($error-message-video-gradient-angle, $error-message-video-gradient-start, $error-message-video-gradient-end);
      }
    }

    @if $error-message-video-is-inline == true {
      margin-top: rem($space-60);

      @include breakpoint-m {
        margin-top: 0;
        margin-left: $space-12;

        &::after {
          content: '';
          position: absolute;
          display: block;
          right: 0;
          height: rem($space-60);
          background-color: $error-message-inline-background;
          top: 0;
          width: calc(100% - 200px);
        }
      }
    }

    @if $error-message-video-is-full-screen == true {
      margin-top: rem(-$space-80);
    }
  }

  &__headline__container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;

    @if $error-message-video-is-full-screen == true {
      justify-content: center;
    }
  }

  &__headline {
    font-family: $error-message-headline-font-family;
    font-size: rem($error-message-headline-font-size);
    line-height: 65%;
    margin: $space-24 $space-32;
    color: $white;
    align-self: center;
    text-transform: uppercase;

    @include breakpoint-m {
      font-size: rem($error-message-headline-font-size * 2);
    }

    @include breakpoint-l {
      font-size: rem($error-message-headline-font-size * 2.5);
      margin-left: $space-80;
      margin-right: $space-80;
    }

    @include breakpoint-x {
      font-size: rem($error-message-headline-font-size * 3.5);
    }
  }

  &__text {
    font-family: $publico-txt;
    line-height: $leading-150;
    padding: rem($space-48) rem($space-16);
    width: 100%;
    align-self: flex-start;
    font-size: $text-18;
    background-color: $white;
    position: relative;
    z-index: 1;
    color: $grey-70;

    @include breakpoint-m {
      max-width: rem(540px);
      margin-top: rem(-$space-64);
      align-self: flex-end;
    }

    &--branded {
      color: $error-message-text-brand-color;
    }

    a {
      &:hover {
        opacity: $default-hover-opacity;
      }

      &:active {
        opacity: $default-active-opacity;
      }
    }
  }
}
