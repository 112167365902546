// Both of these numbers are ~*magic*~ numbers to some extent. For 2 item support, text containers with the dek and headline that are 459px+, we want to sit side-by-side or horizontally, otherwise they stack. For 4 item support, containers that 699px+ (originally 700px, but StraightUps at 1240px in a rail are just one px short) will want RC to sit horizontally.
// TLDR: 459 / 2 = 229.5px, 699 / 4 - 40px (gutters) = 134px
$two-item-flex-basis: 229.5px;
$four-item-horizontal-flex-basis: 134px;
$four-item-vertical-flex-basis: 100%;
$offset-item-negative-mb: rem(17px);

.related-content {
  position: relative;
  z-index: 1;

  &.related-content__parent-no-img {
    padding-left: rem(20px);
    padding-right: rem(20px);
    margin-left: rem(20px);
  }

  .related-content__eyebrow {
    margin-top: 0;
    margin-bottom: 0;

    &-link {
      display: block;
      color: $related-content-opinion-eyebrow-link-color;
      font-size: rem(12px);
      font-weight: 400;
      font-family: $related-content-opinion-eyebrow-link-font;
      text-transform: uppercase;
      padding-left: rem(20px);
      margin-bottom: rem(8px);
    }
  }

  .related-content__headline {
    margin-top: 0;
    margin-bottom: 0;

    &-link {
      display: block;
      color: $related-content-headline-link-color;
      font-size: 1rem;
      line-height: $related-content-headline-link-line-height;
      font-family: $related-content-headline-link-font;
      font-weight: $related-content-headline-link-font-weight;
      padding-left: rem(20px);
    }
  }

  .related-content__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;
  }

  .related-content__item {
    flex-basis: $two-item-flex-basis;
    flex-grow: 1;
    background-color: $related-content-item-bg-color;
    margin-bottom: rem(-17px);
    margin-left: rem(40px);
    padding: rem(16px 0 0 0);

    &.related-content__item-featured {
      // 40px gutters
      @include breakpoint-m {
        flex-basis: $four-item-horizontal-flex-basis;
      }

      &.related-content__item-featured--one-up {
        @include breakpoint-m {
          margin-bottom: $offset-item-negative-mb;
        }

        &--rail-adjacent {
          @include breakpoint-l {
            flex-basis: $four-item-vertical-flex-basis;
          }

          @include breakpoint-x {
            flex-basis: $four-item-horizontal-flex-basis;
            margin-bottom: $offset-item-negative-mb;
          }
        }
      }

      &.related-content__item-featured--standard-lead {
        @include breakpoint-m {
          flex-basis: $four-item-vertical-flex-basis;
        }

        @include breakpoint-l {
          flex-basis: $four-item-horizontal-flex-basis;
          margin-bottom: $offset-item-negative-mb;
        }

        &--rail-adjacent {
          @include breakpoint-m {
            flex-basis: $four-item-vertical-flex-basis;
          }

          @include breakpoint-x {
            flex-basis: $four-item-horizontal-flex-basis;
            margin-bottom: $offset-item-negative-mb;
          }
        }
      }

      &.related-content__item-featured--straight-up {
        @include breakpoint-l {
          flex-basis: $four-item-vertical-flex-basis;
        }

        &--rail-adjacent {
          @include breakpoint-l {
            flex-basis: $four-item-vertical-flex-basis;
          }

          @include breakpoint-x {
            flex-basis: $four-item-horizontal-flex-basis;
            margin-bottom: $offset-item-negative-mb;
          }
        }
      }
    }

    &.related-content__item--lead-section-front {
      @include breakpoint-x {
        margin-bottom: $offset-item-negative-mb;
      }
    }

    &:nth-child(1) {
      z-index: 3;
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      z-index: 1;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    &::before {
      content: "";
      display: block;
      width: 50%;
      height: 1px;
      border-width: 0;
      border-top-style: solid;
      border-top-width: 1px;
      border-color: $grey-40;
      margin-bottom: rem(16px);
      max-width: 229.5px;
    }
  }

  .related-content__icon {
    align-items: flex-start;
    background: transparent;
    bottom: auto;
    color: $related-content-icon-color;
    font-size: 8px;
    justify-content: flex-start;
    left: auto;
    margin-top: 6px;
    position: absolute;

    &.related-content__icon-opinion {
      color: $think-red-40;
      margin-top: rem(4px);
    }

    &.related-content__icon-gallery {
      font-size: 10px;
    }
  }
}
